import React, { useEffect, useState } from "react";
import "rc-steps/assets/index.css";
import AsyncSelect from "react-select/async";
import api from "../../api";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";
import SelectionTable from "../../components/tables/SelectionTable";
import Wizards from "../../components/wizards/Wizards";
import Schedule from "./Schedule";
import PreventTransitionPrompt from "./PreventTransitionPrompt";
import { customHistory } from "../../utils/history";
import CustomOptions from "../task/CustomOptions";
import { Helmet } from "react-helmet-async";
import {
  colourStyles,
  colourStylesLight,
  getIcon,
} from "../../utils/staticMethods";
import useAuth from "../../hooks/useAuth";
import { THEME } from "../../constants";
import useTheme from "../../hooks/useTheme";
import useSrlHelper from "../../hooks/useSrlHelper";

const peopleColumn = [
  {
    Header: "Name",
    accessor: "compoundName",
    link: "/people/edit",
    stateId: "id",
    type: "link",
  },
  {
    Header: " ",
    accessor: "",
    disableSortBy: true,
  },
];

const groupColumn = [
  {
    Header: "Name",
    accessor: "groupName",
    link: "/contactgroup/edit",
    stateId: "id",
    type: "link",
  },
  {
    Header: " ",
    accessor: "",
    disableSortBy: true,
  },
];

const resetOption = [
  { value: 10, name: "Automatically re-arm after 10 seconds" },
  { value: 60, name: "Automatically re-arm after 1 min" },
  { value: 120, name: "Automatically re-arm after 2 mins" },
  { value: 300, name: "Automatically re-arm after 5 mins" },
  { value: 600, name: "Automatically re-arm after 10 mins" },
  { value: 1800, name: "Automatically re-arm after 30 mins" },
  { value: 3600, name: "Automatically re-arm after 1 hour" },
];
let currentData = null;
let deleteThis = true;
const CreateWizard = (props) => {
  const { theme } = useTheme();
  const [current, setCurrent] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const [ruleName, setRuleName] = useState("");
  const [status, setStatus] = useState([
    "process",
    "wait",
    "wait",
    "wait",
    "wait",
    "wait",
  ]);
  const [upperThreshold, setUpperThreshold] = useState("");
  const [lowerThreshold, setLowerThreshold] = useState("");
  const [zoneId, setZoneId] = useState("any");
  const [zoneName, setZoneName] = useState("Any Zone");
  const [curData, setData] = useState(null);
  const [nextClicked, setClickNext] = useState(false);
  const [finish, setFinish] = useState(false);
  const location = useLocation();
  const { user } = useAuth();
  const { modalType, id, link, linkId } = queryString.parse(location.search);
  const { getAssetColumnsForSelectionTable } = useSrlHelper();
  const [assetColumn] = useState(getAssetColumnsForSelectionTable());

  const [steps, setSteps] = useState([
    {
      name: "Name this Rule",
      type: "read",
      id: 0,
    },
    {
      name: "Choose a Speed Limit",
      type: "read",
      id: 1,
    },
    {
      name: "Select Contact Groups",
      type: "read",
      id: 2,
    },
    {
      name: "Associate Assets",
      type: "read",
      id: 3,
    },
    {
      name: "Associate People",
      type: "read",
      id: 4,
    },
  ]);

  const zonePromiseOptions = (inputValue) =>
    new Promise((resolve) => {
      api.get("zones?filter=" + inputValue).then((res) => {
        let arr = res.data;
        if (inputValue === "")
          arr.push({ id: "any", compoundName: "Any Zone" });
        resolve(formatResponse(arr, "zone"));
      });
    });

  const formatResponse = (list, type) => {
    let arr = list.map((item) => {
      return {
        value: item.id,
        label: item.compoundName,
        reference: item.reference,
        icon: type,
      };
    });
    return arr;
  };

  useEffect(() => {
    if (id) {
      api
        .get(`rules/${id}`)
        .then((res) => {
          updateCurrent(res.data);
        })
        .catch((err) => {
          window.history.pushState({}, "", `?modalType=${modalType}`);
        });
    }
  }, [id]);

  useEffect(() => {
    if (finish) {
      deleteThis = false;
      if (link && linkId) {
        customHistory.push(`/${link}/edit?id=${linkId}&direct=rules`);
      } else {
        customHistory.push(`/rules/edit?id=${curData.id}`);
      }
      return;
    }
    deleteThis = true;
  }, [finish]);

  useEffect(() => {
    currentData = curData;
  }, [curData]);

  const getTypeText = () => {
    if (modalType === "speeding") return "Choose a Speed Limit";
    if (modalType === "lowbattery") return "Choose a Battery Threshold";
    if (modalType === "lowvoltage") return "Choose a Voltage Threshold";
    if (
      modalType === "exitzone" ||
      modalType === "enterzone" ||
      modalType === "zonesupervision"
    )
      return "Select a Zone";
    if (modalType === "attendance" || modalType === "motion") return "Schedule";
  };

  useEffect(() => {
    setSteps((prevState) => {
      let arr = prevState.concat([]);
      let filter = arr.filter((item) => item.id === 1)[0];
      filter.name = getTypeText();
      return arr;
    });
    api.get("contactgroups?page=1&limit=10").then((res) => {
      if (Number(res.headers["x-total-count"]) === 0) {
        setSteps((prevState) => {
          let arr = prevState.concat([]);
          let filter = arr.filter((item) => item.id === 1)[0];
          filter.name = getTypeText();
          let filter2 = arr.filter((item) => item.id === 2)[0];
          filter2.type = "create";
          return arr;
        });
      }
    });
    api.get("assets?page=1&limit=10").then((res) => {
      if (Number(res.headers["x-total-count"]) === 0) {
        setSteps((prevState) => {
          let arr = prevState.concat([]);
          let filter = arr.filter((item) => item.id === 1)[0];
          filter.name = getTypeText();
          let filter2 = arr.filter((item) => item.id === 3)[0];
          filter2.type = "create";
          return arr;
        });
      }
    });
    api.get("people?page=1&limit=10").then((res) => {
      if (Number(res.headers["x-total-count"]) === 0) {
        setSteps((prevState) => {
          let arr = prevState.concat([]);
          let filter = arr.filter((item) => item.id === 1)[0];
          filter.name = getTypeText();
          let filter2 = arr.filter((item) => item.id === 4)[0];
          filter2.type = "create";
          return arr;
        });
      }
    });
    if (
      modalType === "exitzone" ||
      modalType === "enterzone" ||
      modalType === "zonesupervision" ||
      modalType === "speeding" ||
      modalType === "lowbattery" ||
      modalType === "lowvoltage"
    ) {
      setSteps((prevState) => {
        let arr = prevState.concat([]);
        arr.splice(2, 0, {
          name: "Schedule",
          type: "read",
          id: 5,
        });
        arr[1].name = getTypeText();
        return arr;
      });
    }
    if (modalType === "attendance" || modalType === "motion") {
      setSteps((prevState) => {
        let arr = prevState.concat([]);
        let filter = arr.filter((item) => item.id === 1)[0];
        filter.name = getTypeText();
        return arr;
      });
    }
    return () => {
      deleteThis && curData && api.delete(`rules/${curData.id}`);
      deleteThis && currentData && api.delete(`rules/${currentData.id}`);
      currentData = null;
      deleteThis = true;
    };
  }, []);

  useEffect(() => {
    if (current > maxStep) {
      setMaxStep(current);
      let arr = status.concat([]);
      let count = arr.length;
      for (let i = 0; i < count; i++) {
        if (i < current) {
          arr[i] = "done";
        }
        if (i === current) {
          arr[i] = "active";
        }
        if (i > current) {
          arr[i] = "wait";
        }
      }
      setStatus(arr);
    } else {
      let arr = status.concat([]);
      let count = arr.length;
      for (let i = 0; i < count; i++) {
        if (i <= maxStep) {
          arr[i] = "done";
        }
        if (i === current) {
          arr[i] = "active";
        }
        if (i > maxStep) {
          arr[i] = "wait";
        }
      }
      setStatus(arr);
    }
  }, [current]);

  const onCancelCreate = () => {
    setFinish(false);
    if (link && linkId) {
      customHistory.push(`/${link}/edit?id=${linkId}&direct=rules`);
    } else {
      customHistory.push("/rules/create");
    }
  };

  const updateCurrent = (data) => {
    let me = JSON.parse(JSON.stringify(data));
    me.zoneId = data?.zoneId ? data.zoneId : "any";
    setData(me);
    setRuleName(data?.ruleName);
    setUpperThreshold(data?.upperThreshold ? data.upperThreshold : "");
    setLowerThreshold(data?.lowerThreshold ? data.lowerThreshold : "");
    setZoneId(data?.zoneId ? data.zoneId : "any");
  };

  const goNext = () => {
    setFinish(false);
    if (current < 1) {
      if (ruleName.length === 0) {
        setClickNext(true);
        return;
      } else {
        setClickNext(false);
      }
      if (modalType === "attendance" || modalType === "motion") {
        let data = {
          ruleName: ruleName,
          type: modalType,
        };
        if (!curData) {
          api.post("rules", data).then((res) => {
            if (link && linkId) {
              api
                .post(`rules/${res.data.id}/${link}/link/${linkId}`)
                .then(() => {
                  updateCurrent(res.data);
                  setCurrent((prevState) => ++prevState);
                  window.history.pushState(
                    {},
                    "",
                    `?modalType=${modalType}&id=${res.data.id}&link=${link}&linkId=${linkId}`
                  );
                  return;
                });
            } else {
              updateCurrent(res.data);
              setCurrent((prevState) => ++prevState);
              window.history.pushState(
                {},
                "",
                `?modalType=${modalType}&id=${res.data.id}`
              );
              return;
            }
          });
        } else if (curData.ruleName !== ruleName) {
          let data = JSON.parse(JSON.stringify(curData));
          data.ruleName = ruleName;
          data.zoneId = zoneId === "any" ? null : zoneId;
          api.put("rules", data).then((res) => {
            updateCurrent(res.data);
            setCurrent((prevState) => ++prevState);
            return;
          });
        } else {
          setCurrent((prevState) => ++prevState);
          return;
        }
      } else {
        setCurrent((prevState) => ++prevState);
        return;
      }
      return;
    }
    if (current === 1) {
      let data = {
        ruleName: ruleName,
        type: modalType,
      };
      if (modalType === "speeding") {
        if (upperThreshold.length === 0 || !Number(upperThreshold)) {
          setClickNext(true);
          return;
        } else {
          setClickNext(false);
        }
        data.upperThreshold = upperThreshold;
      }
      if (
        modalType === "enterzone" ||
        modalType === "exitzone" ||
        modalType === "motion" ||
        modalType === "zonesupervision"
      ) {
        data.zoneId = zoneId === "any" ? null : zoneId;
      }
      if (modalType === "lowbattery" || modalType === "lowvoltage") {
        if (lowerThreshold.length === 0 || !Number(lowerThreshold)) {
          setClickNext(true);
          return;
        } else {
          setClickNext(false);
        }
        data.lowerThreshold = lowerThreshold;
      }
      if (!curData) {
        api.post("rules", data).then((res) => {
          if (link && linkId) {
            api.post(`rules/${res.data.id}/${link}/link/${linkId}`).then(() => {
              updateCurrent(res.data);
              setCurrent((prevState) => ++prevState);
              window.history.pushState(
                {},
                "",
                `?modalType=${modalType}&id=${res.data.id}&link=${link}&linkId=${linkId}`
              );
              return;
            });
          } else {
            updateCurrent(res.data);
            setCurrent((prevState) => ++prevState);
            window.history.pushState(
              {},
              "",
              `?modalType=${modalType}&id=${res.data.id}`
            );
            return;
          }
        });
      } else if (
        curData.ruleName !== ruleName ||
        Number(curData.upperThreshold) !== Number(upperThreshold) ||
        Number(curData.lowerThreshold) !== Number(lowerThreshold) ||
        curData.zoneId !== zoneId
      ) {
        let data1 = JSON.parse(JSON.stringify(curData));
        data1.ruleName = ruleName;
        data1.upperThreshold = upperThreshold ? upperThreshold : null;
        data1.lowerThreshold = lowerThreshold ? lowerThreshold : null;
        data1.zoneId = zoneId === "any" ? null : zoneId;
        api.put("rules", data1).then((res) => {
          updateCurrent(res.data);
          setCurrent((prevState) => ++prevState);
          return;
        });
      } else {
        setCurrent((prevState) => ++prevState);
        return;
      }
    }
    if (current >= 2 && current < steps.length - 1) {
      setCurrent((prevState) => ++prevState);
      return;
    }
    if (current === steps.length - 1) {
      setFinish(true);
      return;
    }
  };

  const goBack = (index) => {
    setFinish(false);
    if (
      (current <= 1 && index > current) ||
      (current === 1 &&
        index > 1 &&
        (modalType === "attendance" || modalType === "motion"))
    ) {
      let data = {
        ruleName: ruleName,
        type: modalType,
      };
      if (modalType === "attendance" || modalType === "motion") {
        if (ruleName.length === 0) {
          setClickNext(true);
          if (ruleName.length > 0 && current === 0 && index > 0) {
            setClickNext(false);
          }
          return;
        } else {
          setClickNext(false);
        }
      }
      if (modalType === "speeding") {
        if (
          ruleName.length === 0 ||
          upperThreshold.length === 0 ||
          !Number(upperThreshold)
        ) {
          setClickNext(true);
          if (ruleName.length > 0 && current === 0 && index === 1) {
            setCurrent(index);
          }
          return;
        } else {
          setClickNext(false);
        }
        data.upperThreshold = upperThreshold;
      }
      if (
        modalType === "enterzone" ||
        modalType === "exitzone" ||
        modalType === "motion" ||
        modalType === "zonesupervision"
      ) {
        if (ruleName.length === 0) {
          setClickNext(true);
          if (ruleName.length > 0 && current === 0 && index === 1) {
            setCurrent(index);
          }
          return;
        } else {
          data.zoneId = zoneId === "any" ? null : zoneId;
          setClickNext(false);
        }
      }
      if (modalType === "lowvoltage" || modalType === "lowbattery") {
        if (
          ruleName.length === 0 ||
          lowerThreshold.length === 0 ||
          !Number(lowerThreshold)
        ) {
          setClickNext(true);
          if (ruleName.length > 0 && current === 0 && index === 1) {
            setCurrent(index);
          }
          return;
        } else {
          setClickNext(false);
        }
        data.lowerThreshold = lowerThreshold;
      }
      if (!curData) {
        api.post("rules", data).then((res) => {
          if (link && linkId) {
            api.post(`rules/${res.data.id}/${link}/link/${linkId}`).then(() => {
              updateCurrent(res.data);
              setCurrent(index);
              window.history.pushState(
                {},
                "",
                `?modalType=${modalType}&id=${res.data.id}&link=${link}&linkId=${linkId}`
              );
              return;
            });
          } else {
            updateCurrent(res.data);
            setCurrent(index);
            window.history.pushState(
              {},
              "",
              `?modalType=${modalType}&id=${res.data.id}`
            );
            return;
          }
        });
      } else if (
        curData.ruleName !== ruleName ||
        Number(curData.upperThreshold) !== Number(upperThreshold) ||
        Number(curData.lowerThreshold) !== Number(lowerThreshold) ||
        curData.zoneId !== zoneId
      ) {
        let data1 = JSON.parse(JSON.stringify(curData));
        data1.ruleName = ruleName;
        data1.upperThreshold = upperThreshold ? upperThreshold : null;
        data1.lowerThreshold = lowerThreshold ? lowerThreshold : null;
        data1.zoneId = zoneId === "any" ? null : zoneId;
        api
          .put("rules", data1)
          .then((res) => {
            updateCurrent(res.data);
            setCurrent(index);
            return;
          })
          .catch(() => {
            return;
          });
      } else {
        setCurrent(index);
        return;
      }
    }
    setCurrent(index);
  };

  const needUpdate = () => {
    api.get(`rules/${curData.id}`).then((res) => {
      updateCurrent(res.data);
    });
  };

  const onCheckChanged = (e, value) => {
    const obj = JSON.parse(JSON.stringify(curData));
    obj[value] = e.target.checked;
    obj.zoneId = zoneId === "any" ? null : zoneId;
    api.put(`rules`, obj).then((res) => {
      updateCurrent(res.data);
    });
  };

  const changeReset = (e) => {
    const obj = JSON.parse(JSON.stringify(curData));
    obj.resetInterval = e.target.value;
    obj.zoneId = zoneId === "any" ? null : zoneId;
    api.put(`rules`, obj).then((res) => {
      updateCurrent(res.data);
    });
  };

  const radioChange = (name) => {
    const obj = JSON.parse(JSON.stringify(curData));
    obj.status = name;
    obj.zoneId = zoneId === "any" ? null : zoneId;
    api.put(`rules`, obj).then((res) => {
      updateCurrent(res.data);
    });
  };

  const getRuleName = (type) => {
    if (type === "attendance") {
      return "Attendance";
    } else if (type === "exitzone") {
      return "Zone Exit";
    } else if (type === "enterzone") {
      return "Zone Entry";
    } else if (type === "lowbattery") {
      return "Low Battery";
    } else if (type === "lowvoltage") {
      return "Low Voltage";
    } else if (type === "motion") {
      return "Motion Alarm";
    } else if (type === "speeding") {
      return "Speeding";
    } else if (type === "pausetracking") {
      return "Pause Tracking";
    } else if (type === "zonesupervision") {
      return "Unsupervised Zones";
    }
    return null;
  };

  return user?.createRules ? (
    <React.Fragment>
      <Helmet defer={false} title={getRuleName(modalType)} />
      <h1 className="h3 mb-3">
        {getIcon(modalType)} {getRuleName(modalType)}
      </h1>
      <PreventTransitionPrompt finish={finish} />
      <Wizards
        steps={steps}
        current={current}
        status={status}
        goBack={goBack}
      />
      {current < 2 && (
        <div>
          {current === 0 && (
            <div>
              <Form.Group className="mt-4 mb-4">
                <div className="mb-4">
                  The first step is to choose a name for this new rule. The name
                  will be used in alerts generated by the rule, and will allow
                  you to easily find this rule if you need to edit it in the
                  future.
                </div>
                <Form.Label>Rule Name</Form.Label>
                <Form.Control
                  type="text"
                  className="no-background-input"
                  name="ruleName"
                  value={ruleName}
                  onChange={(e) => setRuleName(e.target.value)}
                />
                {ruleName.length === 0 && nextClicked && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {"Rule Name is required"}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          )}

          {modalType === "speeding" && current === 1 && (
            <div>
              <Form.Group className="mb-4 mt-4">
                <div className="mb-4">
                  Each time an asset or person equipped with a tracker exceeds
                  this speed, an alert will be generated by this rule and sent
                  to your chosen contacts.
                </div>
                <Form.Label>High Threshold (mph)</Form.Label>
                <Form.Control
                  type="text"
                  className="no-background-input"
                  name="upperThreshold"
                  value={upperThreshold}
                  onChange={(e) => setUpperThreshold(e.target.value)}
                />
                {upperThreshold.length === 0 && nextClicked && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {"Upper threshold is required"}
                  </Form.Control.Feedback>
                )}
                {upperThreshold.length > 0 &&
                  !Number(upperThreshold) &&
                  nextClicked && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {"Upper threshold should be a number"}
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            </div>
          )}
          {(modalType === "lowbattery" || modalType === "lowvoltage") &&
            current === 1 && (
              <Form.Group className="mb-3">
                <div className="mb-4">
                  {modalType === "lowbattery"
                    ? `Each time an asset or person's tracker or tag falls below this battery level, an alert will be generated and sent to your chosen contacts.`
                    : `Each time an asset's tracker supply voltage falls below this level, an alert will be generated and sent to your chosen contacts.`}
                </div>
                <Form.Label>
                  Low Threshold ({`${modalType === "lowbattery" ? "%" : "mV"}`})
                </Form.Label>
                <Form.Control
                  type="text"
                  className="no-background-input"
                  name="lowerThreshold"
                  value={lowerThreshold}
                  onChange={(e) => setLowerThreshold(e.target.value)}
                />
                {lowerThreshold.length === 0 && nextClicked && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {"Lower threshold is required"}
                  </Form.Control.Feedback>
                )}
                {lowerThreshold.length > 0 &&
                  !Number(lowerThreshold) &&
                  nextClicked && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {"Lower threshold should be a number"}
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            )}
          {(modalType === "exitzone" ||
            modalType === "enterzone" ||
            modalType === "zonesupervision") &&
            current === 1 && (
              <Form.Group className="mb-3">
                <Form.Label>Zone</Form.Label>
                <AsyncSelect
                  components={{ Option: CustomOptions }}
                  onChange={(e) => {
                    setZoneId(e.value);
                    setZoneName(e.label);
                  }}
                  defaultInputValue={zoneName}
                  defaultValue={{ value: zoneId, label: zoneName }}
                  styles={
                    theme === THEME.DARK ? colourStyles : colourStylesLight
                  }
                  defaultOptions
                  loadOptions={zonePromiseOptions}
                />
              </Form.Group>
            )}
        </div>
      )}

      {((current === 2 &&
        curData?.id &&
        (modalType === "exitzone" ||
          modalType === "enterzone" ||
          modalType === "zonesupervision" ||
          modalType === "speeding" ||
          modalType === "lowbattery" ||
          modalType === "lowvoltage")) ||
        ((modalType === "attendance" || modalType === "motion") &&
          current === 1)) && (
        <div>
          {curData?.type !== "attendance" && (
            <>
              <Form.Check
                type="radio"
                label="Trigger at all times"
                checked={curData?.status === "armed" || false}
                onChange={() => radioChange("armed")}
              />
              <Form.Check
                type="radio"
                label="Trigger according to the schedule below"
                checked={curData?.status === "timed" || false}
                onChange={() => radioChange("timed")}
              />
              <Form.Check
                type="radio"
                label="Disable"
                className="mb-3"
                checked={curData?.status === "disarmed" || false}
                onChange={() => radioChange("disarmed")}
              />
            </>
          )}
          {curData?.type === "attendance" && (
            <>
              <Form.Check
                type="radio"
                label="Rule active"
                checked={curData.status === "armed" || false}
                onChange={() => radioChange("armed")}
              />
              <Form.Check
                type="radio"
                label="Rule inactive"
                className="mb-3"
                checked={curData.status === "disarmed" || false}
                onChange={() => radioChange("disarmed")}
              />
            </>
          )}
          {(curData?.type === "motion" ||
            curData?.type === "zonesupervision") && (
            <Form.Select
              defaultValue={curData?.resetInterval}
              className="mb-4"
              onChange={(e) => changeReset(e)}
            >
              {resetOption.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          )}
          <Schedule rule={curData} needUpdate={needUpdate} />
        </div>
      )}

      {((current === 2 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 3 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "read" && (
          <div>
            <div className="mt-4">
              Which contact groups would you like to recieve alerts generated by
              this rule?
            </div>
            <SelectionTable
              noDataWarning={() => {}}
              showSelectionTableDefault
              buttonText="Select a Contact Group"
              apiName="contactgroups"
              type="rules"
              filterId=""
              columns={groupColumn}
              id={curData.id}
              entry={`rules/${curData.id}/contactgroups`}
            />
          </div>
        )}
      {((current === 2 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 3 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "create" && (
          <div className="mt-4 mb-4">
            In order to receive alerts generated by this rule you will need to
            set up a contact group and add it to this rule later.
          </div>
        )}

      {((current === 3 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 4 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "read" && (
          <div>
            <div className="mt-4 mb-4">
              Which assets would you like to associate with this rule?
              Associated assets will trigger alerts based on the rule settings.
            </div>
            {
              <Form.Check
                type="switch"
                label="All Assets"
                className="mt-1"
                checked={curData?.allAssets || false}
                onChange={(e) => onCheckChanged(e, "allAssets")}
              />
            }
            {!curData?.allAssets && (
              <SelectionTable
                noDataWarning={() => {}}
                showSelectionTableDefault
                buttonText="Select an Asset"
                apiName="assets"
                type="rules"
                filterId=""
                columns={assetColumn}
                id={curData.id}
                entry={`rules/${curData.id}/assets`}
              />
            )}
          </div>
        )}
      {((current === 3 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 4 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "create" && (
          <div className="mt-4 mb-4">
            In order to receive alerts generated by this rule you will need to
            set up an asset and add it to this rule later.
          </div>
        )}

      {((current === 4 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 5 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "read" && (
          <div>
            <div className="mt-4 mb-4">
              Which people would you like to associate with this rule?
              Associated people will trigger alerts based on the rule settings.
            </div>
            {
              <Form.Check
                type="switch"
                label="All People"
                className="mt-1"
                checked={curData?.allPeople || false}
                onChange={(e) => onCheckChanged(e, "allPeople")}
              />
            }
            {!curData?.allPeople && (
              <SelectionTable
                showSelectionTableDefault
                buttonText="Select a Person"
                apiName="people"
                type="rules"
                filterId=""
                noDataWarning={() => {}}
                columns={peopleColumn}
                id={curData.id}
                entry={`rules/${curData.id}/people`}
              />
            )}
          </div>
        )}
      {((current === 4 &&
        (modalType === "attendance" || modalType === "motion")) ||
        (current === 5 &&
          modalType !== "attendance" &&
          modalType !== "motion")) &&
        curData?.id &&
        steps[current].type === "create" && (
          <div className="mt-4 mb-4">
            In order to receive alerts generated by this rule you will need to
            set up a person and add it to this rule later.
          </div>
        )}

      <div className="mt-3 d-flex align-items-center justify-content-between">
        <Button variant="danger" onClick={() => onCancelCreate()}>
          Cancel Wizard
        </Button>
        <div>
          {current > 0 && (
            <Button
              className="me-2"
              onClick={() => setCurrent((prevState) => --prevState)}
            >
              Back
            </Button>
          )}
          <Button variant="success" onClick={() => goNext()}>
            {current < steps.length - 1 ? "Next" : "Finish"}
          </Button>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export default CreateWizard;
