import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import { Sun } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";

const DeviceCharger = ({ id, type, data, getChargerReady }) => {
  const [charger, setCharger] = useState([]);
  const [showModal, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [curId, setCurrent] = useState(null);
  const notify = useContext(NotyfContext);
  const childRef = useRef();
  const { user } = useAuth();
  const isUnmount = useRef(false);

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 60,
      disableSortBy: true,
      Cell: (cell) => (
        <Button
          variant={
            cell.row.original.assetId ||
            cell.row.original.personId ||
            cell.row.original.zoneId
              ? "danger"
              : "success"
          }
          onClick={() =>
            onOperate(
              cell.row.original.assetId ||
                cell.row.original.personId ||
                cell.row.original.zoneId
                ? "warn"
                : "edit",
              cell.value,
              cell.row.original
            )
          }
        >
          Link
        </Button>
      ),
    },
    {
      Header: "#Serial",
      accessor: "serial",
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Linked",
      accessor: "elementId",
      type: "linkStatus",
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    if (!id) return;
    setShow(false);
    setCurrent(false);
    getTag();
  }, [id]);

  useEffect(() => {
    return () => {
      isUnmount.current = true;
    };
  }, []);

  const getTag = () => {
    api
      .get(`${type}/solarchargers/${id}`)
      .then((res) => {
        if (isUnmount.current) return;
        setCharger(res.data);
        getChargerReady && getChargerReady(res.data);
      })
      .catch(() => {
        if (isUnmount.current) return;
        setCharger(null);
        getChargerReady && getChargerReady(null);
      });
  };

  const trackerCommand = (id, commandId) => {
    api.post(`solarchargers/${id}/sendcommand/${commandId}`).then((res) => {
      notify.open({
        type: "success",
        message: "Command Sent",
      });
    });
  };

  const trackerLink = () => {
    setShow(true);
  };

  const trackerUnlink = (trackerId) => {
    setCurrent(trackerId);
    setShow(true);
  };

  const onOperate = (name, chooseId, data) => {
    if (charger) {
      api
        .post(`solarchargers/${charger.id}/unlink/${id}`, {
          params: { parentId: id, id: charger.id },
        })
        .then((res) => {
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
          getTag();
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
        });
      setCurrent(null);
    } else {
      if (name === "edit") {
        api
          .post(`solarchargers/${chooseId}/link/${id}`, {
            params: { parentId: id, id: curId },
          })
          .then((res) => {
            notify.open({
              type: "success",
              message: "Changes Saved",
            });
            getTag();
          })
          .catch((e) => {
            notify.open({
              type: "error",
              message: e,
            });
          });
        setCurrent(null);
      } else if (name === "warn") {
        setShowWarning(data);
      } else if (name === "relink") {
        api
          .post(
            `solarchargers/${chooseId?.id}/unlink/${
              chooseId?.assetId || chooseId?.personId || chooseId?.zoneId
            }`
          )
          .then((res) => {
            api
              .post(`solarchargers/${chooseId?.id}/link/${id}`, {
                params: { parentId: id, id: curId },
              })
              .then((res) => {
                notify.open({
                  type: "success",
                  message: "Changes Saved",
                });
                getTag();
                setShowWarning(null);
              });
          })
          .catch((e) => {
            notify.open({
              type: "error",
              message: e,
            });
          });
      }
    }
    setShow(false);
  };

  return (
    <React.Fragment>
      {user.role !== "User" && (
        <Container fluid className="p-0 m-0">
          {charger ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h5>
                  <Sun size={18} /> Solar Charger
                </h5>
                {((type === "zones" && user?.editZones) ||
                  (type === "assets" && user?.editAssets) ||
                  (type === "people" && user?.editPeople)) &&
                  user?.role === "Admin" && (
                    <Button
                      variant="danger"
                      onClick={() => trackerUnlink(charger.id)}
                    >
                      Unlink charger
                    </Button>
                  )}
              </div>
              {user?.role === "Root" ? (
                <div className="mt-2">
                  <span>
                    Serial:{" "}
                    <NavLink
                      to={{
                        pathname: "/solarchargers/edit",
                        search: `?id=${charger.id}`,
                      }}
                    >
                      {charger.serial}
                    </NavLink>
                  </span>
                </div>
              ) : (
                <div className="mt-2">Serial: {charger.serial}</div>
              )}
              {charger.commands &&
                charger.commands.length > 0 &&
                user?.role !== "Root" && (
                  <Dropdown>
                    <Dropdown.Toggle variant="success">
                      Send Command
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {charger.commands.map((command, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            trackerCommand(charger.id, command.commandId)
                          }
                        >
                          {command.commandName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <h5>
                <Sun size={18} /> Solar Charger
              </h5>
              {((type === "zones" && user?.editZones) ||
                (type === "assets" && user?.editAssets) ||
                (type === "people" && user?.editPeople)) &&
                user?.role === "Admin" && (
                  <Button variant="primary" onClick={() => trackerLink()}>
                    Link a Solar Charger
                  </Button>
                )}
            </div>
          )}
          <hr />
        </Container>
      )}

      <Modal
        show={showModal}
        size={curId ? `` : "lg"}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${curId ? "Unlink a" : "Link a"} Device`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {charger && (
            <span>
              Are you sure that you wish to unlink this device from{" "}
              {data?.compoundName}?
            </span>
          )}
          {!charger && (
            <CommonTable
              btnClick={onOperate}
              ref={childRef}
              apiName="solarchargers"
              columns={columns}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {charger && (
            <Button variant="danger" onClick={() => onOperate()}>
              Unlink
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarning} onHide={() => setShowWarning(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure that you wish to link this device to{" "}
            {data?.compoundName}? This will unlink it from{" "}
            {showWarning?.assetCompoundName ||
              showWarning?.personCompoundName ||
              showWarning?.zoneCompoundName}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarning(null)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DeviceCharger;
