import { useFormikContext } from "formik";
import React from "react";
import useAuth from "../../hooks/useAuth";
import { Form } from "react-bootstrap";
import AdditionalInfo from "../AdditionalInfo";
import Thresholds from "../Thresholds";

const ElementDefaultFields = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    // handleSubmit,
    // isSubmitting,
    touched,
    values,
  } = useFormikContext();
  const { user } = useAuth();

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Primary Name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          name="primaryName"
          value={values.primaryName}
          isInvalid={Boolean(touched.primaryName && errors.primaryName)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.primaryName && (
          <Form.Control.Feedback type="invalid">
            {errors.primaryName}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Secondary Name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          name="secondaryName"
          value={values.secondaryName}
          isInvalid={Boolean(touched.secondaryName && errors.secondaryName)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.secondaryName && (
          <Form.Control.Feedback type="invalid">
            {errors.secondaryName}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Reference</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          name="reference"
          value={values.reference}
          isInvalid={Boolean(touched.reference && errors.reference)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.reference && (
          <Form.Control.Feedback type="invalid">
            {errors.reference}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      {props.mode === "details" &&
        props.asset &&
        ((props.apiName === "people" &&
          props.asset?.role === "User" &&
          user?.role !== "Admin") ||
          user?.id === props.asset?.id ||
          (user?.role === "Admin" && props.asset?.role === "Admin")) && (
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}

      {props.mode === "details" &&
        props.apiName === "people" &&
        user?.id === props.asset?.id && (
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              name="playSounds"
              label="Play Sounds"
              checked={values.playSounds}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {props.mode === "details" &&
        props.apiName === "people" &&
        user?.id === props.asset?.id && (
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              name="screenAlerts"
              label="Show Screen Alerts"
              checked={values.screenAlerts}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {props.mode === "details" && props.asset && (
        <div>
          <AdditionalInfo
            setAsset={props.setAsset}
            data={props.asset}
            apiName={props.apiName}
          />
        </div>
      )}

      {props.mode === "details" &&
        props.asset &&
        props.apiName &&
        props.apiName !== "people" && (
          <div>
            <Thresholds
              setAsset={props.setAsset}
              data={props.asset}
              apiName={props.apiName}
              handleUpdateData={props.handleUpdateData}
            />
          </div>
        )}
    </>
  );
};

export default ElementDefaultFields;
