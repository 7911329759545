import React, { useContext, useEffect, useState } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import { Button, Col, Container, Row, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import api from "../../api";
import LoadingSpin from "../loadingSpin";
import SelectHeader from "../SelectHeader";
import { DebounceInput } from "react-debounce-input";
import HashTags from "../HashTags";
import {
  AlertCircle,
  BatteryCharging,
  Clock,
  LogIn,
  LogOut,
  MapPin,
  Moon,
  Move,
  PauseCircle,
  TrendingDown,
} from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { dateFromNow } from "../../utils/staticMethods";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const RowSelectionTable = ({
  columns,
  data,
  loading,
  onLinkAddress,
  entry,
  updateOne,
  pageCount,
  onSortColumn,
  type,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      pageCount: pageCount,
      getRowId: (row, index) => {
        return row.id || row.deviceId;
      },
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (sortBy) onSortColumn(sortBy, allColumns);
  }, [sortBy]);

  return (
    <div>
      {data.length > 0 ? (
        <Table responsive="sm" striped {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={columnIndex}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.className}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {column.className !== "icon" && columnIndex > 0 && (
                      <span>
                        {!column.disableSortBy && (
                          <>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className="ms-2"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faSortUp}
                                  className="ms-2"
                                />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                            )}
                          </>
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <tr>
                <td colSpan={allColumns.length}>
                  <LoadingSpin loading={loading} />
                </td>
              </tr>
            )}
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (
                      cell.column.Header === "Name" &&
                      type !== "floorplans"
                    ) {
                      return (
                        <td key={index}>
                          <NavLink
                            to={{
                              pathname: `/${entry}/edit`,
                              search: `?id=${row.original.id}`,
                            }}
                          >{`${row.values[cell.column.id]} ${
                            row.original.reference
                              ? `(${row.original.reference})`
                              : ""
                          }`}</NavLink>
                        </td>
                      );
                    } else if (cell.column.Header === "HashTags") {
                      return (
                        <td key={index}>
                          {row.values.hashTags && (
                            <HashTags hashTags={row.values.hashTags} />
                          )}
                        </td>
                      );
                    } else if (cell.column.Header === "Last Seen") {
                      return (
                        <td key={index}>
                          {row.values.lastSeen ? (
                            <span>
                              <MapPin width={14} height={14} className="me-1" />{" "}
                              {dateFromNow(row.values.lastSeen)}
                            </span>
                          ) : (
                            <span>
                              <Moon width={14} height={14} className="me-1" />{" "}
                              Waiting...
                            </span>
                          )}
                        </td>
                      );
                    } else if (cell.column.Header === " ") {
                      return (
                        <td key={index}>
                          <Button
                            onClick={() =>
                              updateOne(
                                row.original.id || row.original.deviceId
                              )
                            }
                          >
                            Link
                          </Button>
                        </td>
                      );
                    } else if (cell.column.type === "rule" && cell.value) {
                      return (
                        <td key={index}>
                          {cell.value === "lowvoltage" && (
                            <span>
                              <TrendingDown size={18} /> Low Voltage
                            </span>
                          )}
                          {cell.value === "lowbattery" && (
                            <span>
                              <BatteryCharging size={18} /> Low Battery
                            </span>
                          )}
                          {cell.value === "speeding" && (
                            <span>
                              <AlertCircle size={18} /> Speeding
                            </span>
                          )}
                          {cell.value === "attendance" && (
                            <span>
                              <Clock size={18} /> Attendance
                            </span>
                          )}
                          {cell.value === "motion" && (
                            <span>
                              <Move size={18} /> Motion Alarm
                            </span>
                          )}
                          {cell.value === "enterzone" && (
                            <span>
                              <LogIn size={18} /> Zone Entry
                            </span>
                          )}
                          {cell.value === "exitzone" && (
                            <span>
                              <LogOut size={18} /> Zone Exit
                            </span>
                          )}
                          {cell.value === "pausetracking" && (
                            <span>
                              <PauseCircle size={18} /> Pause Tracking
                            </span>
                          )}
                        </td>
                      );
                    } else if (
                      cell.column.type === "ruleStatus" &&
                      cell.value
                    ) {
                      return (
                        <td key={index}>
                          {cell.value === "armed" && <span>Active</span>}
                          {cell.value === "disarmed" && <span>Inactive</span>}
                          {cell.value === "timed" && <span>Scheduled</span>}
                        </td>
                      );
                    } else {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>
          <div className="w-100 p-3 text-center mt-3 mb-3 bg-light">
            No data available in table
          </div>
        </div>
      )}
      <div className="w-100 dataTables_info">
        <Button onClick={() => onLinkAddress(selectedRowIds)}>
          Link All Selected
        </Button>
      </div>
    </div>
  );
};
const RowSelection = ({
  tableColumns,
  goBack,
  entry,
  id,
  onCompleteUpdate,
  type,
  showSelectionTableDefault,
  apiNew,
}) => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [resultCount, setResultCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setResults] = useState([]);
  const [filter, setFilter] = useState("");
  const notify = useContext(NotyfContext);
  const [tableSort, setSort] = useState("");
  const [allColumns, setAllColumns] = useState();

  useEffect(() => {
    if (data && data.length > 0) initSort();
  }, [data]);

  const onSortColumn = (sortItem, allColumns) => {
    setAllColumns(allColumns);
    if (sortItem.length === 0) {
      setSort(null);
      return;
    }
    let sortParam = "";
    let column = allColumns.filter((item) => item.id === sortItem[0].id)[0];
    if (column) {
      if (!sortItem[0].desc) {
        sortParam = `${column.id}.asc`;
      } else if (sortItem[0].desc) {
        sortParam = `${column.id}.desc`;
      }
    }
    setSort(sortParam);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      handleSwitchPage(1);
    }
  }, [tableSort]);

  const initSort = () => {
    if (tableSort) {
      let arr = tableSort.concat();
      let sortParamArr = arr.split(".");
      if (sortParamArr.length < 2) return;
      allColumns.forEach((item) => {
        if (item.id === sortParamArr[0] && sortParamArr[1] === "desc") {
          item.toggleSortBy(true);
        } else if (item.id === sortParamArr[0] && sortParamArr[1] === "asc") {
          item.toggleSortBy(false);
        }
      });
    }
    return;
  };

  useEffect(() => {
    handleSwitchPage(1);
  }, []);

  useEffect(() => {
    if (resultCount) {
      handleSwitchPage(1);
    }
  }, [limit, filter]);

  const filterResults = (query) => {
    setFilter(query);
  };

  const handlePageClick = (data) => {
    handleSwitchPage(data.selected + 1);
  };

  const onChangeSelect = (e) => {
    setLimit(e.target.value);
  };

  const onLinkAddress = (selectedFlatRows) => {
    if (selectedFlatRows.length === 0) {
      return;
    }

    setLoading(true);

    let arr = [];
    Object.keys(selectedFlatRows).forEach((item) => {
      arr.push(item);
    });

    api
      .post(`${type}/${id}/${entry}/linkall`, arr)
      .then(function () {
        setLoading(false);
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        onCompleteUpdate(entry);
      })
      .catch(function (err) {
        setLoading(false);
        notify.open({
          type: "error",
          message: err,
        });
      });
  };

  const handleSwitchPage = (cur) => {
    setCurrentPage(cur);
    setLoading(true);
    api
      .get(
        `${
          apiNew ? apiNew : entry
        }?page=${cur}&limit=${limit}&sort=${tableSort}`,
        {
          params: {
            filter: filter,
          },
        }
      )
      .then((res) => {
        const total = res.headers["x-total-count"];
        setResultCount(total);
        setPageCount(Math.ceil(total / limit) || 1);
        setResults(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateOne = (itemId) => {
    api
      .post(
        `${type}/${type === "floorplans" ? itemId : id}/${
          type === "floorplans" ? "zones" : entry
        }/link/${type === "floorplans" ? id : itemId}`
      )
      .then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        onCompleteUpdate(entry);
      })
      .catch(() => {
        notify.open({
          type: "error",
          message: "Please try it later",
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div className="mt-1 mb-1 d-flex justify-content-end">
          {!showSelectionTableDefault && (
            <Button variant={"secondary"} onClick={() => goBack()}>
              Cancel
            </Button>
          )}
        </div>
        <div className="mb-3 mt-2">
          <Row className="d-flex justify-content-between align-items-center">
            <Col
              sm={12}
              md={6}
              className="d-flex align-items-center dataTables_length"
            >
              <div className="d-flex align-items-center page-select-container">
                <span>Show</span>
                <SelectHeader limit={limit} onChange={onChangeSelect} />
                <span>entries</span>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              className="d-flex dataTables_filter justify-content-lg-end justify-content-sm-center align-items-center"
            >
              <div className="d-flex align-items-center">
                <span>Search:</span>
                <DebounceInput
                  className="table-search debounceInput-search text-gray"
                  minLength={1}
                  value={filter}
                  debounceTimeout={500}
                  onChange={(event) => filterResults(event.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && data.length === 0 ? (
          <LoadingSpin loading={loading} />
        ) : (
          <RowSelectionTable
            pageCount={pageCount}
            updateOne={updateOne}
            entry={entry}
            onLinkAddress={onLinkAddress}
            type={type}
            onSortColumn={onSortColumn}
            columns={tableColumns}
            data={data}
            loading={loading}
          />
        )}
        <Row className="d-flex align-items-center">
          <Col md={5} sm={12} className="dataTables_info">{`Showing ${
            data.length > 0 ? (currentPage - 1) * limit + 1 : 0
          } to ${
            (currentPage - 1) * limit + data.length
          } of ${resultCount} entries`}</Col>
          <Col
            md={7}
            sm={12}
            className="dataTables_paginate d-md-flex justify-content-md-end"
          >
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              forcePage={currentPage - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default RowSelection;
