import React, { useContext } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";
import moment from "moment-timezone";

const columns = [
  {
    Header: "Name",
    accessor: "commandName",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "Date/Time",
    accessor: "dateTime",
    disableSortBy: true,
    Cell: (cell) => {
      return <span>{moment(cell.value).format("DD/MM/YY HH:mm:ss")}</span>;
    },
  },
];

const SettingTab = ({ data, apiName }) => {
  const notify = useContext(NotyfContext);

  const trackerCommand = (deviceId, commandId) => {
    api.post(`${apiName}/${deviceId}/sendcommand/${commandId}`).then((res) => {
      notify.open({
        type: "success",
        message: "Command Sent",
      });
    });
  };

  return (
    <Card>
      {data?.commands && data.commands.length > 0 && (
        <Card.Body>
          <h4 className="mb-3">
            <FontAwesomeIcon icon={faCog} className="me-2"></FontAwesomeIcon>
            Settings
          </h4>
          <Dropdown>
            <Dropdown.Toggle variant="success">Send Command</Dropdown.Toggle>
            <Dropdown.Menu>
              {data.commands.map((command, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => trackerCommand(data.id, command.commandId)}
                >
                  {command.commandName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {data.id && (
            <div className="mt-3">
              <CommonTable
                singlePage
                hideSearch
                type={"dashboard"}
                hideFilter
                apiName={`${apiName}/${data.id}/sentcommands`}
                autoRefresh
                hideAuto
                columns={columns}
              />
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};
export default SettingTab;
