import React, { Component } from "react";

// place search box for google map
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.SearchBox(this.searchInput);
    map.controls[mapApi.ControlPosition.TOP_RIGHT].push(this.searchInput);
    this.searchBox.addListener("places_changed", this.onPlacesChanged);
    this.searchBox.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = ({ map, addplace, mapApi } = this.props) => {
    const places = this.searchBox.getPlaces();
    addplace && addplace(places);
    setTimeout(() => {
      if (places.length === 0) {
        return;
      }
      const bounds = new mapApi.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    }, 100);
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }

  render() {
    return (
      <input
        ref={(ref) => {
          this.searchInput = ref;
        }}
        type="text"
        style={{ zIndex: 100 }}
        onFocus={this.clearSearchBox}
        placeholder="Search for a place..."
        className={`map-input-absolute ${this.props.hide ? "d-none" : ""}`}
      />
    );
  }
}

export default SearchBox;
