import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NewDevice from "./NewDevice";
import { Layers } from "react-feather";

const columns = [
  {
    Header: "Product Name",
    accessor: "productName",
    type: "link",
    link: "/deviceProfiles/edit",
    stateId: "id",
  },
  {
    Header: "Model",
    accessor: "model",
  },
];

const DeviceProfile = () => {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const childRef = useRef();

  return (
    <React.Fragment>
      <Helmet defer={false} title="Device Profiles" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <Layers className={"me-2"} />
            Device Profiles{" "}
            {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
          <Button className="text-nowrap" onClick={() => setShow(true)}>
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            Create New
          </Button>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            ref={childRef}
            setCountParent={setResultCount}
            apiName="deviceprofiles"
            columns={columns}
          />
        </Card>
      </Container>
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Device Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewDevice apiName="deviceprofiles" />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default DeviceProfile;
