import React from "react";

import SidebarNavSection from "./SidebarNavSection";
import useAuth from "../../hooks/useAuth";

const SidebarNav = ({ items }) => {
  const { user } = useAuth();
  return (
    <ul className="sidebar-nav">
      {items &&
        items.map(
          (item) =>
            ((item?.permission && item?.permission === user?.role) ||
              !item?.permission) && (
              <SidebarNavSection
                key={item.title}
                pages={item.pages}
                title={item.title}
                type={item.type}
              />
            )
        )}
    </ul>
  );
};

export default SidebarNav;
