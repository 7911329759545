import React, { useContext } from "react";
import { Alert, Button } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import api from "../../api";
import { setOtherSession, setSession } from "../../utils/jwt";
import NotyfContext from "../../contexts/NotyfContext";
import { NavLink } from "react-router-dom";

// it's a login button for root user to login as other customer
const RootTopBar = ({ data, modal, homePage }) => {
  const { user } = useAuth();
  const notify = useContext(NotyfContext);

  const onOperate = (id) => {
    api
      .post(`accounts/customerlogin`, { customerId: id })
      .then((res) => {
        let token = res.data?.accessToken;
        let token1 = localStorage.getItem("accessToken");
        localStorage.removeItem("recentItem");
        setOtherSession(token1);
        setSession(token);
        if (homePage) {
          window.location.href = window.location.origin + "";
        } else {
          window.location.reload();
        }
      })
      .catch((err) =>
        notify.open({
          type: "error",
          message: "This customer has no administrators",
        })
      );
  };

  return (
    <React.Fragment>
      {user?.role === "Root" && data?.customerId && data?.customerName && (
        <Alert
          className={`position-absolute shadow-sm bg-white text-dark ${
            modal ? "" : "top-1"
          }`}
          style={{ top: modal ? "10px" : "8px" }}
        >
          <div
            className={`d-flex align-items-center pe-2 pt-1 pb-1 ${
              modal ? "" : "ps-2"
            }`}
          >
            <span>
              Customer:{" "}
              <NavLink
                to={{
                  pathname: "/customers/edit",
                  search: `?id=${data?.customerId}`,
                }}
              >
                {data?.customerName}
              </NavLink>
            </span>
            <Button
              variant={"primary"}
              size={"sm"}
              className="ms-2"
              onClick={() => onOperate(data?.customerId)}
            >
              Login
            </Button>
          </div>
        </Alert>
      )}
    </React.Fragment>
  );
};
export default RootTopBar;
