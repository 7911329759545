import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Form, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { File, X } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import CommonTable from "../tables/CommonTable";
import GoogleMapReact from "google-map-react";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faBan,
  faBolt,
  faCog,
  faPlayCircle,
  faStopCircle,
} from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import a_0 from "../../assets/img/directions/0.png";
import a_45 from "../../assets/img/directions/45.png";
import a_90 from "../../assets/img/directions/90.png";
import a_135 from "../../assets/img/directions/135.png";
import a_180 from "../../assets/img/directions/180.png";
import a_225 from "../../assets/img/directions/225.png";
import a_270 from "../../assets/img/directions/270.png";
import a_315 from "../../assets/img/directions/315.png";
import a_360 from "../../assets/img/directions/360.png";
import SearchBox from "../../pages/maps/components/SearchBox";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import api from "../../api";
import {
  downloadFile,
  getDistance,
  getUrl,
  radiusZoomLevel,
  resArr,
  updateMarker,
} from "../../utils/staticMethods";
import { globalConfig } from "../../config";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import LastLocation from "../LastLocation";
import DatetimePicker from "react-bootstrap-daterangepicker";
import LoadingSpin from "../loadingSpin";
import ExportButton from "../ExportButton";
import useSrlHelper from "../../hooks/useSrlHelper";
import PositionsFloorplanSelector from "./PositionsFloorplanSelector";

const queryString = require("query-string");

let flightPath = null;
let markerArr = [];
let overlay1 = null;
let heatmap = null;

// marker icon, it's an arrow with different direction,  it depends on heading
const getMarkerUrl = (item) => {
  let markerurl;
  if (item.heading == null) {
    markerurl = a_0;
  } else {
    let heading = item.heading;
    if (heading === 0) {
      markerurl = a_0;
    } else if (heading > 337 || heading <= 22) {
      markerurl = a_360;
    } else if (heading > 22 && heading <= 67) {
      markerurl = a_45;
    } else if (heading > 67 && heading <= 112) {
      markerurl = a_90;
    } else if (heading > 112 && heading <= 157) {
      markerurl = a_135;
    } else if (heading > 157 && heading <= 202) {
      markerurl = a_180;
    } else if (heading > 202 && heading <= 247) {
      markerurl = a_225;
    } else if (heading > 247 && heading <= 292) {
      markerurl = a_270;
    } else if (heading > 292) {
      markerurl = a_315;
    }
  }
  return markerurl;
};

// popup window when clicking the marker
const InfoWindow = (props) => {
  const { place } = props;
  const popInfo = { lastPosition: place };
  const [zIndex, setZIndex] = useState(10000000);

  useEffect(() => {
    if (props.place.active) {
      setZIndex(10000001);
    } else {
      setZIndex(10000000);
    }
  }, [props.place.active]);

  const infoWindowStyle = {
    position: "absolute",
    bottom: "25px",
    left: "-100px",
    width: 200,
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    fontSize: 13,
    opacity: "100%",
    zIndex: zIndex,
    flexWrap: "nowrap",
    borderRadius: "8px",
  };
  if (!props.show) {
    return null;
  }

  return (
    <div
      id="content"
      className="bg-white gm-style gm-style-iw-t"
      style={infoWindowStyle}
      onClick={(e) => {
        e.stopPropagation();
        props.clickTarget(place);
      }}
    >
      <div className="d-flex justify-content-between align-items-center mt-1 me-1">
        <div className="p-2">
          {moment(place.dateTime).format("DD/MM/YYYY HH:mm:ss")}
        </div>
        <Button
          size="sm"
          variant="link"
          onClick={(e) => props.onClose(place, e)}
        >
          <X size={18} className="float-end cursor-pointer text-secondary" />
        </Button>
      </div>
      <div className="ps-2 pe-2">
        <LastLocation profile={popInfo} markerLabel isPosition detail />
      </div>
    </div>
  );
};

let source = null;
let timer = null;
const Positions = () => {
  const location = useLocation();
  const { id, name, type } = queryString.parse(location.search);
  // marker array for position data, it used to show on the map
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  // error text from backend when request position data
  const [errorText, setErrorText] = useState(null);
  // total count
  const [resultCount, setResultCount] = useState(0);
  const [mapInstance, setInstance] = useState();
  const mapRef = useRef(mapInstance);
  const [heatMap, setHeatMap] = useState(false);
  const [mapApi, setApi] = useState();
  const apiRef = useRef(mapApi);
  // date data in date range picker
  const [duration, setDuration] = useState();
  const [address, setAddress] = useState(null);
  // first load
  const [loaded, setLoaded] = useState(false);
  const [findAddress, setFindAddress] = useState(null);
  // current selected floorplan (may not have)
  const [curFloor, setCurFloor] = useState(null);
  const curFloorRef = useRef(curFloor);
  const [noData, setNoData] = useState();
  // position data just used to display in table
  const [positionOriginal, setPositionOriginal] = useState([]);
  // floorplan picture if it has
  const [picture, setPicture] = useState();
  // map zoom level
  const [zoom, setZoom] = useState(20);
  // loading status, used to show loading animation
  const [loading, setLoading] = useState(false);
  // loading floorplan, it may takes a while
  const [loadingPlan, setLoadingPlan] = useState(false);
  const notify = useContext(NotyfContext);
  const { user } = useAuth();
  const hasPosition = useRef(true);
  const { renderBattery, renderSupply } = useSrlHelper();
  const [floorplans, setFloorplans] = useState([]);
  const [siteplans, setSiteplans] = useState([]);

  // map settings
  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
    };
  };

  // table column settings, click type will show marker popup, click location, will show address modal
  const columns = [
    {
      Header: "Type",
      accessor: "type",
      Cell: (cell) => {
        if (cell.value === "charging start") {
          return (
            <>
              <FontAwesomeIcon icon={faBolt} />{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Charging Start</span>
              </span>
            </>
          );
        } else if (cell.value === "manual") {
          return (
            <>
              <FontAwesomeIcon icon={faCog} />
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Manual</span>
              </span>
            </>
          );
        } else if (cell.value === "start") {
          return (
            <>
              <FontAwesomeIcon icon={faPlayCircle} />
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Start</span>
              </span>
            </>
          );
        } else if (cell.value === "stop") {
          return (
            <>
              <FontAwesomeIcon icon={faStopCircle} />
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Stop</span>
              </span>
            </>
          );
        } else if (cell.value === "charging stop") {
          return (
            <>
              <FontAwesomeIcon icon={faBan} />
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Charging Stop</span>
              </span>
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon icon={faArrowCircleUp} />
              <span
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  //onSelect(cell?.row?.original.floorPlan);
                  setSelectedPosition(cell?.row?.original);
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter(
                      (info) => info.id === cell?.row?.original.id
                    )[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(
                    cell?.row?.original?.latitude,
                    cell?.row?.original?.longitude
                  );
                  mapInstance.panTo(latLng);
                }}
              >
                <span> Position</span>
              </span>
            </>
          );
        }
      },
    },
    {
      Header: "Date/Time",
      accessor: "dateTime",
      type: "date",
      dateFormat: "DD/MM/YYYY HH:mm:ss",
    },
    {
      Header: "Location",
      accessor: "latitude",
      Cell: (cell) => (
        <span>
          {Number(cell.row.original?.latitude).toFixed(6)},{" "}
          {Number(cell.row.original?.longitude).toFixed(6)}
          <span
            className="text-primary cursor-pointer ms-1"
            onClick={() => {
              showAddress(
                cell.row.original?.latitude,
                cell.row.original?.longitude
              );
            }}
          >
            Show Address
          </span>
        </span>
      ),
    },
    {
      Header: "Speed",
      accessor: "speed",
      Cell: (cell) => {
        return (
          <span>
            {cell.value !== null && cell.value >= 0
              ? Math.round(cell.value * 0.6214) + " mph"
              : "-"}
          </span>
        );
      },
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: (cell) => {
        return renderSupply(cell.value);
      },
    },
    {
      Header: "Battery",
      accessor: "battery",
      Cell: (cell) => {
        return renderBattery(cell.value);
      },
    },
  ];

  useEffect(() => {
    // Get the siteplans
    api
      .get("floorplans/siteplans")
      .then((response) => {
        setSiteplans(response.data);
      })
      .catch((reason) => {
        setSiteplans([]);
      });

    return () => {
      markerArr.forEach((item) => {
        item.setMap(null);
      });
      markerArr = [];
      flightPath = null;
      heatmap && heatmap.setMap(null);
      heatmap = null;
    };
  }, []);

  useEffect(() => {
    if (user && !user.showFloorPlans) {
      setLoadingPlan(false);
    }
  }, [user]);

  const showAddress = (lat, lng) => {
    if (!mapApi) {
      notify.open({
        type: "error",
        message: "Google Map Api errors, please refresh the page and try again",
      });
      return;
    }
    setFindAddress(true);
    getReverseGeocodingData(lat, lng);
  };

  const getReverseGeocodingData = (lat, lng) => {
    let latlng = new mapApi.LatLng(lat, lng);
    let geocoder = new mapApi.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status !== mapApi.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === mapApi.GeocoderStatus.OK) {
        setAddress(results[0].formatted_address);
      }
    });
  };

  const getZone = () => {
    // in this position page, only zones data pushed to markerArr, don't need to show assets/people
    if (!mapRef.current || !hasPosition.current) return;
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    api
      .get(
        `maps/nearby?radius=${
          radiusZoomLevel[Math.round(mapRef.current.getZoom())]
        }&latitude=${mapRef.current
          .getCenter()
          .lat()}&longitude=${mapRef.current.getCenter().lng()}&limit=100`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let arr =
            (res.data &&
              res.data.filter(
                (item) =>
                  item.type === "zone" && item.latitude && item.longitude
              )) ||
            [];
          updateMarker(arr, markerArr, [], mapRef.current, apiRef.current);
          let newArr = resArr(arr, markerArr);
          let removeArr = resArr(markerArr, arr);
          newArr.forEach((item) => {
            let m = new apiRef.current.Marker({
              id: item.id,
              type: "zones",
              asset: item.type === "asset" ? 1 : 0,
              person: item.type === "person" ? 1 : 0,
              zone: item.type === "zone" ? 1 : 0,
              ids: [item],
              idsArr: [item.id],
              position: {
                lat: Number(item.latitude),
                lng: Number(Number(item.longitude)),
              },
              label: {
                text: item.compoundName,
                color: curFloorRef.current ? "#FFFFFF" : "#000000",
                fontWeight: "bold",
                className: curFloorRef.current
                  ? "marker-position-top bg-danger ps-1 pe-1 radius-3"
                  : "marker-position-top",
              },
              icon: {
                url: imageSet[getUrl(item.icon)]
                  ? require("../../assets/img/icon/" +
                      imageSet[getUrl(item.icon)]).default
                  : defaultImg,
                scaledSize: new apiRef.current.Size(24, 24),
                anchor: new apiRef.current.Point(12, 12),
              },
              anchor: new apiRef.current.Point(14, 43),
              map: mapRef.current,
            });
            markerArr.push(m);
            if (curFloorRef.current) return;
            if (item.radius) {
              let c = new apiRef.current.Circle({
                id: item.id,
                ids: [item],
                idsArr: [item.id],
                strokeColor: "#090B29",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#090B29",
                fillOpacity: 0.4,
                shape: "circle",
                type: "zone",
                center: {
                  lat: Number(item.latitude),
                  lng: Number(Number(item.longitude)),
                },
                map: mapRef.current,
                radius: Number(item.radius),
              });
              m.diam = Number(item.radius) * 2;
              markerArr.push(c);
            } else if (item.polygon) {
              let firstItem = item.polygon[0].split(",");
              let minLat = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                maxLat = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                minLng = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                maxLng = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                };
              let arr1 = item.polygon.map((item) => {
                let me = item.split(",");
                let lng = Number(me[0]);
                let lat = Number(me[1]);
                if (lat > maxLat.lat) maxLat = { lat, lng };
                if (lat < minLat.lat) minLat = { lat, lng };
                if (lng > maxLng.lng) maxLng = { lat, lng };
                if (lng < minLng.lng) minLng = { lat, lng };
                return { lng, lat };
              });
              let polygon = new apiRef.current.Polygon({
                paths: arr1,
                idsArr: [item.id],
                id: item.id,
                ids: [item],
                type: "zone",
                shape: "polygon",
                strokeColor: "#000000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#242424",
                fillOpacity: 0.4,
                editable: false,
                map: mapRef.current,
              });
              markerArr.push(polygon);
            }
          });
          removeArr.forEach((item) => {
            for (let i = 0; i < markerArr.length; i++) {
              if (markerArr[i].id === item.id) {
                markerArr[i].setMap && markerArr[i].setMap(null);
                markerArr.splice(i, 1);
                break;
              }
            }
          });
        }
      });
  };

  // get position data when map loaded
  useEffect(() => {
    if (mapApi && mapInstance && siteplans) {
      onGetList();
    }
  }, [mapApi, mapInstance, siteplans]);

  // when position data changed, draw polylines between markers
  useEffect(() => {
    if (mapApi && mapInstance && positions && positions.length > 0) {
      hasPosition.current = true;
      let pathArr = [];
      let heatArr = [];
      positions.forEach((item) => {
        pathArr.push({
          lat: Number(item.latitude),
          lng: Number(item.longitude),
        });
        heatArr.push(new mapApi.LatLng(item.latitude, item.longitude));
      });
      flightPath && flightPath.setMap(null);
      heatmap && heatmap.setMap(null);
      heatmap = null;
      flightPath = null;
      heatmap = new mapApi.visualization.HeatmapLayer({
        data: heatArr,
        radius: 20,
      });
      flightPath = new mapApi.Polyline({
        path: pathArr,
        geodesic: true,
        strokeColor: "#0a192e",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      !heatMap && flightPath.setMap(mapInstance);
      heatMap && heatmap.setMap(mapInstance);
    } else if (mapApi && mapInstance && positions && positions.length === 0) {
      hasPosition.current = false;
      setHeatMap(false);
      flightPath && flightPath.setMap(null);
      heatmap && heatmap.setMap(null);
      heatmap = null;
      flightPath = null;
      markerArr.forEach((item) => {
        item.setMap(null);
      });
      markerArr = [];
    }
  }, [mapApi, mapInstance, positions]);

  // get position data with date range, limit=0 means get all data
  const onGetList = () => {
    setLoading(true);
    api
      .get(
        `assets/positions/${id}?page=0&limit=0${
          duration
            ? `&startDate=${duration[0]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}&endDate=${duration[1]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}`
            : ""
        }`
      )
      .then((r) => {
        setPositions(r.data);
        setPositionOriginal(r.data);
        if (Array.isArray(r.data) && r.data.length > 0) {
          setSelectedPosition(r.data[0]);
        }
        const total = r.headers["x-total-count"];
        setResultCount(Number(total));
        if (!r.data || r.data.length === 0) {
          mapInstance && mapInstance.panTo({ lat: 51.5, lng: -0.11 });
          mapInstance && mapInstance.setZoom(6);
          setErrorText("No data found");
        } else {
          const bounds = new mapApi.LatLngBounds();
          r.data.forEach((item) => {
            bounds.extend(new mapApi.LatLng(item.latitude, item.longitude));
          });
          mapInstance.fitBounds(bounds);
        }
        if (r.data.length > 0 && !duration && !loaded) {
          let end = moment(r.data[0].dateTime).endOf("days");
          let start = moment(r.data[r.data.length - 1].dateTime);
          setDuration([start.local(), end.local()]);
        } else if (r.data.length === 0 && !duration) {
          let end = moment().endOf("days");
          let start = moment().subtract(7, "days").startOf("days");
          setDuration([start.local(), end.local()]);
        }

        try {
          var newFloorplans = [];
          r.data.forEach((item) => {
            if (item.floorPlan) {
              if (
                !newFloorplans.find((existingItem) => {
                  return existingItem.id === item.floorPlan?.id;
                })
              ) {
                newFloorplans.push(item.floorPlan);
              }
            }
          });
          if (newFloorplans.length > 0) {
            setFloorplans(newFloorplans.concat(siteplans));
          }
        } catch (error) {}

        setTimeout(() => {
          setLoaded(true);
        }, 1000);
        if (picture && curFloor)
          mapInstance.setHeading(360 - curFloor?.rotation || 0);
        setLoading(false);
      })
      .catch((err) => {
        setPositions([]);
        setPositionOriginal([]);
        setLoading(false);
        setErrorText(err);
        mapInstance && mapInstance.panTo({ lat: 51.5, lng: -0.11 });
        mapInstance && mapInstance.setZoom(6);
      });
  };

  // close popup for a marker
  const onClose = (item, e) => {
    e.stopPropagation();
    let newP = Array.from(positions);
    let filter = newP.findIndex((info) => info.id === item.id);
    newP[filter]["show"] = false;
    setPositions(newP);
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    window.map = map;
    setApi(maps);
    mapRef.current = map;
    apiRef.current = maps;
    // when map moved/zoomed, get the zone data with appropriate radius and lat and lng
    map.addListener("bounds_changed", () => {
      setZoom(map.zoom);
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => getZone(), 500);
    });
  };

  // export position data
  const handleExport = () => {
    api
      .get(
        `${type}/positions/${id}/export?startDate=${duration[0]
          .tz("Europe/London")
          .format("YYYY-MM-DDTHH:mm")}&endDate=${duration[1]
          .tz("Europe/London")
          .format("YYYY-MM-DDTHH:mm")}`,
        { responseType: "blob" }
      )
      .then((res) => downloadFile(res));
  };

  // for floorplan loading picture
  const getPci = () => {
    api
      .get(`files/floorplans/${curFloor.id}`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        let blob = new Blob([res.data], { type: "img/jpeg" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);
        setPicture(url);
      })
      .catch(() => setPicture(null));
  };

  // if has a selected floorplan, loading the picture for that floorplan
  useEffect(() => {
    if (mapApi && mapInstance && curFloor) {
      getPci();
      mapInstance.setOptions({
        mapTypeControl: false,
        streetViewControl: false,
      });
      markerArr.forEach((item) => {
        if (item.type === "zone") {
          item.setMap(null);
        }
        if (item.type === "zones") {
          item.label.className =
            "marker-position-top bg-danger ps-1 pe-1 radius-3";
        }
      });
      markerArr = markerArr.filter((item) => item.type !== "zone");
    } else if (mapApi && mapInstance && !curFloor) {
      setPicture(null);
      overlay1 && overlay1.setMap(null);
      overlay1 = null;
      mapInstance.setOptions({ mapTypeControl: true, streetViewControl: true });
    }
  }, [mapApi, mapInstance, curFloor]);

  // if floorplan has a picture, draw this floorplan image on the map
  useEffect(() => {
    if (picture) initFloorPlan();
  }, [picture]);

  // floorplan is an image with heading props, heading means the map heading, we should rotate the map to fit the image
  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(
        curFloor ? Number(curFloor.northWestLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.northWestLongitude) || 0.1276 : 0.1276
      );
      let southEast = new mapApi.LatLng(
        curFloor ? Number(curFloor.southEastLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.southEastLongitude) || 0.1276 : 0.1276
      );
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      window.overlay = overlay1;
      overlay1.image = picture;
      overlay1.draw = function () {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(southEast);
        const nw = overlayProjection.fromLatLngToDivPixel(northWest);
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function () {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
        }
      }
      setTimeout(() => {
        mapInstance.setHeading(360 - curFloor?.rotation || 0);
        setLoadingPlan(false);
      }, 10);
    }
  };

  const onSelect = (data) => {
    if (data === null) {
      setLoadingPlan(false);
      setNoData(true);
    } else {
      if (!curFloor || curFloor.id !== data.id) {
        setCurFloor(data);
        curFloorRef.current = data;
        setLoadingPlan(true);
      }
    }
  };

  useEffect(() => {
    if (heatMap) {
      heatmap && heatmap.setMap(mapInstance);
      flightPath && flightPath.setMap(null);
    } else {
      heatmap && heatmap.setMap(null);
      flightPath && flightPath.setMap(mapInstance);
    }
  }, [heatMap, positions]);

  const clickTarget = (item) => {
    let arr = positions.concat([]);
    arr.forEach((i) => {
      if (i.id === item.id) {
        i.active = true;
      } else {
        i.active = false;
      }
    });
    setPositions(arr);
  };

  // select date range event
  const onApply = (start, end) => {
    setDuration([moment(start.format()), moment(end.format())]);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title="Reporting" />
      <h3 className="mb-3">
        Reporting -{" "}
        <NavLink to={{ pathname: "/assets/edit", search: `?id=${id}` }}>
          {name}
        </NavLink>
      </h3>
      <div
        style={{ height: 500, width: "100%", position: "relative" }}
        className={`position-relative ${
          curFloor ? "hidden-map floorplan-zone" : ""
        }`}
      >
        {!noData && user?.showFloorPlans && (
          <PositionsFloorplanSelector
            showDefault
            onSelect={onSelect}
            asset={id}
            right
            floorplans={floorplans}
            selectedPosition={selectedPosition}
          />
        )}
        {loadingPlan && (
          <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {loading && (
          <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"],
          }}
          center={{
            lat:
              positions && positions.length > 0
                ? Number(positions[0]?.latitude)
                : 51.5,
            lng:
              positions && positions.length > 0
                ? Number(positions[0]?.longitude)
                : -0.11,
          }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {mapInstance && mapApi && (
            <SearchBox hide={curFloor} map={mapInstance} mapApi={mapApi} />
          )}
          {mapApi &&
            !heatMap &&
            mapInstance &&
            positions &&
            positions.length > 0 &&
            positions.map((item) => (
              <MarkerComponent
                zoom={zoom}
                showInfo={(item) => {
                  setPositions((pre) => {
                    let newP = pre.concat([]);
                    newP.filter((info) => info.id === item.id)[0].show = true;
                    return newP;
                  });
                  let latLng = new mapApi.LatLng(item.latitude, item.longitude);
                  mapInstance.panTo(latLng);
                  // debugger;
                  setSelectedPosition(item);
                }}
                clickTarget={clickTarget}
                currentFloorplan={curFloor}
                item={item}
                onClose={(info, e) => onClose(info, e)}
                key={item.id}
                lat={item.latitude}
                lng={item.longitude}
              />
            ))}
        </GoogleMapReact>
      </div>
      <div className="tab mt-3">
        <Tab.Container id="left-tabs-example" defaultActiveKey="table">
          <Nav variant="tabs">
            <Nav.Link eventKey="table">Table</Nav.Link>
            <Nav.Link eventKey="export">Export</Nav.Link>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="table">
              {mapApi && (
                <div className="d-flex justify-content-between mb-2 flex-wrap">
                  {duration && (
                    <div className="d-flex align-items-center flex-wrap">
                      <DatetimePicker
                        initialSettings={{
                          timePicker: true,
                          startDate: duration[0],
                          endDate: duration[1],
                          timePicker24Hour: true,
                          locale: {
                            format: "DD/MM/YYYY HH:mm",
                          },
                        }}
                        onCallback={onApply}
                      >
                        <input
                          type="text"
                          style={{ width: "280px" }}
                          className="form-control flex-grow-1"
                        />
                      </DatetimePicker>
                      <Button className="ms-2" onClick={() => onGetList()}>
                        Filter
                      </Button>
                    </div>
                  )}
                  {positions && positions.length > 0 && (
                    <Form.Check
                      type="switch"
                      label="Heatmap"
                      style={{ float: "right" }}
                      defaultChecked={heatMap}
                      onChange={(e) => setHeatMap(e.target.checked)}
                    />
                  )}
                </div>
              )}
              {loading && (
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <LoadingSpin loading={loading} />
                </div>
              )}
              {positionOriginal && positionOriginal.length > 0 && !loading && (
                <CommonTable
                  hideSearch
                  data={positionOriginal}
                  apiName={`assets/positions/${id}`}
                  columns={columns}
                />
              )}
              {(!positionOriginal || positionOriginal.length === 0) &&
                !loading && (
                  <div className="w-100 p-3 text-center mt-3 mb-3 bg-light">
                    {errorText}
                  </div>
                )}
            </Tab.Pane>
            <Tab.Pane eventKey="export">
              {Number(resultCount) > 0 && (
                <>
                  <h4 className="tab-title d-flex align-items-center">
                    <File className="me-1" size={18} />
                    Export
                  </h4>
                  <p>
                    This will create a csv export file containing all data
                    recorded between the selected dates.
                  </p>
                  <ExportButton
                    title={"Export"}
                    count={resultCount}
                    onClick={() => handleExport()}
                  />
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal
        show={findAddress}
        onHide={() => {
          setFindAddress(false);
          setAddress(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Address Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {address ? <p>{address}</p> : <p>Searching for address...</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setFindAddress(false);
              setAddress(null);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const MarkerComponent = ({
  item,
  onClose,
  showInfo,
  clickTarget,
  currentFloorplan,
}) => {
  // var isOnCurrentFloorplan = false;
  // if (currentFloorplan) {
  //   isOnCurrentFloorplan = item.floorPlan?.id === currentFloorplan.id;
  // }

  return (
    <div
      onClick={(e) => {
        showInfo(item);
      }}
      style={{
        background: "#00000000",
        display: "flex",
        borderRadius: "4px",
        marginLeft: "-10px",
        marginTop: "-10px",
        cursor: "pointer",
        height: "fit-content",
        width: "fit-content",
        //opacity: `${isOnCurrentFloorplan ? "100%" : "5%"}`
      }}
    >
      <img
        style={{
          width: "20px",
          height: "20px",
        }}
        src={getMarkerUrl(item)}
        alt="marker-icon"
      />
      <InfoWindow
        show={item.show}
        onClose={(info, e) => onClose(info, e)}
        clickTarget={clickTarget}
        place={item}
      />
    </div>
  );
};

export default Positions;
