import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../../api";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Disc, Grid, Package, Users } from "react-feather";
import useAuth from "../../hooks/useAuth";
import QRCodeWithDownload from "../../components/QRCodeWithDownload";
import CommonTable from "../../components/tables/CommonTable";
import NewObject from "../../components/detail/NewObject";
import NotyfContext from "../../contexts/NotyfContext";

const QrCodesScanResult = () => {
  const { serial } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [device, setDevice] = useState(null);
  const childRef = useRef();
  const [elementType, setElementType] = useState(null);
  const [apiName, setApiName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showNewAsset, setShowNewAsset] = useState(false);
  const [showNewPerson, setShowNewPerson] = useState(false);
  const [showNewZone, setShowNewZone] = useState(false);
  const notify = useContext(NotyfContext);

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 60,
      disableSortBy: true,
      Cell: (cell) => (
        <Button
          variant={
            cell.row.original.qrCode?.assetId ||
            cell.row.original.qrCode?.personId ||
            cell.row.original.qrCode?.zoneId
              ? "danger"
              : "success"
          }
          onClick={() =>
            onOperate(
              cell.row.original.qrCode?.assetId ||
                cell.row.original.qrCode?.personId ||
                cell.row.original.qrCode?.zoneId
                ? "warn"
                : "edit",
              cell.value,
              cell.row.original
            )
          }
        >
          Link
        </Button>
      ),
    },
    {
      Header: "Name",
      accessor: "compoundName",
    },
    {
      Header: "Linked",
      //accessor: "elementId",
      //type: "linkStatus",
      disableSortBy: true,
      Cell: (cell) => {
        var d = cell.row.original.qrCode?.serial;
        return <>{d}</>;
      },
    },
  ];

  useEffect(async () => {
    if (serial) {
      try {
        var result = await api.get(`qrcodes/serial/${serial}`);

        setDevice(result.data);
      } catch (error) {
        setDevice(null);
      }
    }
  }, [serial]);

  useEffect(() => {
    if (device) {
      if (device.assetId != null) {
        navigate(`/assets/edit?id=${device.assetId}`);
      } else if (device.personId != null) {
        navigate(`/people/edit?id=${device.personId}`);
      } else if (device.zoneId != null) {
        navigate(`/zones/edit?id=${device.zoneId}`);
      }
    }
  }, [device]);

  const linkElement = (selectedElementType) => {
    setElementType(selectedElementType);
    if (selectedElementType === "Asset") setApiName("assets/withqrcode");
    else if (selectedElementType === "Person") setApiName("people/withqrcode");
    else if (selectedElementType === "Zone") setApiName("zones/withqrcode");
    setShowModal(true);
  };

  const onOperate = (name, chooseId, data) => {
    if (name === "edit") {
      var qrCodeId = device.id;
      var elementId = chooseId;
      api
        .post(`qrcodes/${qrCodeId}/link/${elementId}`)
        .then((res) => {
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
          (async () => {
            try {
              var result = await api.get(`qrcodes/serial/${serial}`);

              setDevice(result.data);
            } catch (error) {
              setDevice(null);
            }
          })();
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
        });
    } else if (name === "warn") {
      setShowWarning(data);
    } else if (name === "relink") {
      var currentlyLinkedQrCodeId = chooseId.qrCode.id;
      var qrCodeId2 = device.id;
      var elementId2 = chooseId.id;
      api
        .post(`qrcodes/${currentlyLinkedQrCodeId}/unlink/${elementId2}`)
        .then((res) => {
          api.post(`qrcodes/${qrCodeId2}/link/${elementId2}`).then((res) => {
            notify.open({
              type: "success",
              message: "Changes Saved",
            });

            (async () => {
              try {
                var result = await api.get(`qrcodes/serial/${serial}`);

                setDevice(result.data);
              } catch (error) {
                setDevice(null);
              }
            })();

            setShowWarning(null);
          });
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
        });
    }
    setShowModal(false);
  };

  return (
    <>
      <Helmet defer={false} title="Scan Result" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Grid className="me-2" /> QR Code
            </h1>
          </div>
        </div>

        <Row>
          <Col md="4" xl="3">
            <Card className="mb-0 border-top">
              <Card.Body>
                {device && <QRCodeWithDownload value={`${device.serial}`} />}
              </Card.Body>
            </Card>
          </Col>
          <Col md="8" xl="9" className="mt-3 mt-sm-0">
            <Card className="mb-0">
              <Card.Body>
                {device != null ? (
                  <>
                    <div>
                      The QR Code <b>{serial}</b> has not been linked to an
                      Asset, Person or Zone.
                    </div>
                    {/* <div>A link can be made using the Devices tab on the <NavLink to={{ pathname: "/assets" }}>Assets</NavLink>, <NavLink to={{ pathname: "/people" }}>People</NavLink> or <NavLink to={{ pathname: "/zones" }}>Zones</NavLink> pages.</div> */}
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>
                        <Package className="me-2" />
                        Assets
                      </h5>
                      <div>
                        {user?.createAssets && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="success"
                            onClick={() => {
                              setShowNewAsset(true);
                            }}
                          >
                            Create new Asset
                          </Button>
                        )}
                        {user?.editAssets && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="primary"
                            onClick={() => {
                              linkElement("Asset");
                            }}
                          >
                            Link an Asset
                          </Button>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>
                        <Users className="me-2" />
                        People
                      </h5>
                      <div>
                        {user?.createPeople && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="success"
                            onClick={() => {
                              setShowNewPerson(true);
                            }}
                          >
                            Create new Person
                          </Button>
                        )}
                        {user?.editPeople && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="primary"
                            onClick={() => {
                              linkElement("Person");
                            }}
                          >
                            Link a Person
                          </Button>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>
                        <Disc className="me-2" />
                        Zones
                      </h5>
                      <div>
                        {user?.createZones && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="success"
                            onClick={() => {
                              setShowNewZone(true);
                            }}
                          >
                            Create new Zone
                          </Button>
                        )}
                        {user?.editZones && user?.role !== "Root" && (
                          <Button
                            className="mx-1"
                            variant="primary"
                            onClick={() => {
                              linkElement("Zone");
                            }}
                          >
                            Link a Zone
                          </Button>
                        )}
                      </div>
                    </div>
                    <hr />
                  </>
                ) : (
                  <>
                    QR Code <b>{serial}</b> was not found.
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <NewObject
        showModal={showNewAsset}
        apiName={"assets"}
        closeModal={() => {
          setShowNewAsset(false);
        }}
        onSuccess={(newId) => {
          setShowNewAsset(false);
          api
            .post(`qrcodes/${device.id}/link/${newId}`)
            .then((res) => {
              notify.open({
                type: "success",
                message: "QR Code linked",
              });
              navigate(`/assets/edit?id=${newId}`);
            })
            .catch((e) => {
              notify.open({
                type: "error",
                message: e,
              });
            });
        }}
      />

      <NewObject
        showModal={showNewPerson}
        apiName={"people"}
        closeModal={() => {
          setShowNewPerson(false);
        }}
        onSuccess={(newId) => {
          setShowNewPerson(false);
          api
            .post(`qrcodes/${device.id}/link/${newId}`)
            .then((res) => {
              notify.open({
                type: "success",
                message: "QR Code linked",
              });
              navigate(`/people/edit?id=${newId}`);
            })
            .catch((e) => {
              notify.open({
                type: "error",
                message: e,
              });
            });
        }}
      />

      <NewObject
        showModal={showNewZone}
        apiName={"zones"}
        closeModal={() => {
          setShowNewZone(false);
        }}
        onSuccess={(newId) => {
          setShowNewZone(false);
          api
            .post(`qrcodes/${device.id}/link/${newId}`)
            .then((res) => {
              notify.open({
                type: "success",
                message: "QR Code linked",
              });
              navigate(`/zones/edit?id=${newId}`);
            })
            .catch((e) => {
              notify.open({
                type: "error",
                message: e,
              });
            });
        }}
      />

      <Modal show={showModal} size={"lg"} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Link a{elementType === "Asset" ? "n" : ""} {elementType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {tag && <span>Are you sure that you wish to unlink this device from {data?.compoundName}?</span>} */}
          {true && (
            <CommonTable
              btnClick={onOperate}
              ref={childRef}
              apiName={apiName}
              columns={columns}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* {tag && <Button variant="danger" onClick={() => onOperate()}>Unlink</Button>} */}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarning} onHide={() => setShowWarning(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure that you wish to link the {elementType}{" "}
            <b>{showWarning?.compoundName}</b> to <b>{device?.serial}</b>? This
            will unlink it from <b>{showWarning?.qrCode?.serial}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarning(null)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QrCodesScanResult;
