import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultImg from "../../assets/img/icon/GPS/placeholder.png";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { getUrl } from "../../utils/staticMethods";
import { globalConfig } from "../../config";
import { SRL_CATEGORIES } from "../../constants";
import useSrlHelper from "../../hooks/useSrlHelper";

const SrlAssetDetails = (props) => {
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const [imgSrc, setImgSrc] = useState();
  const { user } = useAuth();
  const [modalTitle, setModalTitle] = useState(null);
  const { isSrl } = useSrlHelper();

  useEffect(() => {
    setImgSrc(props.asset.icon);
  }, [props.asset.icon]);

  const deleteAsset = async () => {
    await api
      .delete(`${props.apiName || "assets"}/` + props.asset.id)
      .then((res) => {
        navigate(`/${props.apiName || ""}`);
      });
  };

  return (
    <React.Fragment>
      <Card className="shadow-none">
        <Card.Body>
          <h4>
            <FontAwesomeIcon
              icon={faFileAlt}
              className="me-1"
            ></FontAwesomeIcon>{" "}
            Details
          </h4>
          <Formik
            enableReinitialize
            initialValues={{
              assetOwner: props.asset.suppString1 || "SRL",
              fleetNumber: props.asset.fleetNumber || "",
              productName: props.asset.category || "",
              customerFleetNumber: props.asset.suppString2 || "",
              primaryName: props.asset.primaryName || "", // Customer Ref
              email: props.asset.email || "",
              playSounds: props.asset.playSounds || false,
              screenAlerts: props.asset.screenAlerts || false,
              archived: props.asset.archived || false,
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              assetOwner: Yup.string()
                .max(255)
                .matches(
                  /^[^#]*$/,
                  "'#' is a protected character used for hashtag searches and cannot be used here"
                )
                .required("Asset Owner is required"),
              fleetNumber: Yup.string()
                .max(255)
                .matches(
                  /^[^#]*$/,
                  "'#' is a protected character used for hashtag searches and cannot be used here"
                )
                .required("SRL Fleet Number is required"),
              productName: Yup.string()
                .max(255)
                .required("SRL Product Name is required"),
              primaryName: Yup.string()
                .max(255)
                .matches(
                  /^[^#]*$/,
                  "'#' is a protected character used for hashtag searches and cannot be used here"
                ),
              email: Yup.string().email("Invalid email"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              let data = JSON.parse(JSON.stringify(props.asset));
              data.id = props.asset.id;
              data.suppString1 = values.assetOwner;
              data.fleetNumber = values.fleetNumber;
              data.category = values.productName;
              data.suppString2 = values.customerFleetNumber;
              data.primaryName = values.primaryName;
              data.icon = imgSrc;
              if (props.apiName === "assets") {
                data.archived = values.archived;
              }
              if (props.apiName === "people") {
                data.email = values.email;
              }
              if (props.asset.id === user?.id) {
                data.playSounds = values.playSounds;
                data.screenAlerts = values.screenAlerts;
              }
              try {
                api.put(`${props.apiName || "assets"}`, data).then((res) => {
                  notify.open({
                    type: "success",
                    message: "Changes Saved",
                  });
                  props.setAsset(res.data);
                });
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Icon</Form.Label>
                  <div>
                    {(props.type === "zones" && user?.editZones) ||
                    (props.type === "assets" &&
                      user?.editAssets &&
                      !(user?.role === "User" && isSrl())) ||
                    (props.type === "people" && user?.editPeople) ? (
                      <NavLink
                        to={{
                          pathname: "/icons/asset-icon",
                          search: `?id=${props.asset.id}&type=${props.type}`,
                        }}
                      >
                        <img
                          alt={imgSrc}
                          className="profile-icon"
                          src={
                            imageSet[getUrl(imgSrc)]
                              ? require("../../assets/img/icon/" +
                                  imageSet[getUrl(imgSrc)]).default
                              : defaultImg
                          }
                        />
                      </NavLink>
                    ) : (
                      <img
                        alt={imgSrc}
                        className="profile-icon"
                        src={
                          imageSet[getUrl(imgSrc)]
                            ? require("../../assets/img/icon/" +
                                imageSet[getUrl(imgSrc)]).default
                            : defaultImg
                        }
                      />
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Asset Owner</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    name="assetOwner"
                    value={values.assetOwner}
                    isInvalid={Boolean(touched.assetOwner && errors.assetOwner)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={user?.role === "User"}
                  />
                  {!!touched.assetOwner && (
                    <Form.Control.Feedback type="invalid">
                      {errors.assetOwner}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>SRL Fleet Number</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    name="fleetNumber"
                    value={values.fleetNumber}
                    isInvalid={Boolean(
                      touched.fleetNumber && errors.fleetNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={user?.role === "User"}
                  />
                  {!!touched.fleetNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.fleetNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupHashtag">
                  <Form.Label>SRL Product Name</Form.Label>
                  <Form.Select
                    name="productName"
                    value={values.productName}
                    onChange={handleChange}
                    onBlur={handleChange}
                    disabled={user?.role === "User"}
                  >
                    <option value="">please select</option>
                    {SRL_CATEGORIES.map((v, i) => {
                      return (
                        <option key={v.name} value={v.name}>
                          {v.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {touched.productName && (
                    <>
                      <span className="text-danger">{errors.productName}</span>
                    </>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Customer Fleet Number</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    name="customerFleetNumber"
                    value={values.customerFleetNumber}
                    isInvalid={Boolean(
                      touched.customerFleetNumber && errors.customerFleetNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={user?.role === "User"}
                  />
                  {!!touched.customerFleetNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.customerFleetNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Customer Reference</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="primaryName"
                    value={values.primaryName}
                    isInvalid={Boolean(
                      touched.primaryName && errors.primaryName
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.primaryName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.primaryName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {((props.apiName === "people" &&
                  props.asset?.role === "User" &&
                  user?.role !== "Admin") ||
                  user?.id === props.asset?.id ||
                  (user?.role === "Admin" &&
                    props.asset?.role === "Admin")) && (
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      name="email"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}

                {props.apiName === "people" && user?.id === props.asset?.id && (
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="playSounds"
                      label="Play Sounds"
                      checked={values.playSounds}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}

                {props.apiName === "people" && user?.id === props.asset?.id && (
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      name="screenAlerts"
                      label="Show Screen Alerts"
                      checked={values.screenAlerts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}

                {/* <div>
                  <AdditionalInfo setAsset={props.setAsset} data={props.asset} apiName={props.apiName} />
                </div> */}

                {/* <div>
                  <Thresholds setAsset={props.setAsset} data={props.asset} apiName={props.apiName} handleUpdateData={props.handleUpdateData} />
                </div> */}

                {props.apiName === "assets" &&
                  user?.role !== "User" &&
                  globalConfig.applicationTheme !== "srl" && (
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="switch"
                        name="archived"
                        label="Archived"
                        checked={values.archived}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}

                <div className="mt-3">
                  {((props.type === "zones" && user?.editZones) ||
                    (props.type === "assets" && user?.editAssets) ||
                    (props.type === "people" && user?.editPeople) ||
                    user?.id === props.asset?.id) && (
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  )}
                  {((props.type === "zones" && user?.deleteZones) ||
                    (props.type === "assets" && user?.deleteAssets) ||
                    (props.type === "people" && user?.deletePeople)) &&
                    user?.id !== props.asset?.id && (
                      <Button
                        type="button"
                        variant="danger"
                        onClick={() => setModalTitle(true)}
                        className="m-lg-1"
                      >
                        Delete
                      </Button>
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <Modal show={modalTitle} onHide={() => setModalTitle(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.type === "assets" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Asset?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this item.
                </p>
              </>
            )}
            {props.type === "people" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Person?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this person.
                </p>
              </>
            )}
            {props.type === "zones" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Zone?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this item.
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => deleteAsset()} variant="danger">
            Delete
          </Button>
          <Button variant={"secondary"} onClick={() => setModalTitle(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SrlAssetDetails;
