import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import api from "../../api";
import LoadingSpin from "../loadingSpin";
import usePalette from "../../hooks/usePalette";

const Deployment = ({ name, width }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [avg, setAvg] = useState(null);
  const isUmount = useRef(false);
  const palette = usePalette();
  const controller = new AbortController();

  useEffect(() => {
    getData();
    return () => {
      isUmount.current = true;
      controller.abort();
    };
  }, []);

  const getData = () => {
    setLoading(true);
    let labelArr = [];
    let dataArr = [];
    let arr = [];
    let res = [
      { label: "Deployed", value: 2200 },
      { label: "In Depot", value: 2500 },
      { label: "Other", value: 300 },
    ];
    api
      .get(`reports/deploymentsummary`, { signal: controller.signal })
      .then((r) => {
        res[0].value = r.data.deployed;
        res[1].value = r.data.inDepot;
        res[2].value = r.data.other;
        res.forEach((item) => {
          labelArr.push(item.label);
          dataArr.push(item.value);
          arr.push(item);
        });
        setDataArr(arr);
        setAvg(r.data.total);
        let data = {
          labels: arr,
          datasets: [
            {
              data: dataArr,
              backgroundColor: [
                palette.primary,
                palette.warning,
                palette.danger,
                "#E8EAED",
              ],
              borderColor: palette.white,
            },
          ],
        };
        setResult(data);
        setLoading(false);
      });
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let name = data.labels[tooltipItem.index]?.label;
          let currentValue = dataset.data[tooltipItem.index];
          return name + ": " + currentValue;
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
  };

  return (
    <React.Fragment>
      <Col key={name} lg={((width / 100) * 12).toFixed(0)} className="mb-4">
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h4 className="text-dark">Deployment</h4>
          </div>
          {loading && (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center">
              <LoadingSpin loading={loading} />
            </div>
          )}
          {!loading && result && (
            <div>
              <div className="chart chart-xs pb-3">
                <Pie data={result} options={options} />
              </div>
              <div className="ps-3 pe-3">
                <Table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th className="text-end">Assets</th>
                      <th className="text-end">% Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArr.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <FontAwesomeIcon
                            icon={faSquare}
                            style={{
                              color: result.datasets[0].backgroundColor[index],
                            }}
                          />{" "}
                          <NavLink
                            to={{
                              pathname: `/deployment`,
                              search: `?type=${
                                item.label === "In Depot"
                                  ? "Depots"
                                  : item.label
                              }`,
                            }}
                          >
                            {item.label}
                          </NavLink>
                        </td>
                        <td className="text-end">{item.value}</td>
                        <td className="text-end">
                          {item.value === 0
                            ? "0"
                            : `${((item.value / avg) * 100).toFixed(0)}%`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default Deployment;
