import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

// Upload modal for profile screen
const UploadImgModal = ({
  showUpload,
  onHide,
  picture,
  onUpload,
  setUploadFile,
  onDelete,
}) => {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <Modal show={showUpload} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Add Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          {picture && (
            <div className="border-bottom pb-4 mb-3">
              <img alt="profile" className="w-100" src={picture} />
            </div>
          )}
          <div>
            For best results, use an image at least 128px by 128px in .jpg
            format. Square images look best, however portrait and landscape
            images are also accepted.
          </div>
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <Form.Control
              accept="image/*"
              className="w-75 border-0 image-input"
              type="file"
              onChange={(event) => setUploadFile(event.target.files)}
            />
            <Button onClick={() => onUpload()}>Upload</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {picture && (
            <Button variant={"danger"} onClick={() => setShow(true)}>
              Delete
            </Button>
          )}
          <Button onClick={() => onHide()} variant={"secondary"}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you wish to permanently delete this photo?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onDelete();
              setShow(false);
            }}
            variant={"danger"}
          >
            Delete
          </Button>
          <Button onClick={() => setShow(false)} variant={"secondary"}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default UploadImgModal;
