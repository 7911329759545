import React from "react";
import useTheme from "../hooks/useTheme";
import SrlArchivedAssets from "../srl/pages/SrlArchivedAssets";
import ArchivedAssets from "../pages/assets/ArchivedAssets";

const ArchivedAssetsWrapper = () => {
  const { theme } = useTheme();

  return (
    <>
      {theme === "srl" ? (
        <>
          <SrlArchivedAssets></SrlArchivedAssets>
        </>
      ) : (
        <>
          <ArchivedAssets></ArchivedAssets>
        </>
      )}
    </>
  );
};

export default ArchivedAssetsWrapper;
