export const SIDEBAR_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

export const SIDEBAR_BEHAVIOR = {
  STICKY: "sticky",
  FIXED: "fixed",
  COMPACT: "compact",
};

export const LAYOUT = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const THEME = {
  DEFAULT: "default",
  COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
};

export const THEME_PALETTE_LIGHT = {
  primary: "#2ea836", // SRL Green
  "primary-dark": "#1659c7",
  "primary-light": "#84aef2",
  secondary: "#272726", // SRL Carbon
  success: "#2ea836", // SRL Green
  info: "#1F9BCF",
  warning: "#ffed00", // SRL Canary
  danger: "#e30513", // SRL Red
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};

export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  "primary-dark": "#84aef2",
  "primary-light": "#1659c7",
  white: "#293042",
  "gray-100": "#3e4555",
  "gray-200": "#545968",
  "gray-300": "#696e7b",
  "gray-400": "#7f838e",
  "gray-500": "#9498a1",
  "gray-600": "#a9acb3",
  "gray-700": "#bfc1c6",
  "gray-800": "#d4d6d9",
  "gray-900": "#eaeaec",
  black: "#fff",
};

export const SRL_CATEGORIES = [
  {
    category: "Signals",
    name: "SRL Eurolight Signal",
    image: "eurolight.png",
  },
  {
    category: "Signals",
    name: "SRL Radiolight Signal",
    image: "radiolight.png",
  },
  {
    category: "Signals",
    name: "SRL Rapid Response Signal",
    image: "rapidresponse.png",
  },
  {
    category: "Signals",
    name: "SRL Solarlight Signal",
    image: "solarlight.png",
  },
  {
    category: "Signals",
    name: "SRL Ultralight Signal",
    image: "ultralight.png",
  },
  {
    category: "UTMC",
    name: "SRL UTMC Control Unit",
    image: "utmc.png",
  },
  {
    category: "Barriers",
    name: "SRL Temporary Barrier",
    image: "temporarybarrier.png",
  },
  {
    category: "Barriers",
    name: "SRL Semi-Permanent Barrier",
    image: "semipermanentbarrier.png",
  },
  {
    category: "Other Assets",
    name: "SRL CCTV",
    image: "cctv.png",
  },
];
