import { Helmet } from "react-helmet-async";
import ANPR from "../../components/ANPR";
import { useContext, useReducer, useState } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import { saveAnpr } from "../../utils/staticMethods";
import CaptureTag from "../../components/CaptureTag";
import api from "../../api";
import { Button, Card, Container, Form, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MediaDevicesWrapper from "../../components/MediaDevicesWrapper";
import { Check, Info, X, Truck } from "react-feather";

const actionType = Object.freeze({
  restart: 0,
  scanPlate: 5,
  setPlate: 10,
  setPlateNoTag: 11,
  setPlateWithTag: 12, // (error, for assignment, asset should not have a tag)
  setPlateNoAsset: 13, // (error, there must have been an error saving / finding the asset)
  setPlateMultiAsset: 14, // (multiple assets found that match the plate)

  setTag: 20,
  setTagWithAsset: 21,

  linked: 30,
});

const initialState = {
  mode: "scan_plate",
  plate: null,
  asset: null,
  tag: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionType.restart:
      return initialState;

    case actionType.scanPlate:
      return {
        ...state,
        mode: "scan_plate",
        plate: null,
        asset: null,
        tag: null,
        loading: false,
      };

    // case 'scan_tag':
    //     return {
    //         ...state,
    //         mode: 'scan_tag',
    //         loading: false
    //     };

    case actionType.setPlate:
      return {
        ...state,
        plate: action.data.plate,
        asset: action.data.asset,
        tag: null,
        loading: true,
      };

    case actionType.setPlateNoTag:
      return {
        ...state,
        mode: "scan_tag",
        plate: action.data.plate,
        asset: action.data.asset,
        tag: null,
        loading: false,
      };

    case actionType.setPlateWithTag:
      return {
        ...state,
        mode: "scanned_plate_with_tag",
        tag: action.data.tag,
        loading: false,
      };

    case actionType.setPlateNoAsset:
      return {
        ...state,
        mode: "scanned_plate_no_asset",
        plate: action.data.plate,
        tag: null,
        loading: false,
      };

    case actionType.setPlateMultiAsset:
      return {
        ...state,
        mode: "scanned_plate_multi_asset",
        plate: action.data.plate,
        assets: action.data.assets,
        asset: null,
        tag: null,
        loading: false,
      };

    case actionType.setTag:
      return {
        ...state,
        mode: "scanned_tag",
        tag: action.data.tag,
        loading: true,
      };

    case actionType.setTagWithAsset:
      return {
        ...state,
        mode: "scanned_tag_with_asset",
        tag: action.data.tag,
        loading: false,
      };

    case actionType.linked:
      return {
        ...state,
        mode: "linked",
        loading: false,
      };

    default:
      throw Error("Unknown action.");
  }
};

const modeValues = {
  scan_plate: {
    variant: "info",
    icon: <Info className="text-info me-2" size={"2em"} />,
    title: "Scan / type the number plate of the vehicle",
    subTitle: (state) =>
      "Point your device towards the vehicles number plate and press the capture button below or manually enter it",
    exit: null,
    restart: null,
  },
  scan_tag: {
    variant: "info",
    icon: <Info className="text-info me-2" size={"2em"} />,
    title: "Scan the QR code of the tag",
    subTitle: (state) =>
      "Point your device towards the QR code of the tag, the code will be automatically read",
    exit: null,
    restart: null,
  },
  scanned_plate_no_asset: {
    variant: "danger",
    icon: <></>,
    title: "No asset found",
    subTitle: (state) => <>please retry</>,
    exit: null,
    restart: "Start Again",
  },
  scanned_plate_with_tag: {
    variant: "danger",
    icon: <X className="text-danger me-2" size={"2em"} />,
    title: "Vehicle Already Tagged",
    subTitle: (state) => (
      <>
        The vehicle asset with the number plate
        <br />
        <NumberPlate>{state.plate}</NumberPlate>
        <br />
        has already assigned to tag
        <br />
        <Tag>{state.tag.serial}</Tag>
      </>
    ),
    exit: "Exit without Assigning Tag",
    restart: "Start Again",
  },
  scanned_plate_multi_asset: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "Multiple Assets Found",
    subTitle: (state) => (
      <>
        The vehicle number plate
        <br />
        <NumberPlate>{state.plate}</NumberPlate>
        <br />
        has been found for multiple assets, please select one
      </>
    ),
    exit: null,
    restart: null,
  },
  scanned_tag: {
    variant: "info",
    icon: <></>,
    title: "",
    subTitle: (state) => "",
    exit: null,
    restart: null,
  },
  scanned_tag_with_asset: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "Tag Already Assigned",
    subTitle: (state) => (
      <>
        The tag with serial number
        <br />
        <Tag>{state.tag.serial}</Tag>
        <br />
        is already assigned to the vehicle asset named
        <br />
        <NumberPlate>{state.tag.assetCompoundName}</NumberPlate>
      </>
    ),
    exit: "Exit without Assigning Tag",
    restart: "Start Again",
  },
  linked: {
    variant: "success",
    icon: <Check className="text-success me-2" size={"2em"} />,
    title: "Tag Assigned",
    subTitle: (state) => (
      <>
        The vehicle with registration plate
        <br />
        <NumberPlate>{state.plate}</NumberPlate>
        <br />
        has been assigned to the tag with serial number
        <br />
        <Tag>{state.tag.serial}</Tag>
      </>
    ),
    exit: "Exit to Dashboard",
    restart: "Assign Another",
  },
};

const NumberPlate = ({ children }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "yellow",
          color: "black",
          display: "inline-flex",
        }}
        className="rounded my-2 p-2"
      >
        {children}
      </div>
    </>
  );
};

const Tag = ({ children }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "grey",
          fontFamily: "monospace",
          display: "inline-flex",
        }}
        className="rounded my-2 p-2"
      >
        {children}
      </div>
    </>
  );
};

const TagAssign = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const [selectedAsset, setSelectedAsset] = useState(-1);
  const [reassignPressed, setReassignPressed] = useState(false);

  const handleOnMatch = (scannedPlate, image) => {
    saveAnpr(notify, scannedPlate, image, (assets) => {
      if (assets) {
        if (typeof assets === typeof []) {
          if (assets.length === 1) {
            // We have a match for one asset
            var asset = assets[0];
            dispatch({
              type: actionType.setPlate,
              data: { plate: scannedPlate, asset: asset },
            });

            api
              .get(`assets/tags/${asset.id}`)
              .then((response) => {
                const tag = response.data;
                dispatch({
                  type: actionType.setPlateWithTag,
                  data: { plate: scannedPlate, asset: asset, tag: tag },
                });
              })
              .catch((error) => {
                dispatch({
                  type: actionType.setPlateNoTag,
                  data: { plate: scannedPlate, asset: asset, tag: null },
                });
              });

            // ((callback) => {
            //     // Check if asset needs to be unarchived
            //     if (asset.type.includes("archived")) {
            //         api.put(`archived-assets/${asset.id ?? 0}/unarchive`)
            //             .then((response) => {
            //                 callback();
            //             })
            //             .catch((reason) => {
            //                 callback();
            //             });
            //     }
            //     else {
            //         callback();
            //     }
            // })(
            //     () => {
            //         api.get(`assets/tags/${asset.id}`).then((response) => {
            //             const tag = response.data;
            //             dispatch({ type: actionType.setPlateWithTag, data: { plate: scannedPlate, asset: asset, tag: tag } });
            //             //unlinkTag(tag);
            //         }).catch((error) => {
            //             dispatch({ type: actionType.setPlateNoTag, data: { plate: scannedPlate, asset: asset, tag: null } });
            //         });
            //     }
            // );
          } else if (assets.length === 0) {
            dispatch({
              type: actionType.setPlateNoAsset,
              data: { plate: scannedPlate },
            });
          } else {
            dispatch({
              type: actionType.setPlateMultiAsset,
              data: { plate: scannedPlate, assets: assets },
            });
          }
        }
      } else {
        dispatch({
          type: actionType.setPlateNoAsset,
          data: { plate: scannedPlate },
        });
      }
    });
  };

  const handleSetTag = (tag) => {
    //setTag(tag);
    if (tag.assetId == null) {
      // We have a tag with no asset assign so we can assign the currently selected asset to this tag
      try {
        dispatch({ type: actionType.setTag, data: { tag: tag } });
        api
          .post(`tags/${tag.id}/linkunarchive/${state.asset.id}`)
          .then((res) => {
            notify.open({
              type: "success",
              message: "Tag assigned",
            });
            dispatch({ type: actionType.linked, data: {} });
          })
          .catch((e) => {
            notify.open({
              type: "error",
              message: e,
            });
          });
      } catch (error) {
        notify.open({
          type: "error",
          message: error,
        });
      }
    } else {
      dispatch({ type: actionType.setTagWithAsset, data: { tag: tag } });
    }
  };

  const handleSelectAssetClick = (e) => {
    const asset = state.assets[selectedAsset];
    const scannedPlate = state.plate;

    dispatch({
      type: actionType.setPlate,
      data: { plate: scannedPlate, asset: asset },
    });
    api
      .get(`assets/tags/${asset.id}`)
      .then((response) => {
        const tag = response.data;
        dispatch({
          type: actionType.setPlateWithTag,
          data: { plate: scannedPlate, asset: asset, tag: tag },
        });
        //unlinkTag(tag);
      })
      .catch((error) => {
        dispatch({
          type: actionType.setPlateNoTag,
          data: { plate: scannedPlate, asset: asset, tag: null },
        });
      });

    // const tag = asset.
    // dispatch({ type: actionType.setPlateWithTag, data: { plate: scannedPlate, asset: asset, tag: tag } });
  };

  const reassignTag = () => {
    var tag = state.tag;

    try {
      setReassignPressed(true);
      api
        .post(`tags/${tag.id}/unlink/${tag.assetId ?? 0}`)
        .then((res) => {
          notify.open({
            type: "success",
            message: "Tag unassigned",
          });

          // dispatch({ type: 'unlinked', data: {} });

          api
            .post(`tags/${tag.id}/linkunarchive/${state.asset.id}`)
            .then((res) => {
              notify.open({
                type: "success",
                message: "Tag assigned",
              });
              setReassignPressed(false);
              dispatch({ type: actionType.linked, data: {} });
            })
            .catch((e) => {
              notify.open({
                type: "error",
                message: e,
              });
              setReassignPressed(false);
            });
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
          setReassignPressed(false);
        });
    } catch (error) {
      notify.open({
        type: "error",
        message: error,
      });
      setReassignPressed(false);
    }
  };

  return (
    <>
      <Helmet defer={false} title="Assign tag to vehicle" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Truck className="me-1" /> Assign Tag to Vehicle
            </h1>
          </div>
        </div>
        <Card className="bg-white p-3">
          <MediaDevicesWrapper>
            {modeValues[state.mode] ? (
              <div
                className={`mx-0 mb-4 p-2 border border-${
                  modeValues[state.mode]?.variant
                } border-2 rounded-3`}
              >
                <h1 className="d-flex align-items-center">
                  {modeValues[state.mode]?.icon}
                  {modeValues[state.mode]?.title}
                </h1>
                <h3 className="ms-4">
                  {modeValues[state.mode]?.subTitle &&
                    modeValues[state.mode].subTitle(state)}
                </h3>
              </div>
            ) : (
              <>
                <div className="mb-4">{`No values for mode [${state.mode}]`}</div>
              </>
            )}

            <Stack gap={5} direction="vertical">
              {state.mode === "scan_plate" && <ANPR onMatch={handleOnMatch} />}
              {state.mode === "scan_tag" && (
                <div className="d-flex justify-content-center">
                  <div className="number-plate rounded ">
                    &nbsp;{state.plate}&nbsp;
                  </div>
                </div>
              )}
              {state.mode === "scan_tag" && (
                <>
                  <div className="d-flex justify-content-center">
                    <CaptureTag setTag={handleSetTag} />
                  </div>
                </>
              )}
              {state.mode === "scanned_plate_multi_asset" && (
                <>
                  <div className="d-flex justify-content-around">
                    <Form.Select
                      aria-label="Select asset"
                      size="lg"
                      name="selectAsset"
                      className="me-3"
                      onBlur={(e) => {
                        console.log(e);
                        setSelectedAsset(parseInt(e.target.value));
                      }}
                      onChange={(e) => {
                        console.log(e);
                        setSelectedAsset(parseInt(e.target.value));
                      }}
                    >
                      <option value="-1">select an asset</option>
                      {state.assets.map((asset, index) => {
                        return (
                          <option key={index} value={index}>
                            {asset.compoundName}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {
                      <Button
                        disabled={selectedAsset === -1}
                        onClick={(e) => {
                          handleSelectAssetClick(e);
                        }}
                      >
                        Select
                      </Button>
                    }
                  </div>
                </>
              )}
              {state.mode === "scanned_tag_with_asset" && (
                <>
                  <div className="d-flex justify-content-around">
                    <Button
                      disabled={reassignPressed}
                      onClick={(e) => {
                        reassignTag();
                      }}
                    >
                      Reassign Tag to <NumberPlate>{state.plate}</NumberPlate>
                    </Button>
                  </div>
                </>
              )}
              {state.mode === "scanned_plate_with_tag" && (
                <>
                  <div className="d-flex justify-content-around">
                    <Button
                      onClick={(e) => {
                        navigate("/tags/unassign");
                      }}
                    >
                      Unassign a Tag
                    </Button>
                  </div>
                </>
              )}
              <div className="d-flex justify-content-around">
                {modeValues[state.mode].exit && (
                  <Button
                    onClick={(e) => {
                      navigate("/");
                    }}
                  >
                    {modeValues[state.mode].exit}
                  </Button>
                )}
                {modeValues[state.mode].restart && (
                  <Button
                    onClick={(e) => {
                      dispatch({ type: actionType.restart, data: {} });
                    }}
                  >
                    {modeValues[state.mode].restart}
                  </Button>
                )}
              </div>
            </Stack>
          </MediaDevicesWrapper>
        </Card>
      </Container>
    </>
  );
};

export default TagAssign;
