import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Redirector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { redirectTo } = location.state || { redirectTo: "/" };

  useEffect(() => {
    navigate(redirectTo);
  });

  return <></>;
};

export default Redirector;
