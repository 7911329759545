import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "copy-to-clipboard";
import {
  faArrowCircleDown,
  faArrowCircleRight,
  faArrowCircleUp,
  faBan,
  faBolt,
  faCheckSquare,
  faPlug,
  faPowerOff,
  faStopCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Copy, Globe, Radio, Smartphone, Wifi, X } from "react-feather";
import { Button, Card, Dropdown } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";

let tracker = [];
let reader = [];
let tag = [];
let app = [];
let rawTracker = [];
let rawTag = [];
let rawReader = [];
let rawApp = [];
let interval = null;

const Diagnostics = ({ tags, trackers, type, readers, apps, root }) => {
  const [displayReader, setDisplayReader] = useState([]);
  const [displayTracker, setDisplayTracker] = useState([]);
  const [displayTag, setDisplayTag] = useState([]);
  const [displayApp, setDisplayApp] = useState([]);
  const [selectType, setSelect] = useState("tracker");
  const [readerIndex, setReaderIndex] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const { user } = useAuth();
  const readerI = useRef(readerIndex);
  const notify = useContext(NotyfContext);
  const deviceData = useRef();

  const [firstTime, setFirstTime] = useState(false);

  useEffect(() => {
    if ((tags && trackers && apps && type === 1) || (readers && type !== 1)) {
      getRawData();
    }
  }, [tags, trackers, apps, readers]);

  useEffect(() => {
    if (readers && rawReader.length === readers.length) {
      setDisplayReader(rawReader[readerIndex]);
      readerI.current = readerIndex;
    }
  }, [readerIndex]);

  useEffect(() => {
    if (!user) return;

    const weHaveTrackers = trackers && trackers.length > 0;
    const weHaveTags = tags && tags.length > 0;
    const weHaveApps = apps && apps.length > 0;

    if (type === 1) {
      if (weHaveTrackers) {
        setSelect("tracker");
      } else if (weHaveTags) {
        setSelect("tag");
      } else if (weHaveApps) {
        setSelect("app");
      }
    }

    setDeviceCount(0);
    if (weHaveTrackers) {
      setDeviceCount((prev) => {
        return prev + 1;
      });
    }
    if (weHaveTags) {
      setDeviceCount((prev) => {
        return prev + 1;
      });
    }
    if (weHaveApps) {
      setDeviceCount((prev) => {
        return prev + 1;
      });
    }

    if (readers && type !== 1) {
      reader = [];
      rawReader = [];
      setReaderIndex(0);
      setSelect("reader");
      readers.length > 0 &&
        readers.forEach(() => {
          reader.push([]);
          rawReader.push([]);
        });
    }
    if (!firstTime) return;
    clearInterval(interval);
    interval = setInterval(() => {
      getRawData();
    }, 5000);
  }, [firstTime, tags, trackers, apps, readers, user]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
      tracker = [];
      reader = [];
      tag = [];
      app = [];
      rawTracker = [];
      rawTag = [];
      rawReader = [];
      rawApp = [];
    };
  }, []);

  const getRawData = () => {
    if ((!firstTime && type !== 1) || (firstTime && type !== 1)) {
      let promiseArr = [];
      readers &&
        readers.length > 0 &&
        readers.forEach((item) => {
          promiseArr.push(api.get(`readers/${item.id}/raw`));
          if (!firstTime) {
            reader.push([]);
            rawReader.push([]);
          }
        });
      Promise.all(promiseArr)
        .then((res) => {
          res.forEach((item, index) => {
            if (
              (!reader[index] || !reader[index].includes(item.data)) &&
              item.data !== ""
            ) {
              reader[index] && reader[index].push(item.data);
              let strComponent = liveDataFormatter(item.data);
              rawReader[index] && rawReader[index].push(strComponent);
              if (index === readerI.current) {
                setDisplayReader(rawReader[index]);
              }
            }
          });
        })
        .catch((error) => console.log(error));
    }
    if (
      (!firstTime && type === 1 && trackers.length > 0) ||
      (firstTime && type === 1 && trackers.length > 0)
    ) {
      api.get(`trackers/${trackers[0].id}/raw`).then((res) => {
        if (tracker.includes(res.data) || res.data.length === 0) return;
        tracker.push(res.data);
        let strComponent = liveDataFormatter(res.data);
        rawTracker.push(strComponent);
        setDisplayTracker((prevState) => [...prevState, strComponent]);
      });
    }
    if (
      (!firstTime && type === 1 && tags.length > 0) ||
      (firstTime && type === 1 && tags.length > 0)
    ) {
      api.get(`tags/${tags[0].id}/raw`).then((res) => {
        if (tag.includes(res.data) || res.data.length === 0) return;
        tag.push(res.data);
        let strComponent = liveDataFormatter(res.data);
        rawTag.push(strComponent);
        setDisplayTag((prevState) => [...prevState, strComponent]);
      });
    }
    if (
      (!firstTime && type === 1 && apps.length > 0) ||
      (firstTime && type === 1 && apps.length > 0)
    ) {
      api.get(`apps/${apps[0].id}/raw`).then((res) => {
        if (app.includes(res.data) || res.data.length === 0) return;
        app.push(res.data);
        let strComponent = liveDataFormatter(res.data);
        rawApp.push(strComponent);
        setDisplayApp((prevState) => [...prevState, strComponent]);
      });
    }
    setFirstTime(true);
  };

  function utcFormat(utc) {
    let formatted =
      utc.substring(6, 8) +
      "-" +
      utc.substring(4, 6) +
      "-" +
      utc.substring(0, 4) +
      " " +
      utc.substring(8, 10) +
      ":" +
      utc.substring(10, 12) +
      ":" +
      utc.substring(12, 14);
    return formatted;
  }

  const copyText = () => {
    if (!deviceData.current?.innerText) return;
    if (copy(deviceData.current?.innerText)) {
      notify.open({
        type: "success",
        message: "Copied !",
      });
    }
  };

  const clearText = () => {
    if (selectType === "tracker") {
      tracker = [];
      rawTracker = [];
      setDisplayTracker([]);
    } else if (selectType === "tag") {
      tag = [];
      rawTag = [];
      setDisplayTag([]);
    } else if (selectType === "app") {
      app = [];
      rawApp = [];
      setDisplayApp([]);
    } else if (selectType === "reader") {
      reader[readerIndex] = [];
      rawReader[readerIndex] = [];
      setDisplayReader([]);
    }
  };

  const liveDataFormatter = (data) => {
    let dataArray = data.split(",");
    let formattedHtml = "";
    if (dataArray[3] === "gv75" && user?.role !== "Root") {
      switch (dataArray[0]) {
        case "+RESP:GTFRI":
          if (
            (dataArray[12] === "0" && dataArray[11] === "0") ||
            dataArray[12] === "99.900000" ||
            dataArray[11] === "99.900000"
          ) {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faBan}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>
                  Invalid Position Report
                </span>{" "}
                (No GPS data received) at UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[28].substring(0, 14))}
                </span>
              </>
            );
          } else {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faArrowCircleUp}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>Position Report</span>
                UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[28].substring(0, 14))}
                </span>
                GPS UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[13].substring(0, 14))}
                </span>
                Lat/Lng:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {dataArray[12] + " , " + dataArray[11]}
                </span>
                mV: <span style={{ color: "#45AAD8" }}>{dataArray[4]}</span>
                Mph: <span style={{ color: "#45AAD8" }}>{dataArray[8]}</span>
                Bat: <span style={{ color: "#45AAD8" }}>{dataArray[23]}% </span>
                {dataArray[24] === "110000" && (
                  <>
                    Red: <span style={{ color: "#45AAD8" }}> ON </span>
                    Grn: <span style={{ color: "#45AAD8" }}> OFF </span>
                  </>
                )}
                {dataArray[24] === "210300" && (
                  <>
                    Red: <span style={{ color: "#45AAD8" }}> OFF </span>
                    Grn: <span style={{ color: "#45AAD8" }}> ON </span>
                  </>
                )}
                {dataArray[24] !== "210300" && dataArray[24] === "110000" && (
                  <>
                    Red: <span style={{ color: "#45AAD8" }}> OFF </span>
                    Grn: <span style={{ color: "#45AAD8" }}> OFF </span>
                  </>
                )}
              </>
            );
          }
          break;
        case "+RESP:GTRTL":
          if (
            (dataArray[12] === "0" && dataArray[11] === "0") ||
            dataArray[12] === "99.900000" ||
            dataArray[11] === "99.900000"
          ) {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faBan}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>
                  Invalid Location Request
                </span>{" "}
                (No GPS data received) at UTC:
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[20].substring(0, 14))}
                </span>
              </>
            );
          } else {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faArrowCircleDown}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>Location Request</span>
                UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[20].substring(0, 14))}
                </span>
                GPS UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[13].substring(0, 14))}
                </span>
                Lat/Lng:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {dataArray[12] + ", " + dataArray[11]}
                </span>
                Mph: <span style={{ color: "#45AAD8" }}>{dataArray[8]}</span>
              </>
            );
          }
          break;
        case "+RESP:GTSTR":
          if (
            (dataArray[11] === "0" && dataArray[10] === "0") ||
            dataArray[11] === "99.900000" ||
            dataArray[10] === "99.900000"
          ) {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faBan}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>
                  Invalid Movement Started Report
                </span>{" "}
                (No GPS data received) at UTC:
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[19].substring(0, 14))}
                </span>
              </>
            );
          } else {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>Movement Started</span>
                UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[19].substring(0, 14))}
                </span>
                GPS UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[12].substring(0, 14))}
                </span>
                Lat/Lng:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {dataArray[11] + ", " + dataArray[10]}
                </span>
                Mph: <span style={{ color: "#45AAD8" }}>{dataArray[7]}</span>
              </>
            );
          }
          break;
        case "+RESP:GTSTP":
          if (
            (dataArray[11] === "0" && dataArray[10] === "0") ||
            dataArray[11] === "99.900000" ||
            dataArray[10] === "99.900000"
          ) {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faBan}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>
                  Invalid Movement Stopped Report
                </span>{" "}
                (No GPS data received) at UTC:
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[19].substring(0, 14))}
                </span>
              </>
            );
          } else {
            formattedHtml = (
              <>
                <FontAwesomeIcon
                  icon={faStopCircle}
                  style={{ color: "#45AAD8" }}
                ></FontAwesomeIcon>
                <span style={{ color: "#45AAD8" }}>Movement Stopped</span>
                UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[19].substring(0, 14))}
                </span>
                GPS UTC:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {utcFormat(dataArray[12].substring(0, 14))}
                </span>
                Lat/Lng:{" "}
                <span style={{ color: "#45AAD8" }}>
                  {dataArray[11] + ", " + dataArray[10]}
                </span>
                Mph: <span style={{ color: "#45AAD8" }}>{dataArray[7]}</span>
              </>
            );
          }
          break;
        case "+RESP:GTDIS":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faBolt}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Digital Data Received</span> at
              UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[20].substring(0, 14))}
              </span>
            </>
          );
          break;
        case "+RESP:GTPFA":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faPowerOff}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Powered Off</span> at UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[4].substring(0, 14))}
              </span>
            </>
          );
          break;
        case "+RESP:GTPNA":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faPlug}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Powered On</span> at UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[4].substring(0, 14))}
              </span>
            </>
          );
          break;
        case "+ACK:GTRTO":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faCheckSquare}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Command Acknowledged</span> at
              UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[6].substring(0, 14))}
              </span>
            </>
          );
          break;
        case "+ACK:GTDIS":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faCheckSquare}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Command Acknowledged</span> at
              UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[5].substring(0, 14))}
              </span>
            </>
          );
          break;
        case "+ACK:GTFRI":
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faCheckSquare}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>{" "}
              <span style={{ color: "#45AAD8" }}>Command Acknowledged</span> at
              UTC:{" "}
              <span style={{ color: "#45AAD8" }}>
                {utcFormat(dataArray[5].substring(0, 14))}
              </span>
            </>
          );
          break;
        default:
          formattedHtml = (
            <>
              <FontAwesomeIcon
                icon={faCheckSquare}
                style={{ color: "#45AAD8" }}
              ></FontAwesomeIcon>
              <span style={{ color: "#45AAD8" }}>Data Received</span>
            </>
          );
          break;
      }
    } else {
      formattedHtml = data;
    }
    let ele = <div key={data}>{formattedHtml}</div>;
    return ele;
  };

  const getTitle = () => {
    if (selectType === "tracker" && trackers && trackers.length > 0) {
      return (
        <>
          <Globe size={18} /> {trackers[0].serial}
        </>
      );
    } else if (selectType === "tag" && tags && tags.length > 0) {
      return (
        <>
          <Radio size={18} /> {tags[0].serial}
        </>
      );
    } else if (selectType === "app" && apps && apps.length > 0) {
      return (
        <>
          <Smartphone size={18} /> {apps[0].serial}
        </>
      );
    } else if (selectType === "reader" && readers && readers.length > 0) {
      return (
        <>
          <Wifi size={18} /> {readers[readerIndex]?.serial}
        </>
      );
    }
    return "";
  };

  return (
    <Card className="dignostics-card shadow-none">
      <Card.Body className="p-0">
        <Card.Title>Diagnostic Data</Card.Title>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {type === 1 && deviceCount > 1 && (
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  {getTitle()}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="light">
                  {trackers && trackers.length > 0 && (
                    <Dropdown.Item
                      active={selectType === "tracker"}
                      onClick={() => {
                        setSelect("tracker");
                      }}
                    >
                      <Globe size={18} /> {trackers[0].serial}
                    </Dropdown.Item>
                  )}
                  {tags && tags.length > 0 && (
                    <Dropdown.Item
                      active={selectType === "tag"}
                      onClick={() => {
                        setSelect("tag");
                      }}
                    >
                      <Radio size={18} /> {tags[0].serial}
                    </Dropdown.Item>
                  )}
                  {apps && apps.length > 0 && (
                    <Dropdown.Item
                      active={selectType === "app"}
                      onClick={() => {
                        setSelect("app");
                      }}
                    >
                      <Smartphone size={18} /> {apps[0].serial}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {type !== 1 && readers && readers.length > 1 && (
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  {getTitle()}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="light">
                  {readers.map((item, key) => (
                    <Dropdown.Item
                      key={key}
                      active={key === readerIndex}
                      onClick={() => {
                        setSelect("reader");
                        setReaderIndex(key);
                      }}
                    >
                      <Wifi size={18} /> {item.serial}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          {(displayTag.length > 0 ||
            displayReader.length > 0 ||
            displayTracker.length > 0 ||
            displayApp.length > 0) && (
            <div>
              <Button onClick={() => copyText()}>
                <Copy size={14} />
                Copy to Clipboard
              </Button>
              <Button
                onClick={() => clearText()}
                variant="success"
                className="ms-2"
              >
                <X size={14} />
                Clear
              </Button>
            </div>
          )}
        </div>
        <h5 className="mt-4 mb-3">{getTitle()}</h5>
        {displayTag.length > 0 ||
        displayReader.length > 0 ||
        displayTracker.length > 0 ||
        displayApp.length > 0 ? (
          <div ref={deviceData} className="device-raw-data">
            {selectType === "tracker" && displayTracker.length > 0 && (
              <div className="tracker">
                {displayTracker.map((item, index) => (
                  <div key={index} className="d-flex w-100 break-all">
                    <span className="me-1">{index + 1}.</span>
                    {item}
                  </div>
                ))}
              </div>
            )}
            {selectType === "tracker" && displayTracker.length === 0 && (
              <div className="text-gray">Waiting...</div>
            )}

            {selectType === "tag" && displayTag.length > 0 && (
              <div className="tags">
                {displayTag.map((item, index) => (
                  <div key={index} className="d-flex w-100 break-all">
                    <span className="me-1">{index + 1}.</span>
                    {item}
                  </div>
                ))}
              </div>
            )}
            {selectType === "tag" && displayTag.length === 0 && (
              <div className="text-gray">Waiting...</div>
            )}

            {selectType === "app" && displayApp.length > 0 && (
              <div className="apps">
                {displayApp.map((item, index) => (
                  <div key={index} className="d-flex w-100 break-all">
                    <span className="me-1">{index + 1}.</span>
                    {item}
                  </div>
                ))}
              </div>
            )}
            {selectType === "app" && displayApp.length === 0 && (
              <div className="text-gray">Waiting...</div>
            )}

            {selectType === "reader" && displayReader.length > 0 && (
              <div className="tracker">
                {displayReader.map((item, index) => (
                  <div key={index} className="d-flex w-100 break-all">
                    <span className="me-1">{index + 1}.</span>
                    {item}
                  </div>
                ))}
              </div>
            )}
            {selectType === "reader" &&
              displayReader &&
              displayReader.length === 0 && (
                <div className="text-gray">Waiting...</div>
              )}
          </div>
        ) : (
          <div>Waiting...</div>
        )}
      </Card.Body>
    </Card>
  );
};
export default Diagnostics;
