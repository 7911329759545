import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import {
  faBolt,
  faCarBattery,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../api";
import { Info } from "react-feather";

const ChargerInfo = ({ id, detail }) => {
  const [data, setResult] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (id) {
      getChargerInfo();
    }
  }, [id]);
  const getChargerInfo = () => {
    api.get(`solarchargers/status/${id}`).then((r) => {
      setResult(r.data || null);
    });
  };

  return data ? (
    <React.Fragment>
      <Card className={`mb-0 ${detail ? "" : "border-top"}`}>
        <Card.Body className={detail ? "p-0" : ""}>
          <ul className="list-unstyled col-sm-12">
            <li data-toggle="tooltip" title="" data-placement="right">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h6 className="mb-0">SOLAR CHARGER</h6>
                <Info
                  onClick={() => setShow(true)}
                  className="text-primary cursor-pointer ms-2"
                  size={"16"}
                />
              </div>
            </li>
            <li>
              <FontAwesomeIcon
                icon={faCarBattery}
                fixedWidth
                className="me-1"
              />{" "}
              Battery Watts:{" "}
              <span id="solarBatteryWatts">
                {data?.records?.data[107]?.formattedValue}
              </span>
              <br />
              <FontAwesomeIcon icon={faPlug} fixedWidth className="me-1" />{" "}
              Charge State:{" "}
              <span id="solarChargeState">
                {data?.records?.data[85]?.formattedValue}
              </span>
              <br />
              <FontAwesomeIcon icon={faBolt} fixedWidth className="me-1" />{" "}
              Voltage:{" "}
              <span id="solarVoltage">
                {data?.records?.data[81]?.formattedValue}
              </span>
            </li>
          </ul>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>SOLAR CHARGER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FontAwesomeIcon icon={faCarBattery} fixedWidth className="me-1" />
            Battery Watts
          </p>
          <p>
            The amount of power coming out the battery A minus number indicates
            power is being drawn from the battery
          </p>
          <p>
            <FontAwesomeIcon icon={faPlug} fixedWidth className="me-1" />
            Charge State
          </p>
          <p>
            Bulk: The battery is being charged Float: Battery is fully charged &
            trickle charging (topping up) Absorption: The moment between bulk
            and float, (only a brief visible status)
          </p>
          <p>
            <FontAwesomeIcon icon={faBolt} fixedWidth className="me-1" />
            Voltage
          </p>
          <p>
            The critical factor, this number must remain above 11 V for the
            signal to function. The voltage triggers alerts to advise of
            required battery exchanges.{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  ) : null;
};
export default ChargerInfo;
