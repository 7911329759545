import React, { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { Hash } from "react-feather";
import { NavLink } from "react-router-dom";

const columns = [
  {
    Header: "#",
    accessor: "hashstring",
    Cell: (cell) => (
      <NavLink
        to={{
          pathname: "/globalsearch",
          search: `?searchString=${encodeURIComponent("#" + cell.value)}`,
        }}
      >
        {cell.value}
      </NavLink>
    ),
  },
  {
    Header: "People",
    accessor: "people",
    Cell: (cell) => (
      <NavLink
        to={{
          pathname: "/people",
          search: `?filter=${encodeURIComponent(
            "#" + cell.row.original.hashstring
          )}`,
        }}
      >
        {cell.value}
      </NavLink>
    ),
  },
  {
    Header: "Assets",
    accessor: "assets",
    Cell: (cell) => (
      <NavLink
        to={{
          pathname: "/assets",
          search: `?filter=${encodeURIComponent(
            "#" + cell.row.original.hashstring
          )}`,
        }}
      >
        {cell.value}
      </NavLink>
    ),
  },
  {
    Header: "Zones",
    accessor: "zones",
    Cell: (cell) => (
      <NavLink
        to={{
          pathname: "/zones",
          search: `?filter=${encodeURIComponent(
            "#" + cell.row.original.hashstring
          )}`,
        }}
      >
        {cell.value}
      </NavLink>
    ),
  },
  {
    Header: "",
    accessor: "id",
    type: "action",
    returnParam: "hashstring",
    action: [
      { name: "Edit", color: "primary" },
      { name: "Delete", color: "danger" },
    ],
    disableSortBy: true,
  },
];

const Hashtags = () => {
  const [resultCount, setResultCount] = useState(0);
  const [newHashString, setNew] = useState("");
  const [oldHashString, setOld] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrent] = useState("");
  const childRef = useRef();
  const notify = useContext(NotyfContext);

  const btnClick = (name, id) => {
    setCurrent(name);
    setOld(id);
    setNew(id);
    setShowModal(true);
  };

  const editHashTag = () => {
    api
      .put(
        `hashtags?oldHashString=${oldHashString}&newHashString=${newHashString}`
      )
      .then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        childRef.current.updateTable(1);
      });
    setShowModal(false);
  };

  const removeHashTag = () => {
    api.delete(`hashtags/${oldHashString}`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      childRef.current.updateTable(1);
    });
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title="Hashtags" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Hash className={"me-2"} />
          Hashtags {typeof resultCount === "string" && `(${resultCount})`}
        </h1>
        <Card className="bg-white p-3">
          <CommonTable
            ref={childRef}
            setCountParent={setResultCount}
            btnClick={btnClick}
            apiName="hashtags"
            columns={columns}
          />
        </Card>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentAction === "Edit" ? "Edit Hashtag" : "Please Confirm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAction === "Delete" && (
            <span>{`Would you like to remove the Hashtag '${oldHashString}'?`}</span>
          )}
          {currentAction === "Edit" && (
            <input
              value={newHashString}
              onChange={(e) => setNew(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {currentAction === "Delete" && (
            <Button variant="danger" onClick={() => removeHashTag()}>
              {currentAction}
            </Button>
          )}
          {currentAction === "Edit" && (
            <Button variant="primary" onClick={() => editHashTag()}>
              Save
            </Button>
          )}
          <Button variant={"secondary"} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Hashtags;
