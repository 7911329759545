import React from "react";
import { Alert } from "react-bootstrap";

const MediaDeviceCheck = (props) => {
  const { setWaiting, setAllowed, setGotStream } = props;

  const handleUserMedia = React.useCallback(
    (mediaStream) => {
      if (mediaStream) {
        try {
          mediaStream.getTracks().forEach((track) => {
            if (track.readyState === "live" && track.kind === "video") {
              track.stop();
            }
          });
        } catch {}
        setAllowed(true);
        setGotStream(true);
      }
      setWaiting(false);
    },
    [setWaiting, setAllowed, setGotStream]
  );

  React.useEffect(() => {
    setWaiting(true);
    //navigator.mediaDevices.enumerateDevices().then(handleDevices);
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(handleUserMedia)
      .catch((e) => {
        // console.log(e);
        // Assumption is that if we are not allowed we'll get the NotAllowedError,
        //  anything else is an issue with video hardware or no video hardware.
        if (e.name === "NotAllowedError") {
          setWaiting(false);
          setAllowed(false);
        } else {
          setWaiting(false);
          setAllowed(true);
        }
      });
  }, []);

  return (
    <>
      <div>checking...</div>
    </>
  );
};

const MediaDevicesWrapper = ({ children }) => {
  const [waiting, setWaiting] = React.useState(true);
  const [allowed, setAllowed] = React.useState(false);
  const [gotStream, setGotStream] = React.useState(false);

  return (
    <>
      {!allowed && !gotStream && (
        <MediaDeviceCheck
          setWaiting={setWaiting}
          setAllowed={setAllowed}
          setGotStream={setGotStream}
        ></MediaDeviceCheck>
      )}
      {!waiting && !allowed && (
        <Alert variant="danger">
          Access to your media devices has not been granted
        </Alert>
      )}
      {waiting && (
        <Alert variant="info">
          To proceed, you must grant access to your media devices.
        </Alert>
      )}
      {!waiting &&
        (allowed && gotStream ? (
          <> {children} </>
        ) : (
          <Alert variant="danger">Unable to access any video devices.</Alert>
        ))}
    </>
  );
};

export default MediaDevicesWrapper;
