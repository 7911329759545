import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const columns = [
  {
    Header: "",
    accessor: "id",
    type: "action",
    returnParam: "id",
    action: [{ name: "Add", color: "success" }],
    disableSortBy: true,
  },
  {
    Header: "Name",
    accessor: "ruleName",
    type: "link",
    link: "/rules/edit",
    stateId: "id",
  },
  {
    Header: "Type",
    accessor: "type",
    type: "rule",
  },
  {
    Header: "Status",
    accessor: "status",
    type: "ruleStatus",
  },
];

const AddExistingModal = ({
  show,
  onHide,
  apiName,
  dataId,
  onUpdatedRules,
}) => {
  const notify = useContext(NotyfContext);

  const btnClick = (name, id) => {
    api.post(`rules/${id}/${apiName}/link/${dataId}`).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      onUpdatedRules();
    });
  };
  return (
    <React.Fragment>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Existing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable btnClick={btnClick} apiName="rules" columns={columns} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default AddExistingModal;
