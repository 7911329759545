import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Grid } from "react-feather";
import api from "../../api";
import CommonTable from "../tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import QRCodeWithDownload from "../QRCodeWithDownload";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const DeviceQrCode = (props) => {
  const { id, type, data } = props;
  const [qrCode, setQrCode] = useState(null);
  const isUnmount = useRef(false);
  const [showModal, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [curId, setCurrent] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  useEffect(() => {
    if (!id) {
      return;
    }
    getQrCode();
  }, [id]);

  useEffect(() => {
    if (user && id && type) {
      if (user.role === "User") {
        switch (type) {
          case "assets":
            setCanEdit(user.editAssets);
            break;

          case "people":
            setCanEdit(user.editPeople);
            break;

          case "zones":
            setCanEdit(user.editZones);
            break;

          default:
            setCanEdit(false);
            break;
        }
      } else {
        setCanEdit(true);
      }
    }
  }, [user, id, type]);

  useEffect(() => {
    return () => {
      isUnmount.current = true;
    };
  }, []);

  const getQrCode = async () => {
    try {
      var result = await api.get(`${type}/qrcodes/${id}`);
      if (isUnmount.current) return;
      setQrCode(result.data);
    } catch (error) {
      if (isUnmount.current) return;
      setQrCode(null);
    }
  };

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 60,
      disableSortBy: true,
      Cell: (cell) => (
        <Button
          variant={
            cell.row.original.assetId ||
            cell.row.original.personId ||
            cell.row.original.zoneId
              ? "danger"
              : "success"
          }
          onClick={() =>
            onOperate(
              cell.row.original.assetId ||
                cell.row.original.personId ||
                cell.row.original.zoneId
                ? "warn"
                : "edit",
              cell.value,
              cell.row.original
            )
          }
        >
          Link
        </Button>
      ),
    },
    {
      Header: "#Serial",
      accessor: "serial",
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow",
    },
    {
      Header: "Linked",
      accessor: "elementId",
      type: "linkStatus",
      disableSortBy: true,
    },
  ];

  const actionLink = () => {
    setShow(true);
  };

  const actionUnlink = (id) => {
    setCurrent(id);
    setShow(true);
  };

  const onOperate = (name, chooseId, data) => {
    if (qrCode) {
      api
        .post(`qrcodes/${qrCode.id}/unlink/${id}`, {
          params: { parentId: id, id: qrCode.id },
        })
        .then((res) => {
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
          getQrCode();
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
        });
      setCurrent(null);
    } else {
      if (name === "edit") {
        api
          .post(`qrcodes/${chooseId}/link/${id}`, {
            params: { parentId: id, id: curId },
          })
          .then((res) => {
            notify.open({
              type: "success",
              message: "Changes Saved",
            });
            getQrCode();
          })
          .catch((e) => {
            notify.open({
              type: "error",
              message: e,
            });
          });
        setCurrent(null);
      } else if (name === "warn") {
        setShowWarning(data);
      } else if (name === "relink") {
        api
          .post(
            `qrcodes/${chooseId?.id}/unlink/${
              chooseId?.assetId || chooseId?.personId || chooseId?.zoneId
            }`
          )
          .then((res) => {
            api
              .post(`qrcodes/${chooseId?.id}/link/${id}`, {
                params: { parentId: id, id: curId },
              })
              .then((res) => {
                notify.open({
                  type: "success",
                  message: "Changes Saved",
                });
                getQrCode();
                setShowWarning(null);
              });
          })
          .catch((e) => {
            notify.open({
              type: "error",
              message: e,
            });
          });
      }
    }
    setShow(false);
  };

  return (
    <>
      <Container fluid className="p-0 m-0">
        <div className="d-flex justify-content-between align-items-center">
          <h5>
            <Grid size={18} />
            &nbsp;QR Code
          </h5>
          {canEdit &&
            (qrCode ? (
              <>
                <Button
                  variant="danger"
                  onClick={() => actionUnlink(qrCode.id)}
                >
                  Unlink QR Code
                </Button>
              </>
            ) : (
              <>
                <Button variant="primary" onClick={() => actionLink()}>
                  Link QR Code
                </Button>
              </>
            ))}
        </div>
        <div>
          {qrCode && (
            <>
              {user?.role === "Root" ? (
                <div>
                  Serial:{" "}
                  <NavLink
                    to={{
                      pathname: "/qrcodes/edit",
                      search: `?id=${qrCode.id}`,
                    }}
                  >{`${qrCode.serial}`}</NavLink>
                </div>
              ) : (
                <div className="mt-2">QR Code: {qrCode.serial}</div>
              )}
              <QRCodeWithDownload value={`${qrCode.serial}`} />
            </>
          )}
          {!qrCode && (
            <div className="mt-2">
              TracLogik's QR Codes allow the user to easily navigate the website
              by scanning a QR Code, taking the user to the associated Asset,
              Person or Zone.
            </div>
          )}
        </div>
      </Container>
      <hr />
      <Modal
        show={showModal}
        size={curId ? `` : "lg"}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${
            curId ? "Unlink a" : "Link a"
          } QR Code`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrCode && (
            <span>
              Are you sure that you wish to unlink this QR Code from{" "}
              {data?.compoundName}?
            </span>
          )}
          {!qrCode && (
            <CommonTable
              btnClick={onOperate}
              ref={childRef}
              apiName="qrcodes"
              columns={columns}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {qrCode && (
            <Button variant="danger" onClick={() => onOperate()}>
              Unlink
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarning} onHide={() => setShowWarning(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure that you wish to link this QR Code to{" "}
            {data?.compoundName}? This will unlink it from{" "}
            {showWarning?.assetCompoundName ||
              showWarning?.personCompoundName ||
              showWarning?.zoneCompoundName}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarning(null)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeviceQrCode;
