import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { BatteryCharging } from "react-feather";
import api from "../../api";
import LoadingSpin from "../loadingSpin";

const AssetAverageBatteryLifespan = ({ assetId }) => {
  const [asset, setAsset] = useState(null);
  const [avg, setAvg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`assets/averagebatterylifecycle/${assetId}`)
      .then((r) => {
        setLoading(false);
        if (r.data.average === 0) {
          setAsset(null);
          setAvg(null);
          return;
        }
        setAsset(r.data.asset);
        setAvg(
          (((r.data.asset - r.data.average) / r.data.average) * 100).toFixed(1)
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }, [assetId]);

  return (
    <Card className="border flex-grow-1">
      <Card.Header className="pb-0">
        <Card.Title className="d-flex justify-content-between align-items-center">
          <span>Asset Battery Lifecycle</span>
          <div
            className="d-flex justify-content-center align-items-center bg-light"
            style={{ width: "36px", height: "36px", borderRadius: "50%" }}
          >
            <BatteryCharging size={16} />
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body style={{ minHeight: "200px" }}>
        {!loading && asset && (
          <div>
            <h3 className="mt-2 mb-4">{asset} Hours</h3>
            <div className="d-flex">
              <span
                style={{ background: "#37494C" }}
                className="ps-2 pe-2 radius-3 text-success"
              >
                +{avg}%
              </span>
              <span className="ms-2">on base average</span>
            </div>
          </div>
        )}
        {!asset && !loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            Waiting for data
          </div>
        )}
        {loading && (
          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <LoadingSpin loading={loading} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
export default AssetAverageBatteryLifespan;
