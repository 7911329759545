import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import useSrlHelper from "../../hooks/useSrlHelper";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../../utils/staticMethods";
import moment from "moment-timezone";

const SrlFavouritePage = () => {
  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const { type } = queryString.parse(location.search);
  const [columns, setColumns] = useState({ assets: [], people: [], zones: [] });
  const { renderSupply } = useSrlHelper();
  const curTime = useRef(moment());

  useEffect(() => {
    let assetColumns = [];
    let zoneColumns = [];
    let personColumns = [];

    assetColumns.push({
      Header: "Asset Owner",
      accessor: "suppString1",
      visibilityName: "asset-owner",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    });
    assetColumns.push({
      Header: "SRL Fleet #",
      accessor: "fleetNumber",
      visibilityName: "srl-fleet-number",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    });
    assetColumns.push({
      Header: "Customer Fleet #",
      accessor: "suppString2",
      visibilityName: "customer-fleet-number",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    });
    assetColumns.push({
      Header: "SRL Product Name",
      accessor: "category",
      visibilityName: "srl-product-name",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    });
    assetColumns.push({
      Header: "Customer Reference",
      accessor: "primaryName",
      visibilityName: "cust-ref",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    });
    assetColumns.push({
      Header: "Asset Battery",
      accessor: "lastPosition.supply",
      visibilityName: "supply",
      Cell: (cell) => {
        return renderSupply(cell.row?.original?.lastPosition?.supply);
      },
      // disableSortBy: true,
    });
    assetColumns.push({
      Header: "Last Seen",
      accessor: "lastSeen",
      visibilityName: "last-seen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return (
            <>
              <MapPin width={14} height={14} className="me-1" />
              {curTime.current
                ? dateFromNow(cell.value, curTime.current)
                : dateFromNow(cell.value)}
            </>
          );
        }
        return (
          <span>
            <Moon width={14} height={14} className="me-1" /> Waiting...
          </span>
        );
      },
    });
    assetColumns.push({
      Header: "Location Coordinates",
      accessor: "lastPosition",
      visibilityName: "loc-coord",
      disableSortBy: true,
      Cell: (cell) => {
        if (cell.value) {
          var lat = Number(cell.value.latitude).toFixed(6);
          var lng = Number(cell.value.longitude).toFixed(6);
          return (
            <>
              {lat},{lng}
            </>
          );
        } else {
          return <>Waiting...</>;
        }
      },
    });

    zoneColumns.push({
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true,
    });
    zoneColumns.push({
      Header: "Name",
      accessor: "compoundName",
      type: "link",
      stateId: "id",
      link: "/zones/edit",
    });
    zoneColumns.push({
      Header: "Reference",
      accessor: "reference",
      visibilityName: "reference",
    });
    zoneColumns.push({
      Header: "Contents",
      accessor: "contentsCount",
      visibilityName: "contents-count",
      disableSortBy: true,
    });
    zoneColumns.push({
      Header: "Hashtags",
      accessor: "hashTags",
      visibilityName: "hash-tags",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          {cell.value &&
            cell.value.map((tag, i) => (
              <NavLink
                key={tag}
                to={{
                  pathname: "/globalSearch",
                  search: `?searchString=${encodeURIComponent("#" + tag)}`,
                }}
              >
                <span
                  key={i}
                  className="badge bg-primary me-1 my-1 cursor-pointer"
                >
                  {tag}
                </span>
              </NavLink>
            ))}
        </div>
      ),
    });

    personColumns.push({
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true,
    });
    personColumns.push({
      Header: "Name",
      accessor: "compoundName",
      type: "link",
      link: "/people/edit",
      stateId: "id",
    });
    personColumns.push({
      Header: "Reference",
      accessor: "reference",
      visibilityName: "reference",
    });
    personColumns.push({
      Header: "Last Seen",
      accessor: "lastSeen",
      visibilityName: "last-seen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return (
            <>
              <MapPin width={14} height={14} className="me-1" />
              {curTime.current
                ? dateFromNow(cell.value, curTime.current)
                : dateFromNow(cell.value)}
            </>
          );
        }
        return (
          <span>
            <Moon width={14} height={14} className="me-1" /> Waiting...
          </span>
        );
      },
    });
    personColumns.push({
      Header: "Hashtags",
      accessor: "hashTags",
      visibilityName: "hash-tags",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          {cell.value &&
            cell.value.map((tag, i) => (
              <NavLink
                key={tag}
                to={{
                  pathname: "/globalSearch",
                  search: `?searchString=${encodeURIComponent("#" + tag)}`,
                }}
              >
                <span
                  key={i}
                  className="badge bg-primary me-1 my-1 cursor-pointer"
                >
                  {tag}
                </span>
              </NavLink>
            ))}
        </div>
      ),
    });

    setColumns({
      assets: assetColumns,
      people: personColumns,
      zones: zoneColumns,
    });
  }, []);

  const toFirstLetterUpperCase = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
  };

  return (
    <React.Fragment>
      <Helmet
        defer={false}
        title={`Favourite ${toFirstLetterUpperCase(type)}`}
      />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            Favourite {toFirstLetterUpperCase(type)}{" "}
            {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            setCountParent={setResultCount}
            apiName={`accounts/favourites/${type}`}
            columns={columns[type]}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default SrlFavouritePage;
