import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import { AlertTriangle } from "react-feather";

const columns = [
  {
    Header: "Name",
    accessor: "ruleName",
    type: "link",
    link: "/rules/edit",
    stateId: "id",
  },
  {
    Header: "Type",
    accessor: "type",
    type: "rule",
  },
  {
    Header: "Status",
    accessor: "status",
    type: "ruleStatus",
  },
];

const Rules = () => {
  const [resultCount, setResultCount] = useState(0);
  const childRef = useRef();
  const { user } = useAuth();

  return (
    <React.Fragment>
      <Helmet defer={false} title="Rules" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <AlertTriangle className="me-2" />
            Rules {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
          {(user?.role === "Admin" || user?.createRules) && (
            <NavLink to="/rules/create">
              <Button className="text-nowrap">
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                Create New
              </Button>
            </NavLink>
          )}
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            ref={childRef}
            setCountParent={setResultCount}
            apiName="rules"
            columns={columns}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Rules;
