import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Datetime from "react-datetime";
import { X } from "react-feather";

const DateFilter = (props) => {
  const {
    column: { /* filterValue, */ setFilter },
  } = props;
  const [beforeAfter, setBeforeAfter] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);

  const renderInput = (props, openCalendar, closeCalendar) => {
    function clear() {
      closeCalendar();
      props.onChange({ target: { value: "" } });
    }
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setBeforeAfter(!beforeAfter);
          }}
        >
          {!beforeAfter ? "before" : "after"}
        </span>
        &nbsp;
        <input {...props} style={{ minWidth: "110px", maxWidth: "110px" }} />
        <Button variant="" className="p-0 m-0" onClick={clear}>
          <X />
        </Button>
      </div>
    );
  };

  const renderView = (mode, renderDefault) => {
    if (mode === "time") return renderDefault();

    return (
      <Stack>
        {renderDefault()}
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => {
              setBeforeAfter(false);
            }}
          >
            Before
          </button>
          <button
            onClick={() => {
              setBeforeAfter(true);
            }}
          >
            After
          </button>
        </div>
      </Stack>
    );
  };

  useEffect(() => {
    if (dateSelected && dateSelected.isValid && dateSelected.isValid()) {
      setFilter(
        (!beforeAfter ? "»»<" : "»»>") +
          (dateSelected ? dateSelected.toISOString() : "")
      );
    } else {
      setFilter("");
    }
  }, [dateSelected, beforeAfter]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <Datetime
        value={dateSelected}
        onChange={(v) => {
          setDateSelected(v);
        }}
        renderInput={renderInput}
        renderView={renderView}
        dateFormat="DD/MM/YYYY"
        timeFormat={false}
        locale="en-gb"
        utc={true}
        isValidDate={(a, b) => {
          return a < new Date();
        }}
      />
    </div>
  );
};

export default DateFilter;
