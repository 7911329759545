import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle as faCircleSolid,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
  AlertCircle,
  BatteryCharging,
  Clock,
  Globe,
  Grid,
  LogIn,
  LogOut,
  Move,
  PauseCircle,
  Radio,
  Smartphone,
  Sun,
  TrendingDown,
  UserX,
  Wifi,
} from "react-feather";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import defaultImg from "../assets/img/icon/Aircraft/Airliner_Black.png";
import moment from "moment-timezone";
import { THEME_PALETTE_LIGHT as palette } from "../constants";
import axios from "axios";
import api from "../api";

// return table expanded icon
export const faMinusIcon = () => {
  return (
    <span className="fa-layers fa-fw fa-lg me-1 align-middle">
      <FontAwesomeIcon icon={faCircle} color="white" />
      <FontAwesomeIcon
        icon={faCircleSolid}
        color="#D9534F"
        transform="shrink-3"
      />
      <FontAwesomeIcon icon={faMinus} transform="shrink-6" inverse />
    </span>
  );
};

// calculate middle point with p1 and p2
export const middle = (P1, P2) => {
  let lat = (P1.lat() + P2.lat()) / 2;
  let lng = P1.lng() - P2.lng();
  if (lng <= 180 && lng >= -180) {
    lng = (P1.lng() + P2.lng()) / 2;
  } else {
    lng = (P1.lng() + P2.lng() + 360) / 2;
  }
  return { lat, lng };
};

// get display name with type name
export const getName = (name) => {
  if (name === "assets") return "Asset";
  if (name === "people") return "Person";
  if (name === "zones") return "Zone";
  if (name === "readers") return "Reader";
  if (name === "tackers") return "Tracker";
  if (name === "tags") return "Tag";
};

// It is for recentItem widget, these data will save to localstorage
export const filterStorage = (obj) => {
  let storage = JSON.parse(localStorage.getItem("recentItem")) || [];
  let index = -1;
  for (let i = 0; i < storage.length; i++) {
    if (storage[i].id === obj.id) {
      index = i;
      break;
    }
  }
  if (index > -1) {
    storage.splice(index, 1);
    storage.unshift(obj);
  } else {
    storage.unshift(obj);
  }

  if (storage.length > 5) {
    storage = storage.slice(0, 5);
  }
  localStorage.setItem("recentItem", JSON.stringify(storage));
};

// return table expanded icon (responsive table)
export const faPlusIcon = () => {
  return (
    <span className="fa-layers fa-fw fa-lg me-1 align-middle">
      <FontAwesomeIcon icon={faCircle} color="white" />
      <FontAwesomeIcon
        icon={faCircleSolid}
        color="#3F80EA"
        transform="shrink-3"
      />
      <FontAwesomeIcon icon={faPlus} transform="shrink-6" inverse />
    </span>
  );
};

// return if table expanded (responsive table)
export const returnTableExpand = (row, cell) => {
  return row?.allCells[0]?.column?.Header === cell.column.Header ? (
    <span {...row.getToggleRowExpandedProps()}>
      {row.isExpanded ? faMinusIcon() : faPlusIcon()}
    </span>
  ) : null;
};

// calculate if the cell is expanded (responsive table)
export const needShowColumn = (cell, expand) => {
  let arr = expand.filter((item) => item?.Header === cell?.Header);
  return arr.length === 0;
};

export const downloadFile = (response) => {
  let filename = "";
  // See if we can get the filename from the response header, if not, we'll create our own
  try {
    filename = response.headers["content-disposition"]
      .split(";")[1]
      .split("=")[1]
      .trim()
      .replace(/"/g, "");
  } catch (error) {
    filename = new Date().toLocaleString() + ".csv";
  }

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// get image resource in frontend
// asset/person/zone icon here, backend only returns icon name, so I need to write a method to know which category the icon belongs to, and where is this icon
export const getUrl = (url) => {
  if (!url) return "";
  let fileName = url.split(".")[0];
  let formatName = fileName.replaceAll("-", "_");
  if (formatName.indexOf("1") === 0) {
    formatName = formatName.replace("1", "a_1");
  } else if (formatName.indexOf("0") === 0) {
    formatName = formatName.replace("0", "o_0");
  } else if (formatName.indexOf("3") === 0) {
    formatName = formatName.replace("3", "c_3");
  }
  if (formatName === "package") {
    formatName = "package_0";
  }
  return formatName.replaceAll("/", "");
};
// update marker
export const updateMarker = (arr, markerArr, infoItem, mapInstance, mapApi) => {
  /*
  arr: new nearby array from backend
  markerArr: the markers array
  infoItem: popup for markers
  mapInstance: map object
  mapApi: mapApi used to call methods
  * */
  arr.forEach((item) => {
    // loop the new array and then loop the markerArr, compare them:
    for (let i = markerArr.length - 1; i >= 0; i--) {
      // if the new marker has no latitude and longitude, that means it may changed from circle to polygon, so first we remove the existing circle marker, and it will create new polygon marker in other method
      if (markerArr[i].id === item.id && (!item.latitude || !item.longitude)) {
        markerArr[i].setMap(null);
        markerArr.splice(i, 1);
      }
    }
    let m = markerArr.filter((item1) => item1.id === item.id);
    if (m.length > 0) {
      // find the existing markers with same id (it maybe a zone, a zone will have at least two markers, one is icon for the center point, and the other is the area maker for this zone,a circle or a polygon)
      m.forEach((single, index) => {
        // if this marker is a single marker, not a cluster of markers and it is not a zone, update the marker to new position (if marker moved it position will change)
        if (single.idsArr.length <= 1 && single.type !== "zone") {
          single.setPosition({
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          });
        } else if (
          single.type === "zone" &&
          item.radius &&
          single.shape === "circle"
        ) {
          // if this marker is a zone and marker area is a circle shape (a circle should have radius), update the position and radius
          single.setCenter({
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          });
          single.setRadius(Number(item.radius));
        } else if (
          single.type === "zone" &&
          item.polygon &&
          single.shape === "polygon"
        ) {
          // if this marker is a zone and marker area is a polygon (a polygon should have a path array), update the position and path
          let firstItem = item.polygon[0].split(",");
          let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
            maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
          let arr = item.polygon.map((item) => {
            let me = item.split(",");
            let lng = Number(me[0]);
            let lat = Number(me[1]);
            if (lat > maxLat.lat) maxLat = { lat, lng };
            if (lat < minLat.lat) minLat = { lat, lng };
            if (lng > maxLng.lng) maxLng = { lat, lng };
            if (lng < minLng.lng) minLng = { lat, lng };
            return { lng, lat };
          });
          single.setPaths(arr);
        } else if (single.type === "zone" && item.polygon) {
          // if this marker is a zone and marker area is a polygon, update it's center point
          for (let i = 0; i < markerArr.length; i++) {
            if (markerArr[i].type === "zone" && single.id === markerArr[i].id) {
              markerArr[i].setMap(null);
              let firstItem = item.polygon[0].split(",");
              let minLat = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                maxLat = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                minLng = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                },
                maxLng = {
                  lat: Number(firstItem[1]),
                  lng: Number(firstItem[0]),
                };
              let arr = item.polygon.map((item) => {
                let me = item.split(",");
                let lng = Number(me[0]);
                let lat = Number(me[1]);
                if (lat > maxLat.lat) maxLat = { lat, lng };
                if (lat < minLat.lat) minLat = { lat, lng };
                if (lng > maxLng.lng) maxLng = { lat, lng };
                if (lng < minLng.lng) minLng = { lat, lng };
                return { lng, lat };
              });
              markerArr[i] = new mapApi.Polygon({
                paths: arr,
                idsArr: [item.id],
                ids: [item],
                id: item.id,
                type: "zone",
                shape: "polygon",
                strokeColor: "#000000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#242424",
                fillOpacity: 0.4,
                editable: false,
                map: mapInstance,
              });
            }
          }
        } else if (single.type === "zone" && item.radius) {
          // if this marker is a zone and marker area is a circle, update it's center point
          for (let i = 0; i < markerArr.length; i++) {
            if (markerArr[i].type === "zone" && single.id === markerArr[i].id) {
              markerArr[i].setMap(null);
              markerArr[i] = new mapApi.Circle({
                id: item.id,
                idsArr: [item.id],
                ids: [item],
                strokeColor: "#090B29",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#090B29",
                fillOpacity: 0.4,
                shape: "circle",
                type: "zone",
                center: {
                  lat: Number(item.latitude),
                  lng: Number(Number(item.longitude)),
                },
                map: mapInstance,
                radius: Number(item.radius),
              });
            }
          }
        }
      });
      // also update the popup window position because it related to markers
      if (infoItem) {
        let filter = infoItem.filter(
          (info) => info.lastPosition.id === m[0].id
        );
        if (filter.length > 0) {
          filter[0].lastPosition.longitude = Number(item.longitude);
          filter[0].lastPosition.latitude = Number(item.latitude);
        }
      }
      // update the marker icon if it changed
      m[0].idsArr.length <= 1 &&
        m[0].setIcon({
          ...m[0].icon,
          url: imageSet[getUrl(item.icon)]
            ? require("../assets/img/icon/" + imageSet[getUrl(item.icon)])
                .default
            : defaultImg,
        });
    }
  });
};

export const getRes = (num, digit = 3) => {
  if (Number(num) === Math.floor(num)) return Math.floor(num);
  let res = [],
    addNum = 0;
  num += "";
  let [zs, xs] = num.split(".");
  let [, symbol, zsNum] = /^(-?)(\d+\.\d+|\d+)$/.exec(zs);
  for (let i = 0; i < xs.length; i++) {
    const ele = xs[i];
    if (ele !== 0) {
      if (xs[i + digit] && xs[i + digit] > 4) {
        addNum = 1;
        for (let j = i + (digit - 1); j >= 0; j--) {
          if (Number(xs[j]) + addNum === 10) res[j] = "0";
          else {
            res[j] = Number(xs[j]) + addNum;
            addNum = 0;
          }
        }
      } else {
        for (let k = i; k <= i + (digit - 1); k++) {
          res[k] = xs[k];
        }
      }
      break;
    } else res[i] = xs[i];
  }
  return Number(symbol + (Number(zsNum) + addNum) + "." + res.join(""));
};

export const rad = (x) => {
  return (x * Math.PI) / 180;
};

export const getDistance = (p1, p2) => {
  let R = 6378137; // Earth’s mean radius in meter
  let dLat = rad(p2.lat() - p1.lat());
  let dLong = rad(p2.lng() - p1.lng());
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) *
      Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d; // returns the distance in meter
};

// everytime get response from nearby endpoints, calculate array of markers need to add, and array of markers need to remove.
export const resArr = (arr1, arr2) => {
  let returnArr1 = [];
  // remove
  if (arr1.length > 0 && arr1[0].idsArr) {
    let arr = [];
    arr1.forEach((item) => {
      arr = item.ids.concat(arr);
    });
    returnArr1 = arr.filter((item) =>
      arr2.every((val) => {
        return item.id !== val.id;
      })
    );
    let movedMarker = [];
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (
          arr1[i].position &&
          arr1[i].position.lat().toFixed(5) ===
            Number(arr2[j].latitude).toFixed(5) &&
          arr1[i].position.lng().toFixed(5) ===
            Number(arr2[j].longitude).toFixed(5) &&
          arr1[i].type !== "zones" &&
          arr2[j].type !== "zone" &&
          !arr1[i].idsArr.includes(arr2[j].id)
        ) {
          movedMarker.push(arr2[j].id);
        }
      }
    }
    let markerNeedCombine = arr1.filter((item) => {
      return movedMarker.includes(item.id) && item.idsArr.length <= 1;
    });

    let returnArr2 = arr2.filter((v) => {
      for (let i = 0; i < arr1.length; i++) {
        if (
          arr1[i].idsArr.length > 1 &&
          arr1[i].idsArr.includes(v.id) &&
          (Number(v.latitude).toFixed(5) !==
            arr1[i].position.lat().toFixed(5) ||
            Number(v.longitude).toFixed(5) !==
              arr1[i].position.lng().toFixed(5))
        ) {
          return true;
        }
      }
      return false;
    });
    return returnArr1.concat(returnArr2).concat(markerNeedCombine);
  } else {
    return arr1.filter((v) =>
      arr2.every((val) => {
        if (val.idsArr && val.idsArr.length > 0) {
          // add
          if (
            val.idsArr.length > 1 &&
            val.idsArr.includes(v.id) &&
            (Number(v.latitude).toFixed(5) !== val.position.lat().toFixed(5) ||
              Number(v.longitude).toFixed(5) !== val.position.lng().toFixed(5))
          ) {
            return true;
          }
          return !val.idsArr.includes(v.id);
        }
        return false;
      })
    );
  }
};

// rule icon
export const getIcon = (icon) => {
  if (icon === "attendance") {
    return <Clock size={18} />;
  } else if (icon === "exitzone") {
    return <LogOut size={18} />;
  } else if (icon === "enterzone") {
    return <LogIn size={18} />;
  } else if (icon === "lowbattery") {
    return <BatteryCharging size={18} />;
  } else if (icon === "lowvoltage") {
    return <TrendingDown size={18} />;
  } else if (icon === "motion") {
    return <Move size={18} />;
  } else if (icon === "speeding") {
    return <AlertCircle size={18} />;
  } else if (icon === "pausetracking") {
    return <PauseCircle size={18} />;
  } else if (icon === "zonesupervision") {
    return <UserX size={18} />;
  }
  return null;
};

// styles for selector component
export const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#7f838e",
    backgroundColor: "none",
    "&:hover": {
      borderColor: "#7f838e",
    },
    color: "#BFC1C6",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#BFC1C6",
  }),
  indicatorSeparator: () => ({
    backgroundColor: "#7f838e",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#eaeaec",
    backgroundColor: state.isSelected
      ? "#3F80EA"
      : state.isFocused
      ? "#343a4b"
      : "#293042",
    paddingLeft: "0.75rem",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    borderBottom: "1px solid rgba(255, 255, 255, 0.125)",
  }),
  group: () => ({
    margin: 0,
  }),
  groupHeading: () => ({
    color: "#A9ACB3",
    paddingLeft: "0.5rem",
    background: "#343a4b",
    borderBottom: "1px solid rgba(255, 255, 255, 0.125)",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#293042",
    border: "1px solid #7f838e",
  }),
};

export const colourStylesLight = {
  control: (styles) => ({
    ...styles,
    borderColor: "#ced4da",
    backgroundColor: "none",
    "&:hover": {
      borderColor: "#7f838e",
    },
    color: "#222222",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#222222",
  }),
  indicatorSeparator: () => ({
    backgroundColor: "#E0E0E0",
  }),
  option: (provided, state) => ({
    ...provided,
    color: palette["gray-900"],
    backgroundColor: state.isSelected
      ? "#808080"
      : state.isFocused
      ? "#E0E0E0"
      : "#FFFFFF",
    paddingLeft: "0.75rem",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
  }),
  group: () => ({
    margin: 0,
  }),
  groupHeading: () => ({
    color: "#A9ACB3",
    paddingLeft: "0.5rem",
    background: "#F8F8F8",
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#FFFFFF",
    border: "1px solid #7f838e",
  }),
};

export const dateFromNow = (dateTime, curTime) => {
  const SECOND = 1;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;
  const delta = curTime
    ? curTime.diff(moment(dateTime), "seconds")
    : moment().diff(moment(dateTime), "seconds");

  if (!dateTime) return null;

  if (delta <= 1) return "Now";

  if (delta < MINUTE) {
    return delta + " seconds ago";
  }

  if (delta < 2 * MINUTE) return "1 minute ago";

  if (delta < 59 * MINUTE) return Math.floor(delta / MINUTE) + " minutes ago";

  if (delta < 120 * MINUTE) return "an hour ago";

  if (delta < 24 * HOUR) return Math.floor(delta / HOUR) + " hours ago";

  if (delta < 48 * HOUR) return "yesterday";

  if (delta < 30 * DAY) return Math.floor(delta / DAY) + " days ago";

  if (delta < 12 * MONTH) {
    const months = Math.floor(delta / DAY / 30);
    return months <= 1 ? "1 month ago" : months + " months ago";
  } else {
    const years = Math.floor(delta / DAY / 365);
    return years <= 1 ? "1 year ago" : years + " years ago";
  }
};

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// convert blob to file
export const blobToFile = (theBlob, fileName, fileType) => {
  let file = new window.File([theBlob], fileName, { type: fileType });
  return file;
};

export const compressPic = (path, callback) => {
  let img = new Image();
  img.src = path;
  img.onload = function () {
    let that = this;
    let w = that.width,
      h = that.height;
    let quality = 0.3;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let anw = document.createAttribute("width");
    anw.nodeValue = w;
    let anh = document.createAttribute("height");
    anh.nodeValue = h;
    canvas.setAttributeNode(anw);
    canvas.setAttributeNode(anh);
    ctx.drawImage(that, 0, 0, w, h);
    let base64 = canvas.toDataURL("image/jpeg", quality);
    callback(base64);
  };
};

// visible radius for each zoom level
export const radiusZoomLevel = {
  23: 7,
  22: 13,
  21: 25,
  20: 50,
  19: 100,
  18: 200,
  17: 400,
  16: 800,
  15: 1500,
  14: 3000,
  13: 6000,
  12: 12000,
  11: 23000,
  10: 45000,
  9: 90000,
  8: 180000,
  7: 360000,
  6: 720000,
  5: 1440000,
  4: 2880000,
  3: 5760000,
  2: 12000000,
  1: 24000000,
  0: 48000000,
};

export const saveAnpr = (notify, scannedPlate, image, setAssets) => {
  try {
    let data = new FormData();
    data.append("scannedPlate", scannedPlate);

    try {
      let blob = dataURLtoBlob(image);
      let miniFile = blobToFile(blob, "new" + scannedPlate + ".jpg", blob.type);
      data.append("uploadedFile", miniFile);
    } catch (error) {
      data.append(
        "uploadedFile",
        new File([""], "empty.txt", { type: "text/plain" })
      );
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    api
      .post(`assettag/numberplate`, data, config)
      .then((res) => {
        let assets = res.data;
        setAssets(assets);
      })
      .catch((err) => {
        notify.open({
          type: "error",
          message: err,
        });
        setAssets([]);
      });
  } catch (error) {
    notify.open({
      type: "error",
      message: error,
    });
    setAssets([]);
  }
};

export const findAnpr = (notify, scannedPlate, image, setAssets) => {
  var source = axios.CancelToken.source();
  api
    .get("assets", {
      params: {
        filter: scannedPlate,
      },
      cancelToken: source.token,
    })
    .then((res) => {
      setAssets(
        (res.data &&
          res.data.filter((item) => {
            if (item) {
              return item;
            }
            return false;
          })) ||
          []
      );
    })
    .catch((e) => {
      setAssets([]);
    });
};

export const getDeviceIcon = (deviceType) => {
  if (deviceType) {
    if (deviceType.includes("tracker")) {
      return <Globe className="feather align-middle"></Globe>;
    } else if (deviceType.includes("tag")) {
      return <Radio className="feather align-middle"></Radio>;
    } else if (deviceType.includes("reader")) {
      return <Wifi className="feather align-middle"></Wifi>;
    } else if (deviceType.includes("app")) {
      return <Smartphone className="feather align-middle"></Smartphone>;
    } else if (deviceType.includes("qrcode")) {
      return <Grid className="feather align-middle"></Grid>;
    } else if (deviceType.includes("solarcharger")) {
      return <Sun className="feather align-middle"></Sun>;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
