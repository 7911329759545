import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Form } from "react-bootstrap";
// import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
// import axios from "axios";
import markerImg from "../../assets/img/icon/GPS/selectLocation.svg";

// a demo page that not in use now

// let source1 = null;
const ImageEdit = () => {
  const [uploadFile, setUploadFile] = useState(null);
  const [marker, setMarker] = useState(null);
  const notify = useContext(NotyfContext);
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (uploadFile && uploadFile.length > 0)
      notify.open({
        type: "success",
        message: "imageSize：" + (uploadFile[0].size / 1024).toFixed(2) + "KB",
      });
  }, [uploadFile]);

  const onUpload = () => {
    if (!uploadFile || uploadFile.length === 0) return;
    let reader = new FileReader();
    reader.onload = function (evt) {
      let replaceSrc = evt.target.result;
      compressPic(replaceSrc, (base) => {
        let blob = dataURLtoBlob(base);
        let miniFile = blobToFile(
          blob,
          "new" + uploadFile[0].name,
          uploadFile[0].type
        );
        notify.open({
          type: "success",
          message: "imageSize：" + (miniFile.size / 1024).toFixed(2) + "KB",
        });
        setPicture(base);
      });
    };
    reader.readAsDataURL(uploadFile[0]);
  };

  // base64 to blob
  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  // convert blob to file
  const blobToFile = (theBlob, fileName, fileType) => {
    let file = new window.File([theBlob], fileName, { type: fileType });
    return file;
  };

  const compressPic = (path, callback) => {
    let img = new Image();
    img.src = path;
    img.onload = function () {
      let that = this;
      let w = that.width,
        h = that.height;
      let quality = 0.3;
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let anw = document.createAttribute("width");
      anw.nodeValue = w;
      let anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);
      let base64 = canvas.toDataURL("image/jpeg", quality);
      callback(base64);
    };
  };

  // get picture
  // const getPci = () => {
  //   if(source1){
  //     source1.cancel('request canceled');
  //   }
  //   source1 = axios.CancelToken.source();
  //   api.get(`files/profilepic`, {
  //     responseType: "arraybuffer",
  //     cancelToken: source1.token
  //   }).then(res => {
  //     let blob = new Blob([res.data], { type: "img/jpeg" });
  //     let url = (window.URL || window.webkitURL).createObjectURL(blob);
  //     setPicture(url);
  //   }).catch(() => setPicture(null));
  // };

  const addMarker = (e) => {
    setMarker({
      x: (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * 100,
      y: (e.nativeEvent.offsetY / e.currentTarget.offsetHeight) * 100,
    });
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title="Image Edit" />
      <h3>Image Edit</h3>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <Form.Control
          accept="image/*"
          className="w-75 border-0 image-input"
          type="file"
          onChange={(event) => setUploadFile(event.target.files)}
        />
        <Button onClick={() => onUpload()}>Upload</Button>
      </div>
      {picture && (
        <div className="mt-3">
          <h4>Click To Add Marker</h4>
          <div className="position-relative">
            <img
              alt={"png"}
              className="w-100"
              src={picture}
              onClick={(e) => addMarker(e)}
            />
            {marker && (
              <img
                alt={"edit"}
                src={markerImg}
                className="position-absolute"
                style={{
                  left: `${marker.x}%`,
                  top: `${marker.y}%`,
                  width: "24px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageEdit;
