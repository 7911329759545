import React from "react";
import useAuth from "./useAuth";
import { NavLink } from "react-router-dom";
import { fieldNameToAssetName } from "../components/fields/fieldsHelper";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../utils/staticMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faHourglassHalf,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";

const useCustomFieldsHelper = () => {
  const { user } = useAuth();

  const getColumnsForElementType = (elementType) => {
    if (!user) return [];

    let customFieldConfig = null;
    let columns = [];

    const defaultColumns = (columns) => {
      columns.push({
        Header: "Name",
        accessor: "compoundName",
        type: "link",
        stateId: "id",
        link: `/${elementType}/edit`,
      });
      columns.push({
        Header: "Reference",
        accessor: "reference",
        visibilityName: "reference",
      });
    };

    const customFieldColumns = (columns) => {
      customFieldConfig.customFields.reduce((previousResult, currentValue) => {
        if (currentValue.enabled) {
          var accessor = fieldNameToAssetName(currentValue.fieldName);
          switch (currentValue.fieldName) {
            case "primary_name":
              previousResult.push({
                Header: "Name",
                accessor: "compoundName",
                type: "link",
                stateId: "id",
                link: `/${elementType}/edit`,
              });
              break;
            case "secondary_name":
              break;
            default:
              previousResult.push({
                Header: currentValue.displayName,
                accessor: accessor,
                visibilityName: accessor,
              });
              break;
          }
        }

        return previousResult;
      }, columns);
    };

    const commonColumns = (columns) => {
      columns.unshift({
        Header: "",
        accessor: "icon",
        type: "icon",
        disableSortBy: true,
      });

      if (elementType === "assets" || elementType === "people") {
        columns.push({
          Header: "Last Seen",
          accessor: "lastSeen",
          visibilityName: "last-seen",
          Cell: (cell) => {
            if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
              return (
                <>
                  <MapPin width={14} height={14} className="me-1" />
                  {dateFromNow(cell.value)}
                </>
              );
            }
            return (
              <span>
                <Moon width={14} height={14} className="me-1" /> Waiting...
              </span>
            );
          },
        });
      }
      if (elementType === "assets") {
        columns.push({
          Header: "Asset Battery",
          accessor: "lastPosition.supply",
          visibilityName: "supply",
          Cell: (cell) => {
            let supply = cell.row?.original?.lastPosition?.supply;

            if (typeof supply === "number") {
              let level = "";

              if (supply === 0 || supply === 500) {
                level = "Disconnected";
              } else if (supply === 1) {
                level = "Connected";
              } else if (supply > 12250) {
                level = "High";
              } else if (supply > 11500 && supply <= 12250) {
                level = "Medium";
              } else if (supply > 0 && supply <= 11500) {
                level = "Low";
              } else {
                level = "Waiting for data";
              }

              return (
                <>
                  {level === "High" && (
                    <FontAwesomeIcon
                      icon={faBatteryFull}
                      className="srl-green"
                    />
                  )}
                  {level === "Medium" && (
                    <FontAwesomeIcon
                      icon={faBatteryHalf}
                      className="srl-canary"
                    />
                  )}
                  {level === "Low" && (
                    <FontAwesomeIcon
                      icon={faBatteryQuarter}
                      className="srl-red"
                    />
                  )}
                  {level === "Connected" && (
                    <FontAwesomeIcon icon={faPlug} className="grey-500" />
                  )}
                  {level === "Disconnected" && (
                    <FontAwesomeIcon icon={faPlug} className="grey-500" />
                  )}
                  {level === "Waiting for data" && (
                    <FontAwesomeIcon
                      icon={faHourglassHalf}
                      className="grey-500"
                    />
                  )}
                  <span>&nbsp;{level}</span>
                  {["Low", "Medium", "High"].includes(level) && supply && (
                    <span>&nbsp;({supply} mV)</span>
                  )}
                </>
              );
            } else {
              return <></>;
            }
          },
          // disableSortBy: true,
        });
        columns.push({
          Header: "Device Battery",
          accessor: "lastPosition.battery",
          visibilityName: "battery",
          Cell: (cell) => {
            let battery = cell.row?.original?.lastPosition?.battery;

            if (typeof battery === "number") {
              const getBatteryIcon = (battery) => {
                if (battery >= 10 && battery < 25) {
                  return faBatteryQuarter;
                } else if (battery < 10) {
                  return faBatteryEmpty;
                } else if (battery >= 25 && battery <= 50) {
                  return faBatteryHalf;
                } else if (battery > 50 && battery <= 75) {
                  return faBatteryThreeQuarters;
                } else {
                  return faBatteryFull;
                }
              };

              return (
                <>
                  <FontAwesomeIcon
                    icon={getBatteryIcon(battery)}
                    fixedWidth
                    className="me-1"
                  />{" "}
                  {`${battery} %`}
                </>
              );
            } else {
              return <></>;
            }
          },
          // disableSortBy: true,
        });
      }

      if (elementType === "zones") {
        columns.push({
          Header: "Contents",
          accessor: "contentsCount",
          visibilityName: "contents-count",
          disableSortBy: true,
        });
      }

      if (user?.role === "Root") {
        columns.push({
          Header: "Customer",
          accessor: "customerName",
          show: user?.role === "Root",
          disableSortBy: true,
          Cell: (cell) => (
            <NavLink
              to={{
                pathname: `/customers/edit`,
                search: `?id=${cell.row?.original?.customerId}`,
              }}
            >
              {cell.value}
            </NavLink>
          ),
        });
      }

      columns.push({
        Header: "Hashtags",
        accessor: "hashTags",
        visibilityName: "hash-tags",
        disableSortBy: true,
        Cell: (cell) => (
          <div>
            {cell.value &&
              cell.value.map((tag, i) => (
                <NavLink
                  key={tag}
                  to={{
                    pathname: "/globalSearch",
                    search: `?searchString=${encodeURIComponent("#" + tag)}`,
                  }}
                >
                  <span
                    key={i}
                    className="badge bg-primary me-1 my-1 cursor-pointer"
                  >
                    {tag}
                  </span>
                </NavLink>
              ))}
          </div>
        ),
      });
    };

    if (user.role !== "Root") {
      switch (elementType) {
        case "assets":
          customFieldConfig = user.assetFieldConfig;
          break;
        case "people":
          customFieldConfig = user.personFieldConfig;
          break;
        case "zones":
          customFieldConfig = user.zoneFieldConfig;
          break;
        default:
          break;
      }
    }

    if (customFieldConfig && customFieldConfig.enabled) {
      customFieldColumns(columns);
    } else {
      defaultColumns(columns);
    }

    commonColumns(columns);

    return columns;
  };

  return {
    getColumnsForElementType,
  };
};

export default useCustomFieldsHelper;
