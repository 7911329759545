import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import SearchBox from "./SearchBox";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import defaultZone from "./../../../assets/img/icon/GPS/selectLocation.svg";
import { DebounceInput } from "react-debounce-input";
import FloorplanSelector from "../../readers/FloorplanSelector";
import api from "../../../api";
import { globalConfig } from "../../../config";
import {
  getDistance,
  middle,
  radiusZoomLevel,
} from "../../../utils/staticMethods";
import { Crosshair } from "react-feather";

// reader settings tab
let overlay1 = null;
const GeoLocation = (props) => {
  let { reader, onChangeLocation, floorplan, tab, fId } = props;

  const [mapInstance, setInstance] = useState();
  const [planCenter, setPlanCenter] = useState();
  const [showFloor, setShowFloor] = useState(true);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [altitude, setAltitude] = useState(0);
  const [mapApi, setApi] = useState();
  const [picture, setPicture] = useState(null);
  const [wirepas, setWirepas] = useState(false);
  const [wirepasNetwork, setWirepasNetWork] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [ttl, setTTL] = useState(0);
  const [curFloor, setCurFloor] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const searchBox = useRef();

  useEffect(() => {
    if (fId) setLoadingPlan(true);
    initLocation();
  }, []);

  useEffect(() => {
    initLocation();
  }, [reader.id]);

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      draggableCursor: "default",
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.SATELLITE,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
    };
  };

  useEffect(() => {
    if (
      mapApi &&
      mapInstance &&
      reader &&
      ((tab === "floorplans" && floorplan) ||
        (tab === "settings" && !floorplan))
    ) {
      // setTimeout(() => initLocation(), 100)
    }
  }, [mapApi, mapInstance, tab]);

  useEffect(() => {
    if (mapApi && mapInstance && curFloor && floorplan) {
      getPci();
      setLoadingPlan(true);
    }
  }, [mapApi, mapInstance, curFloor]);

  const initLocation = () => {
    if (reader.latitude) setLatitude(reader.latitude);
    if (reader.longitude) setLongitude(reader.longitude);
    if (reader.altitude) setAltitude(reader.altitude);
    if (reader.wirepas) setWirepas(reader.wirepas);
    if (reader.wirepasNetworkId) setWirepasNetWork(reader.wirepasNetworkId);
    if (reader.threshold) setThreshold(reader.threshold);
    if (reader.ttl) setTTL(reader.ttl);
  };

  const getPci = () => {
    api
      .get(`files/floorplans/${curFloor.id}`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        let blob = new Blob([res.data], { type: "img/jpeg" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);
        setPicture(url);
      })
      .catch(() => setPicture(null));
  };

  useEffect(() => {
    if (picture) initFloorPlan();
  }, [picture]);

  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(
        curFloor ? Number(curFloor.northWestLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.northWestLongitude) || 0.1276 : 0.1276
      );
      let southEast = new mapApi.LatLng(
        curFloor ? Number(curFloor.southEastLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.southEastLongitude) || 0.1276 : 0.1276
      );
      !reader?.latitude &&
        !reader?.longitude &&
        mapInstance.setCenter(middle(northWest, southEast));
      if (planCenter) mapInstance.setCenter(middle(northWest, southEast));
      setPlanCenter(middle(northWest, southEast));
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      overlay1.image = picture;
      overlay1.draw = function () {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(southEast);
        const nw = overlayProjection.fromLatLngToDivPixel(northWest);
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function () {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      let index = 0;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
          index = keys[i];
        }
      }
      mapInstance.setZoom(Number(index) - 1);
      setTimeout(() => setLoadingPlan(false), 1000);
    }
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    let thePanorama = map.getStreetView();
    maps.event.addListener(thePanorama, "visible_changed", function () {
      if (thePanorama.getVisible()) {
        setShowFloor(false);
      } else {
        setShowFloor(true);
      }
    });
    maps.event.addListener(map, "mousedown", function (e) {
      onCreate(e.latLng.lat(), e.latLng.lng());
    });
  };

  const onCreate = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  };

  const onSelect = (data) => {
    setCurFloor(data);
  };

  return (
    <div>
      <div>
        <div
          style={{ height: 450, width: "100%" }}
          className="position-relative"
        >
          {floorplan && (
            <div
              className={`${
                showFloor ? "" : "visually-hidden"
              } position-absolute d-flex z-50 w-100`}
            >
              <FloorplanSelector
                showDefault
                default={fId}
                asset={""}
                onSelect={onSelect}
              />
              <div
                className="focus-icon bg-dark radius-3"
                onClick={() => {
                  if (mapInstance && planCenter) {
                    mapInstance.panTo(planCenter);
                  }
                }}
              >
                <Crosshair className="text-primary" size={24} />
              </div>
            </div>
          )}
          {loadingPlan && (
            <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
              <Spinner animation="border" />
            </div>
          )}
          <GoogleMapReact
            options={getMapOptions}
            bootstrapURLKeys={{
              key: globalConfig.googleMapKey,
              libraries: ["places", "geometry", "drawing", "visualization"],
            }}
            center={{
              lat: Number(latitude) || 51.5,
              lng: Number(longitude) || -0.11,
            }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          >
            {mapInstance && mapApi && (
              <SearchBox ref={searchBox} map={mapInstance} mapApi={mapApi} />
            )}
            {mapInstance && mapApi && latitude && longitude && (
              <GeoMarker
                lat={latitude}
                lng={longitude}
                name={reader?.serial}
                defaultZone={defaultZone}
              />
            )}
          </GoogleMapReact>
        </div>
        <Row>
          <Col lg="4">
            <div className="mb-2 mt-2">Latitude</div>
            <DebounceInput
              type="number"
              debounceTimeout={500}
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              className="w-100 table-search debounceInput-search text-gray ms-0"
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">Longitude</div>
            <DebounceInput
              type="number"
              debounceTimeout={500}
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              className="w-100 table-search debounceInput-search text-gray ms-0"
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">Altitude</div>
            <DebounceInput
              type="number"
              debounceTimeout={500}
              value={altitude}
              onChange={(e) => setAltitude(e.target.value)}
              className="w-100 table-search debounceInput-search text-gray ms-0"
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">Sync to Wirepas</div>
            <Form.Check
              size={"lg"}
              checked={wirepas}
              onChange={(e) => setWirepas(e.target.checked)}
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">Wirepas Network</div>
            <Form.Control
              value={wirepasNetwork}
              size={"sm"}
              onChange={(e) => setWirepasNetWork(e.target.value)}
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">Minimum RSSI Threshold</div>
            <Form.Control
              type="number"
              value={threshold}
              size={"sm"}
              onChange={(e) => setThreshold(e.target.value)}
            />
          </Col>
          <Col lg="4">
            <div className="mb-2 mt-2">TTL</div>
            <Form.Control
              type="number"
              value={ttl}
              size={"sm"}
              onChange={(e) => setTTL(e.target.value)}
            />
          </Col>
        </Row>
        <Button
          className="mt-2 mx-2"
          onClick={() =>
            onChangeLocation(
              latitude,
              longitude,
              altitude,
              wirepas,
              wirepasNetwork,
              threshold,
              ttl
            )
          }
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const GeoMarker = ({ defaultZone, name }) => (
  <div style={{ marginLeft: "-15px", marginTop: "-15px" }}>
    <img
      alt="marker"
      style={{ height: "30px", width: "30px", cursor: "pointer" }}
      src={defaultZone}
    />
    {name && (
      <div
        className={`bg-primary text-black position-absolute marker-position-bottom ps-1 pe-1 radius-3 text-nowrap font-weight-bold`}
        style={{ fontSize: "14px", transform: "translate(-50%, 50%)" }}
      >
        {name}
      </div>
    )}
  </div>
);

export default GeoLocation;
