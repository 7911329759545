import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../../components/tables/CommonTable";
import * as queryString from "query-string";
import { Plus } from "react-feather";
import NewDevice from "./NewDevice";
import NewCommand from "./NewCommand";

const CustomerEdit = () => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [data, setData] = useState();
  const [modalTitle, setModalTitle] = useState(null);
  const [commandModal, setCommandModal] = useState(null);
  const notify = useContext(NotyfContext);
  const [showAdd, setShowAdd] = useState(null);
  const [command, setCommand] = useState();
  const childRef = useRef();

  useEffect(() => {
    if (showAdd) childRef.current.stopResize();
    if (commandModal) childRef.current.stopResize();
  }, [showAdd, commandModal]);

  const columns = [
    {
      Header: "Command Name",
      accessor: "commandName",
      minWidth: 150,
      Cell: (cell) => {
        return (
          <span>
            <span
              className="ms-1 text-primary cursor-pointer"
              onClick={() => {
                setCommand(cell.row.original);
                setShowAdd("Edit");
              }}
            >
              {cell.value}
            </span>
          </span>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 120,
    },
    {
      Header: "Command String",
      accessor: "commandString",
      minWidth: 600,
    },
    {
      Header: "ACK String",
      accessor: "ackString",
      minWidth: 600,
    },
  ];

  useEffect(() => {
    getDetail();
  }, [id]);

  const getDetail = () => {
    api.get(`deviceprofiles/${id}`).then((res) => {
      setData(res.data);
      if (!res.data.commands || res.data.commands.length === 0) return;
      childRef.current.updateTable(1);
    });
  };

  const onDeleteShow = () => {
    if (!command?.commandId) {
      notify.open({
        type: "error",
        message: "Command Not Found !",
      });
      return;
    }
    setShowAdd(null);
    setCommandModal("Please Confirm");
  };

  const onDeleteDevice = () => {
    api.delete(`deviceprofiles/${data.id}`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setModalTitle(null);
      window.history.back();
    });
  };

  const onDelete = () => {
    api.delete(`deviceprofiles/commands/${command.commandId}`).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setCommandModal(null);
      setCommand(null);
      getDetail();
    });
  };

  const onUpdate = (value) => {
    setShowAdd(null);
    getDetail();
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={data?.productName || "Device Profile"} />
      <h3 className="mb-3">{data?.productName}</h3>
      <div className="tab mt-3">
        <NewDevice
          onDelete={() => setModalTitle("Please Confirm")}
          onUpdated={() => getDetail()}
          data={data}
          type="edit"
          apiName="deviceprofiles"
        />
      </div>
      <Card className="mt-2">
        <Card.Body>
          <Row className="mb-3 d-flex justify-content-between align-items-center">
            <Col>
              <h4 className="mb-0">Commands</h4>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  setCommand(null);
                  setShowAdd("New");
                }}
                className="d-flex align-items-center"
              >
                <Plus size={16} />
                Create New
              </Button>
            </Col>
          </Row>
          <CommonTable
            ref={childRef}
            columns={columns}
            data={data?.commands}
            hideSearch
          />
        </Card.Body>
      </Card>

      <Modal show={modalTitle} onHide={() => setModalTitle(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Are you sure that you wish to permanently delete this Device
              Profile?
            </p>
            <p>Deletions are not reversible.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onDeleteDevice()} variant="danger">
            Delete
          </Button>
          <Button variant={"secondary"} onClick={() => setModalTitle(null)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={commandModal} onHide={() => setCommandModal(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{commandModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Are you sure that you wish to permanently delete this Command?
            </p>
            <p>Deletions are not reversible.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onDelete()} variant="danger">
            Delete
          </Button>
          <Button variant={"secondary"} onClick={() => setCommandModal(null)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAdd} onHide={() => setShowAdd(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{showAdd} Command</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCommand
            apiName="deviceprofiles/commands"
            data={command}
            type={showAdd}
            onDelete={() => onDeleteShow()}
            onUpdated={onUpdate}
            id={id}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerEdit;
