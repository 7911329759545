import React, { useEffect, useState } from "react";
import { globalConfig } from "../config";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmog,
  faTemperatureLow,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import closeSvg from "./../assets/img/svgs/close.svg";

// third party API to get weather with lat and lng
const WeatherBar = ({ data }) => {
  const [weather, setWeather] = useState(null);
  useEffect(() => {
    fetch(
      `https://api.weatherstack.com/current?access_key=${globalConfig.weatherApiKey}&query=${data?.latitude},${data?.longitude}`
    )
      .then((data) => {
        return data.json();
      })
      .then((ret) => {
        if (ret.error) {
          setWeather({});
        } else {
          setWeather(ret);
        }
      })
      .catch((e) => {
        setWeather({});
      });
  }, [data?.id]);

  return (
    <React.Fragment>
      {weather && (
        <Card className="mb-0 border-top">
          <Card.Body>
            <ul className="list-unstyled col-sm-12">
              <li data-toggle="tooltip" title="" data-placement="right">
                <h6>LIVE WEATHER</h6>
              </li>
              <li data-toggle="tooltip" title="" data-placement="right">
                <FontAwesomeIcon
                  icon={faTemperatureLow}
                  fixedWidth
                  className="me-2"
                />
                Temperature:{" "}
                {weather?.current?.temperature
                  ? weather.current.temperature + " °C"
                  : ""}
              </li>
              <li
                data-toggle="tooltip"
                title=""
                data-placement="right"
                className="d-flex align-items-center"
              >
                {weather?.current?.weather_icons[0] ? (
                  <img
                    alt="weather_icon"
                    style={{ width: "16px" }}
                    className="me-2"
                    src={weather.current.weather_icons[0]}
                  />
                ) : (
                  <img
                    alt="weather_icon"
                    style={{ width: "16px" }}
                    className="me-2"
                    src={closeSvg}
                  />
                )}
                Weather:{" "}
                {weather?.current?.weather_descriptions
                  ? weather.current.weather_descriptions.join(",")
                  : ""}
              </li>
              <li data-toggle="tooltip" title="" data-placement="right">
                <FontAwesomeIcon icon={faWind} fixedWidth className="me-2" />
                Wind: {Math.round(
                  (weather?.current?.wind_speed || 0) * 0.6214
                )}{" "}
                mph
              </li>
              <li data-toggle="tooltip" title="" data-placement="right">
                <FontAwesomeIcon icon={faSmog} fixedWidth className="me-2" />
                Precip:{" "}
                {weather?.current?.precip ? weather.current.precip + "mm" : ""}
              </li>
            </ul>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  );
};
export default WeatherBar;
