import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import useSrlHelper from "../../hooks/useSrlHelper";

const AssetBatteryLevel = ({ asset, assetId }) => {
  const [supply, setSupply] = useState(<></>);
  const { renderSupply } = useSrlHelper();

  useEffect(() => {
    if (asset) {
      setSupply(renderSupply(asset?.lastPosition?.supply, <></>, "6x"));
    }
  }, [assetId]);

  return (
    <>
      <Card className="border flex-grow-1">
        <Card.Header className="pb-0">
          <Card.Title>Asset Battery Level</Card.Title>
        </Card.Header>
        <Card.Body
          style={{ minHeight: "200px", maxHeight: "200px" }}
          className="d-flex align-items-center justify-content-center"
        >
          {asset.lastPosition ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              {supply}
            </div>
          ) : (
            <div>Waiting for data</div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AssetBatteryLevel;
