import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";
import NotyfContext from "../../contexts/NotyfContext";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeviceDetail = (props) => {
  const [profiles, setProfiles] = useState([]);
  const notify = useContext(NotyfContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = () => {
    api
      .get(`deviceprofiles?page=1&limit=999`)
      .then((res) => setProfiles(res.data));
  };
  const onDelete = () => {
    api.delete(`${props.type}/${props.thing.id}`).then((r) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setShow(false);
      window.history.back();
    });
  };

  return (
    <React.Fragment>
      <Card className="shadow-none">
        <Card.Body>
          <h4 className="mb-3">
            <FontAwesomeIcon
              icon={faFileAlt}
              className="me-2"
            ></FontAwesomeIcon>
            Details
          </h4>
          <div className="d-flex align-items-center mb-3">
            {props.thing?.customerId && (
              <div>{props.thing?.customerName} - </div>
            )}
            <NavLink
              className="ms-1"
              to={{
                pathname: `/reallocate`,
                search: `?id=${props.thing?.id}&deviceType=${props.type}`,
              }}
            >
              Customer Reallocation
            </NavLink>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              deviceProfileId: props.thing?.deviceProfileId || "",
              serial: props.thing?.serial || "",
              misdn: props.thing?.misdn || "",
              locked: props.thing?.locked || false,
              wirepasNetworkId: props.thing?.wirepasNetworkId || "",
              deviceName: props.thing?.deviceName || "",
              submit: false,
            }}
            validationSchema={Yup.object()
              .shape({
                serial: Yup.string().required("Serial is required"),
              })
              .when((values, schema) => {
                if (
                  props.type !== "solarchargers" &&
                  props.type !== "qrcodes"
                ) {
                  return schema.shape({
                    deviceProfileId: Yup.string().required(
                      "Device Profile is required"
                    ),
                  });
                }
              })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              let data = JSON.parse(JSON.stringify(props.thing));
              data.id = props.thing.id;
              if (props.type !== "solarchargers" && props.type !== "qrcodes") {
                data.deviceProfileId = values.deviceProfileId;
                data.misdn = values.misdn;
              }
              data.serial = values.serial;
              data.locked = values.locked;
              if (props.type === "readers" || props.type === "tags") {
                data.deviceName = values.deviceName;
              }
              if (props.type === "readers") {
                data.wirepasNetworkId = values.wirepasNetworkId;
              }
              try {
                api.put(`${props.type}`, data).then((res) => {
                  notify.open({
                    type: "success",
                    message: "Changes Saved",
                  });
                  props.setThing && props.setThing(res.data);
                });
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                {props.type !== "solarchargers" && props.type !== "qrcodes" && (
                  <Form.Group className="mb-3">
                    <Form.Label>Device Profile</Form.Label>
                    <Form.Select
                      size="lg"
                      name="deviceProfileId"
                      value={values.deviceProfileId}
                      isInvalid={Boolean(
                        touched.deviceProfileId && errors.deviceProfileId
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value={""} style={{ display: "none" }} />
                      {profiles.length > 0 &&
                        profiles.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.productName}
                          </option>
                        ))}
                    </Form.Select>
                    {!!touched.deviceProfileId && (
                      <Form.Control.Feedback type="invalid">
                        {errors.deviceProfileId}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Serial</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="serial"
                    value={values.serial}
                    isInvalid={Boolean(touched.serial && errors.serial)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.serial && (
                    <Form.Control.Feedback type="invalid">
                      {errors.serial}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {(props.type === "readers" || props.type === "tags") && (
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="lg"
                      name="deviceName"
                      label="deviceName"
                      type="text"
                      value={values.deviceName}
                      isInvalid={Boolean(
                        touched.deviceName && errors.deviceName
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.deviceName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.deviceName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}

                {props.type !== "solarchargers" && props.type !== "qrcodes" && (
                  <Form.Group className="mb-3">
                    <Form.Label>MISDN</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      name="misdn"
                      value={values.misdn}
                      isInvalid={Boolean(touched.misdn && errors.misdn)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.misdn && (
                      <Form.Control.Feedback type="invalid">
                        {errors.misdn}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}

                <div className="mb-3">
                  Date Created:{" "}
                  {moment(props.thing?.dateCreated).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </div>

                <Form.Group className="mb-3">
                  <Form.Check
                    size="lg"
                    type="checkbox"
                    name="locked"
                    label="Locked"
                    checked={values.locked}
                    isInvalid={Boolean(touched.locked && errors.locked)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>

                <div className="mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant="danger"
                    className="ms-1"
                    onClick={() => setShow(true)}
                  >
                    Delete
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you wish to permanently delete this device?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onDelete()} variant={"danger"}>
            Delete
          </Button>
          <Button onClick={() => setShow(false)} variant={"secondary"}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default DeviceDetail;
