import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import { X } from "react-feather";

const TaskTypeModal = ({
  action,
  show,
  closeModal,
  current,
  apiName,
  handleSuccess,
}) => {
  const [inputStr, setInput] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const formRef = useRef();
  const onDelete = () => {
    api.delete(`${apiName}/${current?.id}`).then((res) => {
      handleSuccess && handleSuccess(res.data);
      setTimeout(() => setShowDelete(false), 500);
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
        setTimeout(() => setShowDelete(false), 500);
      }}
    >
      <Modal.Header closeButton>
        {!showDelete && (
          <Modal.Title>
            {action === "Create" ? "New Task Type" : "Edit Task Type"}{" "}
          </Modal.Title>
        )}
        {showDelete && <Modal.Title>Please Confirm</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-0">
          <Card.Body>
            {!showDelete ? (
              <Formik
                innerRef={formRef}
                initialValues={{
                  id: current?.id,
                  name: current?.name || "",
                  description: current?.description || "",
                  statusStrings: current?.statusStrings || [],
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(255)
                    .required("Primary Name is required"),
                  description: Yup.string().max(255),
                })}
                enableReinitialize
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    action === "Create" &&
                      api.post(apiName, values).then((res) => {
                        handleSuccess && handleSuccess(res.data);
                      });
                    action === "Edit" &&
                      api.put(apiName, values).then((res) => {
                        handleSuccess && handleSuccess(res.data);
                      });
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        size="md"
                        type="text"
                        name="name"
                        value={values.name}
                        isInvalid={Boolean(touched.name && errors.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        size="md"
                        type="text"
                        as="textarea"
                        rows={3}
                        name="description"
                        value={values.description}
                        isInvalid={Boolean(
                          touched.description && errors.description
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.description && (
                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Options</Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          size="md"
                          type="text"
                          className="me-2"
                          value={inputStr}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.stopPropagation();
                              e.preventDefault();
                              if (inputStr.trim() === "") return;
                              let arr = values.statusStrings.concat([]);
                              arr.push(inputStr);
                              setFieldValue("statusStrings", arr);
                              setInput("");
                            }
                          }}
                        />
                        <Button
                          variant="primary"
                          onClick={() => {
                            if (inputStr.trim() === "") return;
                            let arr = values.statusStrings.concat([]);
                            arr.push(inputStr);
                            setFieldValue("statusStrings", arr);
                            setInput("");
                          }}
                        >
                          Add
                        </Button>
                      </div>
                      <div className="d-flex mt-2">
                        {values.statusStrings.map((item, index) => (
                          <div
                            key={index}
                            style={{ borderRadius: "2px", color: "white" }}
                            className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-primary"
                          >
                            <span>{item}</span>
                            <X
                              size="12"
                              onClick={() => {
                                let arr = values.statusStrings
                                  .concat([])
                                  .filter((item1) => item1 !== item);
                                setFieldValue("statusStrings", arr);
                              }}
                              className="position-absolute cursor-pointer"
                              color={"#FFFFFF"}
                              style={{ top: "2px", right: "2px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            ) : (
              <span>{`Would you like to remove the Task type '${current?.name}'?`}</span>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        {!showDelete && (
          <Button
            onClick={() => formRef.current?.handleSubmit()}
            variant="primary"
            size="md"
            disabled={formRef.current?.isSubmitting}
          >
            Save
          </Button>
        )}
        {action === "Edit" && (
          <Button
            onClick={() => {
              showDelete ? onDelete() : setShowDelete(true);
            }}
            variant={"danger"}
          >
            Delete
          </Button>
        )}
        <Button
          variant={"secondary"}
          onClick={() => {
            closeModal();
            setTimeout(() => setShowDelete(false), 500);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TaskTypeModal;
