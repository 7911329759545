import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import moment from "moment-timezone";
import ScheduleTaskModal from "./ScheduleTaskModal";
import { useNavigate } from "react-router-dom";
import LogTask from "./LogTask";
import { X, XSquare } from "react-feather";
import api from "../../api";
import DateTime from "react-datetime";
import useAuth from "../../hooks/useAuth";

const OutstandingTask = ({ type }) => {
  const navigate = useNavigate();
  const [resultCount, setResultCount] = useState(0);
  const [current, setOld] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrent] = useState("");
  const [curPage, setCurPage] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [completeDate, setCompleteDate] = useState(moment());
  const [optionsStr, setOptionStr] = useState([]);
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  useEffect(() => {
    if (type && user && !user[type]) {
      navigate("/");
    }
  }, [user, type]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => (
        <span
          onClick={() => btnClick("Edit", cell.row.original)}
          className="text-primary cursor-pointer"
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      className: "w-25",
      Cell: (cell) => (
        <div
          className="text-nowrap overflow-hidden"
          title={cell.value}
          style={{ textOverflow: "ellipsis", minWidth: 0 }}
        >
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Due Date",
      accessor: "due",
      Cell: (cell) => (
        <div>
          <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
        </div>
      ),
    },
    {
      Header: "Asset/Zone",
      accessor: "parentName",
      accessorName: "parentName",
      type: "linkStatus",
      navigateId: "parentId",
      typeName: "parentType",
      reference: "parentReference",
    },
    {
      Header: "Assigned to",
      accessor: "childName",
      accessorName: "childName",
      type: "linkStatus",
      navigateId: "childId",
      typeName: "childType",
      reference: "childReference",
    },
  ];

  const handleSuccess = () => {
    notify.open({
      type: "success",
      message: "Changes Saved",
    });
    setShowModal(false);
    navigate("/scheduledtask");
  };

  const btnClick = (name, id) => {
    setOld(id);
    setCurrent("View");
    setCurPage(true);
    setShowModal(true);
  };

  const pickOption = (str) => {
    setOptionStr((prevState) => {
      let arr = prevState.concat([]);
      if (arr.filter((item) => item === str).length === 0) arr.push(str);
      return arr;
    });
  };

  const onDeleteOption = (str) => {
    setOptionStr((prevState) => {
      let arr = prevState.concat([]);
      return arr.filter((item) => item !== str);
    });
  };

  const handleComplete = () => {
    if (completeDate.diff(moment()) >= 0) return;
    setCompleteModal(false);
    api
      .post(`tasks/complete`, {
        id: current?.id,
        statusStrings: optionsStr,
        completed: completeDate,
      })
      .then((res) => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        childRef.current.updateTable(1);
      });
  };
  const isDateValid = (cur) => {
    return cur.startOf("days").diff(moment()) < 0;
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title="Outstanding Tasks" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <XSquare className="me-2" />
            Outstanding Tasks{" "}
            {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
          <div>
            <LogTask />
            {user?.scheduleTasks && (
              <Button
                onClick={() => {
                  setOld(null);
                  setCurPage(false);
                  setCurrent("Create");
                  setShowModal(true);
                }}
              >
                Schedule a Task
              </Button>
            )}
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            cssClass="table-fixed"
            btnClick={btnClick}
            ref={childRef}
            setCountParent={setResultCount}
            apiName="tasks/outstanding"
            columns={columns}
          />
        </Card>
      </Container>
      <ScheduleTaskModal
        handleComplete={() => {
          setShowModal(false);
          setOptionStr([]);
          setCompleteModal(true);
        }}
        handleEdit={() => {
          setCurrent("Edit");
        }}
        handleSuccess={handleSuccess}
        closeModal={() => setShowModal(false)}
        show={showModal}
        action={currentAction}
        curPage={curPage}
        current={current}
        apiName="tasks/schedule"
      />

      <Modal
        show={completeModal}
        onHide={() => {
          setCompleteModal(false);
          setShowPicker(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete a Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{`Please select completed options:`}</span>
          <div className="mt-2">
            {current?.optionStrings &&
              current?.optionStrings.map((item, index) => (
                <Button
                  variant="light"
                  onClick={() => pickOption(item)}
                  key={index}
                  className="me-2"
                >
                  {item}
                </Button>
              ))}
          </div>
          {optionsStr.length > 0 && (
            <div className="mt-4">
              <h6>Completed Options:</h6>
              <div className="d-flex">
                {optionsStr.map((item, index) => (
                  <div
                    key={index}
                    style={{ borderRadius: "2px" }}
                    className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-success text-black"
                  >
                    <span>{item}</span>
                    <X
                      size="12"
                      onClick={() => {
                        onDeleteOption(item);
                      }}
                      className="position-absolute text-dark cursor-pointer"
                      style={{ top: "2px", right: "2px" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="mt-2">Complete Date:</div>
          <div className="d-flex align-items-center mb-2">
            <div className="text-dark">
              {completeDate.format("DD/MM/YY HH:mm")}
            </div>
            <Button
              variant="link"
              className="ms-3"
              onClick={() => setShowPicker(!showPicker)}
            >
              Edit
            </Button>
          </div>
          {showPicker && (
            <DateTime
              input
              inputProps={{ readOnly: true }}
              className="no-change-date-picker"
              isValidDate={isDateValid}
              initialValue={completeDate}
              onChange={(e) => {
                setCompleteDate(e);
              }}
              timeFormat="HH:mm"
              dateFormat="DD/MM/YY"
            />
          )}
          {completeDate.diff(moment()) >= 0 && (
            <div className="text-danger">
              Please a select a time in the past!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleComplete()}>
            Complete
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setCompleteModal(false);
              setShowPicker(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default OutstandingTask;
