import React from "react";
import { Button, Modal } from "react-bootstrap";
import { customHistory } from "../../utils/history";

class Dialog extends React.Component {
  state = {
    input: "8",
    showDialog: false,
  };
  componentDidMount() {
    const history = customHistory;
    this.block = history.block(this.onBlock);
  }

  componentWillUnmount() {
    this.block && this.block();
  }

  handleInputChange = (e) => this.setState({ input: e.target.value });

  onBlock = (tx) => {
    this.navigation = () => {
      customHistory.push(tx.location.pathname + tx.location.search);
    };
    if (!this.props.finish && !this.state.showDialog) {
      this.setState({ showDialog: true });
    }
    if (this.props.finish || this.state.showDialog) {
      this.block();
      customHistory.push(tx.location.pathname + tx.location.search);
      return true;
    }
    return false;
  };

  onDiscardClick = () => {
    console.log("This will stay here");
    this.setState({ showDialog: false });
  };

  onMoveToNextClick = () => {
    this.block && this.block();
    //Redirect to user where they want to go
    this.navigation && this.navigation();
  };

  render() {
    // console.log("m", this.block);
    return (
      <div>
        <Modal show={this.state.showDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Please Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you wish to leave the rule wizard? This will delete the
            rule and you will need to start the wizard again.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onMoveToNextClick}>Confirm</Button>
            <Button variant="secondary" onClick={this.onDiscardClick}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Dialog;
