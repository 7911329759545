import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { globalConfig } from "../../../config";
import { Button, Col, Row } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import SearchBox from "./SearchBox";
import defaultZone from "./../../../assets/img/icon/GPS/selectLocation.svg";

const GeoLocationTag = (props) => {
  const { tag, onChangeLocation } = props;
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [altitude, setAltitude] = useState(0);
  const searchBox = useRef();

  useEffect(() => {
    initLocation();
  }, []);

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      draggableCursor: "default",
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.SATELLITE,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
    };
  };

  const initLocation = () => {
    if (tag) {
      if (tag.latitude) setLatitude(tag.latitude);
      if (tag.longitude) setLongitude(tag.longitude);
      if (tag.altitude) setAltitude(tag.altitude);
    }
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    maps.event.addListener(map, "mousedown", function (e) {
      onCreate(e.latLng.lat(), e.latLng.lng());
    });
  };

  const onCreate = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
  };

  return (
    <>
      <div style={{ height: 450, width: "100%" }} className="position-relative">
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"],
          }}
          center={{
            lat: Number(latitude) || 51.5,
            lng: Number(longitude) || -0.11,
          }}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {mapInstance && mapApi && (
            <SearchBox ref={searchBox} map={mapInstance} mapApi={mapApi} />
          )}
          {mapInstance && mapApi && latitude && longitude && (
            <GeoMarker
              lat={latitude}
              lng={longitude}
              name={tag?.serial}
              defaultZone={defaultZone}
            />
          )}
        </GoogleMapReact>
      </div>
      <Row>
        <Col lg="4">
          <div className="mb-2 mt-2">Latitude</div>
          <DebounceInput
            type="number"
            debounceTimeout={500}
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
            className="w-100 table-search debounceInput-search text-gray ms-0"
          />
        </Col>
        <Col lg="4">
          <div className="mb-2 mt-2">Longitude</div>
          <DebounceInput
            type="number"
            debounceTimeout={500}
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            className="w-100 table-search debounceInput-search text-gray ms-0"
          />
        </Col>
        <Col lg="4">
          <div className="mb-2 mt-2">Altitude</div>
          <DebounceInput
            type="number"
            debounceTimeout={500}
            value={altitude}
            onChange={(e) => setAltitude(e.target.value)}
            className="w-100 table-search debounceInput-search text-gray ms-0"
          />
        </Col>
      </Row>
      <Button
        className="mt-3"
        onClick={() => onChangeLocation(latitude, longitude, altitude)}
      >
        Save
      </Button>
      {/* <code>{JSON.stringify(tag)}</code> */}
    </>
  );
};

const GeoMarker = ({ defaultZone, name }) => (
  <div style={{ marginLeft: "-15px", marginTop: "-15px" }}>
    <img
      alt="marker"
      style={{ height: "30px", width: "30px", cursor: "pointer" }}
      src={defaultZone}
    />
    {name && (
      <div
        className={`bg-primary text-black position-absolute marker-position-bottom ps-1 pe-1 radius-3 text-nowrap font-weight-bold`}
        style={{ fontSize: "14px", transform: "translate(-50%, 50%)" }}
      >
        {name}
      </div>
    )}
  </div>
);

export default GeoLocationTag;
