import React, { useState } from "react";
import { Package } from "react-feather";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import SrlAssetSummary from "../components/SrlAssetSummary";
import SrlCommonTable from "../components/SrlCommonTable";
import { SRL_CATEGORIES } from "../../constants";
import useSrlHelper from "../../hooks/useSrlHelper";

const SrlArchivedAssets = () => {
  const [resultCount, setResultCount] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [srlCategories] = useState(SRL_CATEGORIES);
  const { getAssetColumnsForCommonTable } = useSrlHelper();
  const [columns] = useState(getAssetColumnsForCommonTable());
  const [sort] = useState([{ id: "category", desc: false }]);

  return (
    <>
      <Helmet defer={false} title="SRL Archived Assets" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Package className="me-2" />
              &nbsp;
              {selectedCategoryName === null
                ? "SRL Archived Assets"
                : selectedCategoryName}
              {typeof resultCount === "string" && (
                <>&nbsp;{`(${resultCount})`}</>
              )}
            </h1>
          </div>
        </div>
        <SrlAssetSummary
          categories={srlCategories}
          selectedCategoryName={selectedCategoryName}
          setSelectedCategoryName={setSelectedCategoryName}
          apiName="archived-assets"
        />
        <SrlCommonTable
          setCountParent={setResultCount}
          apiName="archived-assets"
          columns={columns}
          showExport={true}
          category={selectedCategoryName}
          sort={sort}
        />
      </Container>
    </>
  );
};

export default SrlArchivedAssets;
