import React from "react";
import moment from "moment-timezone";
import CommonTable from "../tables/CommonTable";
import { NavLink } from "react-router-dom";

const columns = [
  {
    Header: "Name",
    accessor: "childName",
    Cell: (cell) => (
      <div>
        <NavLink
          to={{
            pathname: `/${
              cell.row.original.childType === "person"
                ? "people"
                : `${cell.row.original.childType}s`
            }/edit`,
            search: `?id=${cell.row.original.childId}`,
          }}
        >
          {cell.row.original.childName +
            `${
              cell.row.original.childReference
                ? ` (${cell.row.original.childReference})`
                : ""
            }`}
        </NavLink>
        {cell.row.original.childHashTags &&
          cell.row.original.childHashTags.map((item) => (
            <span
              key={item}
              className="ms-1 badge bg-primary me-1 d-flex align-items-center"
            >
              {item}
            </span>
          ))}
      </div>
    ),
  },
  {
    Header: "Arrived",
    accessor: "start",
    type: "dateFromNow",
  },
  {
    Header: "Time",
    accessor: "end",
    Cell: (cell) => (
      <span>{moment(cell?.row?.original?.start).format("DD/MM/YY HH:mm")}</span>
    ),
  },
];

const TimelineTable = ({ id, curTab, type, setCountParent, filterText }) => {
  const getType = () => {
    if (type === "zoneCurrent") return "zones";
    if (type === "assetsCurrent") return "assets";
    if (type === "peopleCurrent") return "people";
  };

  return (
    <React.Fragment>
      <CommonTable
        setCountParent={setCountParent}
        filterText={filterText}
        apiName={`${getType()}/livecontents/${id}`}
        columns={columns}
      />
    </React.Fragment>
  );
};
export default TimelineTable;
