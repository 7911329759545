import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import api from "../../api";
import axios from "axios";
import ReactPaginate from "react-paginate";
import LoadingSpin from "../loadingSpin";
import { useNavigate } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";
import { colourStyles, colourStylesLight } from "../../utils/staticMethods";
import useTheme from "../../hooks/useTheme";
import { THEME } from "../../constants";

let source = null;
const GridView = ({ columns, link, apiName, setCountParent }) => {
  const { theme } = useTheme();
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(false);
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");
  const [result, setResult] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const limit = 10;

  useEffect(() => {
    if (firstLoad) getData(1);
  }, [filter, sort]);

  useEffect(() => {
    getData(1);
  }, []);

  const getData = (p) => {
    setLoading(true);
    setPage(p);
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    api
      .get(apiName, {
        params: {
          filter: filter,
          page: p,
          limit: limit,
          sort: sort,
        },
        cancelToken: source.token,
      })
      .then((r) => {
        setLoading(false);
        setFirstLoad(true);
        const total = Number(r.headers["x-total-count"]);
        setTotalCount(Math.ceil(total / limit) || 1);
        setCountParent && setCountParent(total);
        setResult(r.data);
      })
      .catch(() => {
        setTotalCount(0);
        setCountParent && setCountParent(0);
        setResult([]);
      });
  };

  const handlePageClick = (p) => {
    getData(p.selected + 1);
  };

  const filterResults = (e) => {
    setFilter(e);
  };

  const handleClick = (id) => {
    if (link) {
      navigate(`${link}?id=${id}`);
    }
  };

  useEffect(() => {
    if (columns.length > 0) {
      getOption();
    }
  }, [columns]);

  const getOption = () => {
    let arr = [];
    columns.forEach((item) => {
      if (!item.disableSortBy && item.show !== false) {
        arr.push({
          label: `${item.title} (desc)`,
          value: `${item.value}.desc`,
        });
        arr.push({ label: `${item.title} (asc)`, value: `${item.value}.asc` });
      }
    });
    setOptions([{ label: "Sort By", options: arr }]);
  };

  const handleSelect = (e) => {
    setSort(e?.value || "");
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-wrap position-relative">
        <div className="w-100 d-flex justify-content-between mb-2">
          <Select
            className="table-sorter"
            onChange={(e) => handleSelect(e)}
            isClearable
            options={options}
            placeholder={"Sort by"}
            styles={theme === THEME.DARK ? colourStyles : colourStylesLight}
          />
          <DebounceInput
            className="table-search debounceInput-search text-gray ps-1"
            minLength={0}
            style={{ borderRadius: "5px", borderColor: "#7f838e" }}
            placeholder={"Search"}
            value={filter}
            debounceTimeout={500}
            onChange={(event) => filterResults(event.target.value)}
          />
        </div>
        <div className="position-relative w-100 d-flex flex-wrap">
          {loading && (
            <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center z-50 bg-light opacity-50">
              <LoadingSpin loading={loading} />
            </div>
          )}
          {result.map((item, index) => (
            <div className="w-20 mobile-flex" key={index}>
              <Card
                className="m-1"
                style={{ minHeight: "280px" }}
                onClick={() => handleClick(item.id)}
              >
                <Card.Body>
                  {columns &&
                    columns.map((col, i) => {
                      if (col.show === false) {
                        return null;
                      }
                      return (
                        <div key={i} className="mb-1">
                          {col.Cell ? (
                            col.Cell(result[index])
                          ) : (
                            <>
                              <div>{item[col.value]}</div>
                            </>
                          )}
                        </div>
                      );
                    })}
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
        {totalCount > 1 && (
          <div className="d-flex justify-content-end w-100 mt-2">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={totalCount}
              forcePage={page - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default GridView;
