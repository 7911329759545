import { Spinner } from "react-bootstrap";
import React from "react";

// loading spin
const LoadingSpin = ({ loading }) => {
  return (
    <div className={loading ? "table-loading" : "table-ready"}>
      <Spinner animation="border" />
    </div>
  );
};
export default LoadingSpin;
