import { Helmet } from "react-helmet-async";
import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import IconPage from "./IconPage";
import {
  Aircraft,
  Arrows,
  Assets,
  Bikes,
  Cars,
  Ecommerce,
  Emergencies,
  Energy,
  Family,
  GPS,
  Home,
  Hospital,
  Lights,
  Logistics,
  Machinery,
  Marine,
  Military,
  Multimedia,
  Office,
  Professions,
  PublicTransport,
  Safety,
  Shipping,
  Tools,
  Traffic,
  Trailers,
  Transportation,
  Trucks,
  Watercraft,
} from "./IconList";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api";
import NotyfContext from "../../../contexts/NotyfContext";
import * as queryString from "query-string";

// icons select page, now all the icons stored in frontend, and they were grouped by different categories, all the icons saved in assets/img/icon directory

const Icons = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const { id, type } = queryString.parse(location.search);
  const [data, setData] = useState(null);

  const onSelectIcon = (e) => {
    const url = e.split("/")[1];
    if (id && type) {
      if (!data) {
        notify.error({
          type: "error",
          message: "Network Error !",
        });
        return;
      }
      let obj = JSON.parse(JSON.stringify(data));
      obj.icon = url;
      try {
        api.put(`${type}`, obj).then((res) => {
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
          navigate(`/${type || "assets"}/edit?id=${id}&direct=details`);
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      props.onSelectedIcon && props.onSelectedIcon(url);
    }
  };

  useEffect(() => {
    if (!id || !type) return;
    api.get(`${type}/` + id).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet defer={false} title="Icons" />
      <h1 className="h3">Icons</h1>
      <Container fluid className="bg-white p-3 card">
        <Tab.Container id="left-tabs-example" defaultActiveKey="page1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="page1">Page1</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="page2">Page2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="page3">Page3</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="page4">Page4</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="page1">
              <IconPage
                title="Aircraft"
                imageList={Aircraft}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Bikes"
                imageList={Bikes}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Boats & Watercraft"
                imageList={Watercraft}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Cars & Vans"
                imageList={Cars}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Lights"
                imageList={Lights}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Machinery"
                imageList={Machinery}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Public Transport"
                imageList={PublicTransport}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Trailers"
                imageList={Trailers}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Trucks"
                imageList={Trucks}
                onSelectIcon={onSelectIcon}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="page2">
              <IconPage
                title="Transportation"
                imageList={Transportation}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Assets"
                imageList={Assets}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="GPS & Navigation"
                imageList={GPS}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Professions"
                imageList={Professions}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Logistics"
                imageList={Logistics}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Tools"
                imageList={Tools}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Shipping & Delivery"
                imageList={Shipping}
                onSelectIcon={onSelectIcon}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="page3">
              <IconPage
                title="Ecommerce"
                imageList={Ecommerce}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Traffic Signs"
                imageList={Traffic}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Marine"
                imageList={Marine}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Emergencies"
                imageList={Emergencies}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Hospital"
                imageList={Hospital}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Safety"
                imageList={Safety}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Military"
                imageList={Military}
                onSelectIcon={onSelectIcon}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="page4">
              <IconPage
                title="Office"
                imageList={Office}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Energy"
                imageList={Energy}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Family"
                imageList={Family}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Home"
                imageList={Home}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Multimedia"
                imageList={Multimedia}
                onSelectIcon={onSelectIcon}
              />
              <IconPage
                title="Arrows"
                imageList={Arrows}
                onSelectIcon={onSelectIcon}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </React.Fragment>
  );
};
export default Icons;
