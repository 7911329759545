import { Helmet } from "react-helmet-async";
import CaptureTag from "../../components/CaptureTag";
import { useState, useContext, useReducer } from "react";
import { Button, Card, Container, Form, Spinner, Stack } from "react-bootstrap";
import ANPR from "../../components/ANPR";
import NotyfContext from "../../contexts/NotyfContext";
import { findAnpr } from "../../utils/staticMethods";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import MediaDevicesWrapper from "../../components/MediaDevicesWrapper";
import { Check, Info, MapPin } from "react-feather";

const initialState = {
  mode: "select",
  plate: null,
  asset: null,
  tag: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "restart":
      return initialState;

    case "scan_plate":
      return {
        ...state,
        mode: "scan_plate",
        loading: false,
      };

    case "scan_tag":
      return {
        ...state,
        mode: "scan_tag",
        loading: false,
      };

    case "set_plate":
      return {
        ...state,
        mode: "scanned_plate",
        plate: action.data.plate,
        asset: action.data.asset,
        tag: null,
        loading: true,
      };

    case "set_plate_tag":
      return {
        ...state,
        mode: "scanned_plate",
        plate: action.data.plate,
        asset: action.data.asset,
        tag: action.data.tag,
        loading: true,
      };

    case "set_tag":
      return {
        ...state,
        mode: "scanned_tag",
        tag: action.data.tag,
        loading: true,
      };

    case "set_plate_no_tag":
      return {
        ...state,
        mode: "scanned_plate_no_tag",
        plate: action.data.plate,
        asset: action.data.asset,
        tag: action.data.tag,
        loading: false,
      };

    case "set_tag_no_asset":
      return {
        ...state,
        mode: "scanned_tag_no_asset",
        tag: action.data.tag,
        asset: null,
        loading: false,
      };

    case "set_plate_multi_asset":
      return {
        ...state,
        mode: "scanned_plate_multi_asset",
        plate: action.data.plate,
        assets: action.data.assets,
        asset: null,
        tag: null,
        loading: false,
      };

    case "no_plate":
      return {
        ...state,
        mode: "no_plate",
        plate: action.data.plate,
        asset: null,
        loading: false,
      };

    case "unlinked":
      return {
        ...state,
        mode: "unlinked",
        notSeen: action.data.notSeen,
        loading: false,
      };

    default:
      throw Error(`Unknown action. (${action.type})`);
  }
};

const NumberPlate = ({ children }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "yellow",
          color: "black",
          display: "inline-flex",
        }}
        className="rounded my-2 p-2"
      >
        {children}
      </div>
    </>
  );
};

const Tag = ({ children }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "grey",
          fontFamily: "monospace",
          display: "inline-flex",
        }}
        className="rounded my-2 p-2"
      >
        {children}
      </div>
    </>
  );
};

const modeValues = {
  select: {
    variant: "info",
    icon: <Info className="text-info me-2" size={"2em"} />,
    title: "Select Method",
    subTitle: (state) =>
      "You can unassign a tag by using either the vehicle number plate or the tag QR code",
    exit: null,
    restart: null,
  },
  scan_plate: {
    variant: "info",
    icon: <Info className="text-info me-2" size={"2em"} />,
    title: "Scan / type the number plate of the vehicle",
    subTitle: (state) =>
      "Point your device towards the vehicles number plate and press the capture button below or manually enter it",
    exit: null,
    restart: null,
  },
  scan_tag: {
    variant: "info",
    icon: <Info className="text-info me-2" size={"2em"} />,
    title: "Scan the QR code of the tag",
    subTitle: (state) =>
      "Point your device towards the QR code of the tag, the code will be automatically read",
    exit: null,
    restart: null,
  },
  no_plate: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "No vehicle found",
    subTitle: (state) => (
      <>
        No vehicle asset was found with number plate
        <br />
        <NumberPlate>{state.plate}</NumberPlate>
      </>
    ),
    exit: "Exit without Unassigning Vehicle",
    restart: "Start Again",
  },
  scanned_plate: {
    variant: "info",
    icon: <></>,
    title: "Tag scanned",
    subTitle: (state) => <></>,
    exit: null,
    restart: null,
  },
  scanned_plate_no_tag: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "Vehicle not assigned a tag",
    subTitle: (state) => (
      <>
        The vehicle asset found with number plate
        <br />
        <NumberPlate>{state.plate}</NumberPlate>
        <br />
        has no tag assigned to it.
      </>
    ),
    exit: "Exit without Unassigning Vehicle",
    restart: "Start Again",
  },
  scanned_plate_multi_asset: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "Multiple Assets Found",
    subTitle: (state) => (
      <>
        The vehicle number plate
        <br />
        <NumberPlate bg="warning">{state.plate}</NumberPlate>
        <br />
        has been found for multiple assets, please select one
      </>
    ),
    exit: null,
    restart: null,
  },
  scanned_tag_no_asset: {
    variant: "warning",
    icon: <Info className="text-warning me-2" size={"2em"} />,
    title: "Tag not assigned",
    subTitle: (state) => (
      <>
        The tag found with serial number
        <br />
        <Tag>{state.tag.serial}</Tag>
        <br />
        has no vehicle asset assigned to it.
      </>
    ),
    exit: "Exit without Unassigning Tag",
    restart: "Start Again",
  },
  unlinked: {
    variant: "success",
    icon: <Check className="text-success me-2" size={"2em"} />,
    title: "Tag Unassigned",
    subTitle: (state) => (
      <>
        The vehicle with registration plate
        <br />
        <NumberPlate>{state.tag.assetCompoundName}</NumberPlate>
        <br />
        has been unassigned from the tag with serial number
        <br />
        <Tag>{state.tag.serial}</Tag>
      </>
    ),
    exit: "Exit to Dashboard",
    restart: "Unassign Another",
  },
};

const TagUnassign = () => {
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedAsset, setSelectedAsset] = useState(-1);

  const unlinkTag = (tag) => {
    try {
      api
        .post(`tags/${tag.id}/unlinkarchive/${tag.assetId ?? 0}`)
        .then((res) => {
          notify.open({
            type: "success",
            message: "Tag unassigned",
          });
          dispatch({ type: "unlinked", data: res.data });
        })
        .catch((e) => {
          notify.open({
            type: "error",
            message: e,
          });
        });
    } catch (error) {
      notify.open({
        type: "error",
        message: error,
      });
    }
  };

  const handleSelectAssetClick = (e) => {
    const asset = state.assets[selectedAsset];
    const scannedPlate = state.plate;

    dispatch({
      type: "set_plate",
      data: { plate: scannedPlate, asset: asset },
    });
    api
      .get(`assets/tags/${asset.id}`)
      .then((response) => {
        const tag = response.data;
        dispatch({
          type: "set_plate_tag",
          data: { plate: scannedPlate, asset: asset, tag: tag },
        });
        unlinkTag(tag);
      })
      .catch((error) => {
        dispatch({
          type: "set_plate_no_tag",
          data: { plate: scannedPlate, asset: asset, tag: null },
        });
      });
  };

  return (
    <>
      <Helmet defer={false} title="Remove tag from vehicle" />

      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <MapPin className="me-1" /> Remove tag from vehicle
            </h1>
          </div>
        </div>
        <Card className="bg-white p-3">
          <MediaDevicesWrapper>
            {state.mode === "unlinked" && state.notSeen && (
              <>
                <div className="d-flex justify-content-center">
                  <Card
                    bg={"danger"}
                    style={{ width: "100%" }}
                    className="mb-2 d-flex justify-content-center"
                    size="lg"
                  >
                    <Card.Body>
                      <Card.Title
                        className="d-flex justify-content-center pb-5 blink"
                        style={{ fontSize: "18pt", color: "white" }}
                      >
                        !!! WARNING !!!
                      </Card.Title>
                      <Card.Text
                        className="d-flex justify-content-center pb-5"
                        style={{ fontSize: "18pt", color: "white" }}
                      >
                        Set device aside!
                        <br />
                        Do not use again.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )}
            {modeValues[state.mode] ? (
              <div
                className={`mx-0 mb-4 p-2 border border-${
                  modeValues[state.mode]?.variant
                } border-2 rounded-3`}
              >
                <h1 className="d-flex align-items-center">
                  {modeValues[state.mode]?.icon}
                  {modeValues[state.mode]?.title}
                </h1>
                <h3 className="ms-4">
                  {modeValues[state.mode]?.subTitle &&
                    modeValues[state.mode].subTitle(state)}
                </h3>
              </div>
            ) : (
              <>
                <div className="mb-4">{`No values for mode [${state.mode}]`}</div>
              </>
            )}
            <Stack gap={5} direction="vertical">
              {state.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" size="lg" />
                </div>
              )}

              {state.mode === "select" && (
                <>
                  <Button
                    disabled
                    onClick={(e) => {
                      dispatch({ type: "scan_plate", data: {} });
                    }}
                  >
                    Scan Number Plate
                  </Button>
                  <Button
                    onClick={(e) => {
                      dispatch({ type: "scan_tag", data: {} });
                    }}
                  >
                    Scan Tag
                  </Button>
                </>
              )}

              {state.mode === "scan_tag" && (
                <CaptureTag
                  setTag={(newTag) => {
                    const tag = newTag;
                    if (tag.assetId != null) {
                      dispatch({ type: "set_tag", data: { tag: tag } });
                      unlinkTag(tag);
                    } else {
                      dispatch({
                        type: "set_tag_no_asset",
                        data: { tag: tag },
                      });
                    }
                  }}
                />
              )}

              {state.mode === "scan_plate" && (
                <ANPR
                  onMatch={(scannedPlate, image) => {
                    findAnpr(notify, scannedPlate, image, (assets) => {
                      if (assets) {
                        if (typeof assets === typeof []) {
                          if (assets.length === 1) {
                            var asset = assets[0];
                            dispatch({
                              type: "set_plate",
                              data: { plate: scannedPlate, asset: asset },
                            });
                            api
                              .get(`assets/tags/${asset.id}`)
                              .then((response) => {
                                const tag = response.data;
                                dispatch({
                                  type: "set_plate_tag",
                                  data: {
                                    plate: scannedPlate,
                                    asset: asset,
                                    tag: tag,
                                  },
                                });
                                unlinkTag(tag);
                              })
                              .catch((error) => {
                                dispatch({
                                  type: "set_plate_no_tag",
                                  data: {
                                    plate: scannedPlate,
                                    asset: asset,
                                    tag: null,
                                  },
                                });
                              });
                          } else if (assets.length === 0) {
                            dispatch({
                              type: "no_plate",
                              data: { plate: scannedPlate },
                            });
                          } else {
                            dispatch({
                              type: "set_plate_multi_asset",
                              data: { plate: scannedPlate, assets: assets },
                            });
                          }
                        }
                      } else {
                        dispatch({
                          type: "no_plate",
                          data: { plate: scannedPlate },
                        });
                      }
                    });
                  }}
                />
              )}
              {state.mode === "scanned_plate_multi_asset" && (
                <>
                  <div className="d-flex justify-content-around">
                    <Form.Select
                      aria-label="Select asset"
                      size="lg"
                      name="selectAsset"
                      className="me-3"
                      onBlur={(e) => {
                        console.log(e);
                        setSelectedAsset(parseInt(e.target.value));
                      }}
                      onChange={(e) => {
                        console.log(e);
                        setSelectedAsset(parseInt(e.target.value));
                      }}
                    >
                      <option value="-1">select an asset</option>
                      {state.assets.map((asset, index) => {
                        return (
                          <option key={index} value={index}>
                            {asset.compoundName}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {
                      <Button
                        disabled={selectedAsset === -1}
                        onClick={(e) => {
                          handleSelectAssetClick(e);
                        }}
                      >
                        Select
                      </Button>
                    }
                  </div>
                </>
              )}

              {modeValues[state.mode] && (
                <div className="d-flex justify-content-around">
                  {modeValues[state.mode].exit && (
                    <Button
                      onClick={(e) => {
                        navigate("/");
                      }}
                    >
                      {modeValues[state.mode].exit}
                    </Button>
                  )}
                  {modeValues[state.mode].restart && (
                    <Button
                      onClick={(e) => {
                        dispatch({ type: "restart", data: {} });
                      }}
                    >
                      {modeValues[state.mode].restart}
                    </Button>
                  )}
                </div>
              )}
            </Stack>
          </MediaDevicesWrapper>
        </Card>
      </Container>
      {/* <code>{JSON.stringify(state)}</code> */}
    </>
  );
};

export default TagUnassign;
