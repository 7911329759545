import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";

const QRCodeWithDownload = (props) => {
  const { value } = props;

  const downloadQrCode = () => {
    drawCanvas().then((r) => {
      let downLink = document.createElement("a");
      downLink.href = r;
      downLink.download = value;
      downLink.click();
    });
  };

  const DPR = () => {
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio;
    } else {
      return 1;
    }
  };

  const parseValue = (value) => {
    return parseInt(value, 10);
  };

  const drawCanvas = async () => {
    const dom = document.getElementById("printHtml");
    const box = window.getComputedStyle(dom);
    const width = parseValue(box.width);
    const height = parseValue(box.height);
    const scaleBy = DPR();
    const canvas = document.createElement("canvas");

    canvas.width = width * scaleBy;
    canvas.height = height * scaleBy;
    canvas.style.width = `${canvas.width}px`;
    canvas.style.height = `${canvas.height}px`;
    return await html2canvas(dom, { canvas }).then((canvas) => {
      return canvas.toDataURL("image/png");
    });
  };

  return (
    <>
      <div className="d-flex flex-column align-items-start">
        <div
          id={"printHtml"}
          className="text-center m-3"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <QRCodeCanvas
            level={"H"}
            includeMargin={true}
            value={`${window.location.origin}/scanresult/${value}`}
            id="qrCode"
          />
          <h4
            className="mb-3"
            style={{ backgroundColor: "white", color: "black" }}
          >
            {value}
          </h4>
        </div>
        <span
          id="down_qacode"
          className="cursor-pointer"
          onClick={() => downloadQrCode()}
        >
          Download
        </span>
      </div>
    </>
  );
};

export default QRCodeWithDownload;
