import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/scss/3-components/_signIn.scss";

import useAuth from "../../hooks/useAuth";
import "../../assets/scss/3-components/_video.scss";
import { globalConfig } from "../../config";
import AuthWrapper from "../../components/auth/AuthWrapper";

function SignIn() {
  const navigate = useNavigate();
  const { signIn, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Helmet
        defer={false}
        title={globalConfig.productName.toUpperCase().concat(" Sign In")}
      />
      <AuthWrapper
        title={"Welcome"}
        subTitle={"Sign in to your account to continue"}
      >
        <Formik
          initialValues={{
            submit: false,
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await signIn(values.email, values.password);
              navigate("/");
            } catch (error) {
              let message = error.message || "Something went wrong";
              if (error.message && error.message.indexOf("400") > -1) {
                message = "Invalid Credentials.";
              }
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              <Form.Group className="mb-3">
                <Form.Label className="text-gray">Email</Form.Label>
                <Form.Control
                  size="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-gray">Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
                <small>
                  <Link to="/auth/reset-password">Forgot password?</Link>
                </small>
              </Form.Group>

              <div>
                <Form.Check
                  type="checkbox"
                  id="rememberMe"
                  label="Remember me next time"
                  defaultChecked
                />
              </div>

              <div className="text-center mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AuthWrapper>
    </React.Fragment>
  );
}

export default SignIn;
