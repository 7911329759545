import React, { useContext, useRef, useState } from "react";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import useAuth from "../../hooks/useAuth";

const Notes = ({ id }) => {
  const [showModal, setShow] = useState(false);
  const [curNote, setCurrentNote] = useState(false);
  const [actionName, setActionName] = useState("");
  const [text, setText] = useState("");
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  const columns = [
    {
      Header: "Note",
      accessor: "text",
    },
    {
      Header: "Last Modified",
      accessor: "lastModified",
      type: "date",
      dateFormat: "DD/MM/YY HH:mm",
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      action: [
        { name: "Edit", color: "primary", disabled: !user?.editNotes },
        { name: "Delete", color: "danger", disabled: !user?.deleteNotes },
      ],
    },
  ];

  const btnClick = (name, id) => {
    setActionName(name);
    if (name === "Edit") {
      api.get(`notes/${id}`).then((res) => {
        setCurrentNote(res.data);
        setText(res.data.text);
        setShow(true);
      });
    } else {
      setCurrentNote(id);
      setShow(true);
    }
  };

  const onSave = () => {
    if (actionName === "Edit") {
      api.put(`notes`, { id: curNote.id, text: text }).then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        setShow(false);
        setText("");
        childRef.current.updateTable(1);
      });
    } else if (actionName === "Delete") {
      api.delete(`notes/${curNote}`).then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        setShow(false);
        setText("");
        childRef.current.updateTable(1);
      });
    } else if (actionName === "New") {
      api.post(`notes`, { parentId: id, text: text }).then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        setShow(false);
        setText("");
        childRef.current.updateTable(1);
      });
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
            <FontAwesomeIcon className={"me-1"} icon={faClipboard} /> Notes
          </h4>
          {user?.createNotes && (
            <Button
              className="text-nowrap"
              onClick={() => {
                setActionName("New");
                setShow(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              Create New
            </Button>
          )}
        </div>
        <Card className="bg-white pt-3 shadow-none">
          <CommonTable
            btnClick={btnClick}
            ref={childRef}
            apiName="notes"
            parentId={id}
            columns={columns}
          />
        </Card>
      </Container>
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {actionName !== "Delete" ? `${actionName} Note` : "Please Confirm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {actionName === "Delete" ? (
            <span>Are you sure you want to delete this note ?</span>
          ) : (
            <textarea
              style={{ height: "100px" }}
              className="w-100 table-search debounceInput-search text-gray"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {actionName === "Delete" ? (
            <Button variant="danger" onClick={() => onSave()}>
              Delete
            </Button>
          ) : (
            <Button variant="primary" onClick={() => onSave()}>
              Save
            </Button>
          )}
          <Button
            variant={"secondary"}
            onClick={() => {
              setShow(false);
              setCurrentNote(null);
              setText("");
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Notes;
