import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Button, Form, Modal, Table } from "react-bootstrap";
import api from "../api.js";
import NotyfContext from "../contexts/NotyfContext.js";

const ArchiveElementButton = (props) => {
  const { element, trackers, tags, apps, charger, onSuccess } = props;
  const archived = element.type?.includes("archived") || element.archived;
  const text = archived ? "Unarchive" : "Archive";
  const [show, setShow] = useState(false);
  const [devices, setDevices] = useState([]);
  const [unlinkDevices, setUnlinkDevices] = useState(false);
  const [archiveDevices, setArchiveDevices] = useState(false);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    var devices = [];
    if (_.isArray(trackers)) {
      _.forEach(trackers, (value, index) => {
        devices.push({
          id: value.id,
          serial: value.serial,
          unlink: false,
          archive: false,
          type: "trackers",
        });
      });
    }
    if (_.isArray(tags)) {
      _.forEach(tags, (value, index) => {
        devices.push({
          id: value.id,
          serial: value.serial,
          unlink: false,
          archive: false,
          type: "tags",
        });
      });
    }
    if (_.isArray(apps)) {
      _.forEach(apps, (value, index) => {
        devices.push({
          id: value.id,
          serial: value.serial,
          unlink: false,
          archive: false,
          type: "apps",
        });
      });
    }
    if (_.isObject(charger)) {
      devices.push({
        id: charger.id,
        serial: charger.serial,
        unlink: false,
        archive: false,
        type: "solarchargers",
      });
    }
    setDevices(devices);
    setUnlinkDevices(false);
    setArchiveDevices(false);
  }, [trackers, tags, apps, charger]);

  const handleClick = (event) => {
    var action = _.toLower(text);
    if (action === "archive") {
      api
        .put(`assets/${element.id}/archive/${unlinkDevices}/${archiveDevices}`)
        .then((response) => {
          notify.open({
            type: "success",
            message: "Archived",
          });
          setShow(false);
          onSuccess && onSuccess("archived-assets");
        });
    } else {
      api.put(`assets/${element.id}/unarchive`).then((response) => {
        notify.open({
          type: "success",
          message: "Unarchived",
        });
        setShow(false);
        onSuccess && onSuccess("assets");
      });
    }
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setShow(true);
        }}
        variant={"info"}
        className=""
      >
        {text}
      </Button>
      <Modal size="sm" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {text}&nbsp;{element?.primaryName}
            {element?.secondaryName && " " + element?.secondaryName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Linked devices</h4>
          <Table striped>
            <thead>
              <tr>
                <th>Serial</th>
                {/* <th><div class="d-flex justify-content-center">Unlink</div></th> */}
                {/* <th><div class="d-flex justify-content-center">Archive</div></th> */}
              </tr>
            </thead>
            <tbody>
              {devices &&
                devices.map((value, index) => {
                  return (
                    <tr key={value.id}>
                      <td> {value.serial}</td>
                      {/* <td>
                                                    <div class="d-flex justify-content-center">
                                                        <Form.Check variant="secondary" aria-label="unlink device"
                                                            checked={value.unlink}
                                                            onChange={(e) => { handleUnlinkChange(value.id, e.target.checked); }} />
                                                    </div>
                                                </td> */}
                      {/* <td>
                                                    <div class="d-flex justify-content-center">
                                                        <Form.Check aria-label="archive device"
                                                            checked={value.archive}
                                                            disabled={value.type === "app" || value.type === "charger"}
                                                            onChange={(e) => { handleArchiveChange(value.id, e.target.checked); }} />
                                                    </div>
                                                </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {!archived && (
            <>
              <Form.Check
                aria-label="unlink devices"
                label="Unlink Devices"
                checked={unlinkDevices}
                onChange={(e) => {
                  setUnlinkDevices(e.target.checked);
                }}
              />
              <Form.Check
                aria-label="archive devices"
                label="Archive Devices"
                checked={archiveDevices}
                onChange={(e) => {
                  setArchiveDevices(e.target.checked);
                }}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClick} variant={"primary"}>
            {text}
          </Button>
          <Button onClick={() => setShow(false)} variant={"secondary"}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchiveElementButton;
