import React from "react";
import { Button, Card } from "react-bootstrap";
import {
  AlertCircle,
  BatteryCharging,
  Clock,
  LogIn,
  LogOut,
  Move,
  PauseCircle,
  TrendingDown,
  UserX,
} from "react-feather";
import useAuth from "../../hooks/useAuth";

const RuleItem = (props) => {
  const { user } = useAuth();

  const renderIcon = () => {
    if (props.type === "attendance") {
      return (
        <div>
          <h4>
            <Clock size={18} /> Attendance
          </h4>
          <div>
            Schedule zone attendances and receive alerts for people and assets.
          </div>
        </div>
      );
    } else if (props.type === "exitzone") {
      return (
        <div>
          <h4>
            <LogOut size={18} /> Zone Exit
          </h4>
          <div>Receive an alert when assets or people leave a zone.</div>
        </div>
      );
    } else if (props.type === "enterzone") {
      return (
        <div>
          <h4>
            <LogIn size={18} /> Zone Entry
          </h4>
          <div>Receive an alert when assets or people enter a zone.</div>
        </div>
      );
    } else if (props.type === "zonesupervision") {
      return (
        <div>
          <h4>
            <UserX size={18} /> Unsupervised Zones
          </h4>
          <div>Raise the alarm whenever a zone is unattended</div>
        </div>
      );
    } else if (props.type === "lowbattery") {
      return (
        <div>
          <h4>
            <BatteryCharging size={18} /> Low Battery
          </h4>
          <div>
            Automatic notifications when your tags' or trackers' batteries are
            running low.
          </div>
        </div>
      );
    } else if (props.type === "lowvoltage") {
      return (
        <div>
          <h4>
            <TrendingDown size={18} /> Low Voltage
          </h4>
          <div>
            Alerts you and your contacts when the wired supply voltage to a
            tracker falls below a certain level.
          </div>
        </div>
      );
    } else if (props.type === "motion") {
      return (
        <div>
          <h4>
            <Move size={18} /> Motion Alarm
          </h4>
          <div>Receive an alert when a tracker or tag detects motion.</div>
        </div>
      );
    } else if (props.type === "speeding") {
      return (
        <div>
          <h4>
            <AlertCircle size={18} /> Speeding
          </h4>
          <div>
            Recieve a notification whenever your trackers exceed any chosen
            speed.
          </div>
        </div>
      );
    } else if (props.type === "pausetracking") {
      return (
        <div>
          <h4>
            <PauseCircle size={18} /> Pause Tracking
          </h4>
          <div>Pause tracking based on location.</div>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="flex-fill">
      <Card.Body>
        <div className="h-100 d-flex flex-column justify-content-between align-items-start">
          {renderIcon()}
          <div className="mt-3">
            {props.type !== "pausetracking" && user?.createRules === true && (
              <Button onClick={() => props.onWizards(props.type)}>
                Wizard
              </Button>
            )}
            {user?.createRules === true && (
              <Button
                className="ms-1"
                onClick={() => props.onCreate(props.type)}
              >
                Advanced
              </Button>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export default RuleItem;
