import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import api from "../../api";

const NewGroups = ({ showModal, closeModal, apiName }) => {
  const navigate = useNavigate();

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>New Contact Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Formik
              initialValues={{
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                primaryName: Yup.string()
                  .max(255)
                  .required("Group Name is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  api
                    .post("contactgroups", {
                      groupName: values.primaryName,
                    })
                    .then((res) => {
                      navigate(`/contactgroups/edit?id=${res.data.id}`);
                    });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Group Name</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="primaryName"
                      value={values.primaryName}
                      isInvalid={Boolean(
                        touched.primaryName && errors.primaryName
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.primaryName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.primaryName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default NewGroups;
