import React from "react";

import { Dropdown } from "react-bootstrap";

import { Settings, User } from "react-feather";

import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { setOtherSession, setSession, setUserSession } from "../../utils/jwt";

const NavbarUser = ({ showAdditional, showHasAdmin }) => {
  const navigate = useNavigate();
  const { signOut, user } = useAuth();

  const logOut = () => {
    signOut();
    navigate("/auth/sign-in");
  };

  const logBack = () => {
    let token = localStorage.getItem("accessTokenAdmin");
    setSession(token);
    setOtherSession(null);
    setUserSession(null);
    localStorage.removeItem("recentItem");
    if (window.location?.origin) {
      window.location.href = window.location.origin + "/customers";
    } else {
      navigate("/customers");
    }
  };

  const logBackToAdmin = () => {
    let token = localStorage.getItem("accessTokenUser");
    setSession(token);
    setUserSession(null);
    localStorage.removeItem("recentItem");
    if (window.location?.origin) {
      window.location.href = window.location.origin;
    } else {
      navigate("/");
    }
  };

  const toProfilePage = () => {
    if (user) {
      navigate(`/people/edit?id=${user.id}&direct=details`);
    }
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark">{user?.compoundName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {user?.role !== "Root" && (
          <>
            <Dropdown.Item onClick={() => toProfilePage()}>
              <User size={18} className="align-middle me-2" />
              Profile
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        {showAdditional && (
          <Dropdown.Item onClick={logBack}>Logout back to Root</Dropdown.Item>
        )}
        {showHasAdmin && (
          <Dropdown.Item onClick={logBackToAdmin}>
            Logout back to Admin
          </Dropdown.Item>
        )}
        {!showAdditional && !showHasAdmin && (
          <Dropdown.Item onClick={logOut}>Sign out</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
