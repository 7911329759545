import { Bookmark, Disc, Package, Users } from "react-feather";
import React from "react";

const Option = (props) => {
  const { getStyles, innerRef, innerProps, data } = props;

  const getIcon = (type) => {
    if (type === "asset") {
      return <Package size={14} />;
    } else if (type === "person") {
      return <Users size={14} />;
    } else if (type === "zone") {
      return <Disc size={14} />;
    } else if (type === "task") {
      return <Bookmark size={14} />;
    }
  };
  return (
    <div ref={innerRef} style={getStyles("option", props)} {...innerProps}>
      <div>
        {getIcon(data.icon)}
        <span className="ms-1">{`${data.label} ${
          data.reference ? `(${data.reference})` : ""
        }`}</span>
      </div>
    </div>
  );
};
export default Option;
