import axios from "axios";
import { Notyf } from "notyf";
import { globalConfig } from "./config";

axios.defaults.baseURL = globalConfig.apiKey;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  "accessToken"
)}`;
axios.defaults.headers.common.Theme = globalConfig.applicationTheme;

const axiosInstance = axios.create({});

// response interceptor, show error message if returns status is not 200 and not get method
axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response?.data?.status &&
      response?.data?.status !== 200 &&
      response?.data?.message
    ) {
      if (response.config.method !== "get") {
        let notyf = new Notyf();
        notyf.error({
          type: "error",
          message:
            response.data?.title ||
            response.data?.message ||
            response.data ||
            "something went wrong",
          position: {
            x: "center",
            y: "top",
          },
        });
      }
      return Promise.reject(response.data?.message);
    }
    if (response?.data?.byteLength <= 60) {
      return Promise.reject();
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error?.response?.config?.method !== "get" &&
      error?.response?.config?.url.indexOf("customerLogin") === -1
    ) {
      let notyf = new Notyf();
      notyf.error({
        type: "error",
        message:
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error?.response?.data ||
          "something went wrong",
        position: {
          x: "center",
          y: "top",
        },
      });
    }
    if (
      error?.response?.config?.url &&
      error?.response?.config?.url.indexOf("exportcsv") !== -1
    ) {
      let notyf = new Notyf();
      notyf.error({
        type: "error",
        message:
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error?.response?.data ||
          "something went wrong",
        position: {
          x: "center",
          y: "top",
        },
      });
    }
    return Promise.reject(
      axios.isCancel(error)
        ? error
        : (error?.response && error?.response?.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
