import React, { useEffect, useState } from "react";

import { Button, Card, Col } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../api";
import NewObject from "../detail/NewObject";
import { Disc, Package, Users } from "react-feather";
import useAuth from "../../hooks/useAuth";
import useSrlHelper from "../../hooks/useSrlHelper";
import SrlNewAsset from "../../srl/components/SrlNewAsset";
import { SRL_CATEGORIES } from "../../constants";

const Summary = ({ width, name }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState("");
  const [showModal, setShow] = useState(false);
  const [type, setType] = useState("");
  const { user } = useAuth();
  const { isSrl } = useSrlHelper();
  const [srlCategories] = useState(SRL_CATEGORIES);

  useEffect(() => {
    if (name) {
      setType(getType(name));
    }
  }, [name]);

  useEffect(() => {
    if (type) {
      api.get(`${type}?page=1&limit=1`).then((r) => {
        const total = r.headers["x-total-count"];
        setCount(total);
      });
    }
  }, [type]);

  const getType = (str) => {
    if (str.indexOf("Asset") > -1) {
      return "assets";
    } else if (str.indexOf("Zone") > -1) {
      return "zones";
    } else if (str.indexOf("People") > -1) {
      return "people";
    }
  };

  const getIcon = (str) => {
    if (str.indexOf("Asset") > -1) {
      return <Package className="text-dark" size={24} />;
    } else if (str.indexOf("People") > -1) {
      return <Users className="text-dark" size={24} />;
    } else if (str.indexOf("Zone") > -1) {
      return <Disc className="text-dark" size={24} />;
    }
  };

  const toFirstLetterUpperCase = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
  };

  const createText = (str) => {
    if (str.indexOf("Zone") > -1) {
      return "Create a Zone";
    } else if (str.indexOf("People") > -1) {
      return "Create a Person";
    } else {
      return "Create an Asset";
    }
  };

  return (
    <React.Fragment>
      <Col key={name} lg={((width / 100) * 12).toFixed(0)} className="mb-4">
        <Card className="flex-fill w-100 h-100">
          <div className="m-3 d-flex justify-content-between align-items-center">
            <NavLink to={{ pathname: `/${type}` }}>
              <h3 className="text-primary">
                {count} {toFirstLetterUpperCase(type)}
              </h3>
            </NavLink>
            <div
              style={{ borderRadius: "50%", width: "48px", height: "48px" }}
              className="bg-light text-center d-flex align-items-center justify-content-center"
            >
              {getIcon(name)}
            </div>
          </div>
          <div className="m-3 d-flex flex-column align-items-start">
            {((type === "assets" && !isSrl() && user?.createAssets) ||
              (type === "assets" && isSrl() && user?.role === "Admin") ||
              (type === "people" && user?.createPeople) ||
              (type === "zones" && user?.createZones)) && (
              <Button
                className="align-self-baseline mb-4"
                onClick={() => setShow(true)}
              >
                {createText(name)}
              </Button>
            )}
            {type !== "zones" &&
              user?.editTags &&
              !(user?.role === "User" && isSrl()) && (
                <Button
                  className="align-self-baseline mb-4"
                  variant={"success"}
                  onClick={() =>
                    navigate(`/device/linker?type=${type}&linker=tags`)
                  }
                >
                  Link a Tag
                </Button>
              )}
            {type !== "zones" &&
              user?.editTrackers &&
              !(user?.role === "User" && isSrl()) && (
                <Button
                  className="align-self-baseline"
                  variant={"success"}
                  onClick={() =>
                    navigate(`/device/linker?type=${type}&linker=trackers`)
                  }
                >
                  Link a Tracker
                </Button>
              )}
          </div>
        </Card>
      </Col>
      {isSrl() && type === "assets" ? (
        <SrlNewAsset
          showModal={showModal}
          closeModal={() => setShow(false)}
          apiName={type}
          categories={srlCategories}
        />
      ) : (
        <NewObject
          showModal={showModal}
          closeModal={() => setShow(false)}
          apiName={type}
        />
      )}
    </React.Fragment>
  );
};

export default Summary;
