import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import defaultImg from "../assets/img/icon/GPS/placeholder.png";
import NotyfContext from "../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../hooks/useAuth";
import { getUrl } from "../utils/staticMethods";
import { globalConfig } from "../config";
import { fieldNameToAssetName } from "./fields/fieldsHelper";
import ElementRootFields from "./fields/ElementRootFields";
import ElementCustomFields from "./fields/ElementCustomFields";
import ElementDefaultFields from "./fields/ElementDefaultFields";

const AssetDetails = (props) => {
  const navigate = useNavigate();
  const notify = useContext(NotyfContext);
  const [imgSrc, setImgSrc] = useState();
  const { user } = useAuth();
  const [modalTitle, setModalTitle] = useState(null);
  const [customFieldConfig, setCustomFieldConfig] = useState(null);
  const [schema, setSchema] = useState({});

  useEffect(() => {
    if (!user || !props || !props.asset || !props.apiName) return;

    if (user.role === "Root") {
      api
        .get(`${props.apiName || "assets"}/customfieldconfig/` + props.asset.id)
        .then((res) => {
          setCustomFieldConfig(res.data);
        });
    } else {
      switch (props.apiName) {
        case "assets":
          setCustomFieldConfig(user.assetFieldConfig);
          break;

        case "people":
          setCustomFieldConfig(user.personFieldConfig);
          break;

        case "zones":
          setCustomFieldConfig(user.zoneFieldConfig);
          break;

        default:
          setCustomFieldConfig(user.assetFieldConfig);
          break;
      }
    }
  }, [user, props.asset, props.apiName]);

  useEffect(() => {
    if (!customFieldConfig) return;

    setSchema(
      customFieldConfig.customFields.reduce(
        (previous, value) => {
          let s = Yup.string()
            .max(255)
            .matches(
              /^[^#]*$/,
              "'#' is a protected character used for hashtag searches and cannot be used here"
            );
          if (value.mandatory) {
            s = s.required(value.displayName + " is required");
          }
          let propertyName = fieldNameToAssetName(value.fieldName);
          // previous = { ...previous};
          previous[propertyName] = s;
          return previous;
        },
        {
          email: Yup.string().email("Invalid email"),
        }
      )
    );
  }, [customFieldConfig]);

  useEffect(() => {
    setImgSrc(props.asset.icon);
  }, [props.asset.icon]);

  const deleteAsset = async () => {
    await api
      .delete(`${props.apiName || "assets"}/` + props.asset.id)
      .then((res) => {
        navigate(`/${props.apiName || ""}`);
      });
  };

  return (
    <>
      <h4>
        <FontAwesomeIcon icon={faFileAlt} className="me-1"></FontAwesomeIcon>{" "}
        Details
      </h4>
      <Formik
        enableReinitialize
        initialValues={{
          primaryName: props.asset.primaryName || "",
          secondaryName: props.asset.secondaryName || "",
          reference: props.asset.reference || "",
          fleetNumber: props.asset.fleetNumber || "",
          category: props.asset.category || "",
          suppString1: props.asset.suppString1 || "",
          suppString2: props.asset.suppString2 || "",
          suppString3: props.asset.suppString3 || "",
          suppString4: props.asset.suppString4 || "",
          suppString5: props.asset.suppString5 || "",
          email: props.asset.email || "",
          playSounds: props.asset.playSounds || false,
          screenAlerts: props.asset.screenAlerts || false,
          archived: props.asset.archived || false,
          submit: false,
        }}
        validationSchema={Yup.object().shape(schema)}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let data = JSON.parse(JSON.stringify(props.asset));
          data.id = props.asset.id;
          data.primaryName = values.primaryName;
          data.secondaryName = values.secondaryName;
          data.reference = values.reference;
          data.fleetNumber = values.fleetNumber;
          data.category = values.category;
          data.suppString1 = values.suppString1;
          data.suppString2 = values.suppString2;
          data.suppString3 = values.suppString3;
          data.suppString4 = values.suppString4;
          data.suppString5 = values.suppString5;
          data.icon = imgSrc;
          if (props.apiName === "assets") {
            data.archived = values.archived;
          }
          if (props.apiName === "people") {
            data.email = values.email;
          }
          if (props.asset.id === user?.id) {
            data.playSounds = values.playSounds;
            data.screenAlerts = values.screenAlerts;
          }
          try {
            api.put(`${props.apiName || "assets"}`, data).then((res) => {
              notify.open({
                type: "success",
                message: "Changes Saved",
              });
              props.setAsset(res.data);
            });
          } catch (error) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Icon</Form.Label>
              <div>
                {(props.type === "zones" && user?.editZones) ||
                (props.type === "assets" && user?.editAssets) ||
                (props.type === "people" && user?.editPeople) ? (
                  <NavLink
                    to={{
                      pathname: "/icons/asset-icon",
                      search: `?id=${props.asset.id}&type=${props.type}`,
                    }}
                  >
                    <img
                      alt={imgSrc}
                      className="profile-icon"
                      src={
                        imageSet[getUrl(imgSrc)]
                          ? require("./../assets/img/icon/" +
                              imageSet[getUrl(imgSrc)]).default
                          : defaultImg
                      }
                    />
                  </NavLink>
                ) : (
                  <img
                    alt={imgSrc}
                    className="profile-icon"
                    src={
                      imageSet[getUrl(imgSrc)]
                        ? require("./../assets/img/icon/" +
                            imageSet[getUrl(imgSrc)]).default
                        : defaultImg
                    }
                  />
                )}
              </div>
            </Form.Group>

            {customFieldConfig && customFieldConfig.enabled ? (
              user.role === "Root" ? (
                <ElementRootFields
                  {...props}
                  customFieldConfig={customFieldConfig}
                  mode={"details"}
                />
              ) : (
                <ElementCustomFields
                  {...props}
                  customFieldConfig={customFieldConfig}
                  mode={"details"}
                />
              )
            ) : (
              <ElementDefaultFields {...props} mode={"details"} />
            )}

            {props.apiName === "assets" &&
              user?.role !== "User" &&
              globalConfig.applicationTheme !== "srl" && (
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    name="archived"
                    label="Archived"
                    checked={values.archived}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}

            <div className="mt-3">
              {((props.type === "zones" && user?.editZones) ||
                (props.type === "assets" && user?.editAssets) ||
                (props.type === "people" && user?.editPeople) ||
                user?.id === props.asset?.id) && (
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  Save
                </Button>
              )}
              {((props.type === "zones" && user?.deleteZones) ||
                (props.type === "assets" && user?.deleteAssets) ||
                (props.type === "people" && user?.deletePeople)) &&
                user?.id !== props.asset?.id && (
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => setModalTitle(true)}
                    className="m-lg-1"
                  >
                    Delete
                  </Button>
                )}
            </div>
          </Form>
        )}
      </Formik>
      <Modal show={modalTitle} onHide={() => setModalTitle(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.type === "assets" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Asset?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this item.
                </p>
              </>
            )}
            {props.type === "people" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Person?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this person.
                </p>
              </>
            )}
            {props.type === "zones" && (
              <>
                <p>{`Are you sure that you wish to permanently delete this Zone?`}</p>
                <p>
                  Deletions are not reversible and will effect any associated
                  data such as tracking information related to this item.
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => deleteAsset()} variant="danger">
            Delete
          </Button>
          <Button variant={"secondary"} onClick={() => setModalTitle(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssetDetails;
