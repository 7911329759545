import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import NewObject from "../../components/detail/NewObject";
import CommonTable from "../../components/tables/CommonTable";
import { AlignJustify, Grid, MapPin, Moon, Package } from "react-feather";
import { NavLink } from "react-router-dom";
import GridView from "../../components/tables/GridView";
import { dateFromNow } from "../../utils/staticMethods";

// a demo page shows the assets in different views
function Assets() {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const [tableView, setTableView] = useState(true);

  const { user } = useAuth();

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "compoundName",
      type: "link",
      link: "/assets/edit",
      stateId: "id",
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return (
            <>
              <MapPin width={14} height={14} className="me-1" />
              {dateFromNow(cell.value)}
            </>
          );
        }
        return (
          <span>
            <Moon width={14} height={14} className="me-1" /> Waiting...
          </span>
        );
      },
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: (cell) => (
        <NavLink
          to={{
            pathname: `/customers/edit`,
            search: `?id=${cell.row?.original?.customerId}`,
          }}
        >
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          {cell.value &&
            cell.value.map((tag, i) => (
              <NavLink
                key={i}
                to={{
                  pathname: "/globalSearch",
                  search: `?searchString=${encodeURIComponent("#" + tag)}`,
                }}
              >
                <span
                  key={i}
                  className="badge bg-primary me-1 my-1 cursor-pointer"
                >
                  {tag}
                </span>
              </NavLink>
            ))}
        </div>
      ),
    },
  ];

  const gridColumns = [
    {
      title: "Name",
      value: "compoundName",
      Cell: (cell) => (
        <div>
          <span className="font-weight-bold text-black fs-lg">
            {cell.compoundName} {cell.reference ? `(${cell.reference})` : null}
          </span>
        </div>
      ),
    },
    {
      title: "Hashtags",
      value: "hashTags",
      disableSortBy: true,
      visibilityName: "hash-tags",
      Cell: (cell) => (
        <div>
          {cell.hashTags &&
            cell.hashTags.map((tag, i) => (
              <NavLink
                onClick={(e) => e.stopPropagation()}
                key={i}
                to={{
                  pathname: "/globalSearch",
                  search: `?searchString=${encodeURIComponent("#" + tag)}`,
                }}
              >
                <span
                  key={i}
                  className="badge bg-primary me-1 my-1 cursor-pointer"
                >
                  {tag}
                </span>
              </NavLink>
            ))}
        </div>
      ),
    },
    {
      title: "Last Seen",
      value: "lastSeen",
      visibilityName: "last-seen",
      Cell: (cell) => {
        if (cell.lastSeen && cell.lastSeen.indexOf("0001-01-01") === -1) {
          return (
            <div className="flex-row d-flex align-items-center">
              <MapPin width={14} height={14} className="me-1" />
              {dateFromNow(cell.lastSeen)}
            </div>
          );
        }
        return (
          <span>
            <Moon width={14} height={14} className="me-1" /> Waiting...
          </span>
        );
      },
    },
    {
      title: "Customer",
      value: "customerName",
      visibilityName: "customer-name",
      show: user?.role === "Root",
      Cell: (cell) => (
        <NavLink
          to={{
            pathname: `/customers/edit`,
            search: `?id=${cell?.customerId}`,
          }}
        >
          {cell?.customerName}
        </NavLink>
      ),
    },
  ];

  const sortParam = [
    {
      name: "",
    },
  ];

  return (
    <React.Fragment>
      <Helmet defer={false} title="Assets" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <Package className="me-2" /> Assets{" "}
            {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
          <div className="d-flex align-items-center">
            {tableView ? (
              <Grid
                className="cursor-pointer me-2"
                onClick={() => setTableView(false)}
              />
            ) : (
              <AlignJustify
                className="cursor-pointer me-2"
                onClick={() => setTableView(true)}
              />
            )}
            {(user?.role === "Admin" || user?.createAssets) && (
              <Button onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                Create New
              </Button>
            )}
          </div>
        </div>
        {tableView ? (
          <Card className="bg-white p-3">
            <CommonTable
              setCountParent={setResultCount}
              apiName="assets"
              columns={columns}
              visibilityName="assets"
            />
          </Card>
        ) : (
          <GridView
            setCountParent={setResultCount}
            apiName="assets"
            columns={gridColumns}
            link={"/assets/edit"}
            sort={sortParam}
          />
        )}
      </Container>
      <NewObject
        showModal={showModal}
        closeModal={() => setShow(false)}
        apiName="assets"
      />
    </React.Fragment>
  );
}
export default Assets;
