import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import api from "../api.js";
import _ from "lodash";
import { Globe, Radio, Smartphone, Sun } from "react-feather";
import NotyfContext from "../contexts/NotyfContext.js";
import { Formik } from "formik";

const ArchiveDeviceButton = (props) => {
  const { device, deviceType, onSuccess } = props;
  const [deviceId, setDeviceId] = useState("");
  const [archived, setArchived] = useState(false);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState(null);
  const [devices, setDevices] = useState([]);
  const [unlinkAll, setUnlinkAll] = useState(false);
  const [archiveAll, setArchiveAll] = useState(false);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    if (device) {
      if (device.id !== deviceId) {
        const archived = device?.deviceType?.includes("archived");
        const text = archived ? "Unarchive" : "Archive";

        const assetId = device?.assetId;
        if (assetId) {
          api
            .get(`assets/${assetId}`)
            .then((res) => {
              setAsset(res.data);
              loadAssetDevices(res.data.id);
            })
            .catch((e) => {});
        }

        setDeviceId(device.id);
        setArchived(archived);
        setText(text);
      }
    } else {
      setArchived(false);
      setText("");
      setDevices([]);
    }
  }, [device]);

  const loadAssetDevices = (assetId) => {
    const deviceTypes = ["trackers", "tags", "apps", "solarchargers"];
    setDevices([]);
    var promises = [];
    _.forEach(deviceTypes, (deviceType, index) => {
      promises.push(api.get(`assets/${deviceType}/${assetId}`));
    });
    Promise.allSettled(promises).then((results) => {
      var devices = [];
      _.forEach(results, (result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value.data;
          devices.push({
            id: data.id,
            serial: data.serial,
            unlink: false,
            archive: false,
            type: deviceTypes[index],
          });
        }
      });
      setDevices(devices);
    });
  };

  const getIcon = (deviceType) => {
    const iconSize = 16;
    switch (deviceType) {
      case "trackers":
        return <Globe size={iconSize}></Globe>;
      case "tags":
        return <Radio size={iconSize}></Radio>;
      case "apps":
        return <Smartphone size={iconSize}></Smartphone>;
      case "solarchargers":
        return <Sun size={iconSize}></Sun>;
      default:
        return <></>;
    }
  };

  // const handleClick = (event) => {
  //     var action = _.toLower(text);
  //     if (action === "archive") {
  //         api.put(`${deviceType}/${device.id}/archive/${unlinkAll}/${archiveAll}`)
  //             .then((response) => {
  //                 notify.open({
  //                     type: 'success',
  //                     message: 'Archived'
  //                 });
  //                 setShow(false);
  //                 onSuccess && onSuccess("archived-");
  //             });
  //     }
  //     else {
  //         api.put(`${deviceType}/${device.id}/unarchive`)
  //             .then((response) => {
  //                 notify.open({
  //                     type: 'success',
  //                     message: 'Unarchived'
  //                 });
  //                 setShow(false);
  //                 onSuccess && onSuccess("");
  //             });
  //     }
  // };

  const handleClose = () => {
    setShow(false);
  };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Handle form submission here (e.g., send data to API)
  //     console.log('Form submitted!', { e });
  //     handleClose();
  // };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setShow(true);
        }}
        variant={"info"}
        className=""
      >
        {text}
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        backdrop={"static"}
      >
        <Formik
          // validationSchema={schema}
          onSubmit={async (values, actions) => {
            let { /* setErrors, setStatus, */ setSubmitting } = actions;
            setSubmitting(true);
            try {
              var action = _.toLower(text);
              if (action === "archive") {
                api
                  .put(
                    `${deviceType}/${device.id}/archive/${unlinkAll}/${archiveAll}`
                  )
                  .then((response) => {
                    notify.open({
                      type: "success",
                      message: "Archived",
                    });
                    setShow(false);
                    onSuccess && onSuccess("archived-");
                  })
                  .finally(() => {
                    setSubmitting(false);
                    // setWaitingForResult(false);
                  });
              } else {
                api
                  .post(
                    `${deviceType}/${device.id}/unarchive`,
                    {
                      unarchiveExisting: values.unarchiveExisting,
                      createNew: values.createNew,
                      newAsset: {
                        primaryName: values.primaryName,
                        secondaryName: values.secondaryName,
                        reference: values.reference,
                        fleetNumber: values.fleetNumber,
                        category: values.productName,
                        suppString1: values.assetOwner,
                        suppString2: values.customerFleetNumber,
                      },
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then((response) => {
                    notify.open({
                      type: "success",
                      message: "Unarchived",
                    });
                    setShow(false);
                    onSuccess && onSuccess("");
                  })
                  .finally(() => {
                    setSubmitting(false);
                    // setWaitingForResult(false);
                  });
              }
            } catch (error) {}
          }}
          initialValues={{
            primaryName: "",
            secondaryName: "",
            reference: "",
            assetOwner: "",
            fleetNumber: "",
            productName: "",
            customerFleetNumber: "",
            unarchiveExisting: false,
            createNew: true,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            /* touched, errors, */ setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {text}&nbsp;device&nbsp;{device?.serial}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {devices.length === 0 ? (
                  <>
                    <h4>No linked asset</h4>
                  </>
                ) : (
                  <>
                    <h4>
                      Linked asset : {asset?.primaryName} {asset?.secondaryName}
                    </h4>
                    <Card>
                      <Card.Body className="py-0 by-0">
                        <h5>Devices linked to asset :</h5>
                        <Table striped>
                          <thead>
                            <tr>
                              <th>Serial</th>
                            </tr>
                          </thead>
                          <tbody>
                            {devices &&
                              devices.map((value, index) => {
                                return (
                                  <tr key={value.id}>
                                    <td>
                                      {getIcon(value.type)}&nbsp;{value.serial}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                    {!archived && (
                      <>
                        <Form.Check
                          aria-label="unlink all"
                          label="Unlink All"
                          checked={unlinkAll}
                          onChange={(e) => {
                            setUnlinkAll(e.target.checked);
                          }}
                        />
                        <Form.Check
                          aria-label="archive all"
                          label="Archive All"
                          checked={archiveAll}
                          onChange={(e) => {
                            setArchiveAll(e.target.checked);
                          }}
                        />
                      </>
                    )}
                    {archived && (
                      <>
                        <Form.Check
                          // inline
                          label="Unarchive existing asset, and other linked devices"
                          name="unarchiveExisting"
                          id="unarchiveExisting"
                          type="switch"
                          onChange={(e) => {
                            setFieldValue("createNew", false);
                            handleChange(e);
                          }}
                          checked={values.unarchiveExisting}
                        />
                      </>
                    )}
                  </>
                )}

                {archived && (
                  <>
                    <Form.Check
                      // inline
                      label="Create new asset"
                      name="createNew"
                      id="createNew"
                      type="switch"
                      onChange={(e) => {
                        setFieldValue("unarchiveExisting", false);
                        handleChange(e);
                      }}
                      checked={values.createNew}
                    />
                    {values.createNew && (
                      <>
                        <hr />
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formPrimaryName"
                        >
                          <Form.Label column sm={5}>
                            Primary Name (Customer Ref) [primary_name]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="primaryName"
                              value={values.primaryName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formSecondaryName"
                        >
                          <Form.Label column sm={5}>
                            Secondary Name [secondary_name]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="secondaryName"
                              value={values.secondaryName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formReference"
                        >
                          <Form.Label column sm={5}>
                            Reference [reference]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="reference"
                              value={values.reference}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formAssetOwner"
                        >
                          <Form.Label column sm={5}>
                            (Asset Owner) [supp_string_1]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="assetOwner"
                              value={values.asstOwner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formFleetNumber"
                        >
                          <Form.Label column sm={5}>
                            (Fleet Number) [fleet_number]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="fleetNumber"
                              value={values.fleetNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formCategory"
                        >
                          <Form.Label column sm={5}>
                            (Product Name) [category]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="productName"
                              value={values.productName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-2"
                          controlId="formCustomerFleetNumber"
                        >
                          <Form.Label column sm={5}>
                            (Customer Fleet Number) [supp_string_2]
                          </Form.Label>
                          <Col sm={7}>
                            <Form.Control
                              type="text"
                              name="customerFleetNumber"
                              value={values.customerFleetNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Form.Group>
                      </>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {text}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                {/* <Button onClick={handleClick} variant={"primary"}>{text}</Button> */}
                {/* <Button onClick={() => setShow(false)} variant={"secondary"}>Cancel</Button> */}
                {/* <pre>{JSON.stringify(values)}</pre> */}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ArchiveDeviceButton;
