import copy from "copy-to-clipboard";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Copy } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";

const BulkArchiveResults = (props) => {
  const { results, dryRun, onConfirm, onCancel } = props;
  const [localResults, setLocalResults] = useState(null);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    setLocalResults(results);
  }, [results, dryRun]);

  const copyText = () => {
    if (!localResults) return;
    if (copy(localResults)) {
      notify.open({
        type: "success",
        message: "Copied !",
      });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={localResults}
        onHide={() => {
          setLocalResults(null);
        }}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Bulk Archive Results</Modal.Title>
          <Button onClick={() => copyText()}>
            <Copy size={14} />
            &nbsp;Copy to Clipboard
          </Button>
        </Modal.Header>
        <Modal.Body>
          <pre>{localResults}</pre>
        </Modal.Body>
        <Modal.Footer>
          {dryRun && (
            <Button
              variant="secondary"
              onClick={() => {
                onCancel && onCancel();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => {
              onConfirm && onConfirm();
            }}
          >
            {dryRun ? "Confirm Archive" : "Close"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkArchiveResults;
