import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import { faHeart, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import NewObject from "../../components/detail/NewObject";
import CommonTable from "../../components/tables/CommonTable";
import { Package } from "react-feather";
import { NavLink } from "react-router-dom";
import useCustomFieldsHelper from "../../hooks/useCustomFieldsHelper";
import BulkArchiveButton from "../../components/detail/BulkArchiveButton";

function Assets() {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const { user } = useAuth();
  const { getColumnsForElementType } = useCustomFieldsHelper();
  const columns = useMemo(() => {
    return getColumnsForElementType("assets");
  }, []);

  return (
    <React.Fragment>
      <Helmet defer={false} title="Assets" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Package className="me-2" /> Assets{" "}
              {typeof resultCount === "string" && `(${resultCount})`}
            </h1>
            {user?.role !== "Root" && (
              <NavLink to={{ pathname: "/favourite", search: "?type=assets" }}>
                <FontAwesomeIcon
                  className="ms-4 me-2 text-primary cursor-pointer"
                  icon={faHeart}
                />
                <span>Favourites</span>
              </NavLink>
            )}
          </div>
          <div>
            {(user?.role === "Admin" ||
              (user?.role === "User" && user?.createAssets)) && (
              <Button
                className="text-nowrap mx-1"
                onClick={() => setShow(true)}
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                Create New
              </Button>
            )}
            {user?.role === "Root" && (
              <BulkArchiveButton apiName="assets"></BulkArchiveButton>
            )}
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            setCountParent={setResultCount}
            apiName="assets"
            columns={columns}
            showExport={true}
            visibilityName="assets"
          />
        </Card>
      </Container>
      <NewObject
        showModal={showModal}
        closeModal={() => setShow(false)}
        apiName="assets"
      />
    </React.Fragment>
  );
}

export default Assets;
