import React, { useContext, useEffect, useState } from "react";
import { useZxing } from "react-zxing";
import api from "../../api.js";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext.js";
import axios from "axios";

let source = null;
const DeviceScan = (props) => {
  let { setReaderId } = props;
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);

  const searchReader = async (serial) => {
    try {
      setSearching(true);
      if (source) {
        source.cancel();
      }
      source = axios.CancelToken.source();

      let getReadersResult = await api.get(`readers?filter=${serial}`, {
        cancelToken: source.token,
      });

      source = null;
      if (getReadersResult) {
        if (
          getReadersResult.data &&
          getReadersResult.data.constructor.name === "Array" &&
          getReadersResult.data.length > 0
        ) {
          if (setReaderId) {
            setReaderId(getReadersResult.data[0].id);
          }
          if (getReadersResult.data.length > 1) {
            notify.open({
              type: "warning",
              message: "".concat(
                `Found reader ${getReadersResult.data[0].serial}`,
                "<br/>(first of many)"
              ),
            });
          } else {
            notify.success(`Found reader ${getReadersResult.data[0].serial}`);
          }
        } else {
          notify.error("No match");
        }
      } else {
        notify.error("No results");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        notify.error("Error");
      }
      source = null;
    } finally {
      setSearching(false);
    }
  };

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      searchReader(searchText);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const { ref } = useZxing({
    constraints: {
      audio: false,
      video: {
        width: 320,
        height: 320,
        facingMode: "environment",
      },
    },
    onResult: async (scanResult) => {
      let text = scanResult.getText();
      if (!searching) {
        searchReader(text);
      }
    },
    onError: (error) => {},
  });

  return (
    <>
      <video style={{ width: "200px" }} ref={ref} />
      <Form inline="true" style={{ width: "200px" }}>
        <InputGroup
          className="input-group-navbar"
          style={{ minWidth: "200px" }}
        >
          <Form.Control
            placeholder="Search for serial"
            onKeyDown={(e) => keyDown(e)}
            autoFocus
            onChange={(e) => setSearchText(e.target.value)}
            aria-label="Search"
            value={searchText}
          />
          <Button
            variant=""
            disabled={searching}
            onClick={() => {
              searchReader(searchText);
            }}
          >
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
};

export default DeviceScan;
