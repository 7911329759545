const fieldNamePairs = [
  ["primary_name", "primaryName"],
  ["secondary_name", "secondaryName"],
  ["reference", "reference"],
  ["category", "category"],
  ["legacy_id", "fleetNumber"],
  ["supp_string_1", "suppString1"],
  ["supp_string_2", "suppString2"],
  ["supp_string_3", "suppString3"],
  ["supp_string_4", "suppString4"],
  ["supp_string_5", "suppString5"],
];

export const fieldNameToAssetName = (input) => {
  let nameMap = new Map(fieldNamePairs);
  // Check if the input exists in the map; otherwise, return null or a default value
  return nameMap.get(input) || null;
};
