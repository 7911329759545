import jwtDecode from "jwt-decode";
import { sign, verify } from "jsonwebtoken";
import axios from "./../api";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setOtherSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessTokenAdmin", accessToken);
  } else {
    localStorage.removeItem("accessTokenAdmin");
  }
};

const setUserSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessTokenUser", accessToken);
  } else {
    localStorage.removeItem("accessTokenUser");
  }
};

export {
  verify,
  sign,
  isValidToken,
  setSession,
  setOtherSession,
  setUserSession,
};
