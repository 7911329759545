import {
  Epg,
  Layout,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramText,
  ProgramTitle,
  useEpg,
  useProgram,
} from "planby";

import React, { useEffect, useState } from "react";
import { theme } from "./theme";
import api from "../../api";
import moment from "moment-timezone";
import { Timeline } from "./TimeLine";
import DateTime from "react-datetime";

// A timeline table using planby.js

const PlanBy = () => {
  const [epg, setEpg] = useState([]);
  const [start, setStart] = useState(moment().startOf("days").format());
  const [end, setEnd] = useState(moment().endOf("days").format());
  const [channelData, setChannelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelData,
    epg: epg,
    dayWidth: 5000,
    sidebarWidth: 100,
    itemHeight: 100,
    isSidebar: true,
    isTimeline: true,
    height: 600,
    isLine: true,
    startDate: start,
    endDate: end,
    isBaseTimeFormat: false,
    theme,
  });

  useEffect(() => {
    handleFetchResources();
  }, []);

  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    let data = await api.get(
      `people/attendances/630711dd870181eadc9e75ec?page=1`
    );
    let arr = data.data;
    let channel = arr.map((item) => {
      let obj = {
        uuid: item.id,
        logo: "https://raw.githubusercontent.com/karolkozer/planby-demo-resources/master/resources/channel-logos/png/r-channel.png",
      };
      return obj;
    });
    console.log(channel);
    let epg = arr.map((item) => {
      let obj = {
        channelUuid: item.id,
        title: item.parentName,
        since: moment(item.start).format("2022-10-18THH:mm:ss"),
        till: moment(item.end).format("2022-10-18THH:mm:ss"),
        duration: getDuration(item.duration),
      };
      return obj;
    });
    console.log(epg);
    setEpg(epg);
    setChannelData(channel);
    setIsLoading(false);
  }, []);

  const getDuration = (time) => {
    let currentTime = moment.duration(time, "seconds");
    if (currentTime.days() > 0) {
      let hours = currentTime.days() * 24 + currentTime.hours();
      let min = currentTime.minutes();
      return hours + ":" + min;
    }
    return moment({
      h: currentTime.hours(),
      m: currentTime.minutes(),
      s: currentTime.seconds(),
    }).format("HH:mm");
  };

  const onChangeStart = (d) => {
    let day = d.format("YYYY-MM-DD");
    let time = moment(end).format("HH:mm");
    setStart(d.format());
    setEnd(`${day}T${time}`);
  };
  const onChangeEnd = (d) => {
    let day = d.format("YYYY-MM-DD");
    let time = moment(start).format("HH:mm");
    setStart(`${day}T${time}`);
    setEnd(d.format());
  };

  return (
    <div>
      <div className="d-flex ps-1 align-items-center mb-2">
        <span>Start Time:</span>
        <DateTime
          inputProps={{ readOnly: true }}
          initialViewMode={"days"}
          value={moment(start)}
          className="no-change-date-picker timeline-picker ms-1"
          dateFormat="DD/MM/YYYY"
          onChange={onChangeStart}
          timeFormat="HH:mm"
        />
        <span className="ms-3">End Time:</span>
        <DateTime
          inputProps={{ readOnly: true }}
          initialViewMode={"days"}
          value={moment(end)}
          className="no-change-date-picker timeline-picker ms-2"
          dateFormat="DD/MM/YYYY"
          onChange={onChangeEnd}
          timeFormat="HH:mm"
        />
      </div>
      <Epg isLoading={isLoading} {...getEpgProps()}>
        <Layout
          {...getLayoutProps()}
          renderTimeline={(props) => <Timeline {...props} />}
          renderProgram={({ program, ...rest }) => (
            <Item key={program.data.id} program={program} {...rest} />
          )}
        />
      </Epg>
    </div>
  );
};

const Item = ({ program, ...rest }) => {
  const { styles, formatTime, set12HoursTimeFormat, isLive } = useProgram({
    program,
    ...rest,
  });

  const { data } = program;
  const { title, since, till, duration } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  return (
    <ProgramBox width={styles.width} style={styles.position}>
      <ProgramContent width={styles.width} isLive={isLive}>
        <ProgramFlex>
          <ProgramStack>
            <ProgramTitle>{title}</ProgramTitle>
            <ProgramText>
              <div>
                {sinceTime} - {tillTime}
              </div>
              <div>Duration: {duration}</div>
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
export default PlanBy;
