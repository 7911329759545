import React, { useEffect, useState } from "react";

import { Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useNavigate } from "react-router-dom";
import { MapPin, Maximize, Moon, PlusSquare } from "react-feather";
import { dateFromNow } from "../../utils/staticMethods";
import useSrlHelper from "../../hooks/useSrlHelper";

// shows the favourite assets/people/zones, if user click the favourite button in asset/person/zone detail page, it will show it in this widget
const Favourites = ({ width, name }) => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [columns, setColumns] = useState([]);
  const { isSrl, itemDisplayName } = useSrlHelper();

  useEffect(() => {
    if (name) {
      setType(getType(name));
    }
  }, [name]);

  useEffect(() => {
    var elementColumns = [];

    if (type) {
      elementColumns.push({
        Header: "",
        accessor: "icon",
        type: "icon",
        disableSortBy: true,
        className: "w-10",
      });
      if (isSrl() && type === "assets") {
        elementColumns.push({
          Header: "Name",
          accessor: "id",
          Cell: (cell) => (
            <NavLink
              to={{
                pathname: `/${type}/edit`,
                search: `?id=${cell.row?.original?.id}`,
              }}
            >
              {itemDisplayName(cell.row?.original)}
            </NavLink>
          ),
          // type: "link",
          // link: `/${type}/edit`,
          // stateId: "id",
          disableSortBy: true,
        });
      } else {
        elementColumns.push({
          Header: "Name",
          accessor: "compoundName",
          type: "link",
          link: `/${type}/edit`,
          stateId: "id",
          disableSortBy: true,
        });
      }
      elementColumns.push({
        Header: `${type === "zones" ? "Content" : "Last Seen"}`,
        accessor: `${type === "zones" ? "contentsCount" : "lastSeen"}`,
        Cell: (cell) => {
          if (
            type !== "zones" &&
            cell.value &&
            cell.value.indexOf("0001-01-01") === -1
          ) {
            return (
              <>
                <MapPin width={14} height={14} className="me-1" />
                {dateFromNow(cell.value)}
              </>
            );
          } else if (type !== "zones") {
            return (
              <span>
                <Moon width={14} height={14} className="me-1" /> Waiting...
              </span>
            );
          }
          return <span>{cell.value}</span>;
        },
        disableSortBy: true,
      });

      setColumns(elementColumns);
    }
  }, [type]);

  // get favourite type, this component can be favourite zones, favourite assets or favourite people
  const getType = (str) => {
    if (str.indexOf("Assets") > -1) {
      return "assets";
    } else if (str.indexOf("Zones") > -1) {
      return "zones";
    } else if (str.indexOf("People") > -1) {
      return "people";
    }
  };

  const toFirstLetterUpperCase = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
  };

  return (
    <React.Fragment>
      {type && (
        <Col key={name} lg={((width / 100) * 12).toFixed(0)} className="mb-4">
          <Card
            className="flex-fill w-100 h-100"
            style={{ minHeight: "420px" }}
          >
            <div className="m-3 d-flex justify-content-between align-items-center">
              <h4 className="text-dark">
                Favourite {toFirstLetterUpperCase(type)}
              </h4>
              <div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-1">Add favourite</Tooltip>
                  }
                >
                  <PlusSquare
                    size={18}
                    className="cursor-pointer me-2"
                    onClick={() => navigate(`/${type}`)}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-1">Expand</Tooltip>}
                >
                  <Maximize
                    size={18}
                    className="cursor-pointer"
                    onClick={() => navigate(`/favourite?type=${type}`)}
                  />
                </OverlayTrigger>
              </div>
            </div>
            <CommonTable
              singlePage
              showRange={false}
              type="dashboard"
              apiName={`accounts/favourites/${type}`}
              columns={columns}
              emptyMessage="No Favourites"
            />
          </Card>
        </Col>
      )}
    </React.Fragment>
  );
};

export default Favourites;
