import React from "react";
import api from "../api";
import NotyfContext from "../contexts/NotyfContext";
import useAuth from "../hooks/useAuth";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";
import { Alert, Button, Col, Row, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { Formik } from "formik";
import axios from "axios";

const Thresholds = ({ apiName, setAsset, data, handleUpdateData }) => {
  const [show, setShow] = React.useState(false);
  const [doDelete, setDoDelete] = React.useState(false);
  const [hashtags, setHashtags] = React.useState([]);
  const [selectedThreshold, setSelectedThreshold] = React.useState({});
  const [editedThreshold, setEditedThreshold] = React.useState({});
  const formRef = React.useRef();
  const [thresholds, setThresholds] = React.useState(data.thresholds ?? []);

  const notify = React.useContext(NotyfContext);
  const { user } = useAuth();

  const thresholdTypes = [
    {
      displayName: "Max Allocation",
      thresholdType: "allocationmax",
    },
    // , {
    //   displayName: "Allocation (min)",
    //   thresholdType: "allocationmin"
    // }
  ];

  const thresholdTypeDisplayName = (thresholdType) => {
    return (
      thresholdTypes.find((currentValue, index, arr) => {
        return currentValue.thresholdType === thresholdType;
      })?.displayName ?? "-"
    );
  };

  if (notify == null);

  React.useEffect(() => {
    setThresholds(data.thresholds ?? []);
    let source = axios.CancelToken.source();
    api
      .get(`hashtags`, { cancelToken: source.token })
      .then((res) => {
        source = null;
        setHashtags(res.data && Array.isArray(res.data) ? res.data : []);
      })
      .catch((reason) => {
        if (reason.message !== "unmount") {
          console.error(reason);
        }
      });
    return () => {
      if (source) {
        source.cancel("unmount");
      }
    };
  }, [data]);

  const handleClose = () => {
    setShow(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.type) {
      errors.type = "Type required";
    }
    if (!values.hashstring) {
      errors.hashstring = "Hashtag required";
    }
    if (parseFloat(values.value) < 0) {
      errors.value = "Value must not be negative";
    }
    formRef.current.errors = errors;
    return errors;
  };

  const handleSubmit = async (values, helpers) => {
    if (doDelete) {
      try {
        api
          .delete(
            `${apiName}/${data.id}/thresholds/${values.type}/${values.hashstring}`
          )
          .then(() => {
            notify.open({
              type: "success",
              message: "Threshold deleted",
            });
            handleUpdateData();
            helpers.setSubmitting(false);
            setShow(false);
          })
          .catch((reason) => {
            const message = reason.message || "Something went wrong";
            helpers.setErrors({ submit: message });
            helpers.setSubmitting(false);
          });
      } catch (error) {
        const message = error.message || "Something went wrong";
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    } else if (selectedThreshold) {
      try {
        api
          .put(
            `${apiName}/${data.id}/thresholds/${values.type}/${values.hashstring}/${values.value}`
          )
          .then(() => {
            notify.open({
              type: "success",
              message: "Changes Saved",
            });
            handleUpdateData();
            helpers.setSubmitting(false);
            setShow(false);
          })
          .catch((reason) => {
            const message = reason.message || "Something went wrong";
            helpers.setErrors({ submit: message });
            helpers.setSubmitting(false);
          });
      } catch (error) {
        const message = error.message || "Something went wrong";
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    } else {
      try {
        api
          .post(
            `${apiName}/${data.id}/thresholds/${values.type}/${values.hashstring}/${values.value}`
          )
          .then(() => {
            notify.open({
              type: "success",
              message: "Changes Saved",
            });
            handleUpdateData();
            helpers.setSubmitting(false);
            setShow(false);
          })
          .catch((reason) => {
            const message = reason.message || "Something went wrong";
            helpers.setErrors({ submit: message });
            helpers.setSubmitting(false);
          });
      } catch (error) {
        const message = error.message || "Something went wrong";
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Card className="border-secondary border-1">
        <Card.Body className="pb-1">
          <Card.Title>
            <Stack direction="horizontal" gap={3}>
              <span className="me-auto">Thresholds</span>
              {((user?.editPeople && apiName === "people") ||
                (user?.editZones && apiName === "zones") ||
                (user?.editAssets && apiName === "assets") ||
                user?.id === data?.id) && (
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    setSelectedThreshold(null);
                    setEditedThreshold({ type: "", hashstring: "", value: "" });
                    setShow(true);
                    setDoDelete(false);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  Create New
                </Button>
              )}
            </Stack>
          </Card.Title>
          <Col>
            <Row>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>HashTag</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {thresholds.map((v, i) => {
                    return (
                      <tr key={v}>
                        <td>{thresholdTypeDisplayName(v.type)}</td>
                        <td>
                          <div className="ms-1 badge bg-primary me-1">
                            {v.hashstring}
                          </div>
                        </td>
                        <td>{v.value}</td>
                        <td>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              setSelectedThreshold(v);
                              setEditedThreshold(_.cloneDeep(v));
                              setShow(true);
                              setDoDelete(false);
                            }}
                          >
                            Edit
                          </Button>
                          &nbsp;
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              setSelectedThreshold(v);
                              setEditedThreshold(_.cloneDeep(v));
                              setShow(true);
                              setDoDelete(true);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Card.Body>
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Threshold</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formRef}
            initialValues={editedThreshold}
            validate={validate}
            validateOnChange={validate}
            validateOnBlur={validate}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  {props.errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{props.errors.submit}</div>
                    </Alert>
                  )}
                  <Form.Group
                    className="mb-3"
                    controlId="formGroupThresholdType"
                  >
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name="type"
                      defaultValue={props.values.type}
                      onChange={props.handleChange}
                      onBlur={props.handleChange}
                      disabled={selectedThreshold}
                    >
                      <option value="">please select</option>
                      {thresholdTypes.map((v, i) => {
                        return (
                          <option key={v.thresholdType} value={v.thresholdType}>
                            {v.displayName}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {props.touched.type && (
                      <>
                        <span className="text-danger">{props.errors.type}</span>
                      </>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupHashtag">
                    <Form.Label>Hashtag</Form.Label>
                    <Form.Select
                      name="hashstring"
                      defaultValue={props.values.hashstring}
                      onChange={props.handleChange}
                      onBlur={props.handleChange}
                      disabled={selectedThreshold}
                    >
                      <option value="">please select</option>
                      {hashtags.map((v, i) => {
                        return (
                          <option key={v.hashstring} value={v.hashstring}>
                            {v.hashstring}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {props.touched.hashstring && (
                      <>
                        <span className="text-danger">
                          {props.errors.hashstring}
                        </span>
                      </>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formGroupValue"
                    hidden={doDelete}
                  >
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="number"
                      step="any"
                      name="value"
                      defaultValue={props.values.value}
                      onChange={props.handleChange}
                      onBlur={props.handleChange}
                    />
                    {props.touched.value && (
                      <>
                        <span className="text-danger">
                          {props.errors.value}
                        </span>
                      </>
                    )}
                  </Form.Group>
                  <div hidden={!doDelete}>
                    Are you sure you want to delete this Threshold
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={formRef.current?.isSubmitting}
            onClick={() => {
              formRef.current?.handleSubmit();
            }}
            hidden={doDelete}
          >
            Save
          </Button>
          <Button
            variant="danger"
            disabled={formRef.current?.isSubmitting}
            onClick={() => {
              formRef.current?.handleSubmit();
            }}
            hidden={!doDelete}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Thresholds;
