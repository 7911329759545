import React from "react";
import { useZxing } from "react-zxing";
import { useNavigate } from "react-router-dom";

// scan component, also can use with browser, need permission, only available in mobile view
const ScanVideo = ({ onGet }) => {
  const navigate = useNavigate();
  const { ref } = useZxing({
    onResult(result) {
      let res = result.getText();
      let scanResultIndex = res.indexOf("/scanresult/");
      if (scanResultIndex >= 0) {
        let path = res.substring(scanResultIndex);
        navigate(`${path}`);
      } else {
        navigate(`/globalsearch?searchString=${res}`);
      }
      onGet();
    },
    onError(error) {
      console.log(error);
    },
  });
  return <video style={{ width: "200px" }} ref={ref} />;
};
export default ScanVideo;
