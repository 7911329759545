import React from "react";
import { Button } from "react-bootstrap";
import api from "../../api";
import { setSession, setUserSession } from "../../utils/jwt";

// for switch account, store the old account data to other localstorage, and change it back if it needed
const UserLogin = ({ id, homePage, isUser = false }) => {
  const onOperate = (id) => {
    if (isUser) {
      api.post(`accounts/accessprofilelogin`, { id }).then((res) => {
        let token = res.data?.accessToken;
        let token1 = localStorage.getItem("accessToken");
        localStorage.removeItem("recentItem");
        setUserSession(token1);
        setSession(token);
        if (homePage) {
          window.location.href = window.location.origin + "";
        } else {
          window.location.reload();
        }
      });
    } else {
      api
        .post(`accounts/accessprofilelogin`, { permissionId: id })
        .then((res) => {
          let token = res.data?.accessToken;
          let token1 = localStorage.getItem("accessToken");
          localStorage.removeItem("recentItem");
          setUserSession(token1);
          setSession(token);
          if (homePage) {
            window.location.href = window.location.origin + "";
          } else {
            window.location.reload();
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Button
        variant={"primary"}
        className="ms-2"
        onClick={() => onOperate(id)}
      >
        Login
      </Button>
    </React.Fragment>
  );
};
export default UserLogin;
