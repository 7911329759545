import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Col, Spinner } from "react-bootstrap";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { Package, Users } from "react-feather";

const ZoneMapZone = ({ zone }) => {
  const navigate = useNavigate();
  const [prevZone, setPrevZone] = useState({});
  const [assetsVariant, setAssetsVariant] = useState("info");
  const [peopleVariant, setPeopleVariant] = useState("info");

  useEffect(() => {
    if (zone && prevZone) {
      if (zone.countAsset > prevZone.countAsset) {
        setAssetsVariant("success");
      } else if (zone.countAsset < prevZone.countAsset) {
        setAssetsVariant("danger");
      } else {
        setAssetsVariant("info");
      }

      if (zone.countPerson > prevZone.countPerson) {
        setPeopleVariant("success");
      } else if (zone.countPerson < prevZone.countPerson) {
        setPeopleVariant("danger");
      } else {
        setPeopleVariant("info");
      }
    } else {
      setAssetsVariant("info");
      setPeopleVariant("info");
    }

    //setTimeout(() => { setChanged(false);  }, 4000);
    setPrevZone(zone);
  }, [zone]);

  return (
    <>
      {/* <pre>{JSON.stringify(changed)}</pre> */}
      {/* <pre>{JSON.stringify(prevZone.countAsset)}</pre> */}
      {/* <pre>{JSON.stringify(zone.countAsset)}</pre> */}
      <Card
        className="p-2 m-2"
        style={{
          minWidth: "200px",
          maxWidth: "200px",
          minHeight: "200px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          navigate(`/zones/edit?id=${zone.id}`);
        }}
      >
        <svg
          width="200px"
          height="2em"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: "absolute", left: "-1em" }}
        >
          <line
            x1="1.5em"
            y1="1em"
            x2="200px"
            y2="1em"
            style={{ stroke: "white", strokeWidth: "2" }}
          />
          {/* <circle cx="2.5em" cy="10" r="10" stroke="white" strokeWidth={1} fill="#ffff" /> */}
          <circle cx="2.5em" cy="1em" r="8" stroke="white" strokeWidth={2} />
          <circle cx="2.5em" cy="1em" r="3" stroke="white" strokeWidth={2} />
        </svg>
        <Spinner
          as="span"
          size="sm"
          animation="grow"
          variant="info"
          role="status"
          aria-hidden="true"
          style={{ position: "absolute", right: "1em", top: "1em" }}
        />

        <div className="d-flex flex-row p-1 m-0 mt-4 justify-content-between m-2 align-items-center">
          <h4>{zone.compoundName}</h4>
          {/*  */}
        </div>

        <div className="mt-auto justify-content-between p-2">
          <div
            className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-${assetsVariant}`}
          >
            <div className="px-1">
              <Package />
              <span className="px-2">Assets</span>
            </div>
            <div
              className={`w-25 bg-${assetsVariant} text-bg-${assetsVariant}  p-1`}
            >
              <span className="float-end">
                <b>{zone.countAsset}</b>
              </span>
            </div>
          </div>
          <div
            className={`d-flex flex-row justify-content-between m-2 align-items-center rounded-3 border border-1 border-${peopleVariant}`}
          >
            <div className="px-1">
              <Users />
              <span className="px-2">People</span>
            </div>
            <div
              className={`w-25 bg-${peopleVariant} text-bg-${peopleVariant}  p-1`}
            >
              <span className="float-end">
                <b>{zone.countPerson}</b>
              </span>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Card>
    </>
  );
};

const ZoneMapWidget = ({ name, width }) => {
  const [zones, setZones] = useState([]);

  const fetchData = () => {
    var source = axios.CancelToken.source();
    api
      .get("zones", {
        cancelToken: source.token,
      })
      .then((respose) => {
        var loadedZones = respose.data
          // filter out zones that don't have the "zonemaporder" extra info
          .filter((v) => {
            return (
              v.quantityInfo &&
              v.quantityInfo.find((q) => {
                return q.label === "zonemaporder";
              })
            );
          })
          // sort the array based on the "zonemaporder" value
          .sort((a, b) => {
            var aValue =
              a.quantityInfo.find((q) => {
                return q.label === "zonemaporder";
              })?.value ?? 0;
            var bValue =
              b.quantityInfo.find((q) => {
                return q.label === "zonemaporder";
              })?.value ?? 0;

            return aValue - bValue;
          })
          .map((v) => {
            return { ...v, countAsset: 0, countPerson: 0 };
          });

        var source2 = axios.CancelToken.source();
        api
          .get("reports/allattendancelive?page=1&limit=10000", {
            cancelToken: source2.token,
          })
          .then((response) => {
            response.data.forEach((attendance) => {
              if (attendance.parentType === "zone") {
                var parent = loadedZones.find((v) => {
                  return v.id === attendance.parentId;
                });

                if (parent) {
                  if (attendance.childType === "asset") {
                    parent.countAsset = parent.countAsset + 1;
                  } else if (attendance.childType === "person") {
                    parent.countPerson = parent.countPerson + 1;
                  }
                } else {
                  console.log("no parent");
                }
              }
            });

            setZones(loadedZones);
          });
      });
  };

  useEffect(() => {
    fetchData(); // Initial data fetch

    // Set up the interval
    const intervalId = setInterval(() => {
      fetchData(); // Refresh data every 5 seconds
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures the effect runs only on mount and unmount

  return (
    <>
      {/* <div>{JSON.stringify(zones)}</div> */}
      <Col key={name} lg={((width / 100) * 12).toFixed(0)} className="mb-4">
        <div className="d-flex flex-wrap flex-row justify-content-start">
          {zones.map((zone) => (
            <ZoneMapZone key={zone.id} zone={zone}></ZoneMapZone>
          ))}
        </div>
      </Col>
    </>
  );
};

export default ZoneMapWidget;
