import React from "react";
import FavouritePage from "../pages/widgetPages/FavouritePage";
import useSrlHelper from "../hooks/useSrlHelper";
import SrlFavouritePage from "../srl/pages/SrlFavouritePage";

const FavouritePageWrapper = () => {
  const { isSrl } = useSrlHelper();

  return (
    <>
      {isSrl() ? (
        <>
          <SrlFavouritePage></SrlFavouritePage>
        </>
      ) : (
        <>
          <FavouritePage></FavouritePage>
        </>
      )}
    </>
  );
};

export default FavouritePageWrapper;
