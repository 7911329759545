import React, { useCallback, useMemo, useState } from "react";
import api from "../../api";
import SearchTable from "../../components/tables/SearchTable";
import useSrlHelper from "../../hooks/useSrlHelper";
import { downloadFile } from "../../utils/staticMethods";
import { Helmet } from "react-helmet-async";
import { Card, Container, Spinner } from "react-bootstrap";
import { Package } from "react-feather";

const AssetsSearch = () => {
  const { getColumnsForAssetsSearch } = useSrlHelper();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = React.useRef(0);
  const columns = useMemo(() => {
    return getColumnsForAssetsSearch();
  }, []);

  const fetchData = useCallback(
    async (pageIndex, pageSize, sortBy, filters) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      try {
        setLoading(true);

        var response = await api.post("assets/search", {
          pageIndex,
          pageSize,
          sortBy,
          filters,
        });
        if (fetchId === fetchIdRef.current) {
          setCount(response.data.count);
          setData(response.data.records);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const exportData = useCallback((pageIndex, pageSize, sortBy, filters) => {
    api
      .post("assets/searchexport", { pageIndex, pageSize, sortBy, filters })
      .then((response) => {
        downloadFile(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Helmet defer={false} title="Assets - Advanced Search" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Package className="me-2" /> Assets - Advanced Search{" "}
              {loading && (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                  className="p-0 m-0"
                />
              )}
            </h1>
          </div>
        </div>
        <Card className="bg-white p-3">
          <SearchTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            exportData={exportData}
            count={count}
          />
        </Card>
      </Container>
    </>
  );
};

export default AssetsSearch;
