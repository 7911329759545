import React from "react";
import { Container } from "react-bootstrap";
import { AlertOctagon } from "react-feather";

const PermissionDenied = () => {
  return (
    <>
      <Container fluid>
        <div>
          <AlertOctagon size={32} />
          <br />
          <div>Sorry, you do not have permission to view the page.</div>
        </div>
      </Container>
    </>
  );
};

export default PermissionDenied;
