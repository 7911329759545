import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import moment from "moment-timezone";

const DeploymentsPage = () => {
  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const { id, name } = queryString.parse(location.search);
  const duration = [
    moment().subtract(30, "day").startOf("days"),
    moment().endOf("days"),
  ];

  // const getDate = (d) => {
  //   if (d === "This week") {
  //     return [moment().startOf("week").startOf("days"), moment()];
  //   } else if (d === "Last week") {
  //     return [moment().week(moment().week() - 1).startOf("week").startOf("days"), moment().week(moment().week() - 1).endOf("week").endOf("days")];
  //   } else if (d === "2 weeks ago") {
  //     return [moment().week(moment().week() - 2).startOf("week"), moment().week(moment().week() - 2).endOf("week").endOf("days")];
  //   } else {
  //     return [moment().subtract(30, "days").startOf("days"), moment(),]
  //   }
  // };

  const columns = [
    {
      Header: "Name",
      accessor: "childName",
      Cell: (cell) => (
        <div>
          <NavLink
            to={{
              pathname: `/${
                cell.row.original.childType === "person"
                  ? "people"
                  : `${cell.row.original.childType}s`
              }/edit`,
              search: `?id=${cell.row.original.childId}`,
            }}
          >
            {cell.row.original.childName +
              `${
                cell.row.original.childReference
                  ? ` (${cell.row.original.childReference})`
                  : ""
              }`}
          </NavLink>
        </div>
      ),
    },
    {
      Header: "Start",
      accessor: "start",
      Cell: (cell) => (
        <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
      ),
    },
    {
      Header: "End",
      accessor: "end",
      Cell: (cell) => (
        <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
      ),
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: (cell) => (
        <span>{(Number(cell.value) / 3600).toFixed(1)} hours</span>
      ),
    },
    {
      Header: "Hashtags",
      accessor: "childHashTags",
      Cell: (cell) => (
        <div>
          {cell.value &&
            cell.value.map((tag, i) => (
              <NavLink
                key={i}
                to={{
                  pathname: "/globalSearch",
                  search: `?searchString=${encodeURIComponent("#" + tag)}`,
                }}
              >
                <span className="badge bg-primary me-1 my-1 cursor-pointer">
                  {tag}
                </span>
              </NavLink>
            ))}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <React.Fragment>
      <Helmet defer={false} title={`Battery Exchanges`} />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <span>Deployments</span>
            {name && id && (
              <span>
                {" "}
                -{" "}
                <NavLink to={{ pathname: "/assets/edit", search: `?id=${id}` }}>
                  {name}
                </NavLink>
              </span>
            )}
            <span>{typeof resultCount === "string" && `(${resultCount})`}</span>
          </h1>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            showRange
            delayLoading
            duration={duration}
            setCountParent={setResultCount}
            apiName={`assets/pastdeployments/${id}`}
            columns={columns}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default DeploymentsPage;
