import React from "react";
import { Card, Col } from "react-bootstrap";

const Service = () => {
  return (
    <React.Fragment>
      <Col lg={4} className="mb-4">
        <Card className="flex-fill w-100 h-100 p-3">
          <h4>Service Status</h4>
          <div>
            <p className="mb-0">Current Service Status:</p>
            <p className="text-success">Operational with no issues</p>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default Service;
