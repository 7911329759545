import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import GoogleMapReact from "google-map-react";
import { imageSet } from "../icons/AssetIcon/IconList";
import api from "../../api";
import circlePng from "../../assets/img/clusterImg/m1.png";
import circle from "../../assets/img/clusterImg/m1.png";
import { useNavigate } from "react-router-dom";
import GroupSelector from "../../components/groupSelector/GroupSelector";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import MapDetail from "../../components/detail/MapDetail";
import axios from "axios";
import { Helmet } from "react-helmet-async";
// import * as queryString from "query-string";
import { globalConfig } from "../../config";
import {
  getDistance,
  getUrl,
  middle,
  radiusZoomLevel,
  resArr,
  updateMarker,
} from "../../utils/staticMethods";
import useAuth from "../../hooks/useAuth";
import MarkerModal from "../../components/mapComponents/MarkerModal";
import NearByAssets from "../../components/mapComponents/NearByAssets";
import InfoWindow from "../../components/mapComponents/InfoWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import HashTagFilter from "../../components/mapComponents/HashTagFilter";
import FloorplanSelector from "../readers/FloorplanSelector";

let timer = null;
let markerArr = [];
let followingTimer = null;
let markerCluster = null;
let intervalNearby = null;
let zoomTimer = null;
let overlay1 = null;
let source = null;

const thingsArr = {
  asset: "assets",
  person: "people",
  zone: "zones",
  assets: "assets",
  people: "people",
  zones: "zones",
};

const FloorPlanView = React.memo(({ type }) => {
  const [following, setFollowing] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [zoomLevel, setZoomLevel] = useState(21);
  const [overlay, setOverlay] = useState(false);
  const [infoWindow, setInfoWindow] = useState(null);
  const limit = 450;
  // const location = useLocation();
  const [hashtag, setHashtag] = useState(null);
  const hashtagRef = useRef(hashtag);
  // const { fId } = queryString.parse(location.search);
  const [center, setCenter] = useState(null);
  const [current, setCurrent] = useState(null);
  const [curFloor, setCurFloor] = useState(null);
  const [picture, setPicture] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const firstLoadRef = useRef(firstLoad);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [nearByData, setNearByData] = useState([]);
  const [filter, setFilter] = useState("");
  const [infoItem, setInfoItem] = useState([]);
  const infoItemRef = useRef(infoItem);
  const infoRef = useRef(infoWindow);
  const follow = useRef(following);
  const centerRef = useRef(center);
  const currentRef = useRef(current);
  const zoomLevelRef = useRef(zoomLevel);
  const defaultLevelRef = useRef(0);
  const defaultCenterRef = useRef(null);
  const filterRef = useRef(filter);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    if (type && user && !user[type] && user.role !== "Root") {
      navigate("/");
    }
  }, [user, type]);

  useEffect(() => {
    if (user && mapApi && mapInstance && current && user.defaultFollow) {
      setFollowing(true);
      follow.current = true;
    }
  }, [user, mapApi, mapInstance, current]);

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 360 - curFloor?.rotation || 0,
      gestureHandling: "cooperative",
      streetViewControl: false,
    };
  };

  useEffect(() => {
    infoItemRef.current = infoItem;
  }, [infoItem]);

  // useEffect(() => {
  //   api.get(`floorplans/${fId}`).then(r => {
  //     setCurFloor(r.data);
  //     getPci(r.data.id);
  //   });
  // }, [fId]);

  useEffect(() => {
    if (curFloor && picture && mapInstance && mapApi) {
      initFloorPlan();
    }
  }, [curFloor, picture, mapInstance, mapApi]);

  const getPci = (id) => {
    api
      .get(`files/floorplans/${id}`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        let blob = new Blob([res.data], { type: "img/jpeg" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);
        setPicture(url);
      })
      .catch(() => setPicture(null));
  };

  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0);
      if (overlay1) {
        overlay1.setMap(null);
        overlay1 = null;
      }
      let northWest = new mapApi.LatLng(
        curFloor ? Number(curFloor.northWestLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.northWestLongitude) || 0.1276 : 0.1276
      );
      let southEast = new mapApi.LatLng(
        curFloor ? Number(curFloor.southEastLatitude || 51.5072) : 51.5072,
        curFloor ? Number(curFloor.southEastLongitude) || 0.1276 : 0.1276
      );
      overlay1 = new mapApi.OverlayView();
      overlay1.div = null;
      window.overlay = overlay1;
      overlay1.image = picture;
      overlay1.draw = function () {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(southEast);
        const nw = overlayProjection.fromLatLngToDivPixel(northWest);
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      };
      overlay1.onRemove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      overlay1.onAdd = function () {
        this.div = document.createElement("div");
        this.div.id = "whole-container";
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      };
      overlay1.setMap(mapInstance);
      let d = getDistance(northWest, southEast);
      let keys = Object.keys(radiusZoomLevel).reverse();
      let value = 1000000;
      let index = 0;
      for (let i = 0; i < keys.length; i++) {
        let v = Math.abs(radiusZoomLevel[keys[i]] - d);
        if (v < value) {
          value = v;
          index = keys[i];
        }
      }
      mapInstance.setZoom(Number(index));
      defaultLevelRef.current = Number(index);
      mapInstance.panTo(middle(northWest, southEast));
      setZoomLevel(Number(index));
      zoomLevelRef.current = Number(index);
      setCenter(middle(northWest, southEast));
      centerRef.current = middle(northWest, southEast);
      defaultCenterRef.current = centerRef.current;
      setTimeout(() => setLoadingPlan(false), 1000);
    }
  };

  useEffect(() => {
    setLoadingPlan(true);
    firstLoadRef.current = true;
    setFirstLoad(true);
    return () => {
      timer && clearTimeout(timer);
      timer = null;
      markerArr = [];
      markerCluster = null;
      clearInterval(intervalNearby);
      clearInterval(followingTimer);
      intervalNearby = null;
      clearTimeout(zoomTimer);
      followingTimer = null;
      zoomTimer = null;
    };
  }, []);

  useEffect(() => {
    if (following && current) {
      followingTimer = setInterval(() => {
        getDetail();
      }, 5000);
    } else if (!following) {
      followingTimer && clearInterval(followingTimer);
    }
  }, [following]);

  const getDetail = () => {
    if (!currentRef.current) return;
    api
      .get(`${currentRef.current.type}/${currentRef.current.id}`)
      .then((res) => {
        if (!currentRef.current) return;
        let c = res.data;
        c.type = currentRef.current.type;
        if (
          (c.type === "assets" || c.type === "people") &&
          c.lastPosition &&
          c.lastPosition.latitude &&
          c.lastPosition.longitude &&
          mapApi &&
          mapInstance
        ) {
          if (
            follow.current &&
            (Number(c.lastPosition.latitude) !==
              Number(currentRef.current.lastPosition.latitude) ||
              Number(c.lastPosition.longitude) !==
                Number(currentRef.current.lastPosition.longitude))
          ) {
            mapInstance.panTo({
              lat: Number(c.lastPosition.latitude),
              lng: Number(c.lastPosition.longitude),
            });
            setCenter({
              lat: Number(c.lastPosition.latitude),
              lng: Number(c.lastPosition.longitude),
            });
            centerRef.current = {
              lat: Number(c.lastPosition.latitude),
              lng: Number(c.lastPosition.longitude),
            };
          }
        }
        setCurrent(c);
        currentRef.current = c;
      });
  };

  useEffect(() => {
    if (mapApi && mapInstance) {
      setInfoWindow(
        new mapApi.InfoWindow({
          content: "",
        })
      );
      infoRef.current = new mapApi.InfoWindow({
        content: "",
      });
      if (firstLoad && center) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          zoomLevel !== mapInstance.getZoom() &&
            mapInstance.setZoom(zoomLevelRef.current);
          getNearBy();
          if (intervalNearby) {
            clearInterval(intervalNearby);
            intervalNearby = null;
          }
          intervalNearby = setInterval(() => {
            getNearBy();
          }, 5000);
        }, 700);
      }
    }
  }, [zoomLevel, center, mapApi, mapInstance, firstLoad]);

  const getNearBy = async () => {
    if (!mapInstance.getCenter()) return;
    let count = [0, 0, 0];
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    const res = await api.get(
      `floorplans/nearby?radius=${
        radiusZoomLevel[Math.round(zoomLevelRef.current)]
      }&latitude=${mapInstance.getCenter().lat()}&longitude=${mapInstance
        .getCenter()
        .lng()}&limit=${limit}`,
      {
        params: {
          hashtag: hashtagRef.current,
        },
        cancelToken: source.token,
      }
    );

    if (!firstLoadRef.current) return;
    const totalHeader = res.headers["x-total-count"];
    if (Number(totalHeader) >= limit) {
      setNearByData([]);
      count[0] = Number(totalHeader);
      setOverlay(true);
      markerArr &&
        markerArr.forEach((item) => {
          item.setMap(null);
          item = null;
        });
      markerArr = [];
      if (markerCluster) {
        markerCluster.clearMarkers();
        markerCluster.setMap(null);
        markerCluster = null;
      }
    } else {
      setNearByData(res.data);
      setOverlay(false);
      let arr = res.data;
      if (arr.length > 0) {
        let infoArr = infoItemRef.current.concat([]);
        updateMarker(arr, markerArr, infoArr, mapInstance, mapApi);
        let removeArr = resArr(markerArr, arr);
        removeMaker(removeArr, infoArr);
        let newArr = resArr(arr, markerArr);
        showMarker(newArr, infoArr);
        setInfoItem(infoArr);
      }
    }
    timer = null;
  };

  const removeMaker = (removeArr, infoArr) => {
    removeArr.forEach((item) => {
      let index = infoArr.findIndex((f) => f.lastPosition.id === item.id);
      if (index > -1) {
        infoArr[index].hide = true;
      }
      for (let i = 0; i < markerArr.length; i++) {
        if (markerArr[i].idsArr.includes(item.id)) {
          if (markerArr[i].idsArr.length === 1) {
            markerArr[i].setMap && markerArr[i].setMap(null);
            markerCluster && markerCluster.removeMarker(markerArr[i]);
            markerArr.splice(i, 1);
            break;
          } else if (markerArr[i].idsArr.length > 1) {
            markerArr[i].idsArr = markerArr[i].idsArr.filter(
              (arrItem) => arrItem !== item.id
            );
            markerArr[i].ids = markerArr[i].ids.filter(
              (arrItem) => arrItem.id !== item.id
            );
            markerArr[i].id = markerArr[i].idsArr[0];
            markerArr[i].setMap && markerArr[i].setMap(mapInstance);
            if (markerArr[i].idsArr.length === 1) {
              markerArr[i].setIcon({
                ...markerArr[i].icon,
                scaledSize: new mapApi.Size(24, 24),
                url: imageSet[getUrl(markerArr[i].ids[0].icon)]
                  ? require("../../assets/img/icon/" +
                      imageSet[getUrl(markerArr[i].ids[0].icon)]).default
                  : defaultImg,
              });
              markerArr[i].setLabel({
                className: "marker-position-bottom",
                color: "#000000",
                fontWeight: "bold",
                text: markerArr[i].ids[0].compoundName,
              });
              mapApi.event.clearListeners(markerArr[i], "click");
              // eslint-disable-next-line no-loop-func
              markerArr[i].addListener("click", function (e) {
                e.domEvent.stopImmediatePropagation();
                setCenter(markerArr[i].position);
                centerRef.current = markerArr[i].position;
                mapInstance.panTo(markerArr[i].position);
                if (mapInstance.getZoom() < 18) {
                  mapInstance.setZoom(18);
                }
                let cur = this.ids[0];
                cur.lastPosition = this.ids[0];
                cur.type = this.type;
                currentRef.current = cur;
                setCurrent(cur);
                setInfoItem((pre) => {
                  let obj = { lastPosition: this.ids[0] };
                  if (pre.length === 0) {
                    return [obj];
                  }
                  let arr = pre.concat([]);
                  let filter = pre.filter(
                    (item) => item?.lastPosition?.id !== obj.lastPosition.id
                  );
                  if (filter.length === pre.length) {
                    arr.push(obj);
                    return arr;
                  } else {
                    return filter;
                  }
                });
              });
            }
            break;
          }
        }
      }
    });
  };

  const showMarker = (newArr, infoArr) => {
    newArr.forEach((item) => {
      let filter = markerArr.filter(
        (markerMe) =>
          markerMe.type !== "zone" &&
          markerMe.type !== "zones" &&
          markerMe.position.lat().toFixed(5) ===
            Number(item.latitude).toFixed(5) &&
          markerMe.position.lng().toFixed(5) ===
            Number(item.longitude).toFixed(5)
      );
      if (
        filter &&
        filter.length > 0 &&
        item.type !== "zone" &&
        item.type !== "zones"
      ) {
        if (item.type === "asset") filter[0].asset++;
        if (item.type === "person") filter[0].person++;
        filter[0].ids.push(item);
        filter[0].idsArr.push(item.id);
        filter[0].label.text = " ";
        filter[0].label.className = "font-bold";
        filter[0].icon.url = circlePng;
        filter[0].icon.scaledSize = new mapApi.Size(35, 35);
        mapApi.event.clearListeners(filter[0], "click");
        filter[0].addListener("click", (e) => {
          e.domEvent.stopImmediatePropagation();
          setCenter(filter[0].position);
          centerRef.current = filter[0].position;
          setTableData(filter[0].ids);
        });
        filter[0].setMap(mapInstance);
      } else if (
        (item.type === "zone" || item.type === "zones") &&
        item.latitude &&
        item.longitude
      ) {
        let m = new mapApi.Marker({
          id: item.id,
          type: "zones",
          asset: item.type === "asset" ? 1 : 0,
          person: item.type === "person" ? 1 : 0,
          zone: item.type === "zone" ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: {
            lat: Number(item.latitude),
            lng: Number(Number(item.longitude)),
          },
          label: {
            text: item.compoundName,
            color: "#FFFFFF",
            className: "marker-position-top bg-danger ps-1 pe-1 radius-3",
            fontWeight: "bold",
          },
          icon: {
            url: imageSet[getUrl(item.icon)]
              ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)])
                  .default
              : defaultImg,
            anchor: new mapApi.Point(12, 12),
            scaledSize: new mapApi.Size(24, 24),
          },
          anchor: new mapApi.Point(14, 43),
          map: mapInstance,
        });
        m.addListener("click", function (e) {
          e.domEvent.stopImmediatePropagation();
          let cur = JSON.parse(JSON.stringify(m.ids[0]));
          cur.type = m.type;
          currentRef.current = cur;
          setCurrent(cur);
          mapInstance.setZoom(21);
          setCenter(m.position);
          centerRef.current = m.position;
          mapInstance.panTo(m.position);
        });
        markerArr.push(m);
      } else if (item.latitude && item.longitude) {
        let markerFilter =
          markerArr &&
          markerArr.filter(
            (markerMe) =>
              markerMe?.type === "zones" &&
              markerMe?.position.lat().toFixed(5) ===
                Number(item?.latitude).toFixed(5) &&
              markerMe?.position.lng().toFixed(5) ===
                Number(item?.longitude).toFixed(5)
          );
        let m = new mapApi.Marker({
          id: item.id,
          asset: item.type === "asset" ? 1 : 0,
          person: item.type === "person" ? 1 : 0,
          zone: item.type === "zone" ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: {
            lat: Number(item.latitude || item.lastPosition?.latitude),
            lng: Number(item.longitude || item.lastPosition?.longitude),
          },
          label: {
            text: item.compoundName,
            color: "#FFFFFF",
            className: "marker-position-bottom bg-primary ps-1 pe-1 radius-3",
            fontWeight: "bold",
          },
          type: thingsArr[item.type],
          icon: {
            url: imageSet[getUrl(item.icon)]
              ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)])
                  .default
              : defaultImg,
            scaledSize: new mapApi.Size(24, 24),
            labelOrigin: new mapApi.Point(
              12,
              markerFilter && markerFilter.length > 0 ? -48 : 12
            ),
            anchor: new mapApi.Point(12, 12),
          },
          anchor: new mapApi.Point(14, 43),
          map: mapInstance,
        });
        let filter = infoArr.filter((f) => f.lastPosition.id === item.id);
        if (filter.length > 0) {
          filter[0].lastPosition.latitude = item.latitude;
          filter[0].lastPosition.longitude = item.longitude;
          filter[0].hide = false;
        }
        m.addListener("click", function (e) {
          e.domEvent.stopImmediatePropagation();
          setCenter(m.position);
          centerRef.current = m.position;
          mapInstance.panTo(m.position);
          if (mapInstance.getZoom() < 18) {
            mapInstance.setZoom(18);
          }
          let cur = this.ids[0];
          cur.lastPosition = this.ids[0];
          cur.type = m.type;
          currentRef.current = cur;
          setCurrent(cur);
          setInfoItem((pre) => {
            let obj = { lastPosition: this.ids[0] };
            if (pre.length === 0) {
              return [obj];
            }
            let arr = pre.concat([]);
            let filter = pre.filter(
              (item) => item?.lastPosition?.id !== obj.lastPosition.id
            );
            if (filter.length === pre.length) {
              arr.push(obj);
              return arr;
            } else {
              return filter;
            }
          });
        });
        markerArr.push(m);
        if (m.id !== currentRef.current?.id && markerCluster) {
          markerCluster.addMarker(m);
        }
      }
    });

    if (
      !markerCluster &&
      markerArr.filter((item) => item.type !== "zone" && item.type !== "zones")
        .length > 1
    ) {
      markerCluster = new MarkerClusterer({
        map: mapInstance,
        markers: markerArr.filter(
          (item) => item.type !== "zone" && item.type !== "zones"
        ),
        renderer: {
          render({ position }) {
            return new mapApi.Marker({
              label: "",
              position,
              icon: {
                url: circle,
                scaledSize: new mapApi.Size(36, 36),
                anchor: new mapApi.Point(18, 18),
              },
            });
          },
        },
        algorithm: new GridAlgorithm({
          gridSize: 7,
          maxZoom: 18,
        }),
        onClusterClick(e, cluster) {
          mapInstance.setZoom(18);
          mapInstance.setCenter(cluster.marker.position);
          centerRef.current = cluster.marker.position;
        },
      });
    }
  };

  const clickTarget = (item) => {
    let arr = infoItem.concat([]);
    arr.forEach((i) => {
      if (i.lastPosition.id === item.lastPosition.id) {
        i.active = true;
      } else {
        i.active = false;
      }
    });
    setInfoItem(arr);
  };

  const onClose = (e, item) => {
    e.stopPropagation();
    setInfoItem((pre) => {
      let filter = pre.filter(
        (i) => i.lastPosition.id !== item.lastPosition.id
      );
      return filter;
    });
  };

  const onSelect = (e) => {
    currentRef.current = e;
    setCurrent(e);
    if (
      (e.type === "assets" || e.type === "people") &&
      e.lastPosition &&
      e.lastPosition.latitude &&
      e.lastPosition.longitude &&
      mapApi &&
      mapInstance
    ) {
      mapInstance.panTo({
        lat: Number(e.lastPosition.latitude),
        lng: Number(e.lastPosition.longitude),
      });
      setCenter({
        lat: Number(e.lastPosition.latitude),
        lng: Number(e.lastPosition.longitude),
      });
      centerRef.current = {
        lat: Number(e.lastPosition.latitude),
        lng: Number(e.lastPosition.longitude),
      };
      mapInstance.setZoom(18);
    } else if (e.type === "zones" && e.longitude && e.latitude) {
      mapInstance.panTo({ lat: Number(e.latitude), lng: Number(e.longitude) });
      setCenter({ lat: Number(e.latitude), lng: Number(e.longitude) });
      centerRef.current = { lat: Number(e.latitude), lng: Number(e.longitude) };
      mapInstance.setZoom(18);
    }
    onSetFilter(null);
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    window.map = map;
    maps.event.addListener(map, "zoom_changed", function () {
      if (!filterRef.current) {
        setZoomLevel(map.getZoom());
        zoomLevelRef.current = map.getZoom();
      } else {
        setTimeout(() => {
          setCenter(map.getCenter());
          setZoomLevel(map.getZoom());
          zoomLevelRef.current = map.getZoom();
          setFilter("");
          filterRef.current = "";
        }, 200);
      }
    });
    maps.event.addListener(map, "heading_changed", function () {
      if (overlay1 && map.getHeading() === 0) {
        setTimeout(() => map.setHeading(360 - curFloor?.rotation || 0), 500);
      }
    });
    map.addListener("dragend", () => {
      setCenter(map.getCenter());
      centerRef.current = map.getCenter();
      setZoomLevel(map.getZoom());
      zoomLevelRef.current = map.getZoom();
    });
    map.addListener("dragstart", () => {
      if (intervalNearby) {
        clearInterval(intervalNearby);
        intervalNearby = null;
      }
    });
    let thePanorama = map.getStreetView();
    maps.event.addListener(thePanorama, "visible_changed", function () {
      if (thePanorama.getVisible()) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
    });
  };

  const handleAll = () => {
    if (infoItemRef.current.length === 0) {
      let arr = markerArr.filter(
        (item) =>
          item.ids.length === 1 && item.type !== "zones" && item.type !== "zone"
      );
      let newArr = arr.map((item) => {
        let obj = { lastPosition: item.ids[0] };
        return obj;
      });
      setInfoItem(newArr);
    } else {
      setInfoItem([]);
    }
  };

  const onSetFilter = (tags) => {
    hashtagRef.current = tags;
    setHashtag(tags);
    if (!tags && currentRef.current) {
      firstLoadRef.current = true;
      setFirstLoad(true);
      return;
    }
    api
      .get(`maps/defaultitem`, {
        params: {
          hashtag: hashtagRef.current,
        },
      })
      .then((res1) => {
        let c = res1.data;
        if (!c) {
          currentRef.current = null;
          setCurrent(null);
          mapInstance && mapInstance.setZoom(0);
          markerArr.forEach((item) => item.setMap && item.setMap(null));
          markerArr = [];
          markerCluster &&
            markerCluster.clearMarkers() &&
            markerCluster.setMap(null);
          markerCluster = null;
          firstLoadRef.current = false;
          setFirstLoad(false);
          setNearByData([]);
          setInfoItem([]);
          return;
        }
        c.type = thingsArr[c.type];
        api.get(`${c.type}/${c.id}`).then((res) => {
          if (tags) {
            let cur = res.data;
            cur.type = c.type;
            currentRef.current = cur;
            setCurrent(cur);
            setCenter({ lat: Number(c.latitude), lng: Number(c.longitude) });
            centerRef.current = {
              lat: Number(c.latitude),
              lng: Number(c.longitude),
            };
            mapInstance && mapInstance.setZoom(18);
            mapInstance && mapInstance.panTo(centerRef.current);
          } else {
            mapInstance && mapInstance.setZoom(defaultLevelRef.current);
            mapInstance && mapInstance.panTo(defaultCenterRef.current);
          }
          firstLoadRef.current = true;
          setFirstLoad(true);
        });
      });
  };

  const cancelOption = () => {};

  const showOptions = () => {};

  const onSelectPlan = (selectedFloorplan) => {
    setCurFloor(selectedFloorplan);
    getPci(selectedFloorplan.id);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={curFloor?.floorPlanName || "Floorplan"} />
      <h1 className="h3 mb-3">{curFloor?.floorPlanName}</h1>
      <div style={{ width: "100%" }} className="position-relative hidden-map">
        {loadingPlan && (
          <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {overlay && (
          <div className="p-3 ps-2 bg-light text-warning">
            Too many items to show. Please zoom or search to narrow results
          </div>
        )}
        {!firstLoad && !current && (
          <div className="p-3 ps-2 bg-light text-warning">
            No relevant assets/people to show, please select other hashtag
          </div>
        )}
        {current && !overlay ? (
          <MapDetail
            profile={current}
            following={following}
            onChangeToggle={(e) => {
              follow.current = e.target.checked;
              setFollowing(e.target.checked);
            }}
          />
        ) : null}
        {showSearch && mapApi && (
          <div className="position-absolute mobile-style z-100">
            <GroupSelector onSelect={onSelect} />
          </div>
        )}
        {user?.role !== "Root" && mapApi && (
          <div className="position-absolute cursor-pointer z-50 mt-5 d-flex align-items-center">
            <NearByAssets result={nearByData} />
            <div
              onClick={() => handleAll()}
              className="cursor-pointer z-50 m-1"
            >
              <FontAwesomeIcon
                title={"All labels"}
                className="text-white bg-black mx-1"
                icon={faWindowMaximize}
                size={"lg"}
                color={"#293042"}
                fixedWidth
              />
            </div>
            <HashTagFilter onSetFilter={onSetFilter} showTags={hashtag} />
          </div>
        )}
        <div className={`reader-floorplan-select z-49`}>
          <FloorplanSelector
            cancelOption={cancelOption}
            showOptions={showOptions}
            asset={""}
            showDefault
            onSelect={onSelectPlan}
          />
        </div>
        <GoogleMapReact
          style={{ height: "70vh", position: "relative" }}
          options={getMapOptions}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"],
          }}
          onClick={(ev) => {
            infoRef.current && infoRef.current.close();
          }}
          defaultZoom={0}
          defaultCenter={[51.5288684709715, -0.10159865243033028]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {infoItem &&
            infoItem.map((item) => (
              <InfoWindow
                key={item.lastPosition.id}
                clickTarget={clickTarget}
                lat={item?.lastPosition.latitude}
                lng={item?.lastPosition.longitude}
                show={item}
                onClose={(e) => onClose(e, item)}
              />
            ))}
        </GoogleMapReact>
      </div>
      <MarkerModal tableData={tableData} setTableData={setTableData} />
    </React.Fragment>
  );
});

export default FloorPlanView;
