import { Field, useFormikContext } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { fieldNameToAssetName } from "./fieldsHelper";
import AdditionalInfo from "../AdditionalInfo";
import Thresholds from "../Thresholds";
import useAuth from "../../hooks/useAuth";

// const CustomNewObjectFields = (props) => {
//     const { customFieldConfig } = props;
//     // const {
//     //   errors,
//     //   handleBlur,
//     //   handleChange,
//     //   handleSubmit,
//     //   isSubmitting,
//     //   touched,
//     //   values
//     // } = useFormikContext();
//     // const { user } = useAuth();

//     console.log("CustomNewObjectFields", { props, customFieldConfig });

//     return (
//         <>
//             {customFieldConfig && customFieldConfig.customFields.map((value, index) => {
//                 return (
//                     <React.Fragment key={`${props.apiName}${value.fieldName}`}>
//                         {
//                             value.enabled &&
//                             <Field name={`${fieldNameToAssetName(value.fieldName)}`}>
//                                 {({
//                                     field, // { name, value, onChange, onBlur }
//                                     form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//                                     meta,
//                                 }) => (
//                                     <>
//                                         <Form.Group className={`mb-3`}>
//                                             <Form.Label className="mb-0"><span>{value.displayName}</span></Form.Label>
//                                             {
//                                                 value.fieldName === "category"
//                                                     ?
//                                                     <Form.Select {...field} isInvalid={touched[field.name] && errors[field.name]}>
//                                                         <option value={""}>please select</option>
//                                                         {customFieldConfig.categories && customFieldConfig.categories.map((v, i) => {
//                                                             return <option value={v}>{v}</option>
//                                                         })}
//                                                     </Form.Select>
//                                                     :
//                                                     <Form.Control type="text" {...field} isInvalid={touched[field.name] && errors[field.name]} />
//                                             }
//                                             {touched[field.name] &&
//                                                 <Form.Control.Feedback type="invalid" >
//                                                     {errors[field.name]}
//                                                 </Form.Control.Feedback>
//                                             }
//                                         </Form.Group>
//                                     </>
//                                 )}
//                             </Field>
//                         }
//                     </React.Fragment>
//                 );
//             })}
//         </>
//     );
// };

// const CustomAssetFields = (props) => {
//     const { customFieldConfig } = props;
//     const {
//         errors,
//         handleBlur,
//         handleChange,
//         handleSubmit,
//         isSubmitting,
//         touched,
//         values
//     } = useFormikContext();
//     const { user } = useAuth();

//     return (
//         <>
//             {customFieldConfig && customFieldConfig.customFields.map((value, index) => {
//                 return (
//                     <>
//                         {
//                             value.enabled &&
//                             <Field name={`${fieldNameToAssetName(value.fieldName)}`}>
//                                 {({
//                                     field, // { name, value, onChange, onBlur }
//                                     form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//                                     meta,
//                                 }) => (
//                                     <>
//                                         <Form.Group className={`mb-3`}>
//                                             <Form.Label className="mb-0"><span>{value.displayName}</span></Form.Label>
//                                             {
//                                                 value.fieldName === "category"
//                                                     ?
//                                                     <Form.Select {...field} isInvalid={touched[field.name] && errors[field.name]}>
//                                                         <option value={""}>please select</option>
//                                                         {customFieldConfig.categories && customFieldConfig.categories.map((v, i) => {
//                                                             return <option value={v}>{v}</option>
//                                                         })}
//                                                     </Form.Select>
//                                                     :
//                                                     <Form.Control type="text" {...field} isInvalid={touched[field.name] && errors[field.name]} />
//                                             }
//                                             {touched[field.name] &&
//                                                 <Form.Control.Feedback type="invalid" >
//                                                     {errors[field.name]}
//                                                 </Form.Control.Feedback>
//                                             }
//                                         </Form.Group>
//                                     </>
//                                 )}
//                             </Field>
//                         }
//                     </>
//                 );
//             })}

//             <div>
//                 <AdditionalInfo setAsset={props.setAsset} data={props.asset} apiName={props.apiName} className="text-success" />
//             </div>

//             <div>
//                 <Thresholds setAsset={props.setAsset} data={props.asset} apiName={props.apiName} handleUpdateData={props.handleUpdateData} />
//             </div>

//         </>
//     );
// };

const ElementCustomFields = (props) => {
  const { customFieldConfig } = props;
  const {
    errors,
    handleBlur,
    handleChange,
    // handleSubmit,
    // isSubmitting,
    touched,
    values,
  } = useFormikContext();
  const { user } = useAuth();

  return (
    <>
      {customFieldConfig &&
        customFieldConfig.customFields.map((value, index) => {
          return (
            <React.Fragment key={`custom_field_${index}`}>
              {value.enabled && (
                <Field name={`${fieldNameToAssetName(value.fieldName)}`}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <Form.Group className={`mb-3`}>
                        <Form.Label className="mb-0">
                          <span>{value.displayName}</span>
                        </Form.Label>
                        {value.fieldName === "category" ? (
                          <Form.Select
                            {...field}
                            isInvalid={
                              touched[field.name] && errors[field.name]
                            }
                          >
                            <option value={""}>please select</option>
                            {customFieldConfig.categories &&
                              customFieldConfig.categories.map((v, i) => {
                                return (
                                  <option
                                    key={`custom_field_${index}_option_${i}`}
                                    value={v}
                                  >
                                    {v}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            type="text"
                            {...field}
                            isInvalid={
                              touched[field.name] && errors[field.name]
                            }
                          />
                        )}
                        {touched[field.name] && (
                          <Form.Control.Feedback type="invalid">
                            {errors[field.name]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </>
                  )}
                </Field>
              )}
            </React.Fragment>
          );
        })}

      {props.mode === "details" &&
        props.asset &&
        ((props.apiName === "people" &&
          props.asset?.role === "User" &&
          user?.role !== "Admin") ||
          user?.id === props.asset?.id ||
          (user?.role === "Admin" && props.asset?.role === "Admin")) && (
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}

      {props.mode === "details" &&
        props.apiName === "people" &&
        user?.id === props.asset?.id && (
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              name="playSounds"
              label="Play Sounds"
              checked={values.playSounds}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {props.mode === "details" &&
        props.apiName === "people" &&
        user?.id === props.asset?.id && (
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              name="screenAlerts"
              label="Show Screen Alerts"
              checked={values.screenAlerts}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
        )}

      {customFieldConfig &&
        customFieldConfig.showAdditionalInfo &&
        props.mode === "details" && (
          <div>
            <AdditionalInfo
              setAsset={props.setAsset}
              data={props.asset}
              apiName={props.apiName}
              className="text-success"
            />
          </div>
        )}

      {customFieldConfig &&
        customFieldConfig.showThreshold &&
        props.mode === "details" &&
        props.apiName !== "people" && (
          <div>
            <Thresholds
              setAsset={props.setAsset}
              data={props.asset}
              apiName={props.apiName}
              handleUpdateData={props.handleUpdateData}
            />
          </div>
        )}
    </>
  );
};

export default ElementCustomFields;
