import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import moment from "moment-timezone";
import { THEME_PALETTE_LIGHT as palette } from "../../constants";
import { Line } from "react-chartjs-2";
import api from "../../api";
import LoadingSpin from "../../components/loadingSpin";

const BatteryVoltagePage = () => {
  const location = useLocation();
  const { id, name, date } = queryString.parse(location.search);
  const [result, setResult] = useState(null);
  const [changeDuration, setChangeDuration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delay, setDelay] = useState(false);

  const getDate = (value) => {
    let startDate;
    let endDate;
    if (value === "twoWeek") {
      startDate = moment().subtract(14, "day").startOf("days");
      endDate = moment().endOf("days");
    } else if (value === "month") {
      startDate = moment().subtract(30, "day").startOf("days");
      endDate = moment().endOf("days");
    } else {
      startDate = moment().subtract(7, "day").startOf("days");
      endDate = moment().endOf("days");
    }
    return [startDate, endDate];
  };

  const duration = useRef(getDate(date));

  const columns = [
    {
      Header: "Date/Time",
      accessor: "dateTime",
      type: "date",
      dateFormat: "DD/MM/YYYY HH:mm:ss",
    },
    {
      Header: "Supply",
      accessor: "supply",
      Cell: (cell) => <span>{cell.value ? cell.value + " mV" : "0"}</span>,
    },
  ];
  useEffect(() => {
    if (id) {
      setTimeout(() => setDelay(true), 150);
    }
  }, [id]);

  const resetDuration = (start, end) => {
    drawChart(start, end);
  };

  const drawChart = (start, end) => {
    if (
      changeDuration.length > 1 &&
      changeDuration[0].isSame(start) &&
      changeDuration[1].isSame(end)
    )
      return;
    setLoading(true);
    let labelArr = [];
    let dataArr = [];
    api
      .get(
        `assets/supply/${id}?page=1&startDate=${start
          .tz("Europe/London")
          .format("YYYY-MM-DD HH:mm")}&endDate=${end
          .tz("Europe/London")
          .format("YYYY-MM-DD HH:mm")}`
      )
      .then((r) => {
        let res = r.data;
        res.sort((a, b) => b.dateTime - a.dateTime);
        res.forEach((item) => {
          labelArr.push(moment(item.dateTime));
          dataArr.push(item.supply);
        });
        let data = {
          type: "line",
          labels: labelArr,
          datasets: [
            {
              label: "supply",
              data: dataArr,
              borderColor: palette.primary,
              pointRadius: 0,
              borderWidth: 1,
            },
          ],
        };
        setResult(r.data && r.data.length > 0 ? data : null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    setChangeDuration([start, end]);
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: true,
        boxWidth: 12,
        fontColor: "#A9ACB3",
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          gridLines: {
            display: true,
          },
          time: {
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              millisecond: "DD MMM",
              second: "DD MMM",
              minute: "DD MMM",
              hour: "DD MMM",
              day: "DD MMM",
              week: "DD MMM",
              month: "DD MMM",
              quarter: "DD MMM",
              year: "DD MMM",
            },
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={`Asset Battery Voltage`} />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-3">
            Asset Battery Voltage -{" "}
            <NavLink to={{ pathname: "/assets/edit", search: `?id=${id}` }}>
              {name}
            </NavLink>
          </h3>
        </div>
        {result && (
          <div className="mb-2" style={{ height: "300px" }}>
            {result && !loading && (
              <Line data={result} height={200} options={options} />
            )}
            {loading && (
              <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <LoadingSpin loading={loading} />
              </div>
            )}
          </div>
        )}
        <Card className="bg-white p-3">
          <CommonTable
            resetDuration={resetDuration}
            hideSearch
            duration={duration.current}
            showRange
            delayLoading={delay}
            durationParam="dateTime"
            apiName={`assets/supply/${id}`}
            columns={columns}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default BatteryVoltagePage;
