import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import api from "../../api";
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import Icons from "../../pages/icons/AssetIcon/Icons";
import { getUrl } from "../../utils/staticMethods";
import NotyfContext from "../../contexts/NotyfContext";

const RealLocation = (props) => {
  const [customers, setCustomer] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [device, setDevice] = useState("");
  const [deleteCurrent, setDeleteCurrent] = useState(false);
  const [defaultAsset, setDefaultAsset] = useState(false);
  const [defaultPerson, setDefaultPerson] = useState(false);
  const [defaultZone, setDefaultZone] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [defaultIcon, setShowDefaultIcon] = useState("placeholder.png");
  const location = useLocation();
  const { id, deviceType } = queryString.parse(location.search);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    if (id) {
      getDetail();
      getCustomer();
    }
  }, [id]);

  const onSave = () => {
    let data = {
      id,
      customerId,
      deleteCurrent,
      defaultAsset,
      defaultPerson,
      defaultZone,
      defaultIcon,
    };
    api.post(`${deviceType}/reallocate`, data).then((r) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      window.history.back();
    });
  };

  const getDetail = () => {
    api.get(`${deviceType}/${id}`).then((res) => {
      setDevice(res.data);
      setCustomerId(res.data?.customerId);
    });
  };

  const getCustomer = () => {
    api.get(`customers?page=1&limit=999`).then((res) => setCustomer(res.data));
  };

  const getDetailName = () => {
    if (device?.assetId) {
      return "Asset";
    } else if (device?.personIcon) {
      return "Person";
    }
    return "";
  };

  return (
    <>
      <h3 className="mb-3">Reallocate Device</h3>
      <Card>
        <Card.Body className="p-6">
          <div className="mb-3">
            Which Customer account would you like to move this device to?
          </div>
          <Form.Select
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
          >
            <option value={""} style={{ display: "none" }} />
            {customers.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        {(deviceType === "trackers" ||
          deviceType === "tags" ||
          deviceType === "solarchargers") && (
          <Card.Body className="p-6">
            <p>{`Would you like to automatically create a new Asset ${
              deviceType !== "solarchargers" ? "or Person" : ""
            } for this device?`}</p>
            <p>
              Choose a type, or simply leave unchecked if this isn't required.
            </p>
            <Form.Check
              className="mb-2"
              type={"checkbox"}
              checked={defaultAsset}
              label={`Create a Default Asset called 'Asset-${device?.serial}'`}
              onChange={(e) => {
                setDefaultAsset(e.target.checked);
                setDefaultPerson(false);
              }}
            />
            {deviceType !== "solarchargers" && (
              <Form.Check
                type={"checkbox"}
                checked={defaultPerson}
                label={`Create a Default Person called 'Person-${device?.serial}'`}
                onChange={(e) => {
                  setDefaultPerson(e.target.checked);
                  setDefaultAsset(false);
                }}
              />
            )}
          </Card.Body>
        )}
        {deviceType === "readers" && (
          <Card.Body className="p-6">
            <p>
              Would you like to automatically create a new Zone for this device?
            </p>
            <p>Select it, or simply leave unchecked if this isn't required.</p>
            <Form.Check
              className="mb-2"
              type={"checkbox"}
              checked={defaultZone}
              label={`Create a Default Zone called 'Zone-${device?.serial}'`}
              onChange={(e) => setDefaultZone(e.target.checked)}
            />
          </Card.Body>
        )}
      </Card>
      <Card className="mt-2">
        <Card.Body className="p-6">
          <p>Would you like to choose a new icon for this device?</p>
          <div>
            <img
              alt={defaultIcon}
              className="profile-icon ms-2 mb-2 cursor-pointer"
              onClick={() => setShowIcons(!showIcons)}
              src={
                imageSet[getUrl(defaultIcon)]
                  ? require("../../assets/img/icon/" +
                      imageSet[getUrl(defaultIcon)]).default
                  : defaultImg
              }
            />
            {showIcons && (
              <Icons
                onSelectedIcon={(value) => {
                  setShowDefaultIcon(value);
                  setShowIcons(!showIcons);
                }}
              />
            )}
          </div>
        </Card.Body>
      </Card>
      {device?.assetCompoundName ||
      device?.personCompoundName ||
      device?.zoneCompoundName ? (
        <Card className="mt-2">
          <Card.Body className="p-6">
            <p>
              This device is already linked to{" "}
              {device?.assetCompoundName ||
                device?.personCompoundName ||
                device?.zoneCompoundName}
            </p>
            <Form.Check
              className="mb-3"
              type={"checkbox"}
              checked={deleteCurrent}
              label={`Delete this ${getDetailName()} when the move is completed?`}
              onChange={(e) => setDeleteCurrent(e.target.checked)}
            />
            <Button onClick={() => onSave()}>Finish</Button>
          </Card.Body>
        </Card>
      ) : (
        <Card className={"mt-2"}>
          <Card.Body className={"ps-6"}>
            <Button onClick={() => onSave()}>Finish</Button>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
export default RealLocation;
