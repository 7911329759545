import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import {
  Disc,
  Globe,
  Grid,
  Layers,
  Package,
  Radio,
  Sun,
  Users,
  Wifi,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useSrlHelper from "../../hooks/useSrlHelper";
import QRCodeWithDownload from "../QRCodeWithDownload";

const DeviceDetail = ({ data, type, onChangeName, onChangeWirepas }) => {
  const [name, setName] = useState("");
  const [wirepas, setWirepas] = useState(false);

  const { user } = useAuth();
  const { deviceDetailAssetItemDisplayName } = useSrlHelper();

  useEffect(() => {
    if (data && type === "readers") {
      setWirepas(data.wirepas || false);
    }
    if (data && (type === "readers" || type === "tags")) {
      setName(data.deviceName || "");
    }
  }, [data]);

  const getParent = () => {
    if ((type === "readers" || type === "qrcodes") && data?.zoneId) {
      return (
        <li>
          <Disc size={14} className="me-2" />
          <NavLink
            to={{
              pathname: "/zones/edit",
              search: `?id=${data?.zoneId}`,
            }}
          >{`${data?.zoneCompoundName} ${
            data?.zoneReference ? `(${data?.zoneReference})` : ""
          }`}</NavLink>
        </li>
      );
    } else if (
      (type === "tags" || type === "trackers" || type === "qrcodes") &&
      (data?.assetId || data?.personId)
    ) {
      if (data?.assetId) {
        return (
          <li>
            <Package size={14} className="me-2" />
            <NavLink
              to={{
                pathname: "/assets/edit",
                search: `?id=${data?.assetId}`,
              }}
            >
              {deviceDetailAssetItemDisplayName(data)}
            </NavLink>
          </li>
        );
      } else if (data?.personId) {
        return (
          <li>
            <Users size={14} className="me-2" />
            <NavLink
              to={{
                pathname: "/people/edit",
                search: `?id=${data?.personId}`,
              }}
            >{`${data?.personCompoundName} ${
              data?.personReference ? `(${data?.personReference})` : ""
            }`}</NavLink>
          </li>
        );
      }
    }
  };

  const getTypeIcon = () => {
    if (type === "readers") {
      return <Wifi className="me-2" size={14} />;
    } else if (type === "trackers") {
      return <Globe className="me-2" size={14} />;
    } else if (type === "tags") {
      return <Radio className="me-2" size={14} />;
    } else if (type === "solarchargers") {
      return <Sun className="me-2" size={14} />;
    } else if (type === "qrcodes") {
      return <Grid className="me-2" size={14} />;
    }
    return null;
  };

  return (
    <Card className="shadow-none">
      <Card.Body>
        <ul className="list-unstyled col-sm-12">
          <h6>DEVICE DETAIL</h6>
          <li className="d-flex align-items-center">
            {getTypeIcon()}
            {data?.serial}
          </li>
          {type !== "solarchargers" && type !== "qrcodes" && (
            <li className="d-flex align-items-center">
              <Layers className="me-2" size={14} />
              {data?.model}
            </li>
          )}
          {data?.lastReport && (
            <li>
              <FontAwesomeIcon icon={faHeartbeat} />
              <span className="ms-2">
                {moment(data?.lastReport).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </li>
          )}
          {getParent()}
          {((type === "readers" && user?.editReaders) ||
            (type === "tags" && user?.editTags)) && (
            <Form.Group className="mt-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  onChangeName && onChangeName(e.target.value);
                }}
              />
            </Form.Group>
          )}
          {user?.role === "Admin" &&
            type === "readers" &&
            data?.wirepasNetworkId?.length > 0 && (
              <Form.Group className="mt-2">
                <Form.Check
                  type="switch"
                  name="mesh-wirepas"
                  label="Mesh"
                  checked={wirepas}
                  onChange={(e) => {
                    setWirepas(e.target.checked);
                    onChangeWirepas && onChangeWirepas(e.target.checked);
                  }}
                />
              </Form.Group>
            )}
        </ul>
        {type === "qrcodes" && <QRCodeWithDownload value={`${data?.serial}`} />}
      </Card.Body>
    </Card>
  );
};
export default DeviceDetail;
