import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
import { Image } from "react-feather";

let source1 = null;
const Floorplans = ({ type }) => {
  const [resultCount, setResultCount] = useState(null);
  const [data, setData] = useState([]);
  const childRef = useRef();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (type && user && !user[type] && user.role !== "Root") {
      navigate("/");
    }
  }, [user, type]);

  useEffect(() => {
    if (
      user &&
      user.role !== "Root" &&
      Number(resultCount) >= 1 &&
      data.length > 0
    ) {
      navigate(`/floorplanview?fId=${data[0].id}`);
    }
  }, [data, user, resultCount]);

  useEffect(() => {
    return () => {
      if (source1) {
        source1.cancel("request canceled");
      }
    };
  }, []);

  useEffect(() => {
    if (type && user && !user[type] && user.role !== "Root") {
      navigate("/");
    }
  }, [user, type]);

  const columns = [
    {
      Header: "Name",
      accessor: "floorPlanName",
      Cell: (cell) => (
        <NavLink
          to={{
            pathname: `/floorplanview`,
            search: `?fId=${cell.row.original.id}`,
          }}
        >
          {cell.value || "no name"}
        </NavLink>
      ),
    },
  ];

  const columns1 = [
    {
      Header: "Name",
      accessor: "floorPlanName",
      Cell: (cell) => (
        <span
          onClick={() => btnClick(cell.row.original)}
          className={"text-primary cursor-pointer"}
        >
          {cell.value || "no name"}
        </span>
      ),
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: (cell) => (
        <NavLink
          to={{
            pathname: `/customers/edit`,
            search: `?id=${cell.row?.original?.customerId}`,
          }}
        >
          {cell.value}
        </NavLink>
      ),
    },
  ];

  const btnClick = (c) => {
    navigate(`/floorplan/edit?id=${c.id}`);
  };

  const onGetList = (data) => {
    setData(data);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title="Floorplans" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {resultCount && resultCount >= 0 && (
            <h1 className="h3 mb-0">
              <Image className="me-2" />
              Floorplans {typeof resultCount === "string" && `(${resultCount})`}
            </h1>
          )}
          {user?.role === "Root" && (
            <Button onClick={() => navigate("/floorplan/edit")}>
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              Create New
            </Button>
          )}
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            onGetList={onGetList}
            ref={childRef}
            setCountParent={setResultCount}
            apiName="floorplans"
            columns={user?.role === "Root" ? columns1 : columns}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};
export default Floorplans;
