import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import SelectHeader from "../../components/SelectHeader";
import { DebounceInput } from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpin from "../../components/loadingSpin";
import ReactPaginate from "react-paginate";
import { useExpanded, useSortBy, useTable } from "react-table";
import api from "../../api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import throttle from "lodash.throttle";
import {
  Activity,
  AlertCircle,
  Award,
  BatteryCharging,
  Clock,
  LogIn,
  LogOut,
  MapPin,
  Monitor,
  Moon,
  Move,
  PauseCircle,
  TrendingDown,
  UserX,
} from "react-feather";
import moment from "moment-timezone";
import HashTags from "../../components/HashTags";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultZone from "../../assets/img/icon/GPS/location.png";
import defaultImg from "../../assets/img/icon/GPS/placeholder.png";
import defaultPerson from "../../assets/img/icon/Professions/personplaceholder.png";
import DatetimePicker from "react-bootstrap-daterangepicker";
import useAuth from "../../hooks/useAuth";
import {
  dateFromNow,
  downloadFile,
  getUrl,
  needShowColumn,
  returnTableExpand,
} from "../../utils/staticMethods";
import * as queryString from "query-string";
import axios from "axios";
import ExportButton from "../../components/ExportButton";
import ColumnOptions from "../../components/ColumnOptions";

let SrlCommonTable = (props, ref) => {
  const { columns, visibilityName } = props;
  const tableRef = useRef();

  const columnsFilter = (value) => {
    if (visibilityName) {
      if (value.visibilityName) {
        let key = "col-".concat(visibilityName, "-", value.visibilityName);
        let storedValue = localStorage.getItem(key);
        if (storedValue) {
          let isHidden = JSON.parse(storedValue);

          return isHidden;
        } else {
          // No value store so column is visible
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const newcols = columns.filter(columnsFilter);

  const newProps = { ...props, columns: newcols, oldColumns: columns };

  useImperativeHandle(ref, () => ({
    // methods for parent component
    updateTable: (newVal) => {
      tableRef.current.updateTable(newVal);
    },
    // in mobile view, when updating table, layout may change back to PC view, so this 'resizeTable' function will make table fit the current viewport again
    resizeTable: () => {
      tableRef.current.resizeTable();
    },
    // this 3 functions below all for mobile resize
    resetTable: () => {
      tableRef.current.resetTable();
    },
    stopResize: () => {
      tableRef.current.stopResize();
    },
    continueResize: () => {
      tableRef.current.continueResize();
    },
  }));

  return <CommonTableInner {...newProps} ref={tableRef}></CommonTableInner>;
};

// most of tables in this system will use this component
let CommonTableInner = forwardRef((props, ref) => {
  let source = null;
  let timer = null;
  const { user } = useAuth();
  const location = useLocation();
  const [resultCount, setResultCount] = useState(null);
  const [duration, setDuration] = useState([
    moment().subtract(7, "day").startOf("days"),
    moment().endOf("days"),
  ]);
  const [data, setResults] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [filter, setFilter] = useState(
    queryString.parse(location.search)?.filter || ""
  );
  const [limit, setLimit] = useState(
    queryString.parse(location.search)?.limit ||
      (props.type === "dashboard" && !props.hideFilter ? 5 : 10)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageRef = useRef(currentPage);
  const limitRef = useRef(limit);
  const durationRef = useRef(duration);
  const [autoGetData, setAutoGetData] = useState(false);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const columns = props.columns;
  //const visibilityName = props.visibilityName;
  const [expandRow, setExpandRow] = useState([]);
  const [preWidth, setPreWidth] = useState(null);
  const [preExpand, setPreExpand] = useState([]);
  const [tableSort, setSort] = useState(
    queryString.parse(location.search)?.sort || ""
  );
  const tableSortRef = useRef(tableSort);
  const tableContainer = useRef();
  const navigate = useNavigate();

  // react-table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      onColumnVisibilityChange: setColumnVisibility,
      // autoResetHiddenColumns: true,
      // autoResetExpanded: true,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          return null;
        }),
        ...(props.sort && {
          sortBy: props.sort,
        }),
      },
      state: {
        columnVisibility,
      },
      manualSortBy: true,
    },
    useSortBy,
    useExpanded
  );

  useImperativeHandle(ref, () => ({
    // methods for parent component
    updateTable: (newVal) => {
      if (props.data) {
        hasDataInit(newVal || currentPage);
        return;
      }
      handleSwitchPage(newVal || currentPage, props.showRange);
    },
    // in mobile view, when updating table, layout may change back to PC view, so this 'resizeTable' function will make table fit the current viewport again
    resizeTable: () => {
      resizeTable();
    },
    // this 3 functions below all for mobile resize
    resetTable: () => {
      setExpandRow([]);
      setPreWidth(null);
      setPreExpand([]);
    },
    stopResize: () => {
      window.removeEventListener("resize", resizeTable);
    },
    continueResize: () => {
      window.addEventListener("resize", throttle(resizeTable, 1000));
    },
  }));

  // for sorting current data in table
  const compare = (property) => {
    return function (a, b) {
      let value1 = a[property] || 0;
      let value2 = b[property] || 0;
      if (typeof value1 === "number" && typeof value2 === "number") {
        return value1 - value2;
      }
      return value1?.toString()
        ? value1.toString().localeCompare(value2.toString())
        : 1;
    };
  };

  const compare1 = (property) => {
    return function (a, b) {
      let value1 = a[property] || 0;
      let value2 = b[property] || 0;
      if (typeof value1 === "number" && typeof value2 === "number") {
        return value2 - value1;
      }
      return value2?.toString()
        ? value2.toString().localeCompare(value1.toString())
        : 1;
    };
  };
  // for getting all data from backend and set pagination in frontend
  const hasDataInit = (p) => {
    setCurrentPage(p);
    let myData = props.data.concat([]);
    if (filter) {
      myData = myData.filter(
        (item) =>
          item?.compoundName.toLowerCase().indexOf(filter.toLowerCase()) > -1
      );
    }
    if (tableSort) {
      let arr = tableSort.concat().split(".");
      let arrParam = arr[0];
      let order = arr[1];
      myData =
        order === "asc"
          ? myData.sort(compare(arrParam))
          : myData.sort(compare1(arrParam));
    }
    let list = myData.slice((p - 1) * limit, p * limit);
    setResults(list);
    const total = myData.length;
    setResultCount(total);
    props.setCountParent && props.setCountParent(total);
    // set total page count, minimum value is 1
    setPageCount(Math.ceil(total / limit) || 1);
  };

  // init and show a date range picker if parent component set a default date range
  useEffect(() => {
    if (props.durationSubstract) {
      setDuration([
        moment(props.durationSubstract[0].format()),
        moment(props.durationSubstract[1].format()),
      ]);
      durationRef.current = [
        moment(props.durationSubstract[0].format()),
        moment(props.durationSubstract[1].format()),
      ];
    }
  }, [props.durationSubstract]);

  useEffect(() => {
    if (props.duration) {
      setDuration([
        moment(props.duration[0].format()),
        moment(props.duration[1].format()),
      ]);
      durationRef.current = [
        moment(props.duration[0].format()),
        moment(props.duration[1].format()),
      ];
      !sortBy && handleSwitchPage(pageRef.current, props.showRange);
    }
  }, [props.duration]);

  const onResize = throttle(() => {
    resizeTable();
  }, 1000);

  // init the resize listener for the table, it will be responsive
  useEffect(() => {
    window.addEventListener("resize", onResize);
    if (props.data !== undefined || props.delayLoading === false) {
      return;
    }
    !props.durationSubstract &&
      !props.sort &&
      !props.duration &&
      handleSwitchPage(
        queryString.parse(location.search)?.page || 1,
        props.initDate
      );
    return () => {
      window.removeEventListener("resize", onResize);
      setExpandRow([]);
      setPreWidth(null);
      setPreExpand([]);
      timer && clearInterval(timer);
      setResults([]);
      setLoading(false);
      setPageCount(1);
      setCurrentPage(1);
      setResultCount(0);
      if (source) {
        source.cancel("request canceled");
      }
      source = null;
    };
  }, []);

  // hooks for get data if has api
  useEffect(() => {
    if (loaded) handleSwitchPage(queryString.parse(location.search)?.page || 1);
  }, [
    props.apiName,
    props.parentId,
    props.secFilterId,
    props.secId,
    props.category,
  ]);

  // hooks for sort
  useEffect(() => {
    if (
      sortBy &&
      (!tableSort ||
        sortBy[0]?.id !== tableSort.split(".")[0] ||
        sortBy[0]?.desc !== (tableSort.split(".")[1] === "desc"))
    ) {
      onSortColumn(sortBy);
    }
  }, [sortBy]);

  const resizeTable = () => {
    let expand = formatColumnsData(
      allColumns,
      tableContainer.current?.clientWidth
    );
    setExpandRow(expand);
    window.all = allColumns;
  };

  // for some behaviors, we dont want to load table immediately, this is for the table delay loading
  useEffect(() => {
    if (props.delayLoading) handleSwitchPage(1, true);
  }, [props.delayLoading]);

  // create ref for current page number, because it may use in an interval
  useEffect(() => {
    pageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    if (props.data) {
      hasDataInit(1);
      // responsive for different devices
      resizeTable();
    }
  }, [props.data]);

  useEffect(() => {
    if (data.length > 0) {
      resizeTable();
    }
  }, [data]);

  // page limit and input filter changed
  useEffect(() => {
    limitRef.current = limit;
    if (data.length >= 0 && resultCount !== null) {
      if (props.data) {
        hasDataInit(1);
        return;
      }
      handleSwitchPage(1, props.showRange);
    }
  }, [limit, filter]);

  // property for auto refresh data every 5 seconds
  useEffect(() => {
    if (props.hideAuto) {
      setAutoGetData(true);
    }
  }, [props.hideAuto]);

  // auto get data use timer
  useEffect(() => {
    if (autoGetData && props.autoRefresh) {
      timer = setInterval(
        () => handleSwitchPage(pageRef.current, props.showRange, true),
        5000
      );
    } else if (!autoGetData && props.autoRefresh) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [autoGetData]);

  // get data method
  const handleSwitchPage = (cur, byRange, auto) => {
    if (!props.apiName) return;
    if (!auto) setLoading(true);
    setCurrentPage(cur);
    if (source) {
      source.cancel("request canceled");
    }
    source = axios.CancelToken.source();
    api
      .get(
        `${props.apiName}?page=${cur}&limit=${limitRef.current}${
          props.secId && props.secFilterId
            ? `&${props.secFilterId}=${props.secId}`
            : ""
        }${
          props.id && props.filterId ? `&${props.filterId}=${props.id}` : ""
        }&sort=${tableSortRef.current || ""}${
          props.childId ? `&childId=${props.childId}` : ""
        }${props.parentId ? `&parentId=${props.parentId}` : ""}${
          props.ruleId ? `&ruleId=${props.ruleId}` : ""
        }${
          props.showRange && byRange
            ? `&startDate=${durationRef.current[0]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}&endDate=${durationRef.current[1]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}`
            : ""
        }`,
        {
          params: {
            filter: filter,
            category: props.category,
          },
          cancelToken: source.token,
        }
      )
      .then((res) => {
        // setSort(queryString.parse(location.search)?.sort);
        initSort();
        setResults(
          (res.data &&
            res.data.filter((item) => {
              if (item) {
                return item;
              }
              return false;
            })) ||
            []
        );
        const total = res.headers["x-total-count"];
        setResultCount(total);
        props.setCountParent && props.setCountParent(total);
        setPageCount(Math.ceil(total / limit) || 1);
        props.onGetList && props.onGetList(res.data);
        setLoading(false);
        let start;
        let end;
        if (props.showRange && !byRange) {
          end = moment(res.data[0][props.durationParam || "start"]).endOf(
            "days"
          );
          start = moment(res.data[0][props.durationParam || "start"])
            .subtract(30, "day")
            .startOf("days");
          setDuration([start, end]);
        } else if (Number(total) === 0 && props.showRange && !byRange) {
          start = moment().subtract(30, "day").startOf("days");
          end = moment().endOf("days");
          setDuration([start, end]);
        } else if (props.showRange && byRange) {
          start = duration[0];
          end = duration[1];
        }
        props.resetDuration && props.resetDuration(start, end);
        setLoaded(true);
      })
      .catch((e) => {
        setLoading(false);
        setLoaded(true);
        setResults([]);
        props.onGetList && props.onGetList(e);
      });
  };

  // select limit
  const onChangeSelect = (e) => {
    setLimit(e.target.value);
  };

  // input filter result
  const filterResults = (e) => {
    setFilter(e);
    props.filterResult && props.filterResult(e);
    if (props.autoRefresh && autoGetData && e) setAutoGetData(false);
    if (!e && props.autoRefresh && !autoGetData) setAutoGetData(true);
  };

  // switch page
  const handlePageClick = (obj) => {
    if (props.data) {
      hasDataInit(obj.selected + 1);
      return;
    }
    handleSwitchPage(obj.selected + 1, props.showRange);
  };

  // date range picker select event
  const onApply = (start, end) => {
    setDuration([moment(start.format()), moment(end.format())]);
    durationRef.current = [moment(start.format()), moment(end.format())];
  };

  // method for expanding columns in different screen sizes
  const formatColumnsData = (allColumns, width) => {
    if (width === 0) return preExpand;
    let totalWidth = 0;
    allColumns
      .filter((item) => item.getToggleHiddenProps().checked)
      .forEach((item) => {
        totalWidth += item.minWidth > 0 ? item.minWidth : item.width;
      });
    let expandRow = [];
    let execute = false;
    if (
      (totalWidth > width && preWidth > width) ||
      (totalWidth > width && !preWidth)
    ) {
      expandRow = removeColumn(allColumns, totalWidth, width);
      execute = true;
    } else if (
      ((totalWidth < width && preWidth < width) ||
        (totalWidth < width && !preWidth)) &&
      allColumns.filter((item) => item.getToggleHiddenProps().checked).length <
        allColumns.length
    ) {
      expandRow = addColumn(allColumns, totalWidth, width);
      execute = true;
    }
    setPreWidth(width);
    if (execute) {
      setPreExpand(expandRow);
      return expandRow;
    }
    return preExpand;
  };

  // hide column if width too long
  const removeColumn = (allColumns, totalWidth, width) => {
    let visibleColumns = Object.assign(
      [],
      allColumns.filter((item) => item.getToggleHiddenProps().checked)
    );
    let cellWidth = totalWidth;
    let expandColumns = [];
    let index = allColumns.length - 1;
    while (cellWidth > width && visibleColumns.length !== 0) {
      let cur = visibleColumns.pop();
      expandColumns.unshift(cur);
      cellWidth -= cur.minWidth > 0 ? cur.minWidth : cur.width;
      allColumns[index].toggleHidden(true);
      index--;
    }
    return expandColumns;
  };

  // show columns depends on width
  const addColumn = (allColumns, totalWidth, width) => {
    let hidden = Object.assign(
      [],
      allColumns.filter((item) => !item.getToggleHiddenProps().checked)
    );
    let visibleColumns = Object.assign(
      [],
      allColumns.filter((item) => item.getToggleHiddenProps().checked)
    );
    let cellWidth = totalWidth;
    let index = visibleColumns.length - 1;
    while (cellWidth <= width && hidden.length !== 0) {
      let cur = hidden.shift();
      visibleColumns.push(cur);
      cellWidth += cur.minWidth > 0 ? cur.minWidth : cur.width;
      index++;
      allColumns[index].toggleHidden(false);
    }
    return hidden;
  };

  const onSortColumn = (sortItem) => {
    if (sortItem.length === 0) {
      setSort(null);
      tableSortRef.current = null;
      return;
    }
    let sortParam = "";
    let column = allColumns.filter((item) => item.id === sortItem[0].id)[0];
    if (column) {
      if (!sortItem[0].desc) {
        sortParam = `${column.id}.asc`;
      } else if (sortItem[0].desc) {
        sortParam = `${column.id}.desc`;
      }
    }
    setSort(sortParam);
    tableSortRef.current = sortParam;
  };

  // init sort attr
  const initSort = () => {
    if (tableSortRef.current) {
      let arr = tableSortRef.current.concat();
      let sortParamArr = arr.split(".");
      if (sortParamArr.length < 2) return;
      allColumns.forEach((item) => {
        if (item.id === sortParamArr[0] && sortParamArr[1] === "desc") {
          item.toggleSortBy(true);
        } else if (item.id === sortParamArr[0] && sortParamArr[1] === "asc") {
          item.toggleSortBy(false);
        }
      });
    }
    return;
  };

  useEffect(() => {
    if (data && data.length > 0 && !props.data) {
      handleSwitchPage(1, props.showRange);
    } else if (props.data && props.data.length > 0) {
      hasDataInit(1);
    } else if (props.sort && !loading) {
      handleSwitchPage(1, props.showRange);
    }
  }, [tableSort]);

  const handleExport = (byRange) => {
    api
      .get(
        `${props.apiName}/exportcsv?${
          props.secId && props.secFilterId
            ? `&${props.secFilterId}=${props.secId}`
            : ""
        }${
          props.id && props.filterId ? `&${props.filterId}=${props.id}` : ""
        }&sort=${tableSortRef.current || ""}${
          props.childId ? `&childId=${props.childId}` : ""
        }${props.parentId ? `&parentId=${props.parentId}` : ""}${
          props.ruleId ? `&ruleId=${props.ruleId}` : ""
        }${
          props.showRange && byRange
            ? `&startDate=${durationRef.current[0]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}&endDate=${durationRef.current[1]
                .tz("Europe/London")
                .format("YYYY-MM-DD HH:mm:ss")}`
            : ""
        }`,
        {
          params: {
            filter: filter,
            category: props.category,
          },
        }
      )
      .then((res, headers) => {
        downloadFile(res);
      });
  };

  return (
    <React.Fragment>
      {props.showRange && loaded && (
        <div className="d-flex mt-3 mb-3">
          {duration.length > 0 && (
            <div style={{ maxWidth: "300px", flexGrow: 1 }}>
              <DatetimePicker
                initialSettings={{
                  timePicker: true,
                  startDate: duration[0],
                  endDate: duration[1],
                  timePicker24Hour: true,
                  locale: {
                    format: "DD/MM/YYYY HH:mm",
                  },
                }}
                onCallback={onApply}
              >
                <input type="text" className="form-control flex-grow-1" />
              </DatetimePicker>
            </div>
          )}
          <Button className="ms-2" onClick={() => handleSwitchPage(1, true)}>
            Filter
          </Button>
        </div>
      )}
      {loading && data.length === 0 ? (
        <div className="w-100 justify-content-center d-flex">
          <Spinner animation="border" />
        </div>
      ) : (
        <div
          className={`${
            props.type === "dashboard" &&
            "d-flex justify-content-between flex-column h-100"
          }`}
        >
          <div>
            <Row className="d-flex justify-content-between align-items-center">
              <Col
                sm={12}
                md={6}
                className="d-flex align-items-center dataTables_length"
              >
                {!props.singlePage && (
                  <div
                    className={`d-flex align-items-center page-select-container ${
                      props.type === "dashboard" && "d-none"
                    }`}
                  >
                    <span>Show</span>
                    <SelectHeader limit={limit} onChange={onChangeSelect} />
                    <span>entries</span>
                  </div>
                )}
                {props.autoRefresh && !props.hideAuto && (
                  <Form.Check
                    type="switch"
                    label="auto refresh"
                    className="ms-4"
                    checked={autoGetData}
                    onChange={(e) => setAutoGetData(e.target.checked)}
                  />
                )}
                {props.visibilityName && (
                  <>
                    &nbsp;
                    <ColumnOptions
                      columns={props.oldColumns}
                      name={props.visibilityName}
                    />
                  </>
                )}
              </Col>
              {!props.hideSearch && props.type !== "dashboard" && (
                <Col
                  sm={12}
                  md={6}
                  className={`d-flex dataTables_filter justify-content-lg-end justify-content-sm-center align-items-center`}
                >
                  <div className={`${props.type === "dashboard" && "me-1"}`}>
                    {props.apiName === "assets" && (
                      <>
                        <Button
                          onClick={(e) => {
                            navigate("/assets/search", { replace: true });
                          }}
                        >
                          Advanced Search
                        </Button>
                        <>&nbsp;&nbsp;</>
                      </>
                    )}
                    <span>Search:</span>
                    <DebounceInput
                      style={props.type === "dashboard" ? { width: "70%" } : {}}
                      className="table-search debounceInput-search text-gray"
                      minLength={0}
                      value={filter}
                      debounceTimeout={500}
                      onChange={(event) => filterResults(event.target.value)}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row ref={tableContainer}>
              {data.length > 0 ? (
                <Table
                  bordered={props.bordered}
                  className={`mt-2 dataTable dtr-inline ${
                    props.bordered && "bordered-table"
                  } ${props.cssClass}`}
                  responsive="sm"
                  striped
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map((headerGroup, headerIndex) => (
                      <tr
                        key={headerIndex}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map(
                          (column, columnIndex) =>
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            needShowColumn(column, expandRow) && (
                              <th
                                style={
                                  column.headerStyle ? column.headerStyle : ""
                                }
                                key={columnIndex}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className={column.className}
                              >
                                {column.render("Header")}
                                {column.className !== "icon" && (
                                  <span>
                                    {!column.disableSortBy && (
                                      <>
                                        {column.isSorted ? (
                                          column.isSortedDesc ? (
                                            <FontAwesomeIcon
                                              icon={faSortDown}
                                              className="ms-2"
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              icon={faSortUp}
                                              className="ms-2"
                                            />
                                          )
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faSort}
                                            className="ms-2"
                                          />
                                        )}
                                      </>
                                    )}
                                  </span>
                                )}
                              </th>
                            )
                        )}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading && (
                      <tr className="table-spinner">
                        <td colSpan={allColumns.length}>
                          <LoadingSpin loading={loading} />
                        </td>
                      </tr>
                    )}
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={i}>
                          <tr {...row.getRowProps()} className="default_row">
                            {row.cells.map((cell, cellIndex) => {
                              if (
                                cell.column?.type === "action" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return cell.column?.customWidth ? (
                                  <td
                                    key={cellIndex}
                                    width={cell.column?.customWidth}
                                  >
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.column?.action.map((item, index) => (
                                      <Button
                                        className="me-2"
                                        key={index}
                                        variant={item.color}
                                        disabled={item.disabled}
                                        onClick={() =>
                                          props.btnClick(
                                            item.name,
                                            row.original &&
                                              row.original[
                                                cell.column.returnParam
                                              ]
                                              ? row.original[
                                                  cell.column.returnParam
                                                ]
                                              : row.original
                                          )
                                        }
                                      >
                                        {item.name}
                                      </Button>
                                    ))}
                                  </td>
                                ) : (
                                  <td key={cellIndex} className="d-flex">
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.column?.action.map((item, index) => (
                                      <Button
                                        className="me-2"
                                        key={index}
                                        variant={item.color}
                                        disabled={item.disabled}
                                        onClick={() =>
                                          props.btnClick(
                                            item.name,
                                            row.original &&
                                              row.original[
                                                cell.column.returnParam
                                              ]
                                              ? row.original[
                                                  cell.column.returnParam
                                                ]
                                              : row.original
                                          )
                                        }
                                      >
                                        {item.name}
                                      </Button>
                                    ))}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "link" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                if (props.apiName === "assets") {
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      <NavLink
                                        to={{
                                          pathname: cell.column.link,
                                          search: `?id=${
                                            row.original[cell.column.stateId]
                                          }`,
                                        }}
                                      >
                                        {/* {`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`} */}
                                        {cell.value}
                                        {user?.favouriteAssets &&
                                          user?.favouriteAssets.includes(
                                            row.original[cell.column.stateId]
                                          ) && (
                                            <FontAwesomeIcon
                                              className="ms-1"
                                              icon={faHeart}
                                            />
                                          )}
                                      </NavLink>
                                    </td>
                                  );
                                } else if (props.apiName === "people") {
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      <NavLink
                                        to={{
                                          pathname: cell.column.link,
                                          search: `?id=${
                                            row.original[cell.column.stateId]
                                          }`,
                                        }}
                                      >
                                        {/* {props.hidePersonRef ? <>{`${cell.value}`}</> : <>{`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`}</>} */}
                                        {cell.value}
                                        {user?.favouritePeople &&
                                          user?.favouritePeople.includes(
                                            row.original[cell.column.stateId]
                                          ) && (
                                            <FontAwesomeIcon
                                              className="ms-1"
                                              icon={faHeart}
                                            />
                                          )}
                                      </NavLink>
                                    </td>
                                  );
                                } else if (props.apiName === "zones") {
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      <NavLink
                                        to={{
                                          pathname: cell.column.link,
                                          search: `?id=${
                                            row.original[cell.column.stateId]
                                          }`,
                                        }}
                                      >
                                        {/* {`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`} */}
                                        {cell.value}
                                        {user?.favouriteZones &&
                                          user?.favouriteZones.includes(
                                            row.original[cell.column.stateId]
                                          ) && (
                                            <FontAwesomeIcon
                                              className="ms-1"
                                              icon={faHeart}
                                            />
                                          )}
                                      </NavLink>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      <NavLink
                                        to={{
                                          pathname: cell.column.link,
                                          search: `?id=${
                                            row.original[cell.column.stateId]
                                          }`,
                                        }}
                                      >
                                        {/* {`${cell.value} ${row.original.reference ? `(${row.original.reference})` : ""}`} */}
                                        {cell.value}
                                      </NavLink>
                                    </td>
                                  );
                                }
                              } else if (
                                cell.column.type === "singleString" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {row.original}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "icon" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    <div className="d-flex align-items-center">
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      <img
                                        className="table-icon"
                                        alt="table-icon"
                                        src={
                                          imageSet[getUrl(cell.value)]
                                            ? require("./../../assets/img/icon/" +
                                                imageSet[getUrl(cell.value)])
                                                .default
                                            : defaultImg
                                        }
                                      />
                                      {props.apiName !== "zones" &&
                                        row.original?.role !== undefined &&
                                        row.original?.role !== null && (
                                          <>
                                            {row.original?.role === "User" ? (
                                              <Monitor size={18} />
                                            ) : (
                                              <Award size={18} />
                                            )}
                                          </>
                                        )}
                                    </div>
                                  </td>
                                );
                              } else if (
                                cell.column.type === "count" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {(cell.value && cell.value.length) || 0}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "dateFromNow" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.value &&
                                    cell.value &&
                                    cell.value.indexOf("0001-01-01") === -1 ? (
                                      <span>
                                        {cell.column.id === "lastSeen" && (
                                          <MapPin
                                            width={14}
                                            height={14}
                                            className="me-1"
                                          />
                                        )}{" "}
                                        {cell.column.id === "lastReport" && (
                                          <Activity
                                            width={14}
                                            height={14}
                                            className="me-1"
                                          />
                                        )}
                                        {props.curTime
                                          ? dateFromNow(
                                              cell.value,
                                              props.curTime
                                            )
                                          : dateFromNow(cell.value)}
                                      </span>
                                    ) : (
                                      <span>
                                        <Moon
                                          width={14}
                                          height={14}
                                          className="me-1"
                                        />{" "}
                                        Waiting...
                                      </span>
                                    )}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "date" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {moment(cell.value).format(
                                      cell.column.dateFormat
                                    )}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "hashtag" &&
                                cell.value &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    <HashTags hashTags={cell.value} />
                                  </td>
                                );
                              } else if (
                                cell.column.type === "ruleStatus" &&
                                cell.value &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.value === "armed" && (
                                      <span>Active</span>
                                    )}
                                    {cell.value === "disarmed" && (
                                      <span>Inactive</span>
                                    )}
                                    {cell.value === "timed" && (
                                      <span>Scheduled</span>
                                    )}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "rule" &&
                                cell.value &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                return (
                                  <td key={cellIndex}>
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.value === "lowvoltage" && (
                                      <span>
                                        <TrendingDown size={18} /> Low Voltage
                                      </span>
                                    )}
                                    {cell.value === "lowbattery" && (
                                      <span>
                                        <BatteryCharging size={18} /> Low
                                        Battery
                                      </span>
                                    )}
                                    {cell.value === "speeding" && (
                                      <span>
                                        <AlertCircle size={18} /> Speeding
                                      </span>
                                    )}
                                    {cell.value === "attendance" && (
                                      <span>
                                        <Clock size={18} /> Attendance
                                      </span>
                                    )}
                                    {cell.value === "motion" && (
                                      <span>
                                        <Move size={18} /> Motion Alarm
                                      </span>
                                    )}
                                    {cell.value === "enterzone" && (
                                      <span>
                                        <LogIn size={18} /> Zone Entry
                                      </span>
                                    )}
                                    {cell.value === "exitzone" && (
                                      <span>
                                        <LogOut size={18} /> Zone Exit
                                      </span>
                                    )}
                                    {cell.value === "pausetracking" && (
                                      <span>
                                        <PauseCircle size={18} /> Pause Tracking
                                      </span>
                                    )}
                                    {cell.value === "zonesupervision" && (
                                      <span>
                                        <UserX size={18} /> Unsupervised Zones
                                      </span>
                                    )}
                                  </td>
                                );
                              } else if (
                                cell.column.type === "linkStatus" &&
                                needShowColumn(cell.column, expandRow)
                              ) {
                                if (
                                  row.original.assetId ||
                                  row.original[cell.column.typeName] === "asset"
                                ) {
                                  let pathname = "/assets/edit";
                                  if (row.original.assetType) {
                                    if (
                                      row.original.assetType.startsWith(
                                        "archived"
                                      )
                                    ) {
                                      pathname = "/archived-assets/edit";
                                    }
                                  }
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      {row.original.assetIcon && (
                                        <img
                                          className="table-icon"
                                          alt="table-icon"
                                          src={
                                            imageSet[
                                              getUrl(row.original.assetIcon)
                                            ]
                                              ? require("./../../assets/img/icon/" +
                                                  imageSet[
                                                    getUrl(
                                                      row.original.assetIcon
                                                    )
                                                  ]).default
                                              : defaultImg
                                          }
                                        />
                                      )}
                                      <NavLink
                                        to={{
                                          pathname: pathname,
                                          search: `?id=${
                                            row.original.assetId ||
                                            row.original[cell.column.navigateId]
                                          }`,
                                        }}
                                      >
                                        {row.original.assetCompoundName ||
                                          cell.value}{" "}
                                        {row.original.assetReference ||
                                        row.original[cell.column.reference]
                                          ? `(${
                                              row.original.assetReference ||
                                              row.original[
                                                cell.column.reference
                                              ]
                                            })`
                                          : ""}
                                        {row.original.assetType &&
                                        row.original.assetType?.startsWith(
                                          "archived"
                                        )
                                          ? ` (archived)`
                                          : ""}
                                      </NavLink>
                                    </td>
                                  );
                                } else if (
                                  row.original.personId ||
                                  row.original[cell.column.typeName] ===
                                    "person"
                                ) {
                                  let pathname = "/people/edit";
                                  if (row.original.personType) {
                                    if (
                                      row.original.personType?.startsWith(
                                        "archived"
                                      )
                                    ) {
                                      pathname = "/archived-people/edit";
                                    }
                                  }
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      {row.original.personIcon && (
                                        <img
                                          className="table-icon"
                                          alt="table-icon"
                                          src={
                                            imageSet[
                                              getUrl(row.original.personIcon)
                                            ]
                                              ? require("./../../assets/img/icon/" +
                                                  imageSet[
                                                    getUrl(
                                                      row.original.personIcon
                                                    )
                                                  ]).default
                                              : defaultPerson
                                          }
                                        />
                                      )}
                                      <NavLink
                                        to={{
                                          pathname: pathname,
                                          search: `?id=${
                                            row.original.personId ||
                                            row.original[cell.column.navigateId]
                                          }`,
                                        }}
                                      >
                                        {row.original.personCompoundName ||
                                          cell.value}{" "}
                                        {row.original.personReference ||
                                        row.original[cell.column.reference]
                                          ? `(${
                                              row.original.personReference ||
                                              row.original[
                                                cell.column.reference
                                              ]
                                            })`
                                          : ""}
                                        {row.original.personType &&
                                        row.original.personType?.startsWith(
                                          "archived"
                                        )
                                          ? ` (archived)`
                                          : ""}
                                      </NavLink>
                                    </td>
                                  );
                                } else if (
                                  row.original.zoneId ||
                                  row.original[cell.column.typeName] === "zone"
                                ) {
                                  return (
                                    <td key={cellIndex}>
                                      {expandRow.length > 0 &&
                                        returnTableExpand(row, cell)}
                                      {row.original.zoneIcon && (
                                        <img
                                          className="table-icon"
                                          alt="table-icon"
                                          src={
                                            imageSet[
                                              getUrl(row.original.zoneIcon)
                                            ]
                                              ? require("./../../assets/img/icon/" +
                                                  imageSet[
                                                    getUrl(
                                                      row.original.zoneIcon
                                                    )
                                                  ]).default
                                              : defaultZone
                                          }
                                        />
                                      )}
                                      <NavLink
                                        to={{
                                          pathname: "/zones/edit",
                                          search: `?id=${
                                            row.original.zoneId ||
                                            row.original[cell.column.navigateId]
                                          }`,
                                        }}
                                      >
                                        {row.original.zoneCompoundName ||
                                          cell.value}{" "}
                                        {row.original.zoneReference ||
                                        row.original[cell.column.reference]
                                          ? `(${
                                              row.original.zoneReference ||
                                              row.original[
                                                cell.column.reference
                                              ]
                                            })`
                                          : ""}
                                      </NavLink>
                                    </td>
                                  );
                                }
                                return <td key={cellIndex} />;
                              } else {
                                return needShowColumn(
                                  cell.column,
                                  expandRow
                                ) ? (
                                  <td
                                    key={cellIndex}
                                    className={cell.column?.cssStyle}
                                    {...cell.getCellProps()}
                                  >
                                    {expandRow.length > 0 &&
                                      returnTableExpand(row, cell)}
                                    {cell.render("Cell")}
                                  </td>
                                ) : null;
                              }
                            })}
                          </tr>
                          {row.isExpanded ? (
                            <tr className="child" key={i}>
                              <td
                                colSpan={
                                  allColumns.filter(
                                    (col) => col.getToggleHiddenProps().checked
                                  ).length
                                }
                              >
                                {expandRow.map((item, expandIndex) => {
                                  if (item.type === "linkStatus") {
                                    if (
                                      row.original.assetId ||
                                      row.original[item.typeName] === "asset"
                                    ) {
                                      return (
                                        <div key={expandIndex}>
                                          <span className="fw-bolder text-nowrap me-1">
                                            {item.Header}:
                                          </span>
                                          {row.original.assetIcon && (
                                            <img
                                              className="table-icon"
                                              alt="table-icon"
                                              src={
                                                imageSet[
                                                  getUrl(row.original.assetIcon)
                                                ]
                                                  ? require("./../../assets/img/icon/" +
                                                      imageSet[
                                                        getUrl(
                                                          row.original.assetIcon
                                                        )
                                                      ]).default
                                                  : defaultImg
                                              }
                                            />
                                          )}
                                          <NavLink
                                            to={{
                                              pathname: "/assets/edit",
                                              search: `?id=${
                                                row.original.assetId ||
                                                row.original[item.navigateId]
                                              }`,
                                            }}
                                          >
                                            {row.original.assetCompoundName ||
                                              row.original[
                                                item.accessorName
                                              ]}{" "}
                                            {row.original.assetReference ||
                                            row.original[item.reference]
                                              ? `(${
                                                  row.original.assetReference ||
                                                  row.original[item.reference]
                                                })`
                                              : ""}
                                          </NavLink>
                                        </div>
                                      );
                                    } else if (
                                      row.original.personId ||
                                      row.original[item.typeName] === "person"
                                    ) {
                                      return (
                                        <div key={expandIndex}>
                                          <span className="fw-bolder text-nowrap me-1">
                                            {item.Header}:
                                          </span>
                                          {row.original.personIcon && (
                                            <img
                                              className="table-icon"
                                              alt="table-icon"
                                              src={
                                                imageSet[
                                                  getUrl(
                                                    row.original.personIcon
                                                  )
                                                ]
                                                  ? require("./../../assets/img/icon/" +
                                                      imageSet[
                                                        getUrl(
                                                          row.original
                                                            .personIcon
                                                        )
                                                      ]).default
                                                  : defaultPerson
                                              }
                                            />
                                          )}
                                          <NavLink
                                            to={{
                                              pathname: "/people/edit",
                                              search: `?id=${
                                                row.original.personId ||
                                                row.original[item.navigateId]
                                              }`,
                                            }}
                                          >
                                            {row.original.personCompoundName ||
                                              row.original[
                                                item.accessorName
                                              ]}{" "}
                                            {row.original.personReference ||
                                            row.original[item.reference]
                                              ? `(${
                                                  row.original
                                                    .personReference ||
                                                  row.original[item.reference]
                                                })`
                                              : ""}
                                          </NavLink>
                                        </div>
                                      );
                                    } else if (
                                      row.original.zoneId ||
                                      row.original[item.typeName] === "zone"
                                    ) {
                                      return (
                                        <div key={expandIndex}>
                                          <span className="fw-bolder text-nowrap me-1">
                                            {item.Header}:
                                          </span>
                                          {row.original.zoneIcon && (
                                            <img
                                              className="table-icon"
                                              alt="table-icon"
                                              src={
                                                imageSet[
                                                  getUrl(row.original.zoneIcon)
                                                ]
                                                  ? require("./../../assets/img/icon/" +
                                                      imageSet[
                                                        getUrl(
                                                          row.original.zoneIcon
                                                        )
                                                      ]).default
                                                  : defaultZone
                                              }
                                            />
                                          )}
                                          <NavLink
                                            to={{
                                              pathname: "/zones/edit",
                                              search: `?id=${
                                                row.original.zoneId ||
                                                row.original[item.navigateId]
                                              }`,
                                            }}
                                          >
                                            {row.original.zoneCompoundName ||
                                              row.original[
                                                item.accessorName
                                              ]}{" "}
                                            {row.original.zoneReference ||
                                            row.original[item.reference]
                                              ? `(${
                                                  row.original.zoneReference ||
                                                  row.original[item.reference]
                                                })`
                                              : ""}
                                          </NavLink>
                                        </div>
                                      );
                                    }
                                    return (
                                      <div
                                        key={expandIndex}
                                        className="fw-bolder text-nowrap me-1"
                                      >
                                        {item.Header}:
                                      </div>
                                    );
                                  } else if (item.type === "action") {
                                    return item.customWidth ? (
                                      <div
                                        key={expandIndex}
                                        width={item.customWidth}
                                      >
                                        {item.action.map((item1, index1) => (
                                          <Button
                                            className="m-2"
                                            key={index1}
                                            variant={item1.color}
                                            disabled={item1.disabled}
                                            onClick={() =>
                                              props.btnClick(
                                                item1.name,
                                                row.original.length
                                                  ? row.original
                                                  : row.original[
                                                      item.returnParam
                                                    ]
                                              )
                                            }
                                          >
                                            {item1.name}
                                          </Button>
                                        ))}
                                      </div>
                                    ) : (
                                      <React.Fragment key={expandIndex}>
                                        {item.action.map((item1, index1) => (
                                          <Button
                                            className="m-2"
                                            key={index1}
                                            variant={item1.color}
                                            disabled={item1.disabled}
                                            onClick={() =>
                                              props.btnClick(
                                                item1.name,
                                                row.original.length
                                                  ? row.original
                                                  : row.original[
                                                      item.returnParam
                                                    ]
                                              )
                                            }
                                          >
                                            {item1.name}
                                          </Button>
                                        ))}
                                      </React.Fragment>
                                    );
                                  } else if (
                                    item.type === "rule" &&
                                    row.original[item.id]
                                  ) {
                                    return (
                                      <React.Fragment key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {row.original[item.id] ===
                                          "lowvoltage" && (
                                          <span>
                                            <TrendingDown size={18} /> Low
                                            Voltage
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "lowbattery" && (
                                          <span>
                                            <BatteryCharging size={18} /> Low
                                            Battery
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "speeding" && (
                                          <span>
                                            <AlertCircle size={18} /> Speeding
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "attendance" && (
                                          <span>
                                            <Clock size={18} /> Attendance
                                          </span>
                                        )}
                                        {row.original[item.id] === "motion" && (
                                          <span>
                                            <Move size={18} /> Motion Alarm
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "enterzone" && (
                                          <span>
                                            <LogIn size={18} /> Zone Entry
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "exitzone" && (
                                          <span>
                                            <LogOut size={18} /> Zone Exit
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "pausetracking" && (
                                          <span>
                                            <PauseCircle size={18} /> Pause
                                            Tracking
                                          </span>
                                        )}
                                        {row.original[item.id] ===
                                          "zonesupervision" && (
                                          <span>
                                            <PauseCircle size={18} />{" "}
                                            Unsupervised Zones
                                          </span>
                                        )}
                                      </React.Fragment>
                                    );
                                  } else if (item.type === "singleString") {
                                    return (
                                      <React.Fragment key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {row.original}
                                      </React.Fragment>
                                    );
                                  } else if (item.type === "icon") {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        <div className="d-flex">
                                          <img
                                            className="table-icon"
                                            alt="table-icon"
                                            src={
                                              imageSet[
                                                getUrl(row.original[item.id])
                                              ]
                                                ? require("./../../assets/img/icon/" +
                                                    imageSet[
                                                      getUrl(
                                                        row.original[item.id]
                                                      )
                                                    ]).default
                                                : defaultImg
                                            }
                                          />
                                          {props.apiName !== "zones" &&
                                            row.original?.role !== undefined &&
                                            row.original?.role !== null && (
                                              <>
                                                {row.original?.role ===
                                                "User" ? (
                                                  <Monitor size={18} />
                                                ) : (
                                                  <Award size={18} />
                                                )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    );
                                  } else if (item.type === "count") {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {(row.original[item.id] &&
                                          row.original[item.id].length) ||
                                          0}
                                      </div>
                                    );
                                  } else if (item.type === "dateFromNow") {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {row.original[item.id] &&
                                        row.original[item.id].indexOf(
                                          "0001-01-01"
                                        ) === -1 ? (
                                          <span>
                                            {item.id === "lastSeen" && (
                                              <MapPin
                                                width={14}
                                                height={14}
                                                className="me-1"
                                              />
                                            )}{" "}
                                            {item.id === "lastReport" && (
                                              <Activity
                                                width={14}
                                                height={14}
                                                className="me-1"
                                              />
                                            )}
                                            {props.curTime
                                              ? dateFromNow(
                                                  row.original[item.id],
                                                  props.curTime
                                                )
                                              : dateFromNow(
                                                  row.original[item.id]
                                                )}
                                          </span>
                                        ) : (
                                          <span>
                                            <Moon
                                              width={14}
                                              height={14}
                                              className="me-1"
                                            />{" "}
                                            Waiting...
                                          </span>
                                        )}
                                      </div>
                                    );
                                  } else if (item.type === "date") {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {moment(row.original[item.id]).format(
                                          item.dateFormat
                                        )}
                                      </div>
                                    );
                                  } else if (
                                    item.type === "hashtag" &&
                                    row.original[item.id]
                                  ) {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        <HashTags
                                          hashTags={row.original[item.id]}
                                        />
                                      </div>
                                    );
                                  } else if (
                                    item.type === "ruleStatus" &&
                                    row.original[item.id]
                                  ) {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {row.original[item.id] === "armed" && (
                                          <span>Active</span>
                                        )}
                                        {row.original[item.id] ===
                                          "disarmed" && <span>Inactive</span>}
                                        {row.original[item.id] === "timed" && (
                                          <span>Scheduled</span>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={expandIndex}>
                                        <span className="fw-bolder text-nowrap me-1">
                                          {item.Header}:
                                        </span>
                                        {row.allCells
                                          .filter(
                                            (me) =>
                                              me.column.Header === item.Header
                                          )[0]
                                          .render("Cell")}
                                      </div>
                                    );
                                  }
                                })}
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div>
                  <div className="w-100 p-3 text-center mt-3 mb-3 bg-light">
                    {props.emptyMessage
                      ? props.emptyMessage
                      : "No data available in table"}
                  </div>
                </div>
              )}
            </Row>
          </div>
          {data.length > 0 && !props.singlePage && (
            <Row
              className={`d-flex align-items-center ${
                props.type === "dashboard" && "p-2"
              }`}
            >
              <Col md={5} sm={12} className="dataTables_info">
                {`Showing ${(currentPage - 1) * limit + 1} to ${
                  (currentPage - 1) * limit + data.length
                } of ${resultCount} entries`}
                {props.showExport && (
                  <>
                    &nbsp;&nbsp;
                    <ExportButton
                      title={"Export"}
                      count={resultCount}
                      onClick={() => {
                        handleExport(props.showRange);
                      }}
                    />
                  </>
                )}
              </Col>
              <Col
                md={7}
                sm={12}
                className="dataTables_paginate d-md-flex justify-content-md-end"
              >
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination m-0"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
      {/* {true &&
        <pre>
          <code>{JSON.stringify({ state, l: allColumns.length, columnVisibility, expandRow, preWidth, preExpand }, null, 2)}</code>
        </pre>
      } */}
    </React.Fragment>
  );
});

export default SrlCommonTable = forwardRef(SrlCommonTable);
