import { faColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ColumnOptions = (props) => {
  const { name, columns } = props;
  const [visibilityColumns, setVisibilityColumns] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = (save) => {
    if (save) {
      for (const value in values) {
        localStorage.setItem(`${value}`, `${values[value]}`);
      }

      // Force the page to reload so that the new visible columns take affect
      window.location.reload();
    }
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const [values, setValues] = useState({});

  const loadSettings = () => {
    let newValues = {};
    visibilityColumns.forEach((value, index) => {
      let key = "col-".concat(name, "-", value.visibilityName);
      let storedValue = localStorage.getItem(key);
      if (storedValue) {
        let value = JSON.parse(storedValue);
        newValues[key] = value;
      } else {
        newValues[key] = true;
      }
    });
    setValues(newValues);
  };

  const setKeyValue = (key, value) => {
    let newValues = { ...values };
    newValues[key] = value;
    setValues(newValues);
  };

  const handleChange = (event, key) => {
    let value = event.target.checked;
    setKeyValue(key, value);
  };

  useEffect(() => {
    if (!name || !columns) return;
    setVisibilityColumns(
      columns.filter((value, index) => {
        return value.visibilityName;
      })
    );
  }, [name, columns]);

  useEffect(() => {
    if (!visibilityColumns) return;
    loadSettings();
  }, [visibilityColumns]);

  return (
    <>
      <Button
        variant="light"
        className="d-inline-flex align-items-center mx-2"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faColumns} fixedWidth></FontAwesomeIcon>
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Visible Columns</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {visibilityColumns.map((value, index) => {
            let key = "col-".concat(name, "-", value.visibilityName);
            return (
              <Form.Check
                key={key}
                label={value.Header}
                onChange={(event) => {
                  handleChange(event, key);
                }}
                checked={values[key]}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleClose(true);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ColumnOptions;
