import React, { useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Grid, Wifi } from "react-feather";
import { Helmet } from "react-helmet-async";
import CommonTable from "../../components/tables/CommonTable";
import { Link, NavLink } from "react-router-dom";
import moment from "moment-timezone";
import useAuth from "../../hooks/useAuth";

const ArchivedReaders = () => {
  const [resultCount, setResultCount] = useState(0);
  const childRef = useRef();
  const curTime = useRef(moment());
  const { user } = useAuth();

  const columns = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: (cell) => {
        if (user?.role === "Root") {
          return (
            <span>
              <NavLink
                to={{
                  pathname: "/readers/edit",
                  search: `?id=${cell.row.original.id}`,
                }}
              >
                {cell.value}
              </NavLink>
              {cell.row.original.wirepasNetworkId && (
                <Grid className="ms-2" size={16} />
              )}
              {cell.row.original.wirepas && <Link className="ms-1" size={16} />}
            </span>
          );
        } else {
          return <span>{cell.value}</span>;
        }
      },
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "deviceName",
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow",
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: (cell) => (
        <NavLink
          to={{
            pathname: `/customers/edit`,
            search: `?id=${cell.row?.original?.customerId}`,
          }}
        >
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Helmet defer={false} title="Archived Readers" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0">
            <Wifi className="me-2" />
            Archived Readers{" "}
            {typeof resultCount === "string" && `(${resultCount})`}
          </h1>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            curTime={curTime.current}
            ref={childRef}
            setCountParent={setResultCount}
            apiName="archived-readers"
            columns={columns}
          />
        </Card>
      </Container>
    </>
  );
};

export default ArchivedReaders;
