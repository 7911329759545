import React from "react";
import { Card, Col } from "react-bootstrap";

const ZoneMapImageWidget = ({ name, width }) => {
  return (
    <>
      <Col
        key={name}
        lg={((width / 100) * 12).toFixed(0)}
        className="position-relative mb-4"
      >
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
          <Card.Title className="px-3 pt-3">Zone Map Image</Card.Title>
          <Card.Text className="p-2" as="div">
            {/* <img width={"100%"} src={image} alt="Zone Map"></img> */}
            <img
              width={"100%"}
              src={process.env.PUBLIC_URL + "/img/zonemapimage.png"}
              alt="Zone Map"
            />
          </Card.Text>
        </Card>
      </Col>
    </>
  );
};

export default ZoneMapImageWidget;
