import React, { useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, setIsOpen } = useSidebar();
  const location = useLocation();

  useEffect(() => {
    setIsOpen(true);
  }, [location]);

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <NavLink className="sidebar-brand" to="/">
            <div
              alt={"logo-img"}
              className="align-middle align-items-center sidebar-logo"
            />
          </NavLink>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
