import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Table, FormCheck } from "react-bootstrap";
import { Formik, FieldArray, Field, useField } from "formik";
import * as Yup from "yup";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const CategoriesField = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const { value } = meta;
  const { setValue } = helpers;

  const [seperatedString, setSeperatedString] = useState("");

  useEffect(() => {
    if (value) {
      const tagString = value.join(",");
      setSeperatedString(tagString);
    }
  }, [value]);

  return (
    <>
      <Form.Control
        {...field}
        value={seperatedString}
        onBlur={(e) => {
          var spacedString = e.target.value;
          const cleanArray = spacedString
            .split(",")
            .map((item) => item.trim())
            .filter(Boolean);
          setValue(cleanArray);
        }}
        onChange={(e) => {
          setSeperatedString(e.target.value);
        }}
      ></Form.Control>
    </>
  );
};

const CustomFieldConfigField = (props) => {
  const { title } = props;
  const [
    field,
    meta,
    // helpers,
  ] = useField(props.name);

  const { value } = meta;
  // const { setValue } = helpers;

  return (
    <>
      {value && (
        <Card className="bg-body border border-info">
          <Card.Header
            className={`h4 border-bottom ${
              value.enabled ? "bg-success" : "bg-danger"
            }`}
          >
            {title}
          </Card.Header>
          <Card.Body>
            <Table borderless hover responsive="lg" size="sm">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "200px",
                      minWidth: "200px",
                      maxWidth: "200px",
                    }}
                  ></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Enabled</td>
                  <td sm="10">
                    <Field
                      name={`${field.name}.enabled`}
                      as={FormCheck}
                      checked={value.enabled}
                    ></Field>
                  </td>
                </tr>
                <tr>
                  <td sm="2">Show Additional Info</td>
                  <td sm="10">
                    <Field
                      name={`${field.name}.showAdditionalInfo`}
                      as={FormCheck}
                      checked={value.showAdditionalInfo}
                    ></Field>
                  </td>
                </tr>
                <tr>
                  <td sm="2">ShowThreshold</td>
                  <td sm="10">
                    <Field
                      name={`${field.name}.showThreshold`}
                      as={FormCheck}
                      checked={value.showThreshold}
                    ></Field>
                  </td>
                </tr>
                <tr>
                  <td sm="2">Categories</td>
                  <td sm="10">
                    <CategoriesField name={`${field.name}.categories`} />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table borderless hover responsive="lg" size="sm">
              <thead className="bg-info border-bottom">
                <tr>
                  <th
                    style={{
                      width: "140px",
                      minWidth: "140px",
                      maxWidth: "140px",
                    }}
                    className="h6"
                  >
                    Field Name
                  </th>
                  <th
                    style={{
                      width: "70px",
                      minWidth: "70px",
                      maxWidth: "70px",
                    }}
                    className="h6 text-center"
                  >
                    Enabled
                  </th>
                  <th
                    style={{
                      width: "90px",
                      minWidth: "90px",
                      maxWidth: "90px",
                    }}
                    className="h6 text-center"
                  >
                    Mandatory
                  </th>
                  <th className="h6">Display Name</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="customFields">
                  {({ push, remove }) => (
                    <>
                      {value &&
                        value.customFields &&
                        value.customFields.map((value, index, array) => {
                          return (
                            <tr key={`custom-fields-field-${index}`}>
                              <td>
                                <Field
                                  name={`${field.name}.customFields.${index}.fieldName`}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => <Form.Label>{field.value}</Form.Label>}
                                </Field>
                              </td>
                              <td className="text-center">
                                <Field
                                  name={`${field.name}.customFields.${index}.enabled`}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <Form.Check
                                      inline
                                      id={field.name}
                                      checked={field.value}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td className="text-center">
                                <Field
                                  name={`${field.name}.customFields.${index}.mandatory`}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <Form.Check
                                      inline
                                      id={field.name}
                                      checked={field.value}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td>
                                <Field
                                  name={`${field.name}.customFields.${index}.displayName`}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <Form.Control
                                      type="text"
                                      sm={2}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </FieldArray>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const CustomFields = (props) => {
  const { customerId, assetFieldConfig, personFieldConfig, zoneFieldConfig } =
    props;
  const [initialValues, setInitialValues] = useState({});
  const notify = useContext(NotyfContext);

  const validationSchema = Yup.object({
    pairs: Yup.array().of(
      Yup.object({
        //key: Yup.string().required("Key is required"),
        displayName: Yup.string().required("Display Name is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    api.put(`customers/customfields/${customerId}`, values).then((res) => {
      //   onUpdated && onUpdated(res.data)
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
    });
  };

  useEffect(() => {
    if (assetFieldConfig && personFieldConfig && zoneFieldConfig) {
      setInitialValues({
        assetFieldConfig: assetFieldConfig,
        personFieldConfig: personFieldConfig,
        zoneFieldConfig: zoneFieldConfig,
      });
    }
  }, [assetFieldConfig, personFieldConfig, zoneFieldConfig]);

  return (
    <>
      {/* <Card.Title>Secondary Card Title</Card.Title> */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {values && (
              <>
                <CustomFieldConfigField
                  name="assetFieldConfig"
                  title="Asset Fields"
                />
                <CustomFieldConfigField
                  name="personFieldConfig"
                  title="Person Fields"
                />
                <CustomFieldConfigField
                  name="zoneFieldConfig"
                  title="Zone Fields"
                />
              </>
            )}

            {/* <Card className="bg-body border border-info" >
                            <Card.Header className="border-bottom" >Asset Fields</Card.Header>
                            <Card.Body>
                                <Table borderless hover responsive="lg" size="sm">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }}>Field Name</th>
                                            <th style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }} className="text-center">Enabled</th>
                                            <th style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }} className="text-center">Mandatory</th>
                                            <th>Display Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <FieldArray name="customFields">
                                            {({ push, remove }) => (
                                                <>
                                                    {
                                                        values && values.customFields &&
                                                        values.customFields.map((value, index, array) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <Field name={`customFields.${index}.fieldName`}>
                                                                            {({
                                                                                field, // { name, value, onChange, onBlur }
                                                                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                                meta,
                                                                            }) => (
                                                                                <Form.Label>{field.value}</Form.Label>
                                                                            )}
                                                                        </Field>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Field name={`customFields.${index}.enabled`}>
                                                                            {({
                                                                                field, // { name, value, onChange, onBlur }
                                                                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                                meta,
                                                                            }) => (
                                                                                <Form.Check inline id={field.name} checked={field.value} {...field} />
                                                                            )}
                                                                        </Field>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Field name={`customFields.${index}.mandatory`}>
                                                                            {({
                                                                                field, // { name, value, onChange, onBlur }
                                                                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                                meta,
                                                                            }) => (
                                                                                <Form.Check inline id={field.name} checked={field.value} {...field} />
                                                                            )}
                                                                        </Field>
                                                                    </td>
                                                                    <td>
                                                                        <Field name={`customFields.${index}.displayName`}>
                                                                            {({
                                                                                field, // { name, value, onChange, onBlur }
                                                                                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                                                meta,
                                                                            }) => (
                                                                                <Form.Control type="text" tdumn sm={2} {...field} />
                                                                            )}
                                                                        </Field>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </>
                                            )}
                                        </FieldArray>

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card> */}
            <Button type="submit">Save</Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomFields;
