import React, { useEffect, useRef, useState } from "react";
import api from "../../api";
import { Image, Map } from "react-feather";
import { DebounceInput } from "react-debounce-input";
import { ListGroup, Spinner } from "react-bootstrap";

const FloorplanSelector = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showing, setShowing] = useState(false);
  const [current, setCurrent] = useState(null);
  const firstLoad = useRef(false);
  const isUnmount = useRef(false);

  const onSearch = (search) => {
    setLoading(true);
    api
      .get(
        `floorplans?page=1&limit=10&filter=${search}${
          props.showDefault ? `&parentId=${props.asset}` : ""
        }`
      )
      .then((res) => {
        if (isUnmount.current) return;
        setData(res.data);
        setLoading(false);
        if (!firstLoad.current && props.showDefault && !props.default) {
          props.onSelect && props.onSelect(res.data[0] || null);
          setSearchString(res.data[0]?.floorPlanName || "");
          setCurrent(res.data[0] || null);
          res.data.length === 0 && props.noResult && props.noResult();
        }
        if (props.default) {
          props.onSelect &&
            props.onSelect(
              res.data.filter((item) => item.id === props.default)[0] ||
                res.data[0]
            );
          setSearchString(
            res.data.filter((item) => item.id === props.default)[0]
              ?.floorPlanName || res.data[0].floorPlanName
          );
          setCurrent(
            res.data.filter((item) => item.id === props.default)[0] ||
              res.data[0]
          );
        }
        firstLoad.current = true;
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (props.asset) {
      firstLoad.current = false;
      onSearch("");
    }
  }, [props.asset]);

  useEffect(() => {
    if (props.showDefault) {
      onSearch("");
    }
  }, [props.showDefault]);

  useEffect(() => {
    const handleClick = () => {
      setShowing(false);
      props.cancelOption && props.cancelOption();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
      setLoading(false);
      isUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    setShowing(false);
  }, [props.showOptions]);

  return (
    <div
      style={{ right: props.right ? "54px" : "", margin: "10px" }}
      className={`z-49 mobile-style position-absolute ${
        !props.right ? `left-0` : "right-0"
      } top-0`}
    >
      <DebounceInput
        className="hashtag-input text-gray w-100"
        style={{
          border: "1px solid #7f838e",
          padding: "4px 8px",
          fontSize: "12px",
          height: "32px",
        }}
        minLength={0}
        debounceTimeout={300}
        value={searchString}
        placeholder="Search floorplans"
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => {
          setShowing(true);
        }}
        onChange={(event) => {
          onSearch(event.target.value);
        }}
      />

      {loading && (
        <div className="w-100 bg-light d-flex justify-content-center p-2">
          <Spinner animation="border" />
        </div>
      )}

      <div style={{ maxHeight: "500px" }} className="overflow-auto">
        {showing && (
          <ListGroup>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <ListGroup.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    setSearchString(item.floorPlanName);
                    props.onSelect && props.onSelect(item);
                    setShowing(false);
                    setCurrent(item);
                  }}
                  key={index}
                  className={`cursor-pointer list-group-hover ${
                    current && item.id === current.id ? "bg-primary" : ""
                  }`}
                >
                  <Image size={14} />
                  <span className="ms-1">{item.floorPlanName}</span>
                </ListGroup.Item>
              ))}
            {props.showMap && (
              <ListGroup.Item
                onClick={(event) => {
                  event.stopPropagation();
                  setSearchString("Map");
                  props.onSelect && props.onSelect(null);
                  setShowing(false);
                  setCurrent(null);
                }}
                className={`cursor-pointer list-group-hover ${
                  !current ? "bg-primary" : ""
                }`}
              >
                <Map size={14} />
                <span className="ms-1">Map</span>
              </ListGroup.Item>
            )}
          </ListGroup>
        )}
      </div>
    </div>
  );
};

export default FloorplanSelector;
