import { Field } from "formik";
import React from "react";
import { fieldNameToAssetName } from "./fieldsHelper";
import { Form } from "react-bootstrap";
import AdditionalInfo from "../AdditionalInfo";
import Thresholds from "../Thresholds";

const ElementRootFields = (props) => {
  const { customFieldConfig } = props;

  return (
    <>
      {customFieldConfig &&
        customFieldConfig.customFields.map((value, index) => {
          return (
            <Field name={`${fieldNameToAssetName(value.fieldName)}`}>
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <>
                  <Form.Group className={`mb-3`}>
                    <Form.Label
                      className={`mb-0 ${
                        value.enabled ? "text-success" : "text-danger"
                      }`}
                    >
                      <span>
                        {value.displayName}&nbsp;[{value.fieldName}]
                      </span>
                    </Form.Label>
                    {value.fieldName === "category" ? (
                      <Form.Select
                        {...field}
                        isInvalid={touched[field.name] && errors[field.name]}
                      >
                        <option value={""}>please select</option>
                        {customFieldConfig.categories &&
                          customFieldConfig.categories.map((v, i) => {
                            return <option value={v}>{v}</option>;
                          })}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={touched[field.name] && errors[field.name]}
                      />
                    )}
                    {touched[field.name] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[field.name]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </>
              )}
            </Field>
          );
        })}

      {props.mode === "details" && (
        <div>
          <AdditionalInfo
            setAsset={props.setAsset}
            data={props.asset}
            apiName={props.apiName}
            className="text-success"
          />
        </div>
      )}
      {props.mode === "details" && props.apiName !== "people" && (
        <div>
          <Thresholds
            setAsset={props.setAsset}
            data={props.asset}
            apiName={props.apiName}
            handleUpdateData={props.handleUpdateData}
          />
        </div>
      )}
    </>
  );
};

export default ElementRootFields;
