import React, { useContext, useRef, useState } from "react";
import CommonTable from "../../components/tables/CommonTable";
import moment from "moment-timezone";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { CheckSquare, Square, X, XSquare } from "react-feather";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import ScheduleTaskModal from "./ScheduleTaskModal";
import LogTask from "./LogTask";

const TasksTable = ({ type, id }) => {
  const [parentCount1, setResultCount1] = useState(0);
  const [parentCount2, setResultCount2] = useState(0);
  const [parentCount3, setResultCount3] = useState(0);
  const [current, setOld] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [optionsStr, setOptionStr] = useState([]);
  const [completeModal, setCompleteModal] = useState(false);
  const [currentAction, setCurrent] = useState("");
  const [curTab, setCurTab] = useState("scheduled");
  const notify = useContext(NotyfContext);
  const [curPage, setCurPage] = useState(false);
  const duration = [
    moment().subtract(90, "day").startOf("days"),
    moment().endOf("days"),
  ];
  const duration1 = [
    moment().startOf("days"),
    moment().add(90, "day").endOf("days"),
  ];
  const childRef = useRef();
  const childRef1 = useRef();
  const childRef2 = useRef();
  const getTypeParent = () => {
    return type === "assets" || type === "zones" ? id : null;
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => (
        <span
          onClick={() => btnClick("View", cell.row.original)}
          className="text-primary cursor-pointer"
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Completed Date",
      accessor: "completed",
      Cell: (cell) => (
        <div>
          <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
        </div>
      ),
    },
    {
      Header: "Completed By",
      accessor: "childName",
      accessorName: "childName",
      reference: "childReference",
      type: "linkStatus",
      navigateId: "childId",
      typeName: "childType",
    },
  ];

  const columnsSchedule = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => (
        <span
          onClick={() => {
            btnClick("View", cell.row.original);
            curTab === "outstanding" && setCurPage(true);
          }}
          className="text-primary cursor-pointer"
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Due Date",
      accessor: "due",
      Cell: (cell) => (
        <div>
          <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
        </div>
      ),
    },
    {
      Header: "Assigned to",
      accessor: "childName",
      accessorName: "childName",
      reference: "childReference",
      type: "linkStatus",
      navigateId: "childId",
      typeName: "childType",
    },
  ];
  const columnsAssetZone = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => (
        <span
          onClick={() => btnClick("View", cell.row.original)}
          className="text-primary cursor-pointer"
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Completed Date",
      accessor: "completed",
      Cell: (cell) => (
        <div>
          <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
        </div>
      ),
    },
    {
      Header: "Asset/Zone",
      accessor: "parentName",
      accessorName: "parentName",
      type: "linkStatus",
      navigateId: "parentId",
      typeName: "parentType",
      reference: "parentReference",
    },
  ];

  const columnsAssetZoneDue = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => (
        <span
          onClick={() => {
            btnClick("View", cell.row.original);
            curTab === "outstanding" && setCurPage(true);
          }}
          className="text-primary cursor-pointer"
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Due Date",
      accessor: "due",
      Cell: (cell) => (
        <div>
          <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
        </div>
      ),
    },
    {
      Header: "Asset/Zone",
      accessor: "parentName",
      accessorName: "parentName",
      type: "linkStatus",
      navigateId: "parentId",
      typeName: "parentType",
      reference: "parentReference",
    },
  ];

  const btnClick = (name, id) => {
    setOld(id);
    setCurrent(name);
    setShowModal(true);
  };

  const getTypeChild = () => {
    return type === "people" ? id : null;
  };

  const pickOption = (str) => {
    setOptionStr((prevState) => {
      let arr = prevState.concat([]);
      if (arr.filter((item) => item === str).length === 0) arr.push(str);
      return arr;
    });
  };

  const handleComplete = () => {
    setCompleteModal(false);
    api
      .post(`tasks/complete`, {
        id: current?.id,
        statusStrings: optionsStr,
        completed: moment().format(),
      })
      .then((res) => {
        notify.open({
          type: "success",
          message: "Changes Saved",
        });
        if (curTab === "outstanding") {
          childRef1.current.updateTable(1);
        } else {
          childRef.current.updateTable(1);
        }
        childRef2.current.updateTable(1);
      });
  };

  const handleSuccess = () => {
    notify.open({
      type: "success",
      message: "Changes Saved",
    });
    if (currentAction === "Edit") {
      if (curTab === "outstanding") {
        childRef1.current.updateTable(1);
        childRef.current.updateTable(1);
      } else {
        childRef.current.updateTable(1);
      }
    } else {
      if (curTab === "outstanding") {
        childRef1.current.updateTable(1);
      } else {
        childRef.current.updateTable(1);
      }
    }
    setShowModal(false);
    setCurPage(false);
  };

  const handleEdit = () => {
    setCurrent("Edit");
  };

  const onDeleteOption = (str) => {
    setOptionStr((prevState) => {
      let arr = prevState.concat([]);
      return arr.filter((item) => item !== str);
    });
  };
  return (
    <React.Fragment>
      <div className="sub-tab mt-4">
        <Tab.Container defaultActiveKey={curTab} onSelect={(e) => setCurTab(e)}>
          <Nav variant="tabs" className="d-flex justify-content-between">
            <div className="d-flex">
              <Nav.Item>
                <Nav.Link className="white-text" eventKey="scheduled">
                  Scheduled
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="white-text" eventKey="completed">
                  Completed
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="white-text" eventKey="outstanding">
                  Outstanding
                </Nav.Link>
              </Nav.Item>
            </div>
            <LogTask />
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="scheduled">
              <h4 className="mb-2 d-flex align-items-center">
                <Square size={16} />
                <span className="ms-1 me-1">Scheduled</span>
                <span>({parentCount1})</span>
              </h4>
              <CommonTable
                duration={duration1}
                initDate
                showRange
                durationParam={"due"}
                sort={[{ id: "due", desc: false }]}
                ref={childRef}
                setCountParent={setResultCount1}
                apiName="tasks/scheduled"
                parentId={getTypeParent()}
                childId={getTypeChild()}
                columns={
                  type === "people" ? columnsAssetZoneDue : columnsSchedule
                }
              />
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <h4 className="mb-2 d-flex align-items-center">
                <CheckSquare size={16} />
                <span className="ms-1 me-1">Completed</span>
                <span>({parentCount2})</span>
              </h4>
              <CommonTable
                sort={[{ id: "completed", desc: true }]}
                duration={duration}
                initDate
                showRange
                durationParam={"completed"}
                ref={childRef2}
                setCountParent={setResultCount2}
                apiName="tasks/completed"
                parentId={getTypeParent()}
                childId={getTypeChild()}
                columns={type === "people" ? columnsAssetZone : columns}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="outstanding">
              <h4 className="mb-2 d-flex align-items-center">
                <XSquare size={16} />
                <span className="ms-1 me-1">Outstanding</span>
                <span>({parentCount3})</span>
              </h4>
              <CommonTable
                ref={childRef1}
                setCountParent={setResultCount3}
                apiName="tasks/outstanding"
                parentId={getTypeParent()}
                childId={getTypeChild()}
                columns={
                  type === "people" ? columnsAssetZoneDue : columnsSchedule
                }
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <Modal show={completeModal} onHide={() => setCompleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Complete a Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{`Please select completed options:`}</span>
          <div className="mt-2">
            {current?.optionStrings &&
              current?.optionStrings.map((item, index) => (
                <Button
                  variant="light"
                  onClick={() => pickOption(item)}
                  key={index}
                  className="me-2"
                >
                  {item}
                </Button>
              ))}
          </div>
          {optionsStr.length > 0 && (
            <div className="mt-4">
              <h6>Completed Options:</h6>
              <div className="d-flex">
                {optionsStr.map((item, index) => (
                  <div
                    key={index}
                    style={{ borderRadius: "2px" }}
                    className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-success text-black"
                  >
                    <span>{item}</span>
                    <X
                      size="12"
                      onClick={() => {
                        onDeleteOption(item);
                      }}
                      className="position-absolute text-dark cursor-pointer"
                      style={{ top: "2px", right: "2px" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleComplete()}>
            Complete
          </Button>
          <Button variant="secondary" onClick={() => setCompleteModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ScheduleTaskModal
        handleComplete={() => {
          setShowModal(false);
          setCurPage(false);
          setOptionStr([]);
          setCompleteModal(true);
        }}
        completed={curTab === "completed"}
        handleEdit={handleEdit}
        handleSuccess={handleSuccess}
        closeModal={() => {
          setShowModal(false);
          setCurPage(false);
        }}
        show={showModal}
        action={currentAction}
        curPage={curPage}
        current={current}
        apiName="tasks/schedule"
      />
    </React.Fragment>
  );
};
export default TasksTable;
