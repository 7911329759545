import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Streetview from "../maps/components/Streetview";
import api from "../../api";
import useAuth from "../../hooks/useAuth";
import RootTopBar from "../../components/navbar/RootTopBar";
import { Helmet } from "react-helmet-async";

const CreateNewFloorPlan = ({
  showUpload,
  onHide,
  picture,
  onUpload,
  setUploadFile,
  handleSave,
  curFloor,
  onDelete,
  id,
  customerOption,
  uploading,
}) => {
  const [center, setCenter] = useState(null);
  const [name, setName] = useState("");
  const [customer, setCustomer] = useState("");
  const [errorName, setErrorName] = useState(null);
  const [errorCustomer, setErrorCustomer] = useState(null);
  const [errorPicture, setErrorPicture] = useState(null);
  const { user } = useAuth();
  const mapRef = useRef();

  const onCenterChanged = (c) => {
    setCenter(c);
  };
  useEffect(() => {
    if (curFloor) {
      setName(curFloor.floorPlanName || "");
      setCustomer(curFloor.customerId || "");
    }
  }, [curFloor]);

  useEffect(() => {
    setErrorPicture(null);
  }, [picture]);

  useEffect(() => {
    return () => {
      if (curFloor && curFloor.floorPlanName.trim() === "") {
        cancelModal();
      }
    };
  }, []);
  const onSave = () => {
    let obj1 = mapRef.current.onGetPath();
    let valid = true;
    if (!picture) {
      setErrorPicture("Please upload an image");
      valid = false;
    }
    if (name.trim() === "") {
      setErrorName("Please input floorplan name");
      valid = false;
    }
    if (!customer) {
      setErrorCustomer("Please select a customer");
      valid = false;
    }
    if (valid) {
      let obj = {
        customerId: customer,
        floorPlanName: name,
        altitude: obj1?.altitude,
        rotation: obj1?.rotate,
        northWestLatitude: obj1?.position.lat().toString(),
        northWestLongitude: obj1?.position.lng().toString(),
        southEastLatitude: obj1?.position1.lat().toString(),
        southEastLongitude: obj1?.position1.lng().toString(),
        polygon: obj1?.pathArray || null,
      };
      handleSave && handleSave(obj);
      setName("");
      setCustomer("");
    }
  };

  const cancelModal = () => {
    if (!id && picture) {
      api.delete(`files/floorplans/${curFloor?.id}`).then(() => {});
    }
    if (curFloor?.floorPlanName.trim() === "" && curFloor?.id) {
      api.delete(`floorplans/${curFloor?.id}`).then((r) => onHide());
    } else {
      onHide();
    }
    setName("");
    setCustomer("");
  };

  return (
    <React.Fragment>
      <Helmet
        defer={false}
        title={curFloor?.floorPlanName || "New Floorplan"}
      />
      <RootTopBar data={curFloor} homePage />
      <h3>{curFloor?.floorPlanName ? "Edit Floorplan" : "New Floorplan"}</h3>
      <div className="position-relative">
        <Form.Group className="mb-2">
          <Form.Label>Customer</Form.Label>
          <Form.Select
            size="lg"
            name="customer"
            value={customer}
            onChange={(e) => {
              setCustomer(e.target.value);
              setErrorCustomer(null);
            }}
          >
            <option value={""} disabled style={{ display: "none" }}></option>
            {customerOption &&
              customerOption.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Select>
          {errorCustomer && <div className="text-danger">{errorCustomer}</div>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Floorplan Name:</Form.Label>
          <Form.Control
            size={"lg"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              e.target.value.trim() !== "" && setErrorName(null);
            }}
          />
          {errorName && <div className="text-danger">{errorName}</div>}
        </Form.Group>
        <Streetview
          ref={mapRef}
          curFloor={curFloor}
          img={picture}
          onCenterChanged={onCenterChanged}
        />
        {user?.role === "Root" && (
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <Form.Control
              accept="image/*"
              className="w-75 border-0 image-input"
              type="file"
              onChange={(event) => setUploadFile(event.target.files)}
            />
            <Button
              disabled={uploading}
              onClick={() => {
                onUpload(center, name, customer);
              }}
            >
              {uploading ? "Uploading.." : "Upload"}
            </Button>
          </div>
        )}
        {errorPicture && <div className="text-danger">{errorPicture}</div>}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          className="me-1"
          onClick={() => cancelModal()}
          variant={"secondary"}
        >
          Back
        </Button>
        {user?.role === "Root" && (
          <Button className="me-1" onClick={() => onSave()}>
            Save
          </Button>
        )}
        {curFloor && user?.role === "Root" && (
          <Button variant={"danger"} onClick={() => onDelete()}>
            Delete
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};
export default CreateNewFloorPlan;
