import React, { useEffect, useRef, useState } from "react";
import { Card, Col, ProgressBar } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import { NavLink } from "react-router-dom";
import api from "../../api";
import moment from "moment-timezone";
import LoadingSpin from "../loadingSpin";

const BatteryExchanges = ({ name, width }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const isUmount = useRef(false);

  useEffect(() => {
    getData();
    return () => {
      isUmount.current = true;
    };
  }, []);

  const getData = () => {
    setLoading(true);
    const apiArr = [
      api.get(
        `reports/batteryexchanges?page=1&limit=10&startDate=${moment()
          .startOf("week")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("week")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `reports/batteryexchanges?page=1&limit=10&startDate=${moment()
          .week(moment().week() - 1)
          .startOf("week")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .week(moment().week() - 1)
          .endOf("week")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `reports/batteryexchanges?page=1&limit=10&startDate=${moment()
          .week(moment().week() - 2)
          .startOf("week")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .week(moment().week() - 2)
          .endOf("week")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
    ];
    let res = [
      { label: "This week", value: 562 },
      { label: "Last week ", value: 723 },
      {
        label: "2 weeks ago ",
        value: 821,
      },
    ];
    let total = 0;
    Promise.all(apiArr).then((r) => {
      r.forEach((item, index) => {
        res[index].value = Number(item.headers["x-total-count"]);
        if (res[index].value > total) total = res[index].value;
      });
      setTotal(total);
      setResult(res);
      setLoading(false);
    });
  };

  const columns = [
    {
      Header: "Period",
      accessor: "label",
      disableSortBy: true,
      Cell: (cell) => (
        <NavLink
          to={{ pathname: "/batteryExchanges", search: `?date=${cell.value}` }}
        >
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: "Count",
      accessor: "value",
      width: "200px",
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "id",
      className: "w-35",
      disableSortBy: true,
      Cell: (cell) => (
        <ProgressBar now={(cell.row.original.value / total) * 100} />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Col key={name} lg={((width / 100) * 12).toFixed(0)} className="mb-4">
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h4 className="text-dark">Asset Battery Exchanges</h4>
          </div>
          {loading ? (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center">
              <LoadingSpin loading={loading} />
            </div>
          ) : (
            <CommonTable
              data={result}
              singlePage
              showRange={false}
              filterId={"parentHashtag"}
              id={"customer"}
              type="dashboard"
              columns={columns}
            />
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default BatteryExchanges;
