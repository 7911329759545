import { Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { useZxing } from "react-zxing";
import api from "../api";
import { useContext, useState } from "react";
import NotyfContext from "../contexts/NotyfContext";

const CaptureTag = ({ setTag }) => {
  const [loading, setLoading] = useState(false);
  const notify = useContext(NotyfContext);
  const { ref } = useZxing({
    constraints: {
      audio: false,
      video: {
        width: 320,
        height: 320,
        facingMode: "environment",
      },
    },
    onResult: (result) => {
      if (!loading) {
        setLoading(true);
        api
          .get("tags?page=1&limit=999", {
            params: {
              filter: result.getText(),
            },
          })
          .then((result) => {
            if (result.status === 200) {
              if (result.data.length === 1) {
                notify.open({
                  type: "success",
                  message: "Tag found",
                });
                setTag(result.data[0]);
              } else {
                notify.open({
                  type: "error",
                  message: "Tag not found",
                });
              }
            }
            setLoading(false);
          })
          .catch((error) => {
            setTag(null);
            notify.open({
              type: "error",
              message: "Failed to get tag",
            });
            setLoading(false);
          });
      } else {
      }
    },
    onError: (error) => {},
  });

  return (
    <>
      <Container fluid className="p-1">
        <Row className="m-0">
          <Col
            className=""
            lg={{ span: 8, offset: 2 }}
            xl={{ span: 6, offset: 3 }}
          >
            <Stack gap={2}>
              <div className="d-flex justify-content-center">
                <video style={{ width: "200px" }} ref={ref} />
              </div>
              {loading && (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" size="lg" />
                </div>
              )}
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CaptureTag;
