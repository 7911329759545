import React from "react";

const HashTags = (props) => {
  return (
    <React.Fragment>
      {props.hashTags.map((tag, i) => {
        return (
          <span key={i} className="badge bg-primary me-1 my-1">
            {tag}
          </span>
        );
      })}
    </React.Fragment>
  );
};

export default HashTags;
