import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import NewObject from "../../components/detail/NewObject";
import CommonTable from "../../components/tables/CommonTable";
import { Package } from "react-feather";
import useCustomFieldsHelper from "../../hooks/useCustomFieldsHelper";

function ArchivedAssets() {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  // const { user } = useAuth();
  const { getColumnsForElementType } = useCustomFieldsHelper();
  const columns = useMemo(() => {
    return getColumnsForElementType("assets");
  }, []);

  return (
    <React.Fragment>
      <Helmet defer={false} title="Archived Assets" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Package className="me-2" /> Archived Assets{" "}
              {typeof resultCount === "string" && `(${resultCount})`}
            </h1>
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable
            setCountParent={setResultCount}
            apiName="archived-assets"
            columns={columns}
            showExport={true}
            visibilityName="archived-assets"
          />
        </Card>
      </Container>
      <NewObject
        showModal={showModal}
        closeModal={() => setShow(false)}
        apiName="assets"
      />
    </React.Fragment>
  );
}

export default ArchivedAssets;
