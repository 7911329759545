import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import Favourites from "../../components/widgets/Favourites";
import api from "../../api";
import Service from "../../components/widgets/Service";
import Email from "../../components/widgets/Email";
import Call from "../../components/widgets/Call";
import RecentItems from "../../components/widgets/RecentItems";
import MiniFloorplan from "../../components/widgets/MiniFloorplan";
import CagesByDepot from "../../components/widgets/CagesByDepot";
import CagesByCustomer from "../../components/widgets/CagesByCustomer";
import CagesByCustomerWithAllocation from "../../components/widgets/CagesByCustomerWithAllocation";
import CageDistribution from "../../components/widgets/CageDistribution";
import CagesInTransit from "../../components/widgets/CagesInTransit";
import CratesByDepot from "../../components/widgets/CratesByDepot";
import CratesByCustomer from "../../components/widgets/CratesByCustomer";
import CratesByCustomerWithAllocation from "../../components/widgets/CratesByCustomerWithAllocation";
import CrateDistribution from "../../components/widgets/CrateDistribution";
import CratesInTransit from "../../components/widgets/CratesInTransit";
import MiniMap from "../../components/widgets/MiniMap";
import { Helmet } from "react-helmet-async";
import BatteryLevel from "../../components/widgets/BatteryLevel";
import Deployment from "../../components/widgets/Deployment";
import BatteryExchanges from "../../components/widgets/BatteryExchanges";
import AverageBatteryLifespan from "../../components/widgets/AverageBatteryLifespan";
import Summary from "../../components/widgets/Summary";
import AttendanceHistWidget from "../../components/widgets/AttendanceHistWidget";
import AttendanceLiveWidget from "../../components/widgets/AttendanceLiveWidget";
import { globalConfig } from "../../config";
import ZoneMapWidget from "../../components/widgets/ZoneMapWidget";
import TagAssignment from "../../components/widgets/TagAssignment";
import MultiAssetSearch from "../../components/widgets/MultiAssetSearch";
import ContentsCountWidget from "../../components/widgets/ContentsCountWidget";
import ZoneMapImageWidget from "../../components/widgets/ZoneMapImageWidget";
import TagAssignmentCountWidget from "../../components/widgets/TagAssignmentCountWidget";

const Default = ({ widgets, onGetOne }) => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const isUmount = useRef(false);

  useEffect(() => {
    // need user info loaded first, user info contains permission settings
    if (widgets) {
      setData(widgets);
    } else {
      if (user)
        api.get(`widgets`).then((res) => {
          if (isUmount.current) return;
          setData(res.data.sort(sortArray));
        });
    }
  }, [user]);

  useEffect(() => {
    return () => {
      isUmount.current = true;
    };
  }, []);

  const sortArray = (a, b) => {
    return a?.position - b?.position;
  };

  // widget types here, return the correct widget according to type, when created new widget, should add the widget here
  const getComponent = (props) => {
    const { name } = props;
    if (name === "MiniMap" && user?.showMap) {
      return <MiniMap onClick={() => onSelect(props)} {...props} />;
    } else if (
      name === "FavouritePeople" ||
      name === "FavouriteAssets" ||
      name === "FavouriteZones"
    ) {
      return <Favourites onClick={() => onSelect(props)} {...props} />;
    } else if (name === "RecentItems") {
      return <RecentItems onClick={() => onSelect(props)} {...props} />;
    } else if (name === "MiniFloorplan" && user?.showFloorPlans) {
      return <MiniFloorplan onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CagesByDepot") {
      return <CagesByDepot onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CagesByCustomer") {
      return <CagesByCustomer onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CagesByCustomerWithAllocation") {
      return (
        <CagesByCustomerWithAllocation
          onClick={() => onSelect(props)}
          {...props}
        />
      );
    } else if (name === "CagesDistribution") {
      return <CageDistribution onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CagesInTransit") {
      return <CagesInTransit onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CratesByDepot") {
      return <CratesByDepot onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CratesByCustomer") {
      return <CratesByCustomer onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CratesByCustomerWithAllocation") {
      return (
        <CratesByCustomerWithAllocation
          onClick={() => onSelect(props)}
          {...props}
        />
      );
    } else if (name === "CratesDistribution") {
      return <CrateDistribution onClick={() => onSelect(props)} {...props} />;
    } else if (name === "CratesInTransit") {
      return <CratesInTransit onClick={() => onSelect(props)} {...props} />;
    } else if (name === "BatteryLevel") {
      return <BatteryLevel onClick={() => onSelect(props)} {...props} />;
    } else if (name === "Deployment") {
      return <Deployment onClick={() => onSelect(props)} {...props} />;
    } else if (name === "BatteryExchanges") {
      return <BatteryExchanges onClick={() => onSelect(props)} {...props} />;
    } else if (name === "AverageBatteryLifespan") {
      return (
        <AverageBatteryLifespan onClick={() => onSelect(props)} {...props} />
      );
    } else if (
      name === "AssetSummary" ||
      name === "PeopleSummary" ||
      name === "ZoneSummary"
    ) {
      return <Summary onClick={() => onSelect(props)} {...props} />;
    } else if (name === "AttendanceHistWidget") {
      return (
        <AttendanceHistWidget onClick={() => onSelect(props)} {...props} />
      );
    } else if (name === "AttendanceLiveWidget") {
      return (
        <AttendanceLiveWidget onClick={() => onSelect(props)} {...props} />
      );
    } else if (name === "TagAssignment") {
      return <TagAssignment onClick={() => onSelect(props)} {...props} />;
    } else if (name === "MultiAssetSearch") {
      return <MultiAssetSearch onClick={() => onSelect(props)} {...props} />;
    } else if (name === "ZoneContentsCount") {
      return <ContentsCountWidget onClick={() => onSelect(props)} {...props} />;
    } else if (name === "TagAssignmentCount") {
      return (
        <TagAssignmentCountWidget onClick={() => onSelect(props)} {...props} />
      );
    } else if (name === "ZoneMapWidget") {
      return <ZoneMapWidget onClick={() => onSelect(props)} {...props} />;
    } else if (name === "ZoneMapImageWidget") {
      return <ZoneMapImageWidget onClick={() => onSelect(props)} {...props} />;
    }
    return null;
  };

  const onSelect = (props) => {
    if (!widgets) return;
    onGetOne && onGetOne(props);
  };

  const productName = globalConfig.productName;

  return (
    <React.Fragment>
      <Helmet defer={false} title={productName.toUpperCase()} />
      <Container fluid className="p-0">
        {user && !widgets && (
          <h1 className="mb-4">
            Welcome to {productName}, {user.primaryName}.
          </h1>
        )}
        <Row>
          {data &&
            data.map((item) => (
              <React.Fragment key={item.name}>
                {getComponent(item)}
              </React.Fragment>
            ))}
        </Row>
        {/* 3 fixed widgets below*/}
        {!widgets && (
          <Row>
            <Service />
            <Email />
            <Call />
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Default;
