import React, { useEffect, useRef, useState } from "react";
import useTheme from "../hooks/useTheme";
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";
import SrlAssetsEdit from "../srl/pages/SrlAssetsEdit";
import ArchivedAssetsEdit from "../pages/assets/ArchivedAssetsEdit";

const ArchivedAssetsEditWrapper = () => {
  const { theme } = useTheme();
  const location = useLocation();
  const { id, direct } = queryString.parse(location.search);
  const idRef = useRef(id);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (id !== idRef.current) {
      setShow(false);
      idRef.current = id;
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [id]);

  return show ? (
    <>
      {theme === "srl" ? (
        <>
          <SrlAssetsEdit id={id} direct={direct} />
        </>
      ) : (
        <>
          <ArchivedAssetsEdit id={id} direct={direct} />
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default ArchivedAssetsEditWrapper;
