import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { Modal, ListGroup, Button } from "react-bootstrap";
import api from "../../api";
import { DebounceInput } from "react-debounce-input";
import { X } from "react-feather";

const HashTagFilter = ({ onSetFilter, showTags }) => {
  const [showModal, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentTag, setTag] = useState("");

  useEffect(() => {
    api.get(`hashtags?page=1&limit=10`).then((res) => {
      setOptions(res.data && Array.isArray(res.data) ? res.data : []);
    });
  }, []);

  const onTextChange = (e, isInput) => {
    setTag(e);
    if (isInput) {
      api.get(`hashtags?page=1&limit=10&filter=${e}`).then((res) => {
        setOptions(res.data && Array.isArray(res.data) ? res.data : []);
      });
    }
  };

  return (
    <React.Fragment>
      <div>
        {showTags ? (
          <div className="badge bg-primary p-1 cursor-pointer">
            <span onClick={() => setShow(true)}>{showTags}</span>
            <X
              className="cursor-pointer ms-1"
              size={14}
              onClick={() => onSetFilter(null)}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setTag("");
              setShow(true);
            }}
            className="cursor-pointer z-50 m-1"
          >
            <FontAwesomeIcon
              title={"Hashtag filter"}
              icon={faHashtag}
              size={"lg"}
              color={"#293042"}
              className="text-white bg-black mx-1"
              fixedWidth
            />
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter by Hashtag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DebounceInput
            className="hashtag-input text-gray"
            minLength={0}
            debounceTimeout={300}
            value={currentTag}
            onChange={(event) => onTextChange(event.target.value, true)}
          />
          <ListGroup className="mt-2">
            {options.map((item, index) => (
              <ListGroup.Item
                onClick={(event) => {
                  event.stopPropagation();
                  onTextChange(item?.hashstring);
                }}
                key={index}
                className="cursor-pointer list-group-hover"
              >
                {item?.hashstring}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onSetFilter(currentTag);
              setShow(false);
            }}
            variant="primary"
          >
            Filter
          </Button>
          <Button
            className="ms-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default HashTagFilter;
