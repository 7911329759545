import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Card,
  Form,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { AlertCircle } from "react-feather";
import BulkArchiveResults from "./BulkArchiveResults";

const BulkArchiveBody = (props) => {
  const { apiName, onSuccess } = props;
  const [bulkArchiveResults, setBulkArchiveResults] = useState(null);
  const [waitingForResult, setWaitingForResult] = useState(false);
  const formRef = useRef();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [],
      "application/csv": [],
      "text/x-csv": [],
      "application/x-csv": [],
      "text/comma-separated-values": [],
      "text/x-comma-separated-values": [],
    },
  });

  const notify = useContext(NotyfContext);

  const files = acceptedFiles.map((file) => file);

  useEffect(() => {
    formRef.current.setFieldValue("csv", files);
  }, [files]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        <p>Serial,MISDN</p>
        <p>Serial,MISDN</p>
        <p>Serial,MISDN</p>
        <p>
          If there's no MISDN, leave the comma after Serial in place (including
          the last record) e.g.:
        </p>
        <p>Serial,</p>
        <p>Serial,</p>
        <p>Serial,</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Card>
        <Card.Body className="p-0">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              unlinkDevices: false,
              archiveDevices: true,
              dryRun: true,
              csv: null,
              submit: false,
            }}
            validationSchema={Yup.object()
              .shape({
                // customer: Yup.string()
                //     .max(255)
                //     .required("Customer is required"),
              })
              .when((values, schema) => {
                return schema.shape({
                  csv: Yup.mixed().test(
                    "fileSize",
                    "File is required",
                    (file) => {
                      if (file && file.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  ),
                });
              })}
            onSubmit={async (values, actions) => {
              let { setErrors, setStatus, setSubmitting } = actions;
              setSubmitting(true);
              let config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };
              const data = new FormData();
              data.append("UnlinkDevices", values.unlinkDevices);
              data.append("ArchiveDevices", values.archiveDevices);
              data.append("DryRun", values.dryRun);
              data.append("Csv", values.csv[0]);
              try {
                setWaitingForResult(true);
                api
                  .post(`${apiName}/bulkarchive`, data, config)
                  .then((res) => {
                    if (values.dryRun) {
                      notify.open({
                        type: "success",
                        message: "Bulk archive verified",
                      });
                      setBulkArchiveResults(res.data);
                    } else {
                      notify.open({
                        type: "success",
                        message: "Bulk archive complete",
                      });
                      setBulkArchiveResults(res.data);
                    }
                    // props.setAsset && props.setAsset(res.data);
                  })
                  .finally(() => {
                    setSubmitting(false);
                    setWaitingForResult(false);
                  });
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                setWaitingForResult(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  <Form.Group>
                    <Form.Label>
                      Format
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="right"
                        overlay={popover}
                      >
                        <AlertCircle
                          className="text-primary ms-2"
                          size={"16"}
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <div
                      {...getRootProps({ className: "dropzone form-upload" })}
                    >
                      {values.csv && values.csv.length > 0 && (
                        <aside>{values.csv[0].name}</aside>
                      )}
                      <input name="csv" {...getInputProps()} />
                      {(!values.csv || values.csv.length === 0) && (
                        <>
                          <p>Drop a file here, or click to select a file</p>
                          <em>(Only .csv will be accepted)</em>
                        </>
                      )}
                    </div>
                    {errors.csv && touched.csv ? (
                      <div
                        style={{
                          paddingTop: 5,
                          color: "#d9534f",
                          fontSize: ".75rem",
                        }}
                      >
                        {errors.csv}
                      </div>
                    ) : null}
                  </Form.Group>

                  <br />

                  <Form.Group className="mb-3">
                    <Form.Check
                      size="lg"
                      type="switch"
                      id="unlinkDevices"
                      label="Unlink Devices"
                      checked={values.unlinkDevices}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      size="lg"
                      type="switch"
                      id="archiveDevices"
                      label="Archive Devices"
                      checked={values.archiveDevices}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <div className="mt-3">
                    {!waitingForResult && values.dryRun && (
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={waitingForResult}
                      >
                        Verify
                      </Button>
                    )}
                  </div>
                  {waitingForResult && <Spinner />}
                </Form>
                <BulkArchiveResults
                  results={bulkArchiveResults}
                  dryRun={values.dryRun}
                  onConfirm={() => {
                    setBulkArchiveResults(null);
                    if (values.dryRun) {
                      setFieldValue("dryRun", !values.dryRun);
                      handleSubmit();
                    } else {
                      onSuccess && onSuccess();
                    }
                  }}
                  onCancel={() => {
                    setBulkArchiveResults(null);
                  }}
                />
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export default BulkArchiveBody;
