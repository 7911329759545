import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import api from "../../api";
import { getName } from "../../utils/staticMethods";
import useAuth from "../../hooks/useAuth";
import { fieldNameToAssetName } from "../fields/fieldsHelper";
import ElementRootFields from "../fields/ElementRootFields";
import ElementCustomFields from "../fields/ElementCustomFields";
import ElementDefaultFields from "../fields/ElementDefaultFields";

const NewObject = (props) => {
  const { showModal, closeModal, apiName, onSuccess } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const [customFieldConfig, setCustomFieldConfig] = useState(null);
  const [schema, setSchema] = useState({});

  useEffect(() => {
    if (user && apiName) {
      if (user.role === "Root") {
        // Not used as Root shouldn't create elements
        // api.get(`${apiName || "assets"}/customfieldconfig/0`).then((res) => {
        //   setCustomFieldConfig(res.data);
        // });
      } else {
        switch (apiName) {
          case "assets":
            setCustomFieldConfig(user.assetFieldConfig);
            break;

          case "people":
            setCustomFieldConfig(user.personFieldConfig);
            break;

          case "zones":
            setCustomFieldConfig(user.zoneFieldConfig);
            break;

          default:
            setCustomFieldConfig(user.assetFieldConfig);
            break;
        }
      }
    }
  }, [user, apiName]);

  useEffect(() => {
    if (!customFieldConfig) return;

    setSchema(
      customFieldConfig.customFields.reduce((previous, value) => {
        let s = Yup.string()
          .max(255)
          .matches(
            /^[^#]*$/,
            "'#' is a protected character used for hashtag searches and cannot be used here"
          );
        if (value.mandatory) {
          s = s.required(value.displayName + " is required");
        }
        let propertyName = fieldNameToAssetName(value.fieldName);
        // previous = { ...previous};
        previous[propertyName] = s;
        return previous;
      }, {})
    );
  }, [customFieldConfig]);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`New ${getName(apiName)}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="shadow-none">
          <Card.Body>
            <Formik
              enableReinitialize
              initialValues={{
                submit: false,
                primaryName: "",
                secondaryName: "",
                reference: "",
                fleetNumber: "",
                category: "",
                suppString1: "",
                suppString2: "",
                suppString3: "",
                suppString4: "",
                suppString5: "",
              }}
              validationSchema={Yup.object().shape(schema)}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  api
                    .post(apiName, {
                      primaryName: values.primaryName,
                      secondaryName: values.secondaryName,
                      reference: values.reference,
                      fleetNumber: values.fleetNumber,
                      category: values.category,
                      suppString1: values.suppString1,
                      suppString2: values.suppString2,
                      suppString3: values.suppString3,
                      suppString4: values.suppString4,
                      suppString5: values.suppString5,
                    })
                    .then((res) => {
                      if (onSuccess) {
                        onSuccess(res.data.id);
                      } else {
                        navigate(`/${apiName}/edit?id=${res.data.id}`);
                      }
                    });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  {customFieldConfig && customFieldConfig.enabled ? (
                    user.role === "Root" ? (
                      <ElementRootFields
                        {...props}
                        customFieldConfig={customFieldConfig}
                        mode={"new"}
                      />
                    ) : (
                      <ElementCustomFields
                        {...props}
                        customFieldConfig={customFieldConfig}
                        mode={"new"}
                      />
                    )
                  ) : (
                    <ElementDefaultFields {...props} mode={"new"} />
                  )}

                  <div className="mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default NewObject;
