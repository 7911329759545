import React, { useContext, useRef, useState } from "react";
import Widgets from "./../../components/widgets/index";
import { globalConfig } from "../../config";
import api from "../../api";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { Formik } from "formik";
import NotyfContext from "../../contexts/NotyfContext";

const WidgetTabs = ({
  data,
  id,
  onUpdate,
  isProfile,
  apiName = "customers/widgets",
}) => {
  const options =
    apiName.indexOf("assetwidgets") > -1
      ? globalConfig.assetWidgets
      : globalConfig.widgetsConfig.concat(globalConfig.reportWidgets);
  const [widget, setWidget] = useState(null);
  const [show, setShow] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const notify = useContext(NotyfContext);

  const onGetOne = (item) => {
    setWidget(item);
    setShow("edit");
  };

  const handleSort = (index1, index2) => {
    if (index1 === index2) return;
    let obj1 = JSON.parse(JSON.stringify(data[index1]));
    let obj2 = JSON.parse(JSON.stringify(data[index2]));
    obj1.position = index2;
    obj2.position = index1;
    if (isProfile) {
      obj1.permissionId = id;
      obj2.permissionId = id;
    } else {
      obj1.customerId = id;
      obj2.customerId = id;
    }
    setLoading(true);
    api.put(`${apiName}`, obj1).then(() => {
      api.put(`${apiName}`, obj2).then(() => {
        onUpdate && onUpdate();
        setLoading(false);
      });
    });
  };

  const onDelete = () => {
    if (!widget) return;
    api.delete(`${apiName}/${widget.id}`).then((r) => {
      setShowDelete(false);
      setWidget(null);
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      onUpdate && onUpdate();
    });
  };

  return (
    <React.Fragment>
      <Modal show={show} size={"lg"} onHide={() => setShow(null)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {show === "create" ? "New Tile" : "Edit Tile"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              name: widget?.name || "",
              width: widget?.width || 50,
              position: widget?.position || 0,
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("tile is required"),
              width: Yup.number().required("width is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (widget?.id) {
                  let filter = data.filter(
                    (item) => item.name === values.name && item.id !== widget.id
                  );
                  if (filter.length > 0) {
                    notify.open({
                      type: "error",
                      message: "Tile already exists",
                    });
                    return;
                  }
                  let obj = {
                    id: widget.id,
                    name: values.name,
                    position: values.position,
                    width: values.width,
                  };
                  if (isProfile) {
                    obj.permissionId = id;
                  } else {
                    obj.customerId = id;
                  }
                  api.put(`${apiName}`, obj).then((r) => {
                    setShow(null);
                    setWidget(null);
                    onUpdate && onUpdate();
                    notify.open({
                      type: "success",
                      message: "Changes Saved",
                    });
                  });
                } else {
                  if (data) {
                    let filter = data.filter(
                      (item) => item.name === values.name
                    );
                    if (filter.length > 0) {
                      notify.open({
                        type: "error",
                        message: "Tile already exists",
                      });
                      return;
                    }
                  }
                  let obj = {
                    name: values.name,
                    width: values.width,
                  };
                  if (data && data.length > 1) {
                    let max = 0;
                    data.forEach((item) => {
                      if (item.position > max) max = item.position;
                    });
                    obj.position = max + 1;
                  } else {
                    obj.position = 0;
                  }
                  if (isProfile) {
                    obj.permissionId = id;
                  } else {
                    obj.customerId = id;
                  }
                  api.post(`${apiName}`, obj).then((r) => {
                    setShow(null);
                    setWidget(null);
                    onUpdate && onUpdate();
                    notify.open({
                      type: "success",
                      message: "Changes Saved",
                    });
                  });
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Select a Tile:</Form.Label>
                  <div className="d-flex flex-wrap tiles-selector">
                    {options.map((item) => (
                      <div
                        key={item.name}
                        onClick={() => setFieldValue("name", item.name)}
                        className={`${
                          values.name === item.name ? "is-active" : ""
                        } d-flex flex-column p-2 m-1 cursor-pointer option-item-hover`}
                      >
                        <span>{item.label}</span>
                        <img
                          alt={"widgets"}
                          className="w-35 mt-1"
                          src={
                            require("./../../assets/img/widgets/" +
                              options.filter((o) => o.name === item.name)[0]
                                .src).default
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {!!touched.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Width(0-100):</Form.Label>
                  <Form.Select
                    isInvalid={Boolean(touched.width && errors.width)}
                    name="width"
                    label="width"
                    value={values.width}
                    size={"lg"}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value={25} label={"25%"} />
                    <option value={30} label={"30%"} />
                    <option value={50} label={"50%"} />
                    <option value={75} label={"75%"} />
                    <option value={100} label={"100%"} />
                  </Form.Select>
                  {!!touched.width && (
                    <Form.Control.Feedback type="invalid">
                      {errors.width}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              formRef.current?.handleSubmit();
            }}
          >
            Save
          </Button>
          {show === "edit" && (
            <Button
              onClick={() => {
                setShow(false);
                setShowDelete(true);
              }}
              variant={"danger"}
            >
              Delete
            </Button>
          )}
          <Button
            variant={"secondary"}
            onClick={() => {
              setShow(null);
              setWidget(null);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{`Are you sure that you wish to permanently delete this tile?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} onClick={() => onDelete()}>
            Delete
          </Button>
          <Button variant={"secondary"} onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end mb-4">
        <Button
          variant="primary"
          onClick={() => {
            setWidget(null);
            setShow("create");
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          Create New
        </Button>
      </div>
      {data && data.length > 0 ? (
        <Widgets
          apiName={apiName}
          loading={loading}
          switchPlace={handleSort}
          onGetOne={onGetOne}
          widgets={data}
        />
      ) : (
        <div>No tiles added</div>
      )}
    </React.Fragment>
  );
};
export default WidgetTabs;
