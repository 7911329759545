import React, { useEffect, useState } from "react";
import api from "../../api";
import moment from "moment-timezone";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

let timer = null;

const SrlTimeLine = ({ id, name, onGetAttendance, apiName }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getAttendance();
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    timer = setInterval(() => {
      getAttendance();
    }, 5000);
  }, [id]);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
      setList([]);
    };
  }, []);

  const getAttendance = () => {
    api
      .get(`${apiName}/attendances/` + id + "?limit=10")
      .then((res) => {
        setList(res.data);
        onGetAttendance && onGetAttendance(res.data);
      })
      .catch(() => {
        setList([]);
        onGetAttendance && onGetAttendance([]);
      });
  };

  // const getDuration = (time) => {
  //   if(time === 0) return 'Unknown'
  //   let currentTime = moment.duration(time, "seconds");
  //   if (currentTime.days() > 0) {
  //     let hours = currentTime.days() * 24 + currentTime.hours()
  //     let min = currentTime.minutes()
  //     return hours + ':' + min
  //   }
  //   return moment({
  //     h: currentTime.hours(),
  //     m: currentTime.minutes(),
  //     s: currentTime.seconds()
  //   }).format("HH:mm");
  // };

  const isSameDay = (start, end) => {
    if (end.indexOf("0001-01-01") > -1) return true;
    return start.split("T")[0] === end.split("T")[0];
  };
  return (
    <React.Fragment>
      {list.length > 0 ? (
        <ul className="timeline">
          {list.map((item, key) => (
            <li
              className="timeline-item ps-5 pb-5 border-bottom mt-3"
              key={key}
            >
              <h3 className="mb-1">
                <NavLink
                  to={{
                    pathname: `/${
                      item.parentType === "asset" ? "assets" : "zones"
                    }/edit`,
                    search: `?id=${item.parentId}`,
                  }}
                  className="link-primary"
                >
                  {item.parentName}
                </NavLink>
                <span className="ms-1">
                  {isSameDay(item.start, item.end)
                    ? moment(item.start).format("DD/MM/YY")
                    : `${moment(item.start).format("DD/MM/YY")} - ${moment(
                        item.end
                      ).format("DD/MM/YY")}`}
                </span>
              </h3>
              {item.parentHashTags &&
                item.parentHashTags.map((item1) => (
                  <div key={item1} className="ms-1 badge bg-primary me-1">
                    {item1}
                  </div>
                ))}
              <div className="mt-1">
                <FontAwesomeIcon icon={faSignInAlt} fixedWidth />
                <span className="ms-1">Arrived:</span>
                <span>&nbsp; {moment(item.start).format("HH:mm")}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
                <span className="ms-1">Departed:</span>
                <span>
                  &nbsp;{" "}
                  {item.end.indexOf("0001-01-01") > -1
                    ? "Missed"
                    : moment(item.end).format("HH:mm")}
                </span>
              </div>
              <div>
                <FontAwesomeIcon icon={faClock} fixedWidth />
                <span className="ms-1">Duration:</span>
                <span>
                  &nbsp;
                  {moment
                    .duration(moment(item.start).diff(moment(item.end)))
                    .humanize()}
                </span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>
          There's no attendance history to show yet. Attendances are created
          when people or assets spend time within zones. This is done
          automatically when a GPS tracker moves through a 'Geofence' or when a
          tag is seen by a reader assigned to a zone.
        </p>
      )}
      <div className="d-flex justify-content-end">
        {list.length >= 5 && (
          <NavLink
            to={{
              pathname: "/report/attendance",
              search: `?id=${id}&name=${encodeURIComponent(
                name
              )}&type=${apiName}`,
            }}
          >
            <Button>View All</Button>
          </NavLink>
        )}
      </div>
    </React.Fragment>
  );
};
export default SrlTimeLine;
