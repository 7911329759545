import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import api from "../../api";
import moment from "moment-timezone";
import LoadingSpin from "../loadingSpin";
import { Maximize } from "react-feather";
import { useNavigate } from "react-router-dom";
import usePalette from "../../hooks/usePalette";

const AssetBatteryExchanges = ({ assetId, asset }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const palette = usePalette();

  useEffect(() => {
    getData();
  }, [assetId]);

  const getData = () => {
    setLoading(true);
    let labelArr = ["This week", "This month", "This year"];
    let dataArr = [];
    let avgArr = [];
    const apiArr = [
      api.get(
        `assets/utilisationhours/${assetId}?startDate=${moment()
          .startOf("week")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("week")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `assets/utilisationhours/${assetId}?startDate=${moment()
          .startOf("month")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("month")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `assets/utilisationhours/${assetId}?startDate=${moment()
          .startOf("year")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("year")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `reports/utilisationhours?startDate=${moment()
          .startOf("week")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("week")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `reports/utilisationhours?startDate=${moment()
          .startOf("month")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("month")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
      api.get(
        `reports/utilisationhours?startDate=${moment()
          .startOf("year")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}&endDate=${moment()
          .endOf("year")
          .endOf("days")
          .format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}`
      ),
    ];
    let isEmpty = true;
    Promise.all(apiArr)
      .then((r) => {
        r.forEach((item, index) => {
          if (Number(item.data) > 0) isEmpty = false;
          if (index <= 2) {
            dataArr.push((Number(item.data) / 3600).toFixed(1));
          } else {
            avgArr.push((Number(item.data) / 3600).toFixed(1));
          }
        });
        let data = {
          type: "bar",
          labels: labelArr,
          datasets: [
            {
              label: "asset",
              data: dataArr,
              backgroundColor: palette.primary,
              barThickness: 20,
            },
            {
              label: "base avg",
              data: avgArr,
              backgroundColor: palette.black,
              barThickness: 20,
            },
          ],
        };
        setResult(isEmpty ? null : data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const options = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        boxWidth: 12,
        fontColor: "#A9ACB3",
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return tooltipItem.value + " hours";
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            fontColor: "#A9ACB3",
            fontSize: 12,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card className="border flex-grow-1">
        <Card.Header className="pb-0">
          <Card.Title>
            <div className="d-flex justify-content-between align-items-center">
              <span>Asset Utilisation Hours</span>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-1">Expand</Tooltip>}
              >
                <Maximize
                  size={18}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/deployments?id=${assetId}&name=${encodeURIComponent(
                        asset.compoundName
                      )}`
                    )
                  }
                />
              </OverlayTrigger>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-1" style={{ minHeight: "200px" }}>
          {!result && !loading && (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              Waiting for data
            </div>
          )}
          {!loading && result && (
            <Bar data={result} height={200} options={options} />
          )}
          {loading && (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center">
              <LoadingSpin loading={loading} />
            </div>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
export default AssetBatteryExchanges;
