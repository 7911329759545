import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ExportButton = ({ title, count, onClick }) => {
  const countNumber = Number(count);
  const countLimit = 15000;
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        className="me-1 mb-1 mb-sm-0"
        onClick={() => {
          setShow(true);
        }}
        variant={"primary"}
        disabled={countNumber <= 0}
      >
        {title}
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {countNumber <= countLimit && (
          <Modal.Body>
            This will create a CSV export file for download. This will be done
            in the background, and may take some time, depending on the size of
            the export. The export will appear in your browser downloads when
            complete.
          </Modal.Body>
        )}
        {countNumber > countLimit && (
          <Modal.Body>
            Too many records to export in one file. Please select less records
            by refining your search, or by selecting a smaller date range.
          </Modal.Body>
        )}
        <Modal.Footer>
          {countNumber <= countLimit && (
            <Button
              onClick={(e) => {
                setShow(false);
                onClick(e);
              }}
              variant={"primary"}
            >
              {title}
            </Button>
          )}
          <Button onClick={() => setShow(false)} variant={"secondary"}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportButton;
