import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";

import AssetHasTag from "../../components/AssetHasTag";
import DeviceTracker from "../../components/detail/DeviceTracker";
import DeviceTag from "../../components/detail/DeviceTag";
import Notes from "../../components/detail/Notes";
import { AlertTriangle } from "react-feather";
import CommonTable from "../../components/tables/CommonTable";
import Diagnostics from "../../components/detail/Diagnostics";
import FavouriteModal from "../../components/favourite/FavouriteModal";
import Calendar from "../../components/calendar/Calendar";
import useAuth from "../../hooks/useAuth";
import AddExistingModal from "../../pages/rules/AddExistingModal";
import QRCodeComponent from "../../components/detail/QRCodeComponent";
import { filterStorage } from "../../utils/staticMethods";
import RootTopBar from "../../components/navbar/RootTopBar";
import axios from "axios";
import DeviceCharger from "../../components/detail/DeviceCharger";
import ChargerInfo from "../../components/ChargerInfo";
import SignalMonitoring from "../../components/SignalMonitoring";
import WeatherBar from "../../components/WeatherBar";
import StatisticsTab from "../../components/detail/StatisticsTab";
import EditPosition from "../../components/EditPosition";
import DeviceApp from "../../components/detail/DeviceApp";
import { SRL_CATEGORIES } from "../../constants";
import SrlAssetDetails from "../components/SrlAssetDetails";
import SrlLastLocation from "../components/SrlLastLocation";
import SrlLastAttendance from "../components/SrlLastAttendance";
import SrlOverviewTab from "../components/SrlOverviewTab";

const columns = [
  {
    Header: "Name",
    accessor: "ruleName",
    type: "link",
    link: "/rules/edit",
    stateId: "id",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status",
    type: "ruleStatus",
  },
];
let interval;
let source1 = null;
const SrlAssetsEdit = ({ id, direct }) => {
  const location = useLocation();
  const [asset, setAsset] = useState({});
  const [loadLastPosition, setLoadLastPosition] = useState(0);
  const [loadAssetDetails, setLoadAssetDetails] = useState(0);
  const [trackers, setTrackers] = useState(null);
  const [charger, setCharger] = useState(null);
  const [tags, setTags] = useState(null);
  const [tab, setTab] = useState("overview");
  const [apps, setApps] = useState(null);
  const [attendance, setAttendance] = useState(null);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const ruleRef = useRef();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isUnmount = useRef(false);
  const [showEditPosition, setShowEditPosition] = useState(false);
  const [archived, setArchived] = useState(false);
  const [picture, setPicture] = useState(null);
  const [, setCategory] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    getAsset();
    getWidgets();
    if (direct) {
      setTab(direct);
    } else {
      setTab("overview");
    }
    interval && clearInterval(interval);
    interval = setInterval(() => {
      getAsset();
    }, 5000);
  }, [id]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
      isUnmount.current = true;
      if (source1) {
        source1.cancel("request canceled");
      }
    };
  }, []);

  const getChargerReady = (charger) => {
    setCharger(charger);
  };

  useEffect(() => {
    if (asset.id) {
      try {
        let obj = {
          link: location.pathname,
          search: location.search,
          name: `${asset.compoundName} ${
            asset.reference ? `(${asset.reference})` : ""
          }`,
          icon: asset.icon,
          id: asset.id,
        };
        filterStorage(obj);
      } catch (error) {}

      if (asset.lastPosition != null) {
        setLoadLastPosition(1);
      }

      if (asset && asset.type.includes("archived")) {
        setArchived(true);
      } else {
        setArchived(false);
      }

      var category = SRL_CATEGORIES.find((v) => {
        return v.name === asset.category;
      });
      if (category && category.image) {
        setPicture(
          require(`../../assets/img/srl/profile/${category.image}`).default
        );
      }
      setCategory(category);

      setLoadAssetDetails(1);
    }
  }, [asset]);

  const getAsset = () => {
    if (source1) {
      source1.cancel("request canceled");
    }
    source1 = axios.CancelToken.source();
    api
      .get(`assets/` + id, {
        cancelToken: source1.token,
      })
      .then((res) => {
        if (isUnmount.current) return;
        setAsset(res.data);
      });
  };

  const getWidgets = () => {
    api.get(`assets/widgets/${id}`).then((r) => {
      if (r.data && r.data.length > 0) {
        setWidgets(r.data.sort(sortArray));
      }
    });
  };

  const sortArray = (a, b) => {
    return a?.position - b?.position;
  };

  const onGetAttendance = (list) => {
    setAttendance(list);
  };

  const getTrackerReady = (list) => {
    setTrackers(list);
  };

  const getTagReady = (list) => {
    setTags(list);
  };

  const getAppReady = (list) => {
    setApps(list);
  };

  const handleUpdateData = () => {
    getAsset();
  };
  const onTabChange = (e) => {
    setTab(e);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={asset.category || "Asset"}></Helmet>
      <RootTopBar data={asset} />
      <Container fluid className="p-0">
        {asset.category && (
          <h1 className="h3 mb-3">
            {`${asset.category || "Asset"} ${archived ? "(archived)" : ""}`}
            {!archived && <FavouriteModal id={asset.id} type="assets" />}
          </h1>
        )}
        <Row>
          <Col md="4" xxl="3">
            <Card className="mb-0 border-top">
              <Card.Body>
                <div>
                  Asset Owner: <b>{asset.suppString1}</b>
                </div>
                <div>
                  SRL Fleet Number: <b>{asset.fleetNumber}</b>
                </div>
                <div>
                  Customer Fleet Number: <b>{asset.suppString2}</b>
                </div>
                {picture && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      boxAlign: "center",
                      alignItems: "center",
                      boxPack: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img alt="profile" className="w-100" src={picture} />
                    {archived && (
                      <img
                        src={process.env.PUBLIC_URL + "/img/Archived.png"}
                        alt="Archived"
                        style={{
                          position: "absolute",
                          left: "0px",
                          width: "100%",
                          zIndex: "1",
                          opacity: "0.6",
                        }}
                      />
                    )}
                  </div>
                )}
                {!picture && archived && (
                  <img
                    src={process.env.PUBLIC_URL + "/img/Archived.png"}
                    alt="Archived"
                    style={{ width: "100%" }}
                  />
                )}
              </Card.Body>
            </Card>
            <Card className="mb-0 border-top">
              <Card.Body>
                <h6>CUSTOMER REFERENCE</h6>
                <div>{asset.primaryName ?? ""}</div>
              </Card.Body>
            </Card>
            {loadLastPosition ? (
              <SrlLastLocation profile={asset} apiName="assets" />
            ) : null}
            {trackers &&
              trackers.length > 0 &&
              trackers[0].showSignalMonitoring && (
                <SignalMonitoring asset={asset} tracker={trackers[0]} />
              )}
            {charger && <ChargerInfo id={charger.id} />}
            {asset.lastAttendance ? (
              <SrlLastAttendance profile={asset} />
            ) : null}
            {loadLastPosition ? <WeatherBar data={asset.lastPosition} /> : null}
            <AssetHasTag
              apiName="assets"
              handleUpdateData={handleUpdateData}
              id={id}
              hashTags={asset.hashTags}
            />

            {user?.role === "Admin" &&
              asset &&
              asset.contentsCount === 0 &&
              !archived && (
                <div className="border-top p-3 bg-white">
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setShowEditPosition(true)}
                  >
                    Update Location
                  </span>
                </div>
              )}
          </Col>
          <Col md="8" xxl="9" className="mt-3 mt-sm-0">
            <div className="tab">
              <Tab.Container
                id="left-tabs-example"
                activeKey={tab}
                onSelect={(e) => onTabChange(e)}
              >
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Overview</Nav.Link>
                  </Nav.Item>
                  {widgets && asset && (
                    <Nav.Item>
                      <Nav.Link eventKey="statistics">Statistics</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                  {(!archived || user?.role === "Root") && (
                    <Nav.Item>
                      <Nav.Link eventKey="devices">Devices</Nav.Link>
                    </Nav.Item>
                  )}
                  {user?.role !== "Root" && (
                    <Nav.Item>
                      <Nav.Link eventKey="calendar">Calendar</Nav.Link>
                    </Nav.Item>
                  )}
                  {!archived && (
                    <Nav.Item>
                      <Nav.Link eventKey="diagnostics">Diagnostics</Nav.Link>
                    </Nav.Item>
                  )}
                  {user?.role !== "Root" && !archived && (
                    <Nav.Item>
                      <Nav.Link eventKey="rules">Rules</Nav.Link>
                    </Nav.Item>
                  )}
                  {user?.viewNotes && user?.role !== "Root" && (
                    <Nav.Item>
                      <Nav.Link eventKey="notes">Notes</Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <SrlOverviewTab
                      tab={tab}
                      apiName={"assets"}
                      data={asset}
                      onGetAttendance={onGetAttendance}
                      trackers={trackers}
                      tags={tags}
                      id={id}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="statistics">
                    <StatisticsTab
                      tab={tab}
                      id={id}
                      data={widgets}
                      asset={asset}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="details">
                    {loadAssetDetails ? (
                      <SrlAssetDetails
                        apiName="assets"
                        type="assets"
                        asset={asset}
                        setAsset={setAsset}
                      />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="devices">
                    <DeviceTracker
                      id={id}
                      getTrackerReady={getTrackerReady}
                      type="assets"
                      data={asset}
                    />
                    <DeviceTag
                      getTagReady={getTagReady}
                      id={id}
                      type="assets"
                      data={asset}
                    />
                    <DeviceApp
                      getAppReady={getAppReady}
                      id={id}
                      type="assets"
                      data={asset}
                    />
                    {(user?.showSolarMonitoring || user?.role === "Root") && (
                      <DeviceCharger
                        getChargerReady={getChargerReady}
                        id={id}
                        type="assets"
                        data={asset}
                      />
                    )}
                    <QRCodeComponent
                      value={id}
                      type={"Asset"}
                      icon={asset.icon}
                      name={asset.compoundName}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="calendar">
                    {asset.id && (
                      <Calendar
                        attendance={attendance}
                        id={asset.id}
                        type={"assets"}
                        data={asset}
                        tab={tab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diagnostics">
                    {asset.id && (
                      <Diagnostics
                        tags={tags}
                        root
                        trackers={trackers}
                        apps={apps}
                        id={id}
                        type={1}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="rules">
                    <div className="d-flex justify-content-between mb-3">
                      <h4 className="text-nowrap me-1">
                        <AlertTriangle size={18} /> Rules
                      </h4>
                      <div>
                        {user?.editAssets && (
                          <Button
                            onClick={() => setShowRuleModal(true)}
                            className="me-1"
                            variant="success"
                          >
                            Add to Existing
                          </Button>
                        )}
                        {user?.editAssets && user?.createRules && (
                          <Button
                            onClick={() =>
                              navigate(`/rules/create?link=assets&linkId=${id}`)
                            }
                          >
                            Create New
                          </Button>
                        )}
                      </div>
                    </div>
                    <CommonTable
                      ref={ruleRef}
                      apiName="rules"
                      parentId={id}
                      columns={columns}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notes">
                    <Notes id={id} type="assets" />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <AddExistingModal
        onHide={() => setShowRuleModal(false)}
        show={showRuleModal}
        apiName={"assets"}
        dataId={id}
        onUpdatedRules={() => {
          ruleRef.current.updateTable(1);
          setShowRuleModal(false);
        }}
      />
      <EditPosition
        apiName={"assets"}
        show={showEditPosition}
        data={asset}
        onHideModal={() => setShowEditPosition(false)}
      />
    </React.Fragment>
  );
};

export default SrlAssetsEdit;
