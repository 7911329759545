import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BulkArchiveBody from "./BulkArchiveBody";

const BulkArchiveButton = (props) => {
  const { apiName } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        className="text-nowrap mx-1"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Bulk Archive
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <DeviceForm setAsset={updateData} customerOption={customerOption} deviceOption={deviceOption} apiName={'trackers'} /> */}
          <BulkArchiveBody
            apiName={apiName}
            onSuccess={() => {
              setShowModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BulkArchiveButton;
