import { Button, Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MultiAssetSearchWidget = ({ name, width }) => {
  const navigate = useNavigate();

  return (
    <>
      <Col
        key={name}
        lg={((width / 100) * 12).toFixed(0)}
        className="position-relative mb-4"
      >
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
          <Card.Title className="p-3">Multi Asset Search</Card.Title>
          <Card.Text className="p-3" as="div">
            <Col>
              <div className="d-flex justify-content-around">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => {
                    navigate("/maps/multiAsset");
                  }}
                >
                  Multi Asset Search
                </Button>
              </div>
            </Col>
          </Card.Text>
        </Card>
      </Col>
    </>
  );
};

export default MultiAssetSearchWidget;
