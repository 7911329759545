import React from "react";

// wizards component
const Wizards = ({ steps, current, status, goBack }) => {
  const clickItem = (index) => {
    if (status[index] === "done") {
      goBack(index);
    }
  };
  return (
    <React.Fragment>
      <ul className="nav-progress nav-wizards mb-3">
        {steps.map((item, index) => (
          <li className="nav-item" key={index} onClick={() => clickItem(index)}>
            <div className={`nav-link-wizard default ${status[index]}`}>
              <div className="num">{index + 1}</div>
              {item.name}
            </div>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};
export default Wizards;
