import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../api";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Card,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import { useDropzone } from "react-dropzone";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import defaultImg from "../assets/img/icon/Aircraft/Airliner_Black.png";
import Icons from "../pages/icons/AssetIcon/Icons";
import { AlertCircle } from "react-feather";
import { getUrl } from "../utils/staticMethods";

const DeviceForm = (props) => {
  const notify = useContext(NotyfContext);

  const formRef = useRef();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/csv": [
        ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
      ],
    },
  });
  const [bulk, setBulk] = useState(false);
  const [showIcons, setShowIcons] = useState(false);

  const files = acceptedFiles.map((file) => file);

  useEffect(() => {
    formRef.current.setFieldValue("csv", files);
  }, [files]);

  const bulkUpdate = (e) => {
    setBulk(e.target.checked);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        <p>Serial,MISDN</p>
        <p>Serial,MISDN</p>
        <p>Serial,MISDN</p>
        <p>
          If there's no MISDN, leave the comma after Serial in place (including
          the last record) e.g.:
        </p>
        <p>Serial,</p>
        <p>Serial,</p>
        <p>Serial,</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <React.Fragment>
      <Card className="shadow-none">
        <Card.Body>
          {props.apiName !== "qrcodes" && (
            <div className="d-flex justify-content-end">
              <Form.Check
                type="switch"
                label="Bulk Upload"
                checked={bulk}
                onChange={(e) => bulkUpdate(e)}
              />
            </div>
          )}
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              isBulk: bulk,
              customer: props.asset?.customer || "",
              deviceProfile: props.asset?.deviceProfile || "",
              defaultAsset: props.asset?.defaultAsset || false,
              defaultPerson: props.asset?.defaultPerson || false,
              defaultZone: props.asset?.defaultZone || false,
              serial: props.asset?.serial || "",
              count: props.asset?.count || 0,
              misdn: props.asset?.misdn || "",
              deviceName: props.asset?.deviceName || "",
              csv: null,
              defaultIcon: props.asset?.defaultIcon || "placeholder.png",
              submit: false,
            }}
            validationSchema={Yup.object()
              .shape({
                customer: Yup.string()
                  .max(255)
                  .required("Customer is required"),
              })
              .when((values, schema) => {
                if (!bulk) {
                  if (props.apiName === "qrcodes") {
                    return schema.shape({});
                  } else if (props.apiName !== "solarchargers") {
                    return schema.shape({
                      deviceProfile: Yup.string().required(
                        "Device Profile is required"
                      ),
                      serial: Yup.string().required("Serial is required"),
                    });
                  }
                  return schema.shape({
                    serial: Yup.string().required("Serial is required"),
                  });
                } else {
                  if (props.apiName === "readers") {
                    return schema.shape({
                      csv: Yup.mixed().test(
                        "fileSize",
                        "File is required",
                        (file) => {
                          if (file && file.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      ),
                    });
                  }
                  return schema.shape({
                    csv: Yup.mixed().test(
                      "fileSize",
                      "File is required",
                      (file) => {
                        if (file && file.length > 0) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    ),
                  });
                }
              })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              if (!bulk) {
                let data = {
                  customerId: values.customer,
                  defaultIcon: values.defaultIcon,
                  serial: values.serial,
                };
                if (
                  props.apiName !== "solarchargers" &&
                  props.apiName !== "qrcodes"
                ) {
                  data.deviceProfileId = values.deviceProfile;
                  data.misdn = values.misdn;
                }
                if (props.apiName === "readers" || props.apiName === "tags") {
                  data.deviceName = values.deviceName;
                }
                if (props.apiName === "readers") {
                  data.defaultZone = values.defaultZone;
                } else {
                  data.defaultAsset = values.defaultAsset;
                  data.defaultPerson = values.defaultPerson;
                }
                if (props.apiName === "qrcodes") {
                  data.count = values.count;
                  data.defaultZone = values.defaultZone;
                }
                try {
                  api.post(`${props.apiName}`, data).then((res) => {
                    notify.open({
                      type: "success",
                      message: "Changes Saved",
                    });
                    props.setAsset && props.setAsset(res.data);
                  });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              } else {
                let config = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                };
                const data = new FormData();
                data.append("CustomerId", values.customer);
                data.append("DeviceProfileId", values.deviceProfile);
                data.append("DefaultAsset", values.defaultAsset);
                data.append("DefaultPerson", values.defaultPerson);
                data.append("DefaultZone", values.defaultZone);
                data.append("DefaultIcon", values.defaultIcon);
                data.append("Csv", values.csv[0]);
                try {
                  api
                    .post(`${props.apiName}/bulk`, data, config)
                    .then((res) => {
                      notify.open({
                        type: "success",
                        message: "Changes Saved",
                      });
                      props.setAsset && props.setAsset(res.data);
                    });
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Customer</Form.Label>
                  <Form.Select
                    size="lg"
                    type="text"
                    name="customer"
                    value={values.customer}
                    isInvalid={Boolean(touched.customer && errors.customer)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option
                      value=""
                      disabled
                      style={{ display: "none" }}
                    ></option>
                    {props.customerOption &&
                      props.customerOption.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  {!!touched.customer && (
                    <Form.Control.Feedback type="invalid">
                      {errors.customer}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {props.apiName !== "solarchargers" &&
                  props.apiName !== "qrcodes" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Device Profile</Form.Label>
                      <Form.Select
                        size="lg"
                        type="text"
                        name="deviceProfile"
                        value={values.deviceProfile}
                        isInvalid={Boolean(
                          touched.deviceProfile && errors.deviceProfile
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option
                          value=""
                          disabled
                          style={{ display: "none" }}
                        ></option>
                        {props.deviceOption &&
                          props.deviceOption.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.productName}
                            </option>
                          ))}
                      </Form.Select>
                      {!!touched.deviceProfile && (
                        <Form.Control.Feedback type="invalid">
                          {errors.deviceProfile}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}

                {!bulk && props.apiName !== "qrcodes" && (
                  <Form.Group className="mb-3">
                    <Form.Label>Serial</Form.Label>
                    <Form.Control
                      type="text"
                      name="serial"
                      value={values.serial}
                      isInvalid={Boolean(touched.serial && errors.serial)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.serial && (
                      <Form.Control.Feedback type="invalid">
                        {errors.serial}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
                {!bulk && props.apiName === "qrcodes" && (
                  <Form.Group className="mb-3">
                    <Form.Label>Count</Form.Label>
                    <Form.Control
                      type="number"
                      name="count"
                      value={values.count}
                      isInvalid={Boolean(touched.count && errors.count)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.count && (
                      <Form.Control.Feedback type="invalid">
                        {errors.count}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
                {(props.apiName === "readers" || props.apiName === "tags") &&
                  !bulk && (
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        size="lg"
                        name="deviceName"
                        label="deviceName"
                        type="text"
                        value={values.deviceName}
                        isInvalid={Boolean(
                          touched.deviceName && errors.deviceName
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.deviceName && (
                        <Form.Control.Feedback type="invalid">
                          {errors.deviceName}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}

                {!bulk &&
                  props.apiName !== "solarchargers" &&
                  props.apiName !== "qrcodes" && (
                    <Form.Group className="mb-3">
                      <Form.Label>MISDN</Form.Label>
                      <Form.Control
                        type="text"
                        name="misdn"
                        value={values.misdn}
                        isInvalid={Boolean(touched.misdn && errors.misdn)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.misdn && (
                        <Form.Control.Feedback type="invalid">
                          {errors.misdn}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  )}
                <Form.Group className="mb-3">
                  <Form.Label>Default Icon</Form.Label>
                  <div>
                    <img
                      alt={values.defaultIcon}
                      className="profile-icon ms-2 mb-2 cursor-pointer"
                      onClick={() => setShowIcons(!showIcons)}
                      src={
                        imageSet[getUrl(values.defaultIcon)]
                          ? require("./../assets/img/icon/" +
                              imageSet[getUrl(values.defaultIcon)]).default
                          : defaultImg
                      }
                    />
                    {showIcons && (
                      <Icons
                        onSelectedIcon={(value) => {
                          setFieldValue("defaultIcon", `${value}`);
                          setShowIcons(!showIcons);
                        }}
                      />
                    )}
                  </div>
                </Form.Group>

                {props.apiName !== "readers" && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Check
                        size="lg"
                        type="switch"
                        id="defaultAsset"
                        label="Add Default Asset"
                        checked={values.defaultAsset}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    {props.apiName !== "solarchargers" && (
                      <Form.Group className="mb-3">
                        <Form.Check
                          size="lg"
                          type="switch"
                          id="defaultPerson"
                          label="Add Default Person"
                          checked={values.defaultPerson}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )}
                  </>
                )}
                {(props.apiName === "readers" ||
                  props.apiName === "qrcodes") && (
                  <Form.Group className="mb-3">
                    <Form.Check
                      size="lg"
                      type="switch"
                      id="defaultZone"
                      label="Add Default zone"
                      checked={values.defaultZone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {bulk && (
                  <Form.Group>
                    <Form.Label>
                      Format
                      <OverlayTrigger
                        trigger={["hover", "hover"]}
                        placement="right"
                        overlay={popover}
                      >
                        <AlertCircle
                          className="text-primary ms-2"
                          size={"16"}
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <div
                      {...getRootProps({ className: "dropzone form-upload" })}
                    >
                      {values.csv && values.csv.length > 0 && (
                        <aside>{values.csv[0].name}</aside>
                      )}
                      <input name="csv" {...getInputProps()} />
                      {(!values.csv || values.csv.length === 0) && (
                        <>
                          <p>Drop a file here, or click to select a file</p>
                          <em>(Only .csv will be accepted)</em>
                        </>
                      )}
                    </div>
                    {errors.csv && touched.csv ? (
                      <div
                        style={{
                          paddingTop: 5,
                          color: "#d9534f",
                          fontSize: ".75rem",
                        }}
                      >
                        {errors.csv}
                      </div>
                    ) : null}
                  </Form.Group>
                )}

                <div className="mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default DeviceForm;
